import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leaseInformation: {
    landCode: "",
    landType: "",
    landInAcres: null,
    startDate: "",
    endDate: "",
    pricePerYear: null,
    totalPrice: null,
    remarks: "",
    landImage: "",
  },

  farmerInformation: {
    name: "",
    mobileNumber: "",
    address: "",
    pincode: "",
    state: "",
    city: "",
  },
  leaseLandFilters: {
    download: "",
    fromDateStart: "",
    toDateStart: "",
    fromDateEnd: "",
    toDateEnd: "",
    leaseId: "",
  },
  specificLandCropsList: {
    fromStartDate: "",
    fromEndDate: "",
    toStartDate: "",
    toEndDate: "",
  },
  cropsListFilter: {
    fromStartDate: "",
    fromEndDate: "",
    toStartDate: "",
    toEndDate: "",
    landId: "",
  },
  yieldListFilter: {
    fromYieldDate: "",
    toYieldDate: "",
    cropId: "",
    landId: "",
  },
  expenseListFilter: {
    fromExpenseDate: "",
    toExpenseDate: "",
    cropId: "",
    landId: "",
  },
  purchasesList: {
    fromDate: "",
    toDate: "",
  },
  specificLandYieldsList: {
    fromYieldDate: "",
    toYieldDate: "",
    cropId: "",
  },
  specificLandExpensesList: {
    fromExpenseDate: "",
    toExpenseDate: "",
    landId: "",
    cropId: "",
  },
  saleInformationList: {
    fromDate: "",
    toDate: "",
  },
  specificLandSalesListFilter: {
    fromDate: "",
    toDate: "",
  },
};

export const footerManagementSlice = createSlice({
  name: "leaseLand",
  initialState,
  reducers: {
    updateLeaseLandRegistration(state, action) {
      state.leaseInformation = {
        ...state.leaseInformation,
        ...action.payload,
      };
    },
    updateFarmerInformation(state, action) {
      state.farmerInformation = {
        ...state.farmerInformation,
        ...action.payload,
      };
    },
    updateLeaseLandFilter(state, action) {
      state.leaseLandFilters = {
        ...state.leaseLandFilters,
        ...action.payload,
      };
    },
    updateSpecificLandCropList(state, action) {
      state.specificLandCropsList = {
        ...state.specificLandCropsList,
        ...action.payload,
      };
    },
    updateCropsList(state, action) {
      state.cropsListFilter = {
        ...state.cropsListFilter,
        ...action.payload,
      };
    },
    updateYieldListFilter(state, action) {
      state.yieldListFilter = {
        ...state.yieldListFilter,
        ...action.payload,
      };
    },
    updateExpenseListFilter(state, action) {
      state.expenseListFilter = {
        ...state.expenseListFilter,
        ...action.payload,
      };
    },
    updatePurchasesListFilter(state,action) {
      state.purchasesList = {
        ...state.purchasesList,
        ...action.payload
      }
    },
    updateSpecificLandYieldsList(state, action) {
      state.specificLandYieldsList = {
        ...state.specificLandYieldsList,
        ...action.payload,
      };
    },
    updateSpecificLandExpensesList(state, action) {
      state.specificLandExpensesList = {
        ...state.specificLandExpensesList,
        ...action.payload,
      };
    },
    updateSaleInformationListFilter(state,action) {
      state.saleInformationList = {
        ...state.saleInformationList,
        ...action.payload,
      };
    },
    updateSpecificLandSalesListFilter(state, action) {
      state.specificLandSalesListFilter = {
        ...state.specificLandSalesListFilter,
        ...action.payload,
      };
    },
  },
});
