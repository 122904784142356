import FileSaver from "file-saver";
import XLSX from "xlsx";

export const exportToCSV = (props) => {
  console.log(props, "excell");
  const { fileName, downloadData, keys, header, totalDetailsRow } = props;

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const slicedHeaderArray = header
    ?.map((item, index) => item?.title)
    .filter((item, i) => i > 0);

  let headingobj = {};
  keys?.forEach((item, index) => {
    headingobj[item] = slicedHeaderArray[index];
  });

  const Heading = [headingobj];

  let activeData = downloadData;

  const modifiedData = activeData?.map((item) => {
    let newObj = {};
    Object.keys(item).map((key) => {
      if (keys.includes(key)) {
        if (Array.isArray(item[key])) {
          // Join the array elements with a comma and space
          newObj[key] = item[key].join(", ");
        } else {
          newObj[key] = item[key];
        }
      }
    });
    return newObj;
  });

  console.log(modifiedData, "modifiedData export to csv");

  const getMaxLengthForProperty = (modifiedData, prop) => {
    return Math.max(
      ...modifiedData?.map((customer) => String(customer[prop])?.length)
    );
  };

  const wscols = Object?.keys(modifiedData[0] || {})?.map((prop) => ({
    wch: getMaxLengthForProperty(modifiedData, prop) + 20 || 20,
  }));

  const ws = XLSX.utils.json_to_sheet(Heading, {
    header: keys,
    skipHeader: true,
    origin: 0, //ok
  });

  ws["!cols"] = wscols;
  XLSX.utils.sheet_add_json(ws, modifiedData, {
    header: keys,
    skipHeader: true,
    origin: -1,
  });

  // Calculate sums for specific columns
  const sums = {};
  const columnsToSum = totalDetailsRow;

  columnsToSum?.forEach((col) => {
    sums[col] = modifiedData.reduce(
      (sum, item) => sum + (parseFloat(item[col]) || 0),
      0
    );
  });

  // Create the total row
  const totalRow = {};
  keys.forEach((key) => {
    totalRow[key] = columnsToSum?.includes(key) ? sums[key] : "";
  });

  // Append the total row to the worksheet
  XLSX.utils.sheet_add_json(ws, [totalRow], {
    header: keys,
    skipHeader: true,
    origin: modifiedData.length + 1, // append to the end of the data
  });

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
