import connection from "../../assets/connection.svg";
import logo from "../../assets/logo.svg";

const NetworkIssuePage = () => {
  return (
    <div className='bg-white p-[1rem] sm:p-[2rem] h-screen '>
      <div
        onClick={() => {
          window.location.reload();
        }}>
        <img
          alt=''
          className='w-[4rem] lg:w-[6rem] h-auto cursor-pointer'
          src={logo}
        />
      </div>

      <div className='flex flex-col  justify-center items-center h-auto max-h-[80%] grow w-full gap-[1rem]'>
        <img
          className='h-[23rem] w-[23rem] lg:h-[43rem] lg:w-[43rem]'
          src={connection}
        />
      </div>
    </div>
  );
};

export default NetworkIssuePage;
