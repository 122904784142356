import { Icon } from "@iconify/react";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { RiDeleteBinLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { AdminAuthorURL } from "../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../stores/AuthContext";
import { customStyles } from "../../../../helpers/CustomDataTableStyles";
import { exportToCSV } from "../../../../helpers/ExcelSheetFunction";
import PdfDownload from "../../../../helpers/PdfDownload";
import { MilkPriceRevisionFilter } from "../../../../helpers/ReportFilteration";
import LvreddyLoader from "../../../../loaders/LvreddyLoader";
import CustomPagination from "./../../../animalModule/animalManageLists/CustomPagination";
import { queryClient } from "../../../../../App";

export default function MilkPriceRevision() {
  const { id } = useParams();

  const [vendorPaymentsData, setVendorPaymentsData] = useState([]);
  const [totalPaymentsData, setTotalPaymentsData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [keyword, setKeyword] = useState(""); // search keyword
  const [page, setPage] = useState(1);

  const [date, setDate] = useState(""); // search date
  const [showError, setShowError] = useState(false); // show error message
  const [showAddForm, setShowAddForm] = useState(false);

  const [newDate, setNewDate] = useState("");
  const [newSession, setNewSession] = useState("");
  const [totalLitres, setTotalLitres] = useState("");
  const [cost, setCost] = useState("");
  const { fromDate, toDate } = useSelector(
    (state) => state.filterManage?.milkPriceRevision
  );

  const [basicInfo] = useOutletContext();

  const Navigate = useNavigate(); // navigation hook

  const { getAccessToken } = useAuth();

  const deleteSpecificMilkRevision = async (deletePaymentId) => {
    try {
      const token = await getAccessToken();

      const url =
        AdminAuthorURL.vendor.milkPriceChange.deleteMilkRevisionById(
          deletePaymentId
        );
      const options = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();

      if (response.ok) {
        toast.success(responseObj?.message);
      } else {
        toast.error(responseObj?.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteSpecificMilkRevisionMutation = useMutation({
    mutationFn: deleteSpecificMilkRevision,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchMilkPriceRevisions"] });
    },

  });

  const columns = [
    {
      name: "Effective Date",
      selector: (row) => row?.effectiveDate,
    },
    {
      name: "Price Configuration Per Litre",
      selector: (row) =>
        `${row?.newPriceConfigurationPerLitre?.currency?.symbol} ${row?.newPriceConfigurationPerLitre?.value}`,
    },

    {
      name: "Remarks",
      selector: (row) => row?.remarks,
    },
    {
      name: "Action",
      id: "action",
      cell: (row) => (
        <div className='flex space-x-2'>
          <div className='border rounded p-1 cursor-pointer'>
            <Icon
              icon={"material-symbols:edit"}
              className='text-[1.7rem] cursor-pointer text-[#5E718D]  '
              onClick={() => {
                document
                  .getElementById("vendorStepper")
                  .scrollIntoView({ top: 0, behavior: "smooth" });
                Navigate(
                  `/vendorManagement/edit/${id}/milkPriceRevision/edit/${row?._id}`,
                  {
                    relative: "path",
                  }
                );
              }}
            />
          </div>
          <div className='border rounded p-1 cursor-pointer'>
            <RiDeleteBinLine
              className='text-[1.7rem] cursor-pointer rounded text-[#5E718D] '
              onClick={async () => {
                await deleteSpecificMilkRevisionMutation.mutate(row?._id);
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const fetchMilkPriceRevisions = async (download) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.milkPriceChange.fetchMilkRevisions(
        id,
        page,
        keyword,
        download,
        fromDate,
        toDate
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(response, "milk price revisions data");

      console.log(responseData, "milk price revisions");

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData?.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { data: downloadData } = useQuery({
    queryKey: ["fetchMilkPriceRevisions", fromDate, toDate, keyword, page],
    queryFn: async () => await fetchMilkPriceRevisions(true),
  });

  const { data, isLoading } = useQuery({
    queryKey: ["fetchMilkPriceRevisions", fromDate, toDate, keyword, page],
    queryFn: async () => await fetchMilkPriceRevisions(false),
  });

  useEffect(() => {
    if (data) {
      setTotalData(data?.totalData);
      const updatedData = data?.limitedData?.map((item) => ({
        ...item,
        amount: `${_.round(item?.newPriceConfigurationPerLitre?.value, 2)}`,
        effectiveDate: `${moment(item?.effectiveDate).format("DD-MM-YYYY")}`,
      }));

      setVendorPaymentsData(updatedData);
      setTotalAmount(`₹ ${_.round(data?.totalAmount, 2)}`);
    } else {
      setVendorPaymentsData([]);
    }
  }, [data]);

  useEffect(() => {
    if (downloadData) {
      const updatedData = data?.limitedData?.map((item) => ({
        ...item,
        amount: `${_.round(item?.newPriceConfigurationPerLitre?.value, 2)}`,
        effectiveDate: `${moment(item?.effectiveDate).format("DD-MM-YYYY")}`,
      }));

      let totalAmount = 0;

      updatedData?.forEach((item) => {
        totalAmount += parseFloat(item?.newPriceConfigurationPerLitre?.value);
      });

      setTotalPaymentsData(updatedData);
      setTotalAmount(`₹ ${_.round(totalAmount, 2)}`);
    } else {
      setTotalPaymentsData([]);
    }
  }, [data]);

  const header = [
    {
      title: "S.No",
      width: "5%",
    },
    {
      title: "Effective Date",
      width: "31.6%",
    },
    {
      title: "Price Configuration Per Litre",
      width: "31.6%",
    },

    {
      title: "Remarks",
      width: "31.6%",
    },
  ];

  const keys = ["effectiveDate", "amount", "remarks"];

  const handleExcelSheetDownload = () => {
    exportToCSV({
      fileName: `Milk Price Revision Details of ${basicInfo?.vendorName}`,
      downloadData: totalPaymentsData,
      keys,
      header,
      totalDetailsRow: ["amount"],
    });
  };

  return (
    <div className=''>
      <Outlet />
      <div className='bg-white  md:mx-[0.5rem] my-[0.5rem] flex-1 overflow-y-auto py-[1rem] flex flex-col gap-y-[2rem] rounded'>
        <div className='flex flex-col gap-y-[2rem] pt-4 pb-6   '>
          <div className='px-[0.5rem] xs:px-[1rem] md:px-[2rem] flex flex-row justify-between'>
            <h1 className='text-[1.2rem] text-[#1A3032] font-bold '>
              Milk Price Revision
            </h1>
            <div className='text-white flex flex-row gap-[2rem] max-md:hidden xl:hidden '>
              {!showAddForm && (
                <button
                  onClick={() =>
                    Navigate(
                      `/vendorManagement/edit/${id}/milkPriceRevision/add`,
                      {
                        relative: "path",
                      }
                    )
                  }
                  style={{
                    background:
                      "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                  }}
                  className='rounded-md flex flex-row gap-[0.5rem] px-[1rem] py-[0.5rem]'>
                  <Icon
                    icon='material-symbols:add'
                    width={25}
                    className='text-[0.8em]'
                  />
                  <span>Add Milk Price</span>
                </button>
              )}
              <button
                style={{
                  background:
                    "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                }}
                className='rounded-md flex flex-row gap-[0.5rem] px-[1rem] py-[0.5rem] items-center'>
                <Icon
                  icon='material-symbols:download-rounded'
                  className='text-[1.5rem]'
                />
                <span>Download Report</span>
              </button>
            </div>
          </div>

          <div className='w-full flex flex-col gap-[1rem] md:gap-[2rem] md:flex-row md:items-center justify-between px-[0.5rem] xs:px-[1rem] md:px-[2rem] '>
            <div className='w-full flex  flex-col md:flex-row items-center gap-[1rem] md:gap-[2rem]'>
              <div
                className='w-full flex flex-row items-center text-[#555555] px-[1rem] py-[0.5rem] rounded-md gap-[0.5rem]'
                style={{
                  background: "#FFFFFF",
                  border: "0.1px solid #DFDFDF",
                  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
                  borderRadius: "5.09937px",
                }}>
                <Icon icon='ri:search-line' className='text-[1.4rem]' />
                <input
                  type='text'
                  className='text-[#7C7C7C] placeholder-[#7C7C7C]  text-sm outline-none'
                  placeholder='Search by name'
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>
              <div className='w-full justify-between flex flex-row gap-[1rem] max-xs:text-[0.8rem]'>
                <div className='relative flex justify-end w-full'>
                  <button
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                    style={{
                      background:
                        "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                    }}
                    className='text-white outline-none  rounded-md text-center  gap-[0.5rem] px-[2rem] py-[0.5rem] '>
                    Filter
                  </button>
                  {isFilterOpen && (
                    <div className='bg-white p-[1rem]  shadow-lg absolute top-[50px] right-[10%]  z-10 rounded-[0.5rem] w-auto'>
                      <MilkPriceRevisionFilter />
                    </div>
                  )}
                </div>

                <button
                  style={{
                    background:
                      "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                  }}
                  className='text-white w-full rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[1rem] py-[0.5rem] md:hidden'>
                  <span> Filter</span>
                </button>
              </div>
            </div>
            <div className='max-md:w-full max-xs:text-[0.8rem] whitespace-nowrap max-md:justify-between text-white max-md:flex md:hidden xl:flex flex-row gap-[1rem] md:gap-[2rem] '>
              {!showAddForm && (
                <button
                  onClick={() =>
                    Navigate(
                      `/vendorManagement/edit/${id}/milkPriceRevision/add`,
                      {
                        relative: "path",
                      }
                    )
                  }
                  style={{
                    background:
                      "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                  }}
                  className='rounded-md max-md:w-full flex justify-center flex-row items-center gap-[0.5rem] px-[1rem] py-[0.5rem]'>
                  <Icon
                    icon='material-symbols:add'
                    width={25}
                    className='text-[0.5em] xs:text-[0.8em]'
                  />
                  <span>Add Milk Price</span>
                </button>
              )}
              <Menu>
                <MenuHandler>
                  <button
                    style={{
                      background:
                        "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                    }}
                    className='rounded-md flex flex-row gap-[0.5rem] px-[1rem] py-[0.5rem] items-center'>
                    <Icon
                      icon='material-symbols:download-rounded'
                      className='text-[1.5rem]'
                    />
                    <span>Download Report</span>
                  </button>
                </MenuHandler>
                <MenuList>
                  <PDFDownloadLink
                    fileName='milkRevisionDetails.pdf'
                    document={
                      <PdfDownload
                        title={`Milk Price Revision Details of ${basicInfo?.vendorName}`}
                        headersArray={header}
                        keys={keys}
                        data={totalPaymentsData}
                        totalRow={{
                          index: 2,
                          data: [totalAmount],
                        }}
                      />
                    }>
                    <MenuItem className='text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]'>
                      {" "}
                      <Icon
                        icon='bxs:file-pdf'
                        className='text-[#BE0A23] w-[1.6rem] h-auto'
                      />
                      Download as PDF
                    </MenuItem>
                  </PDFDownloadLink>

                  <hr className='my-[0.5rem]' />

                  <MenuItem
                    onClick={handleExcelSheetDownload}
                    className='text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]'>
                    <Icon
                      icon='file-icons:microsoft-excel'
                      className='text-[#29672E] w-[1.1rem] h-auto'
                    />
                    Download as Excel Sheet
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
        <div className=''>
          <div className='p-3 pt-0 animal-table '>
            <div className='m-2 '>
              {isLoading ? (
                <div className='flex justify-center items-center p-[2.5rem]'>
                  <LvreddyLoader />
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={vendorPaymentsData}
                  customStyles={customStyles}
                  pagination
                  paginationComponent={() =>
                    CustomPagination({
                      rowsPerPage: 10,
                      rowCount: totalData,
                      currentPage: page,
                      onChangePage: setPage,
                    })
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
