import { useQuery } from "@tanstack/react-query";
import "flatpickr/dist/themes/material_green.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { AdminAuthorURL } from "../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../stores/AuthContext";
import { dataActions } from "../../../../stores/IndexRedux";
import { customStyles } from "../../../helpers/CustomDataTableStyles";
import LvreddyLoader from "../../../loaders/LvreddyLoader";
import CustomPagination from "../../animalModule/animalManageLists/CustomPagination";
import "../../employeeModule/styles.css";

const EmployeeOvertimeAllowance = () => {
  const { keyword } = useOutletContext();
  const [selectId, setSelectId] = useState([]);
  const [page, setPage] = useState(1);
  const [overtimeAllowancesData, setOverTimeallowancesData] = useState([]);
  const [totalOvertimeAllowanceData, setOvertimeAllowanceData] = useState([]);
  const [totalData, setTotalData] = useState();

  const { fromDate, toDate, employeeName, date, shift } = useSelector(
    (state) => state.employee.overtimeAllowanceFilter
  );

  const dispatch = useDispatch();

  const { getAccessToken } = useAuth();
  const columns = [
    {
      name: "Employee Code",
      selector: (row) => row.employeeId,
      id: "employeeId",
    },
    {
      name: "Name",
      id: "name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
    },

    {
      name: "Date",
      id: "date",
      selector: (row) =>
        row.date ? moment(row?.date).format("DD/MM/YYYY") : "N.A.",
    },
    {
      name: "Overtime Allowance Amount",
      id: "amount",
      selector: (row) => row.amount,
    },
    {
      name: "Remarks",
      id: "remarks",
      selector: (row) => row.remarks,
    },
  ];

  const fetchTotalOvertimeAllowances = async (download) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.payroll.fetchAllOvertimeAllowances(
        page,
        keyword,
        date,
        download,
        fromDate,
        toDate,
        employeeName ? employeeName?.value : ""
      );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(response, "overtime allowances response");
      console.log(responseData, "response data");

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const { data: downloadData } = useQuery({
    queryKey: [
      "fetchTotalOvertimeAllowances",
      keyword,
      fromDate,
      toDate,
      employeeName,
      date,
    ],
    queryFn: async () => await fetchTotalOvertimeAllowances(true),
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchTotalOvertimeAllowances",
      keyword,
      page,
      fromDate,
      toDate,
      employeeName,
      date,
    ],
    queryFn: async () => await fetchTotalOvertimeAllowances(false),
  });

  // handle selection of rows in all animals table
  const handleSelection = ({ selectedRows }) => {
    setSelectId(selectedRows);
  };

  useEffect(() => {
    if (data) {
      console.log(data, "999999999999");
      setOverTimeallowancesData(data?.limitedData);
      setTotalData(data?.totalData);
    } else {
      setOverTimeallowancesData([]);
      setTotalData(0);
    }
  }, [data, page, fromDate, toDate, employeeName, date, keyword]);

  useEffect(() => {
    if (downloadData) {
      const updatedData = downloadData?.limitedData?.map((item) => ({
        ...item,
        date: moment(item?.date).format("DD-MM-YYYY"),
        name: `${item?.firstName} ${item?.lastName}`,
      }));

      let totalAmount = 0;
      downloadData?.limitedData?.forEach((item) => {
        totalAmount += parseFloat(item?.amount);
      });

      dispatch(
        dataActions.updateData({
          data: updatedData,
          stats: [totalAmount],
        })
      );
    }
  }, [downloadData, page, fromDate, toDate, employeeName, date, keyword]);

  return (
    <>
      {isLoading ? (
        <div className='flex justify-center items-center p-[2.5rem]'>
          <LvreddyLoader />
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={overtimeAllowancesData}
          customStyles={customStyles}
          progressPending={isLoading}
          pagination
          paginationComponent={() =>
            CustomPagination({
              rowsPerPage: 10,
              rowCount: totalData,
              currentPage: page,
              onChangePage: setPage,
            })
          }
          // selectableRows
          // selectableRowsComponent={CustomCheckbox}
          // onSelectedRowsChange={handleSelection}
        />
      )}
    </>
  );
};

export default EmployeeOvertimeAllowance;
