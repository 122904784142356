import { Icon } from "@iconify/react";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Option,
  Select,
  ThemeProvider,
} from "@material-tailwind/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { RiDeleteBinLine } from "react-icons/ri";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { AdminAuthorURL } from "../../../baseUrl/BaseUrl";
import { useAuth } from "../../../stores/AuthContext";
import { filterManageActions } from "../../../stores/IndexRedux";
import { customStyles } from "../../helpers/CustomDataTableStyles";
import { theme } from "../../helpers/CustomTheme";
import { exportToCSV } from "../../helpers/ExcelSheetFunction";
import PdfDownload from "../../helpers/PdfDownload";
import { DailyMilkBulkUploadSalesFilter } from "../../helpers/ReportFilteration";
import LvreddyLoader from "../../loaders/LvreddyLoader";
import CustomCheckbox from "../animalModule/animalManageLists/CustomCheckbox";

const BulkMilkSaleUpload = () => {
  const [deletes, setDeletes] = useState([]);

  const [selectedRecords, setSelectedRecords] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [bulkMilkSalesData, setBulkMilkSalesData] = useState([]);

  const [searchKey, setSearchKey] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [recordsValidationError, setRecordsValidationError] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [salesStats, setSalesStats] = useState([]);

  const { fromDate, toDate, milkSession, date, vendorId } = useSelector(
    (state) => state?.filterManage?.bulkDailyMilkSales
  );

  const [records, setRecords] = useState([
    {
      vendorCustomId: "",
      vendorName: "",
      date: date,
      session: "",
      quantity: "",
      cost: "",
      updated: false,
      _id: null,
    },
  ]);

  const { getAccessToken } = useAuth();
  const dispatch = useDispatch();

  const fetchBulkMilkSaleUpload = async (download) => {
    setDeletes([]);

    console.log(vendorId);
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchMilkSalesByDate(
        searchKey,
        date,
        fromDate,
        toDate,
        vendorId ? vendorId?.value : "",
        milkSession
      );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(responseData, "vendors data data");
      console.log(response, "response vendors data");
      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchVendorIds = async (key) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchVendorIds(key || "");
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(responseData, "vendors ids");

      if (response.ok) {
        const updatedVendors = responseData?.response?.vendors?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        setVendors(updatedVendors);

        return updatedVendors;
      } else {
        setVendors([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleValidations = () => {
    return records.every(
      (item) =>
        item?.vendorId !== "" && item?.quantity !== "" && item?.session !== ""
    );
  };

  const handleSubmit = async () => {
    if (!handleValidations()) {
      setRecordsValidationError(true);
      setLoader(false);
    } else {
      setRecordsValidationError(false);
      setLoader(true);
      try {
        const updatedRecords = records?.map((item) => ({
          vendorId: item?.vendorCustomId,

          date: item?.date,
          session: item?.session,
          quantity: item?.quantity,
          _id: item?._id || "",
          updated: item?.updated,
        }));

        console.log(updatedRecords, "updated records");
        const token = await getAccessToken();
        const url = AdminAuthorURL.vendor.uploadBulkMilkSales;

        console.log(records, "records");

        const options = {
          method: "PUT",
          body: JSON.stringify({ data: updatedRecords, deletes: deletes }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };

        const response = await fetch(url, options);
        const responseObj = await response.json();

        console.log(response, "bulk milk uploads");

        console.log(responseObj, "bulk upload ");
        if (response.ok) {
          toast.success(responseObj.message);
          setLoader(false);

          setDeletes([]);
        } else {
          toast.error(responseObj.message);
        }
      } catch (e) {
        console.log(e);
        toast.error("Failed to bulk attendance upload");
      }
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchBulkMilkSaleUpload",
      searchKey,
      date,
      fromDate,
      toDate,
      vendorId,
      milkSession,
    ],
    queryFn: async () => await fetchBulkMilkSaleUpload(false),
  });

  /**-------------------------------------Updating-------------------------------------------------- */
  const handleChange = (index, field, value) => {
    const updatedRecords = [...records];
    updatedRecords[index][field] = value;
    if (updatedRecords[index]["_id"]) {
      updatedRecords[index]["updated"] = true;
    }

    setRecords(updatedRecords);
  };

  /*---------------------------Deleting row---------------------------------------------------------- */
  const handleRemoveRow = (index) => {
    if (records[index]["_id"]) {
      setDeletes((items) => [...items, records[index]["_id"]]);
    }

    setRecords((prevRecords) => prevRecords.filter((_, i) => i !== index));
  };

  const vendorLoadOptions = async (inputValue) => {
    const data = await fetchVendorIds(inputValue);
    return data;
  };

  useEffect(() => {
    fetchVendorIds("");
  }, []);

  const columns = [
    {
      name: "S.No",
      id: "sno",
      selector: (row, index) => index + 1,
      minWidth: "130px",
    },
    {
      name: "Vendor Name",

      width: "250px",
      cell: (row, index) => (
        <div className=''>
          <AsyncSelect
            menuPortalTarget={document.body}
            menuPlacement='auto'
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              menu: (provided, state) => ({
                ...provided,
                width: "auto",
                minWidth: "100%",
              }),
              menuList: (provided, state) => ({
                ...provided,
                maxHeight: "200px",
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            })}
            onChange={(e) => {
              console.log(e);
              handleChange(index, "vendorCustomId", e?.value);
              handleChange(index, "vendorName", e?.label);
            }}
            className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={
              row?.vendorCustomId && row?.vendorName
                ? {
                    value: row?.vendorCustomId,
                    label: _.truncate(row?.vendorName, { length: 16 }),
                  }
                : null
            }
            defaultOptions={vendors}
            loadOptions={vendorLoadOptions}
            maxMenuHeight={200}
          />
        </div>
      ),
      grow: 1.5,
    },

    {
      name: "Date",

      cell: (row, index) => (
        <div
          style={{
            border: "none",
          }}>
          <input
            type='date'
            value={row?.date}
            readOnly
            className='border-none outline-none'
            onChange={(e) => {
              handleChange(index, "date", e.target.value);
            }}
          />
        </div>
      ),

      grow: 0.8,
      width: "240px",
    },

    {
      name: "Quantity",

      cell: (row, index) => (
        <div>
          <input
            type='number'
            value={_.round(row.quantity, 2) || ""}
            step='any'
            className={`border border-gray-700 outline-none ${
              recordsValidationError &&
              !row?.quantity &&
              "border border-red-400"
            } `}
            onChange={(e) => {
              handleChange(index, "quantity", e.target.value);
            }}
          />
        </div>
      ),

      grow: 0.8,
      width: "240px",
    },

    {
      name: "Session",

      cell: (row, index) => (
        <div className='flex flex-col gap-[0.5rem]'>
          <Select
            style={{
              border:
                recordsValidationError &&
                row?.session === `` &&
                "border border-red-400",
            }}
            onChange={(value) => {
              handleChange(index, "session", value);
            }}
            label='Select Session'
            value={row?.session}>
            <Option value='Morning'>Morning</Option>
            <Option value='Evening'>Evening</Option>
          </Select>
        </div>
      ),

      grow: 0.8,
      width: "240px",
    },
    {
      name: "Cost",

      cell: (row, index) => (
        <div>
          <p>{_.round(row?.cost, 2)}</p>
        </div>
      ),

      grow: 0.8,
      width: "240px",
    },

    {
      name: "Delete",
      id: "delete",
      cell: (row, index) => (
        <div className='border rounded p-1 cursor-pointer'>
          {/* <img src={deleteIcon} alt="deleteIcon" onClick={() => Navigate("//animals/manage")} className="cursor-pointer " /> */}
          <RiDeleteBinLine
            className='text-[1.7rem] cursor-pointer rounded text-[#5E718D] '
            // navigate to admin page on click of delete icon
            onClick={() => {
              handleRemoveRow(index);
            }}
          />
        </div>
      ),
      grow: 0.5,
    },
  ];

  /**------------------------------------Adding row------------------------------------------------- */

  const handleAddRow = () => {
    const rows = records;
    setRecords([
      ...rows,
      {
        vendorCustomId: "",
        vendorName: "",
        session: "",
        quantity: "",
        cost: "",
        date: date,
        updated: false,
        _id: null,
      },
    ]);
  };

  const handleTableRows = ({ selectedRows }) => {
    const rows = selectedRows.map((item) => item["_id"]);

    setSelectedRecords(rows);
    setDeletes([...deletes, ...rows]);
  };

  const deleteSelectedRows = () => {
    const filteredRows = records.filter(
      (item) => selectedRecords.includes(item["_id"]) === false
    );

    setRecords(filteredRows);
  };

  useEffect(() => {
    if (data) {
      const updatedData = data?.data?.map((item) => ({
        ...item,
        updated: item?.updated,

        date: moment(item?.date).format("YYYY-MM-DD"),
        quantity: _.round(item?.quantity?.value, 2),
        cost: _.round(item?.costs?.value, 2),
      }));

      if (updatedData?.length === 0) {
        setRecords([
          {
            vendorCustomId: "",
            vendorName: "",
            date: date,
            session: "",
            quantity: "",
            cost: "",
            updated: false,
            _id: null,
          },
        ]);
      } else {
        let totalQuantity = 0;
        let totalCost = 0;

        updatedData.forEach((item) => {
          totalQuantity += parseFloat(item?.quantity);
          totalCost += parseFloat(item?.costs?.value);
        });

        setSalesStats([
          `${_.round(totalQuantity, 2)} L`,
          `₹ ${_.round(totalCost, 2)}`,
        ]);

        setRecords(updatedData);
      }
    } else {
      setSalesStats([]);
      setRecords([
        {
          vendorCustomId: "",
          vendorName: "",
          date: date,
          session: "",
          quantity: "",
          cost: "",
          updated: false,
          _id: null,
        },
      ]);
    }
  }, [data, searchKey, date, fromDate, toDate, vendorId, milkSession]);

  const header = [
    {
      title: "S.No",
      width: "5%",
    },
    {
      title: "Vendor Id",
      width: "19%",
    },
    {
      title: "Vendor Name",
      width: "19%",
    },
    {
      title: "Date",
      width: "19%",
    },
    {
      title: "Session",
      width: "19%",
    },
    {
      title: "Quantity",
      width: "19%",
    },
    {
      title: "Cost",
      width: "19%",
    },
  ];

  const keys = [
    "vendorCustomId",
    "vendorName",
    "date",
    "session",
    "quantity",
    "cost",
  ];

  const handleExcelSheetDownload = () => {
    exportToCSV({
      fileName: "Bulk Milk Sales",
      downloadData: records,
      keys: keys,
      header: header,
      totalDetailsRow: ["quantity", "cost"],
    });
  };

  const downloadUpdateRecords = records?.map((item) => ({
    ...item,
    quantity: item?.quantity + " L",
    date: moment(item?.date).format("DD-MM-YYYY"),
  }));

  console.log(salesStats, "sales stats");

  return (
    <div className='bg-white md:mx-[0.5rem] my-[0.5rem] flex flex-col flex-1 overflow-y-auto py-[1rem] font-poppins'>
      <ThemeProvider value={theme}>
        <div className='border-b-[1px] border-[#DFDFDF] py-[1rem] px-[0.5rem] xs:max-xl:px-[1rem] xl:px-[2rem]'>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-row gap-[1rem] items-center '>
              <h1 className='text-[1.2rem] text-[#1A3032] font-bold '>
                Add Bulk Milk Sales
              </h1>
            </div>
          </div>
        </div>
        <div
          className='w-full px-4 py-8'
          style={{ border: "1px solid #F0F0F0" }}>
          <div className='w-full flex flex-col gap-[1rem] md:gap-[2rem] md:flex-row md:items-center justify-between px-[0.5rem] xs:px-[1rem] md:px-[2rem] '>
            <div className='w-full flex flex-col gap-[1rem] xl:flex-row items-center xl:gap-[2rem]'>
              <div className='w-full flex  flex-col md:flex-row items-center gap-[1rem] md:gap-[1rem] xl:gap-[2rem]'>
                <div
                  className='w-full flex flex-row items-center text-[#555555] px-[1rem] py-[0.5rem] rounded-md gap-[0.5rem] max-w-[270px] lg:max-w-[300px]'
                  style={{
                    background: "#FFFFFF",
                    border: "0.1px solid #DFDFDF",
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
                    borderRadius: "5.09937px",
                  }}>
                  <Icon icon='ri:search-line' className='text-[1.4rem]' />
                  <input
                    type='text'
                    className='text-[#7C7C7C] placeholder-[#7C7C7C]  text-sm outline-none'
                    placeholder='Search name'
                    onChange={(e) => {
                      setSearchKey(e.target.value);
                    }}
                  />
                </div>
                <div className='w-full grid grid-cols-1 sm:grid-cols-2 md:flex md:flex-row  gap-[1rem] max-xs:text-[0.8rem] '>
                  <div
                    className='px-[1rem] max-md:w-full py-[0.5rem]'
                    style={{
                      background: "#FFFFFF",
                      boxShadow: "0px 4px 16px rgba(0, 153, 112, 0.08)",
                      borderRadius: "6px",
                    }}>
                    <input
                      type='date'
                      placeholder='Date'
                      value={date}
                      className='text-[#7C7C7C] placeholder-[#7C7C7C] w-full sm:max-w-[10rem] outline-none '
                      onChange={(e) => {
                        dispatch(
                          filterManageActions.updateBulkDailyMilkSales({
                            date: e.target.value,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className='relative flex justify-end w-full'>
                    <button
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                      style={{
                        background:
                          "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                      }}
                      className='text-white outline-none  rounded-md text-center  gap-[0.5rem] px-[2rem] py-[0.5rem] '>
                      Filter
                    </button>
                    {isFilterOpen && (
                      <div className='bg-white p-[1rem]  shadow-lg absolute top-[50px] right-[10%]  z-10 rounded-[0.5rem] w-auto'>
                        <DailyMilkBulkUploadSalesFilter />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Menu>
                <MenuHandler>
                  <button
                    style={{
                      background:
                        "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                    }}
                    className='rounded-md flex flex-row gap-[0.5rem] px-[1rem] py-[0.5rem] items-center text-white whitespace-nowrap'>
                    <Icon
                      icon='material-symbols:download-rounded'
                      className='text-[1.5rem]'
                    />
                    <span>Download Report</span>
                  </button>
                </MenuHandler>
                <MenuList>
                  <PDFDownloadLink
                    fileName='bulkSalesDetails.pdf'
                    document={
                      <PdfDownload
                        headersArray={header}
                        keys={keys}
                        data={downloadUpdateRecords}
                        title='Bulk Sales Details'
                        totalRow={{
                          index: 5,
                          data: salesStats,
                        }}
                      />
                    }>
                    <MenuItem className='text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]'>
                      {" "}
                      <Icon
                        icon='bxs:file-pdf'
                        className='text-[#BE0A23] w-[1.6rem] h-auto'
                      />
                      Download as PDF
                    </MenuItem>
                  </PDFDownloadLink>

                  <hr className='my-[0.5rem]' />

                  <MenuItem
                    onClick={handleExcelSheetDownload}
                    className='text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]'>
                    <Icon
                      icon='file-icons:microsoft-excel'
                      className='text-[#29672E] w-[1.1rem] h-auto'
                    />
                    Download as Excel Sheet
                  </MenuItem>
                </MenuList>
              </Menu>

              <button
                onClick={deleteSelectedRows}
                style={{
                  background:
                    "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                }}
                className='text-white self-end w-[100px] rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[1rem] py-[0.5rem] '>
                Delete
              </button>
            </div>
          </div>
          <form action=''>
            <div className='my-8 '>
              {isLoading ? (
                <div className='flex justify-center items-center p-[2.5rem]'>
                  <LvreddyLoader />
                </div>
              ) : (
                <DataTable
                  data={records}
                  columns={columns}
                  customStyles={customStyles}
                  selectableRows
                  selectableRowsComponent={CustomCheckbox}
                  onSelectedRowsChange={handleTableRows}
                  fixedHeader
                  fixedHeaderScrollHeight='80vh'
                />
              )}

              <div className='flex flex-row justify-end'>
                <button
                  style={{
                    background:
                      "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                  }}
                  className='rounded-md max-md:w-full text-white flex justify-center flex-row items-center gap-[0.5rem] px-[1rem] py-[0.5rem] shrink-0'
                  onClick={() => {
                    handleAddRow();
                  }}
                  type='button'>
                  <Icon
                    icon='material-symbols:add'
                    width={25}
                    className='text-[0.5em] xs:text-[0.8em]'
                  />

                  <span>Add New</span>
                </button>
              </div>

              {recordsValidationError && (
                <p className='text-[#E92215] text-sm'>
                  *Records Should'nt be empty fill all fields
                </p>
              )}
            </div>
            <div className='mt-[6rem] flex justify-end'>
              {loader ? (
                <ThreeDots
                  height='50'
                  width='50'
                  radius='9'
                  color='#9FB947'
                  ariaLabel='three-dots-loading'
                  wrapperStyle={{}}
                  wrapperClassName=''
                  visible={true}
                />
              ) : (
                <button
                  type='button'
                  onClick={() => {
                    handleSubmit();
                  }}
                  className='rounded-md px-[2rem] py-[0.5rem] text-white text-[0.8rem] xs:text-[1rem]'
                  style={{
                    background:
                      "linear-gradient(90deg, #9FB947 -10.36%, #739500 100%)",
                  }}>
                  Save
                </button>
              )}
            </div>
          </form>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default BulkMilkSaleUpload;
