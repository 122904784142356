import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { AdminAuthorURL } from "../../../../../baseUrl/BaseUrl";
import { customStyles } from "../../../../helpers/CustomDataTableStyles";
import LvreddyLoader from "../../../../loaders/LvreddyLoader";

import _ from "lodash";
import moment from "moment";
import { useAuth } from "../../../../../stores/AuthContext";
import { dataActions } from "../../../../../stores/IndexRedux";
import CustomPagination from "../../../animalModule/animalManageLists/CustomPagination";

const DailySalesReports = () => {
  const [dailySalesReports, setDailySalesReports] = useState([]);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  const { fromDate, toDate, vendorId } = useSelector(
    (state) => state?.filterManage?.dailySalesReports
  );

  const { searchKey } = useOutletContext();

  const { getAccessToken } = useAuth();

  const dispatch = useDispatch();

  const fetchDailySalesReports = async (download) => {
    console.log("function called");
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchVendorMilkSalesReports(
        page,
        searchKey,
        fromDate,
        toDate,
        download,
        vendorId ? vendorId?.value : ""
      );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);

      const responseData = await response.json();

      console.log(responseData, "daily sales reports");
      console.log(response, "daily sales reports response");

      if (response.ok) {
        return responseData?.response;
      } else {
        throw new Error(responseData?.message);
      }
    } catch (e) {
      // handle error
    }
  };

  const { data: downloadD } = useQuery({
    queryKey: ["fetchDailySalesReports", fromDate, toDate, searchKey, vendorId],
    queryFn: async () => await fetchDailySalesReports(true),
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchDailySalesReports",
      fromDate,
      toDate,
      searchKey,
      page,
      vendorId,
    ],
    queryFn: async () => await fetchDailySalesReports(false),
  });

  useEffect(() => {
    if (data) {
      setDailySalesReports(data?.limitedData);
      setTotalData(data?.totalData);
    }
  }, [data]);

  const columns = [
    {
      name: "Vendor Id",
      selector: (row) => row?.vendorId,
      width: "130px",
    },
    {
      name: "Vendor Name",
      selector: (row) => row?.vendorName,
      width: "210px",
    },
    {
      name: "Date",
      selector: (row) => moment(row?.date).format("DD-MMM-YYYY"),
    },
    {
      name: "Morning Milk Price Per Litre",
      selector: (row) =>
        row?.morningPricePerLitre ? row?.morningPricePerLitre : "N/A",
      width: "230px",
    },
    {
      name: "Morning Cost",
      selector: (row) =>
        row?.morningCost ? _.round(row?.morningCost, 2) : "N/A",
      width: "210px",
    },
    {
      name: "Morning Quantity",
      selector: (row) =>
        row?.morningQuantity ? _.round(row?.morningQuantity, 2) : "N/A",
      width: "180px",
    },
    {
      name: "Evening Milk Price Per Litre",
      selector: (row) =>
        row?.eveningPricePerLitre ? row?.eveningPricePerLitre : "N/A",
      width: "280px",
    },
    {
      name: "Evening Cost",
      selector: (row) =>
        row?.eveningCost ? _.round(row?.eveningCost, 2) : "N/A",
      width: "180px",
    },
    {
      name: "Evening Quantity",
      selector: (row) =>
        row?.eveningQuantity ? _.round(row?.eveningQuantity, 2) : "N/A",
      width: "180px",
    },

    {
      name: "Total Cost",
      selector: (row) => (row?.totalCost ? _.round(row?.totalCost, 2) : "N/A"),
      width: "180px",
    },
    {
      name: "Total Quantity",
      selector: (row) =>
        row?.totalQuantity ? _.round(row?.totalQuantity, 2) : "N/A",
      width: "180px",
    },
  ];

  useEffect(() => {
    if (downloadD) {
      const updatedData = downloadD?.limitedData?.map((item) => ({
        ...item,
        date: item?.date ? moment(item?.date).format("DD-MM-YYYY") : "N.A",
        morningPricePerLitre: item?.morningPricePerLitre
          ? _.round(item?.morningPricePerLitre, 2)
          : "N.A",
        morningCost: item?.morningCost ? _.round(item?.morningCost, 2) : "N.A",
        morningQuantity: item?.morningQuantity
          ? _.round(item?.morningQuantity, 2)
          : "N.A",
        eveningPricePerLitre: item?.eveningPricePerLitre
          ? _.round(item?.eveningPricePerLitre, 2)
          : "N.A",
        eveningCost: item?.eveningCost ? _.round(item?.eveningCost, 2) : "N.A",
        eveningQuantity: item?.eveningQuantity
          ? _.round(item?.eveningQuantity, 2)
          : "N.A",
        totalCost: item?.totalCost ? _.round(item?.totalCost, 2) : "N.A",
        totalQuantity: item?.totalQuantity
          ? _.round(item?.totalQuantity, 2)
          : "N.A",
      }));

      const totalTotalCost = downloadD?.limitedData?.reduce(
        (a, b) => (b?.totalCost !== null ? a + b?.totalCost : a),
        0
      );
      const totalQuantity = downloadD?.limitedData?.reduce(
        (a, b) => (b?.totalQuantity !== null ? a + b?.totalQuantity : a),
        0
      );

      console.log(totalTotalCost, totalQuantity);

      dispatch(dataActions.updateData(updatedData));
      dispatch(
        dataActions.updateTotalStats([
          `₹${_.round(totalTotalCost, 2)}`,
          `₹${_.round(totalQuantity, 2)}`,
        ])
      );
    }
  }, [downloadD]);

  return (
    <div className={` pt-0 animal-table `}>
      <div className={` `}>
        {isLoading ? (
          <div className='flex justify-center items-center p-[2.5rem]'>
            <LvreddyLoader />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={dailySalesReports}
            customStyles={customStyles}
            pagination
            paginationComponent={() =>
              CustomPagination({
                rowsPerPage: 10,
                rowCount: totalData,
                currentPage: page,
                onChangePage: setPage,
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default DailySalesReports;
