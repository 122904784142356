import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import _, { round } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import letterHeadFooterFrame from "../../assets/Frame 1171275330.png";
import reddyLogo from "../../assets/LV Reddy Farm Logo 1.jpg";
import letterHeadSideBlock from "../../assets/Vector 7 (1).jpg";
import mail from "../../assets/mail.png";
import network from "../../assets/network.png";
import telephone from "../../assets/old-typical-phone.png";
import serif from "./PTSerif-Bold.ttf";
import roboto from "./Roboto-Bold.ttf";

Font.register({
  family: "Bree Serif",
  fonts: [{ src: serif, fontWeight: 700 }],
});

Font.register({
  family: "Roboto",
  fonts: [{ src: roboto, fontWeight: 800 }],
});

const PAGE_HEIGHT = 400;
// Create styles

const VendorBillSlip = ({ data }) => {
  const [isLastPage, setIsLastPage] = useState(false);

  // const pageRef = useRef(null);
  // const [instance, updateInstance] = usePDF({ document: null });

  const pageHeight = 600;
  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      position: "relative",

      width: "100%",
      height: pageHeight,

      paddingBottom: 0,
    },
    section: {
      width: "100%",

      flexGrow: 1,
    },

    backgroundImage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: "20%",
      left: 0,
      right: 0,
      bottom: "20%",
      opacity: 0.1,
    },
    contactText: {
      fontSize: "11px",
      fontWeight: 800,
      color: "#00A15C",
    },
    payslipMainContainer: {
      display: "flex",
      justifyContent: "flex-end",

      width: "100%",
    },
    payslipContainer: {
      height: "auto",
      borderLeft: "4px solid #739500",
      borderRadius: "10px",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    payDetailsContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    netPayContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "7px",
    },
    payTitle: {
      fontFamily: "Bree Serif",
      fontSize: "10px",
      color: "#b3a4a2",
    },
    payCount: {
      fontFamily: "Roboto",
      fontSize: "13px",
      color: "#000",
      fontWeight: "bold",
    },
    deductionBorder: {
      height: "auto",
      width: "3px",
      backgroundColor: "red",

      borderRadius: "10px",
      marginHorizontal: "8px",
      paddingTop: "4px",
    },
    advancesContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    detailsHeadingText: {
      fontSize: "11px",
      color: "#4f4b4b",
    },
    detailsValue: {
      fontSize: "11px",
      color: "#000",
    },
    detailsMainContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
    },
    salaryFinalSheetContainer: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
      gap: "20px",
    },
    grossPayContainer: {
      borderLeft: "2px solid #9FB947",
      borderRadius: "10px",
      height: "auto",
      maxHeight: "100px",
      position: "relative",
      padding: "10px",
      width: "200px",
    },
    grossPayTitle: {
      position: "absolute",
      top: "-4px",
      left: "10px",
      fontFamily: "Roboto",
      fontSize: "6px",
      whitespace: "nowrap",
      width: "100%",
    },
    advancePayContainer: {
      borderLeft: "2px solid red",
      borderRadius: "10px",

      height: "auto",
      maxHeight: "100px",
      position: "relative",
      padding: "10px",
      width: "200px",
    },
    overTimeAllowancesContainer: {
      borderLeft: "2px solid #9FB947",
      borderRadius: "10px",
      height: "auto",
      maxHeight: "100px",
      position: "relative",
      padding: "10px",
      width: "200px",
    },
  });

  const Header = () => (
    <View
      fixed
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        marginBottom: "20px",
        height: "auto",

        paddingRight: 10,
        paddingLeft: 10,
        flexShrink: 0,
      }}>
      <Image
        style={{
          height: "60px",
          width: "85PX",

          flexShrink: 0,
          marginTop: "6px",
        }}
        src={reddyLogo}
      />

      <View
        style={{
          alignSelf: "center",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
          flexShrink: 0,
        }}>
        <Text
          style={{
            fontSize: "23px",
            alignSelf: "center",
            color: "#00A15C",

            fontFamily: "Bree Serif",

            paddingBottom: "2px",
          }}>
          LV REDDY DAIRY FARM
        </Text>
        <View
          style={{
            height: "4px",
            width: "100vw",
            backgroundColor: "#060606",
            marginLeft: "20px",
            borderTopLeftRadius: "100%",
          }}></View>

        <View style={{ alignSelf: "center" }}>
          <Text
            style={{
              fontSize: "12px",
              fontFamily: "Roboto",
              color: "#000",
              paddingTop: "4px",
              fontWeight: "bold",
            }}>
            GST: 37AAJFL5107A1ZP
          </Text>
        </View>
      </View>

      <View
        style={{
          alignSelf: "flex-end",
          marginTop: "-10px",
        }}>
        <Image
          src={letterHeadSideBlock}
          style={{ height: "auto", width: "200px" }}
        />
      </View>
    </View>
  );

  const Footer = () => (
    <View
      fixed
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: "190px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        boxSizing: "content-box",
        width: "100%",
        paddingTop: 10,
        flexShrink: 0,
      }}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontFamily: "Roboto",
          paddingLeft: 10,
          paddingRight: 10,
        }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
          }}>
          <View
            style={{
              backgroundColor: "#00A15C",
              height: "24px",
              width: "24px",
              color: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Image src={telephone} style={{ height: "16px", width: "16px" }} />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}>
            <Text style={styles.contactText}>+91 9666995008</Text>
            <Text style={styles.contactText}>+91 9666109229</Text>
          </View>
        </View>
        <View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <View
            style={{
              backgroundColor: "grey",
              height: "24px",
              width: "24px",
              color: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Image src={network} style={{ height: "16px", width: "16px" }} />
          </View>
          <View
            style={{
              alignSelf: "center",
            }}>
            <Text style={styles.contactText}>lvreddydairyfarm.com</Text>
          </View>
        </View>
        <View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <View
            style={{
              backgroundColor: "#00A15C",
              height: "24px",
              width: "24px",
              color: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Image src={mail} style={{ height: "16px", width: "16px" }} />
          </View>
          <View
            style={{
              alignSelf: "center",
            }}>
            <Text style={styles.contactText}>info@lvreddydairyfarm.com</Text>
          </View>
        </View>
      </View>
      <View
        style={{
          height: "2px",
          width: "100%",
          backgroundColor: "#00A15C",
          marginTop: "10px",
          marginBottom: "10px",
          boxSizing: "content-box",
        }}></View>

      <View
        style={{
          width: "100%",
        }}>
        <Text
          style={{
            fontSize: "14px",
            color: "green",
            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "Roboto",
          }}>
          3-180,Bhupal nagar village,Nandanapalli Post, Kurnool Dist , Andhra
          Pradesh-518452
        </Text>
      </View>

      <View style={{ marginTop: "10px" }}>
        <Image
          src={letterHeadFooterFrame}
          style={{
            height: "54px",
            width: "100%",
          }}
        />
      </View>
    </View>
  );

  const Background = () => (
    <View style={styles.backgroundImage} fixed>
      <Image
        fixed
        src={reddyLogo}
        style={{
          height: "auto",
          width: "400px",
        }}
      />
    </View>
  );

  return (
    <Document>
      {/* basic information */}

      <Page wrap size='A4' orientation={"portrait"} style={styles.page}>
        <Header />
        <Background />
        <View
          style={{
            paddingHorizontal: "20px",
            fontFamily: "Roboto",
            display: "flex",
            flexDirection: "column",

            gap: "10px",
            width: "100%",
            flexGrow: 1,
          }}>
          <View
            style={{
              alignSelf: "flex-end",
              flexShrink: 0,
            }}>
            <View style={styles.payslipContainer}>
              <Text
                style={{
                  fontFamily: "Bree Serif",
                  fontSize: "16px",
                  color: "#060606",
                  alignSelf: "center",
                }}>
                Bill Slip: {moment(data?.cStartDate).format("MMMM YYYY")}
              </Text>

              <Text
                style={{
                  fontFamily: "Bree Serif",
                  fontSize: "14px",
                  color: "#060606",
                  alignSelf: "center",
                }}>
                {moment(data?.cStartDate).format("DD-MM-YYYY")} To{" "}
                {moment(data?.cEndDate).format("DD-MM-YYYY")}
              </Text>
            </View>
          </View>
          <View
            style={{
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "auto",
              gap: "10px",
              marginTop: "10px",
            }}>
            <View style={styles.netPayContainer}>
              <Text style={styles.payTitle}>Net Payment</Text>
              <Text style={styles.payCount}>
                ({data?.currency?.symbol}) {round(data?.netPayment, 2)}
              </Text>
            </View>
            <View
              style={{
                alignSelf: "center",
              }}>
              <Text>=</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                <View
                  style={{
                    height: "auto",
                    width: "3px",
                    backgroundColor: "green",

                    borderRadius: "10px",
                    marginHorizontal: "8px",
                    paddingTop: "4px",
                  }}></View>
                <View style={styles.netPayContainer}>
                  <Text style={styles.payTitle}>Sub-total Milk Cost</Text>
                  <Text style={styles.payCount}>
                    +{round(data?.subTotalMilkCost, 2)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  alignSelf: "center",
                  paddingHorizontal: "15px",
                }}>
                <Text>-</Text>
              </View>
              <View style={styles.deductionBorder}></View>
              <View style={styles.netPayContainer}>
                <Text style={styles.payTitle}>Total Payment</Text>
                <Text style={styles.payCount}>
                  - {_.round(data?.totalPaidAmount, 2)}
                </Text>
              </View>
              {data?.carriedForwardPayment < 0 ? (
                <View style={styles.deductionBorder}></View>
              ) : (
                <View
                  style={{
                    height: "auto",
                    width: "3px",
                    backgroundColor: "green",

                    borderRadius: "10px",
                    marginHorizontal: "8px",
                    paddingTop: "4px",
                    marginLeft: "10px",
                  }}></View>
              )}

              <View style={{ ...styles.netPayContainer }}>
                <Text style={{ ...styles.payTitle }}>
                  Carried Forward Payment
                </Text>
                <Text style={styles.payCount}>
                  {round(data?.carriedForwardPayment, 2)}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}>
              <View style={styles.detailsMainContainer}>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}>Vendor Name:</Text>
                </View>

                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}>Vendor Id:</Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}> Mobile Number:</Text>
                </View>

                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}>Aadhar Number:</Text>
                </View>

                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}>Pan Number:</Text>
                </View>

                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}>
                    Date Of Joining:
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}>
                    Morning Milk Quantity:
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}>
                    Evening Milk Quantity:
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}>
                    Morning Milk Cost:
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}>
                    Evening Milk Cost:
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsHeadingText}>
                    Total Collection Days:
                  </Text>
                </View>
              </View>
              <View style={styles.detailsMainContainer}>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {data?.vendorName || "N.A"}
                  </Text>
                </View>

                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {data?.vendorId || "N.A"}
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {data?.mobileNumber || "N.A"}
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {data?.aadhaarNumber || "N.A"}
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {data?.panCardNumber || "N.A"}
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {moment(data?.joiningDate).format("DD-MM-YYYY") || "N.A"}
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {_.round(data?.morningMilkQuantity, 2) || 0} L
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {_.round(data?.eveningMilkQuantity, 2) || 0} L
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {data?.currency?.symbol +
                      " " +
                      _.round(data?.morningMilkCost) || 0}
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {data?.currency?.symbol +
                      " " +
                      _.round(data?.eveningMilkCost) || 0}
                  </Text>
                </View>
                <View style={styles.detailsContainer}>
                  <Text style={styles.detailsValue}>
                    {_.round(data?.totalCollectionDays) || "N.A"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.salaryFinalSheetContainer}>
              <View style={styles.grossPayContainer}>
                <Text style={styles.grossPayTitle}>
                  Price Configuration Per Litre
                </Text>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      paddingVertical: "10px",
                    }}>
                    {/* <Text style={styles.payCount}>{data?.pricePerLitre}</Text> */}
                  </View>
                  <View
                    style={{
                      alignSelf: "flex-end",
                      display: "flex",
                      height: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                      }}>
                      {/* <Text style={styles.payTitle}>Total:</Text> */}

                      <Text style={styles.payCount}>
                        {data?.currency?.symbol} {round(data?.pricePerLitre, 2)}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.advancePayContainer}>
                <Text style={styles.grossPayTitle}>Total Milk Quantity</Text>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      paddingVertical: "10px",
                    }}>
                    {/* <Text style={styles.payTitle}>Advances</Text> */}
                  </View>
                  {/* <View
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        paddingVertical: "10px",
                      }}>
                      <Text style={styles.payTitle}>21 August 1981</Text>
                      <Text style={styles.payCount}>{data?.advance}</Text>
                    </View> */}

                  <View
                    style={{
                      alignSelf: "flex-end",
                      display: "flex",
                      height: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                      }}>
                      {/* <Text style={styles.payTitle}>Total:</Text> */}

                      <Text style={styles.payCount}>
                        {_.round(data?.totalMilkQuantity, 2)} Litres
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <Footer />
      </Page>
    </Document>
  );
};

export default VendorBillSlip;
