import React from "react";
import { NavLink } from "react-router-dom";

const BillingReportsStepper = () => {
  return (
    <ul className='flex flex-row px-[2rem] w-full overflow-x-auto whitespace-nowrap py-[1.1rem] gap-[2rem] lg:gap-[4rem] text-[0.8rem] xs:text-[1rem] font-[600] text-[#8897AE] border border-[#DFDFDF]'>
      <li>
        <NavLink
          to={""}
          className={({ isActive }) =>
            `py-[1rem] px-[1rem] cursor-pointer ${
              isActive
                ? "text-[#9FB947] border-b-[0.15rem] border-[#9FB947]"
                : ""
            }`
          }
          end>
          Bills
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"bills-regeneration"}
          className={({ isActive }) =>
            `py-[1rem] px-[1rem] cursor-pointer ${
              isActive
                ? "text-[#9FB947] border-b-[0.15rem] border-[#9FB947]"
                : ""
            }`
          }>
          Bills Regeneration
        </NavLink>
      </li>
    </ul>
  );
};

export default BillingReportsStepper;
