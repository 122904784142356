import { ref, update } from "firebase/database";
import { database } from "../../Firebase";

export const PlayNotificationSound = () => {
    try {
      // Replace 'notification-sound.mp3' with the correct path to your sound file
      const audio = new Audio("https://lvreddy.s3.ap-south-1.amazonaws.com/audio/Abe+Yaar+Notification+Ringtone+Download+-+MobCup.Com.Co.mp3");
      console.log(audio)
      audio.play();
    } catch (error) {
      console.error("Error playing notification sound:", error);
    }
  };
  


export const updateNotificationStatus = (userId,id,status) => {
    
    const notificationsPath = `users/${userId}/notifications/${id}/`;

    update(ref(database, notificationsPath), {
      status,
    });
  };