import { Icon } from "@iconify/react";
import { Button, Option, Select } from "@material-tailwind/react";
import { useMutation } from "@tanstack/react-query";
import Flatpickr from "react-flatpickr";
import { Controller, useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AdminAuthorURL } from "../../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../../stores/AuthContext";
import { queryClient } from "../../../../../../App";

const AddCropInformation = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const { getAccessToken } = useAuth();

  const addCropInformation = async (data) => {
    try {
      console.log("add crop information mutation started...  ", data);
      const token = await getAccessToken();
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.addCropInformation(id);
      const options = {
        method: "POST",
        body: JSON.stringify(data),

        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();
      console.log(response, "add crop");
      console.log(responseObj, "add crop information");
      if (response.ok) {
        toast.success(responseObj?.message);
        reset();
      } else {
        toast.error(responseObj?.message);
      }
    } catch (e) {
      console.log(e);
      throw new Error(e?.message);
    }
  };

  const addCropInformationMutation = useMutation({
    mutationFn: addCropInformation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchCropsList"] });
    },
  });

  const submitData = async (data) => {
    try {
      console.log(data, "crop information add data");
      const cropData = {
        remarks: data?.remarks || "",
        cropName: data?.cropName || "",
        fieldSize: data?.landInAcres || "",
        cultivationStartDate: data?.dateOfCultivationStarted
          ? data?.dateOfCultivationStarted[0].toISOString()
          : "",
        cultivationEndDate: data?.dateOfCultivationEnded
          ? data?.dateOfCultivationEnded[0].toISOString()
          : "",
      };

      await addCropInformationMutation.mutate(cropData);
    } catch (e) {
      console.log(e);
    }
    console.log(data, "crop information add data");
  };
  return (
    <form
      onSubmit={handleSubmit(submitData)}
      action=''
      className='flex flex-col items-center px-[1rem]'>
      <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-[2rem] m-4 w-full md:w-[700px] lg:w-[800px]'>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='crop-name' className='text-[14px] text-[#3D4A5C]'>
            Crop Name<span className=' text-[#f00]'>*</span>
          </label>

          <Controller
            control={control}
            name='cropName'
            rules={{
              required: "*This field is required",
            }}
            render={({ field }) => (
              <Select label='Select crop name' {...field}>
                <Option value={"Supernavier"}>Supernavier</Option>
                <Option value={"Maize"}>Maize</Option>
              </Select>
            )}
          />
          {errors?.cropName && (
            <p className='text-[#E92215] text-sm'>
              {errors?.cropName?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='landInAcres' className='text-[14px] text-[#3D4A5C]'>
            Land(Acres)<span className=' text-[#f00]'>*</span>
          </label>
          <input
            id='landInAcres'
            type='number'
            step='any'
            name='landInAcres'
            {...register("landInAcres", {
              required: "*This field is required",
            })}
            className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
            placeholder='15acres'
          />
          {errors?.landInAcres && (
            <p className='text-[#E92215] text-sm'>
              {errors?.landInAcres?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label
            htmlFor='dateOfCultivation'
            className='text-[14px] text-[#3D4A5C]'>
            Date of Cultivation<span className=' text-[#f00]'>*</span>
          </label>

          <Controller
            name='dateOfCultivationStarted'
            control={control}
            rules={{ required: "*This field is required" }}
            render={({ field, fieldState: { error } }) => (
              <>
                <Flatpickr
                  {...field}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                  className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                  placeholder='Select Date'
                />
              </>
            )}
          />
          {errors?.dateOfCultivationStarted && (
            <p className='text-[#E92215] text-sm'>
              {errors?.dateOfCultivationStarted?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='remarks' className='text-[14px] text-[#3D4A5C]'>
            Date of Cultivation Ended
          </label>
          <Controller
            name='dateOfCultivationEnded'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Flatpickr
                  {...field}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                  className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                  placeholder='Select Date'
                />
              </>
            )}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] lg:col-span-2'>
          <label htmlFor='remarks' className='text-[14px] text-[#3D4A5C]'>
            Remarks
          </label>
          <textarea
            rows={5}
            id='remarks'
            type='date'
            name='remarks'
            {...register("remarks")}
            className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
            placeholder='Write something here...'
          />
        </div>
      </div>
      <div className='flex flex-row justify-end gap-4  my-20 w-full md:w-[700px] lg:w-[800px]'>
        <Button
          onClick={() => navigate(-1)}
          variant='outlined'
          color='green'
          className=' border-[#9FB947] w-[8rem] flex justify-center items-center  gap-3 capitalize '>
          <Icon
            icon='akar-icons:arrow-left'
            className='text-[1.5rem] text-[#8897AE] cursor-pointer '
          />
          <span className='text-[#8897AE]'>Cancel</span>
        </Button>
        {addCropInformationMutation?.isPending ? (
          <div className='flex justify-center items-center w-[8rem]'>
            <ThreeDots
              height='50'
              width='50'
              radius='9'
              color='#9FB947'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClassName=''
              visible={true}
            />
          </div>
        ) : (
          <Button
            type='submit'
            color='green'
            className='w-[8rem] flex justify-center items-center capitalize  bg-[#9FB947] '>
            Save
          </Button>
        )}
      </div>
    </form>
  );
};

export default AddCropInformation;
