import { useNavigate, useOutletContext } from "react-router-dom";

import { Icon } from "@iconify/react";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { AdminAuthorURL } from "../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../stores/AuthContext";
import { dataActions } from "../../../../../stores/IndexRedux";
import { customStyles } from "../../../../helpers/CustomDataTableStyles";
import LvreddyLoader from "../../../../loaders/LvreddyLoader";
import CustomPagination from "../../../animalModule/animalManageLists/CustomPagination";

const LeaseLandList = () => {
  const [page, setPage] = useState(1);
  const [leaseLandList, setLeaseLandList] = useState([]);
  const [totalData, setTotalData] = useState(0);

  const { searchKey } = useOutletContext();

  const { fromDateStart, toDateStart, fromDateEnd, toDateEnd, leaseId } =
    useSelector((state) => state?.leaseLand?.leaseLandFilters);

  const navigate = useNavigate();

  const { getAccessToken } = useAuth();

  const dispatch = useDispatch();

  const columns = [
    // table columns
    {
      name: "S.No",

      id: "s.no",
      cell: (row, index) => index + 1,
    },
    {
      name: "Land Code",
      id: "id",
      selector: (row) => row.id,
      width: "150px",
    },
    {
      name: "Type of Land",
      id: "landType",
      selector: (row) => row.landType,
      width: "150px",
    },
    {
      name: "Land(Acres)",
      id: "fieldSize",
      selector: (row) => row.fieldSize,
      width: "150px",
    },
    {
      name: "Lease Starting Date",
      id: "leaseStartDate",
      selector: (row) => moment(row.leaseStartDate).format("DD-MM-YYYY"),
      grow: 1,
      width: "180px",
    },
    {
      name: "Lease Ending Date",
      id: "leaseEndDate",
      selector: (row) => moment(row.leaseEndDate).format("DD-MM-YYYY"),
      grow: 1,
      width: "180px",
    },
    {
      name: "Lease Price(year)",
      id: "annualCostPerUnit",
      selector: (row) => row.annualCostPerUnit,
      width: "200px",
    },
    {
      name: "Total Cost",
      id: "totalCost",
      selector: (row) => row.totalCost,
      width: "200px",
    },
    {
      name: "remarks",
      id: "remarks",
      selector: (row) => row.remarks,
    },
    {
      name: "Action",
      id: "action",
      cell: (row) => (
        <div className='flex space-x-2'>
          <div className='border rounded p-1 cursor-po inter'>
            <Icon
              icon={"material-symbols:edit"}
              className='text-[1.7rem] cursor-pointer text-[#5E718D]  '
              onClick={() =>
                navigate(`/greenFooder/lease-land-view/${row?._id}`, {
                  relative: "path",
                })
              }
              // navigate to all animals view page on edit icon click
            />
          </div>

          <div className='border rounded p-1 cursor-pointer'>
            <RiDeleteBinLine className='text-[1.7rem] cursor-pointer rounded text-[#5E718D] ' />
          </div>
        </div>
      ),
    },
  ];

  const leaseInformationData = [
    {
      landCode: "LVR001",
      typeOfLand: "Black Soil",
      landAcres: "15acres",
      leaseStartingDate: "28/02/2023",
      leaseEndingDate: "14/03/2023",
      leasePriceYear: "28/02/2023",
      remarks: "No",
    },
    {
      landCode: "LVR001",
      typeOfLand: "Black Soil",
      landAcres: "15acres",
      leaseStartingDate: "28/02/2023",
      leaseEndingDate: "14/03/2023",
      leasePriceYear: "28/02/2023",
      remarks: "No",
    },
    {
      landCode: "LVR001",
      typeOfLand: "Black Soil",
      landAcres: "15acres",
      leaseStartingDate: "28/02/2023",
      leaseEndingDate: "14/03/2023",
      leasePriceYear: "28/02/2023",
      remarks: "No",
    },
    {
      landCode: "LVR001",
      typeOfLand: "Black Soil",
      landAcres: "15acres",
      leaseStartingDate: "28/02/2023",
      leaseEndingDate: "14/03/2023",
      leasePriceYear: "28/02/2023",
      remarks: "No",
    },
    {
      landCode: "LVR001",
      typeOfLand: "Black Soil",
      landAcres: "15acres",
      leaseStartingDate: "28/02/2023",
      leaseEndingDate: "14/03/2023",
      leasePriceYear: "28/02/2023",
      remarks: "No",
    },
    {
      landCode: "LVR001",
      typeOfLand: "Black Soil",
      landAcres: "15acres",
      leaseStartingDate: "28/02/2023",
      leaseEndingDate: "14/03/2023",
      leasePriceYear: "28/02/2023",
      remarks: "No",
    },
    {
      landCode: "LVR001",
      typeOfLand: "Black Soil",
      landAcres: "15acres",
      leaseStartingDate: "28/02/2023",
      leaseEndingDate: "14/03/2023",
      leasePriceYear: "28/02/2023",
      remarks: "No",
    },
    {
      landCode: "LVR001",
      typeOfLand: "Black Soil",
      landAcres: "15acres",
      leaseStartingDate: "28/02/2023",
      leaseEndingDate: "14/03/2023",
      leasePriceYear: "28/02/2023",
      remarks: "No",
    },
    {
      landCode: "LVR001",
      typeOfLand: "Black Soil",
      landAcres: "15acres",
      leaseStartingDate: "28/02/2023",
      leaseEndingDate: "14/03/2023",
      leasePriceYear: "28/02/2023",
      remarks: "No",
    },
  ];

  const fetchLeaseLandLists = async (download) => {
    try {
      const token = await getAccessToken();
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.fetchLeaseLandList(
          page,
          searchKey,
          download,
          fromDateStart,
          toDateStart,
          fromDateEnd,
          toDateEnd,
          leaseId
        );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(response, "lease land list");
      console.log(responseData, "response ");
      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      throw new Error("Failed to fetch data");
    }
  };

  const { data: downloadD } = useQuery({
    queryKey: [
      "fetchLeaseLandList",
      fromDateStart,
      toDateStart,
      fromDateEnd,
      toDateEnd,
      leaseId,
    ],
    queryFn: async () => await fetchLeaseLandLists(true),
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchLeaseLandList",
      fromDateStart,
      toDateStart,
      fromDateEnd,
      toDateEnd,
      leaseId,
      page,
      searchKey,
    ],
    queryFn: async () => await fetchLeaseLandLists(false),
  });

  useEffect(() => {
    if (data) {
      setLeaseLandList(data?.limitedData);

      setTotalData(data?.totalData);
    } else {
      setLeaseLandList([]);

      setTotalData(0);
    }
  }, [data]);

  useEffect(() => {
    if (downloadD) {
      let totalAcres = 0;
      let totalCost = 0;

      downloadD?.limitedData?.forEach((item) => {
        totalAcres += parseFloat(item.fieldSize);
        totalCost += parseFloat(item.totalCost);
      });

      const updatedData = downloadD?.limitedData?.map((item) => ({
        ...item,
        leaseStartDate: moment(item.leaseStartDate).format("DD-MM-YYYY"),
        leaseEndDate: moment(item.leaseEndDate).format("DD-MM-YYYY"),
      }));

      dispatch(dataActions.updateData(updatedData));
      dispatch(
        dataActions.updateTotalStats([
          totalAcres,
          "",
          "",
          "",
          `₹ ${_.round(totalCost, 2)}`,
        ])
      );
      console.log(downloadD, "code download data");
    } else {
      dispatch(dataActions.updateData([]));
      dispatch(dataActions.updateTotalStats([]));
    }
  }, [downloadD]);

  return (
    <div className='p-3 pt-0 animal-table '>
      <div className='m-2 '>
        {isLoading ? (
          <div className='flex justify-center items-center p-[2.5rem]'>
            <LvreddyLoader />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={leaseLandList}
            customStyles={customStyles}
            pagination
            paginationComponent={() =>
              CustomPagination({
                rowsPerPage: 10,
                rowCount: totalData,
                currentPage: page,
                onChangePage: setPage,
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default LeaseLandList;
