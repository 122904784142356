import { Icon } from "@iconify/react";
import { Button, Option, Select } from "@material-tailwind/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { Controller, useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AdminAuthorURL } from "../../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../../stores/AuthContext";

const EditCropInformation = () => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const { cropId } = useParams();
  const { getAccessToken } = useAuth();

  const updateCropInformation = async (data) => {
    try {
      const token = await getAccessToken();
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.updateCropDetailsByCropId(
          cropId
        );

        console.log(url)
      const options = {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();
      console.log(response, "update crop response");
      console.log(responseData, "update crop response data");

      if (response.ok) {
        toast.success(responseData.message);
      } else {
        throw new Error(responseData?.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateCropInformationMutation = useMutation({
    mutationFn: updateCropInformation,
    onSuccess: () => {},
  });

  const fetchCropDetails = async () => {
    try {
      console.log("fetching crop details started...");
      const token = await getAccessToken();
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.fetchCropDetailsByCropId(
          cropId
        );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(response, "crop details response");
      console.log(responseData, "crop details response data");

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      throw new Error(e?.message);
    }
  };

  const { data } = useQuery({
    queryKey: ["fetchCropsDetails", cropId],
    queryFn: fetchCropDetails,
  });

  const submitData = async (data) => {
    try {
      const updateData = {
        cropName: data.cropName,
        fieldSize: data.landInAcres,
        cultivationStartDate:
          typeof data?.dateOfCultivationStarted === "string"
            ? data.dateOfCultivationStarted
            : data?.dateOfCultivationStarted[0].toISOString(),
        cultivationEndDate:
          typeof data?.dateOfCultivationEnded === "string"
            ? data?.dateOfCultivationEnded
            : data?.dateOfCultivationEnded[0]?.toISOString(),
        remarks: data.remarks,
      };

      console.log(updateData, "update data");

      await updateCropInformationMutation.mutate(updateData);
    } catch (e) {
      // Handle error here
    }
  };

  useEffect(() => {
    if (data) {
      setValue("cropName", data?.cropName);
      setValue("landInAcres", data?.fieldSize);
      setValue("dateOfCultivationStarted", data?.cultivationStartDate);
      setValue("dateOfCultivationEnded", data?.cultivationEndDate);
      setValue("remarks", data?.remarks);
    }
  }, [data]);

  console.log("edit crop information called");

  return (
    <form
      onSubmit={handleSubmit(submitData)}
      action=''
      className='flex flex-col items-center px-[1rem]'>
      <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-[2rem] m-4 w-full md:w-[700px] lg:w-[800px]'>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='crop-name' className='text-[14px] text-[#3D4A5C]'>
            Crop Name<span className=' text-[#f00]'>*</span>
          </label>

          <Controller
            control={control}
            name='cropName'
            rules={{
              required: "*This field is required",
            }}
            render={({ field }) => (
              <Select {...field} label='Crop Name' className=''>
                <Option value='Supernavier'>Supernavier</Option>
                <Option value='Maize'>Maize</Option>
              </Select>
            )}
          />
          {errors?.cropName && (
            <p className='text-[#E92215] text-sm'>
              {errors?.cropName?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='landInAcres' className='text-[14px]'>
            Land(Acres)<span className=' text-[#f00]'>*</span>
          </label>
          <input
            id='landInAcres'
            type='number'
            step='any'
            name='landInAcres'
            {...register("landInAcres", {
              required: "*This field is required",
            })}
            className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
            placeholder='15acres'
          />
          {errors?.landInAcres && (
            <p className='text-[#E92215] text-sm'>
              {errors?.landInAcres?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='dateOfCultivation' className='text-[14px]'>
            Date of Cultivation<span className=' text-[#f00]'>*</span>
          </label>

          <Controller
            name='dateOfCultivationStarted'
            control={control}
            rules={{ required: "*This field is required" }}
            render={({ field, fieldState: { error } }) => (
              <>
                <Flatpickr
                  {...field}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                  className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                  placeholder='Select Date'
                />
              </>
            )}
          />
          {errors?.dateOfCultivationStarted && (
            <p className='text-[#E92215] text-sm'>
              {errors?.dateOfCultivationStarted?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='remarks' className='text-[14px]'>
            Date of Cultivation Ended
          </label>
          <Controller
            name='dateOfCultivationEnded'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Flatpickr
                  {...field}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                  className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                  placeholder='Select Date'
                />
              </>
            )}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] lg:col-span-2'>
          <label htmlFor='remarks' className='text-[14px]'>
            Remarks
          </label>
          <textarea
            rows={5}
            id='remarks'
            name='remarks'
            {...register("remarks")}
            type='date'
            className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
            placeholder='Write something here...'
          />
        </div>
      </div>
      <div className='flex flex-row justify-end gap-4  my-20 w-full md:w-[700px] lg:w-[800px]'>
        <Button
          onClick={() => navigate(-1)}
          variant='outlined'
          color='green'
          className=' border-[#9FB947] w-[8rem] flex justify-center items-center  gap-3 capitalize '>
          <Icon
            icon='akar-icons:arrow-left'
            className='text-[1.5rem] text-[#8897AE] cursor-pointer '
          />
          <span className='text-[#8897AE]'>Cancel</span>
        </Button>
        {updateCropInformationMutation?.isPending ? (
          <div className='flex justify-center items-center w-[8rem]'>
            <ThreeDots
              height='50'
              width='50'
              radius='9'
              color='#9FB947'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClassName=''
              visible={true}
            />
          </div>
        ) : (
          <Button
            type='submit'
            color='green'
            className='w-[8rem] flex justify-center items-center capitalize  bg-[#9FB947] '>
            Save
          </Button>
        )}
      </div>
    </form>
  );
};

export default EditCropInformation;
