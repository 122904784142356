import { RiDeleteBinLine } from "react-icons/ri";

import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { customStyles } from "../../../../../helpers/CustomDataTableStyles";
import CustomCheckbox from "../../../../animalModule/animalManageLists/CustomCheckbox";

const SaleInformationEdit = ({ editStatus }) => {
  const navigate = useNavigate();

  const { fromDate, toDate } = useSelector(
    (state) => state?.leaseLand?.specificLandSalesListFilter
  );

  const columns = [
    // table columns
    {
      name: "S.No",

      id: "s.no",
      cell: (row, index) => index + 1,
    },

    {
      name: "Feed Type",
      id: "feedType",
      selector: (row) => row.feedType,
    },
    {
      name: "Customer Name",
      id: "customerName",
      selector: (row) => row.customerName,
      width: "200px",
    },
    {
      name: "Mobile Number",
      id: "mobileNumber",
      selector: (row) => row.mobileNumber,
      width: "200px",
    },
    {
      name: "Address",
      id: "address",
      selector: (row) => row.address,
    },
    {
      name: "Price(1 Qun)",
      id: "pricePerQuintals",
      selector: (row) => row.pricePerQuintals,
      width: "140px",
    },

    {
      name: "Quantity(Qun)",
      id: "quantity",
      selector: (row) => row.quantity,
      width: "190px",
    },
    {
      name: "Unit Price1 (Qun)",
      id: "unitPrice",
      selector: (row) => row.unitPrice,
    },
    {
      name: "Total Price",
      id: "totalPrice",
      selector: (row) => row.totalPrice,
    },
    {
      name: "Date",
      id: "date",
      selector: (row) => row.date,
    },
    {
      name: "Remarks",
      id: "remarks",
      selector: (row) => row.remarks,
    },

    {
      name: "Action",
      id: "action",
      cell: (row) => (
        <div className='flex space-x-2'>
          <div className='border rounded p-1 cursor-pointer'>
            <Icon
              onClick={() => {
                navigate(
                  `/greenFooder/lease-land-view/sale-information-edit/edit/${row.id}`
                );
              }}
              icon={"material-symbols:edit"}
              className='text-[1.7rem] cursor-pointer text-[#5E718D]  '
              // navigate to all animals view page on edit icon click
            />
          </div>
          <div className='border rounded p-1 cursor-pointer'>
            <RiDeleteBinLine className='text-[1.7rem] cursor-pointer rounded text-[#5E718D] ' />
          </div>
        </div>
      ),
    },
  ];

  const { data, isLoading } = useSelector({});

  return (
    <div>
      <Outlet />

      <div className='bg-[#fff]  md:mx-[0.5rem] my-[0.5rem] flex-1 overflow-y-auto py-[1rem] flex flex-col gap-y-[2rem] rounded'>
        <div className='flex flex-col gap-y-[2rem] pt-4 pb-6 border-b'>
          <div className='flex flex-col px-[0.5rem] xs:[1rem] md:px-[2rem] flex flex-row justify-between'>
            <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center gap-[1.5rem] py-[1.5rem]'>
              <div className='flex flex-col lg:flex-row  gap-y-[1rem]'>
                <div
                  className='w-full lg:mr-[3rem]   flex flex-row items-center text-[#555555] px-[1rem] py-[0.5rem] rounded-md gap-[0.5rem]'
                  style={{
                    background: "#FFFFFF",
                    border: "0.1px solid #DFDFDF",
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
                    borderRadius: "5.09937px",
                  }}>
                  <Icon icon='ri:search-line' className='text-[1.4rem]' />
                  <input
                    type='text'
                    className='text-[#7C7C7C] placeholder-[#7C7C7C]  text-sm outline-none'
                    placeholder='Search name'
                  />
                </div>

                <div className='w-full justify-between flex flex-row gap-[1rem] max-xs:text-[0.8rem]'>
                  <div
                    className='px-[1rem] max-md:w-full py-[0.5rem]'
                    style={{
                      background: "#FFFFFF",
                      boxShadow: "0px 4px 16px rgba(0, 153, 112, 0.08)",
                      borderRadius: "6px",
                    }}>
                    <input
                      type='date'
                      placeholder='Date'
                      className='text-[#7C7C7C] placeholder-[#7C7C7C] w-full outline-none '
                    />
                  </div>

                  <button
                    style={{
                      background:
                        "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                    }}
                    className='text-white w-full lg:hidden rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[1rem] py-[0.5rem] md:hidden'>
                    <span> Filters</span>
                  </button>
                </div>
              </div>
              <div className='max-md:w-full  max-xs:text-[0.8rem] whitespace-nowrap max-md:justify-between text-white max-md:flex  xl:flex flex-row gap-[1rem] md:gap-[2rem] '>
                <Link to={"add"}>
                  <button
                    onClick={() => NaN}
                    style={{
                      background:
                        "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                    }}
                    className='rounded-md  max-md:w-full flex justify-center flex-row items-center gap-[0.5rem] px-[1rem] py-[0.5rem]'>
                    <Icon
                      icon='material-symbols:add'
                      width={25}
                      className='text-[0.5em] xs:text-[0.8em]'
                    />
                    <span>Add New </span>
                  </button>
                </Link>

                <button
                  style={{
                    background:
                      "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                  }}
                  className='rounded-md max-md:w-full justify-center flex flex-row gap-[0.5rem] px-[1rem] py-[0.5rem] items-center'>
                  <Icon
                    icon='material-symbols:download-rounded'
                    className='text-[1rem] xs:text-[1.5rem]'
                  />
                  <span>Download Report</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* steps={steps} currentStep={currentStep} */}

        <div className='p-3 pt-0 animal-table '>
          <div className='m-2 '>
            <DataTable
              columns={columns}
              data={[]}
              customStyles={customStyles}
              selectableRows
              selectableRowsComponent={CustomCheckbox}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleInformationEdit;
