import { Icon } from "@iconify/react";
import { Option, Select, ThemeProvider } from "@material-tailwind/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Flatpickr from "react-flatpickr";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { queryClient } from "../../../../App";
import { AdminAuthorURL } from "../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../stores/AuthContext";
import { dataActions } from "../../../../stores/IndexRedux";
import { customStyles } from "../../../helpers/CustomDataTableStyles";
import { theme } from "../../../helpers/CustomTheme";
import VendorBillSlip from "../../../helpers/VendorBillSlip";
import LvreddyLoader from "../../../loaders/LvreddyLoader";
import CustomPagination from "../../animalModule/animalManageLists/CustomPagination";

const BillsRegeneration = () => {
  const [regenerateBillsFilter, setRegenerateFilter] = useState(false);

  const [regenerateBillFromDate, setRegenerateBillFromDate] = useState("");
  const [regenerateBillToDate, setRegenerateBillToDate] = useState("");
  const [regenerateBillFromDateShift, setRegenerateBillFromDateShift] =
    useState("1");
  const [regenerateBillToDateShift, setRegenerateBillToDateShift] =
    useState("1");

  const [regenerateBillsApproveFilter, setRegenerateApproveFilter] =
    useState(false);

  const [regenerateBillApproveFromDate, setRegenerateBillApproveFromDate] =
    useState("");
  const [regenerateBillApproveToDate, setRegenerateBillApproveToDate] =
    useState("");
  const [
    regenerateBillApproveFromDateShift,
    setRegenerateBillApproveFromDateShift,
  ] = useState("1");
  const [
    regenerateBillApproveToDateShift,
    setRegenerateBillApproveToDateShift,
  ] = useState("1");
  const { searchKey } = useOutletContext();
  const [totalVendorBills, setTotalVendorBills] = useState([]);
  const dispatch = useDispatch();

  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const { fromDate, toDate, vendorId } = useSelector(
    (state) => state.filterManage?.vendorBillGeneration
  );

  const { getAccessToken } = useAuth();

  function formatNumber(num) {
    const isNegative = num < 0;
    const absNum = Math.abs(num);
    let formattedNum;

    if (absNum >= 1000000000) {
      formattedNum = (absNum / 1000000000).toPrecision(3) + "B";
    } else if (absNum >= 1000000) {
      formattedNum = (absNum / 1000000).toPrecision(3) + "M";
    } else if (absNum >= 1000) {
      formattedNum = (absNum / 1000).toPrecision(4) + "K";
    } else {
      formattedNum = absNum.toString();
    }

    return isNegative ? "-" + formattedNum : formattedNum;
  }

  const regenerateBillingReports = async () => {
    console.log("fucn called");
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.regenerateVendorBillRecords(
        regenerateBillFromDate || "",
        regenerateBillToDate || "",
        regenerateBillFromDateShift,
        regenerateBillToDateShift
      );

      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();

      console.log(responseObj, " Regenerate Bills Response");
      console.log(response);
      if (response.ok) {
        toast.success(responseObj?.message);
      } else {
        toast.error(responseObj?.message);
        //throw new Error("Failed to regenerate bills");
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const approveRegeneratingBillingReports = async () => {
    console.log("fucn called");
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.approveRegenerateBillRecords(
        regenerateBillFromDate,
        regenerateBillToDate
      );

      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();

      console.log(responseObj, "approved Regenerate Bills Response");
      console.log(response);
      if (response.ok) {
        toast.success(responseObj?.message);
      } else {
        toast.error(responseObj?.message);
        // new Error("Failed to regenerate bills");
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const approveRegeneratedBilllingReportsMutation = useMutation({
    mutationFn: approveRegeneratingBillingReports,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchVendorBills"] });

      setRegenerateBillApproveFromDate("");
      setRegenerateBillApproveToDate("");
    },
  });

  const regenerateBillMutation = useMutation({
    mutationFn: regenerateBillingReports,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [""] });

      setRegenerateBillFromDate("");
      setRegenerateBillToDate("");
      setRegenerateBillFromDateShift("1");
      setRegenerateBillToDateShift("1");
    },
  });

  const fetchPreparedVendorBills = async (download) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchBills(
        vendorId ? vendorId?.value : "",
        fromDate,
        toDate,
        searchKey,
        "PREPARED",
        page,
        download
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}  `,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(response, "preparedc bill response");
      console.log(responseData);

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchPreparedVendorBills",
      fromDate,
      toDate,
      vendorId,
      searchKey,
      page,
    ],
    queryFn: async () => await fetchPreparedVendorBills(false),
  });

  const { data: downloadData } = useQuery({
    queryKey: [
      "fetchPreparedVendorBills",
      fromDate,
      toDate,
      vendorId,
      searchKey,
    ],
    queryFn: async () => await fetchPreparedVendorBills(true),
  });

  useEffect(() => {
    if (data) {
      console.log(data, "bill generation");
      setTotalVendorBills(data?.limitedData);
      setTotalData(data?.totalData);
    }
  }, [data]);

  useEffect(() => {
    if (downloadData) {
      let totalNetPayment = 0;
      let subTotalMilkCost = 0;
      let totalMilkQuantity = 0;
      let totalCarriedForwardPayment = 0;
      let totalPaidAmount = 0;

      downloadData?.limitedData?.forEach((item) => {
        totalNetPayment += item?.netPayment;
        subTotalMilkCost += item?.subTotalMilkCost;
        totalMilkQuantity += item?.totalMilkQuantity;
        totalCarriedForwardPayment += item?.carriedForwardPayment;

        totalPaidAmount += item?.totalPaidAmount;
      });
      const updatedData = downloadData?.limitedData?.map((item) => ({
        ...item,
        startDate: moment(item?.cStartDate).format("DD-MM-YYYY"),
        endDate: moment(item?.cEndDate).format("DD-MM-YYYY"),

        netPayment: _.round(item?.netPayment, 2),
        subTotalMilkCost: _.round(item?.subTotalMilkCost, 2),
        totalMilkQuantity: _.round(item?.totalMilkQuantity, 2),
        totalPaidAmount: _.round(item?.totalPaidAmount, 2),
      }));

      dispatch(dataActions.updateData(updatedData));

      dispatch(
        dataActions?.updateTotalStats([
          `₹ ${formatNumber(_.round(totalNetPayment, 2))}`,
          ``,
          `₹ ${formatNumber(_.round(subTotalMilkCost, 2))}`,
          `${formatNumber(_.round(totalMilkQuantity, 2))} L`,
          `₹ ${formatNumber(_.round(totalCarriedForwardPayment, 2))}`,
          `₹ ${formatNumber(_.round(totalPaidAmount, 2))}`,
        ])
      );
    } else {
      dispatch(dataActions.updateData([]));

      dispatch(dataActions?.updateTotalStats([]));
    }
  }, [downloadData]);

  const columns = [
    {
      name: "Vendor Id",
      id: "vendorId",
      selector: (row) => row?.vendorId,
    },
    {
      name: "Vendor Name",
      id: "vendorName",
      selector: (row) => row?.vendorName,
      width: "200px",
    },
    {
      name: "Start Date",
      id: "startDate",
      selector: (row) => moment(row?.cStartDate).format("DD-MM-YYYY"),
      width: "180px",
    },
    {
      name: "End Date",
      id: "endDate",
      selector: (row) => moment(row?.cEndDate).format("DD-MM-YYYY"),
      width: "190px",
    },
    {
      name: "Milk Collection Days",

      selector: (row) => row?.totalCollectionDays,
      width: "180px",
    },
    {
      name: "Net Payment",
      id: "netPayment",
      selector: (row) =>
        row?.currency?.symbol + " " + _.round(row?.netPayment, 2),
      width: "190px",
    },
    {
      name: "Price Per Litre",
      id: "pricePerLitre",
      selector: (row) => row?.currency?.symbol + " " + row?.pricePerLitre,
      width: "220px",
    },
    {
      name: "Sub-Total Milk Cost",
      selector: (row) =>
        row?.currency?.symbol + " " + _.round(row?.subTotalMilkCost, 2),
      width: "210px",
    },
    {
      name: "Total Milk Quantity",
      id: "totalMilkQuantity",
      selector: (row) => _.round(row?.totalMilkQuantity, 2) + "  L",
      width: "190px",
    },
    {
      name: "Carried Forward Payment",
      id: "carriedForwardPayment",
      selector: (row) =>
        row?.currency?.symbol + " " + _.round(row?.carriedForwardPayment, 2),
      width: "220px",
    },
    {
      name: "Total Paid Amount",
      id: "totalPaidAmount",
      selector: (row) =>
        row?.currency?.symbol + " " + _.round(row?.totalPaidAmount, 2),
      width: "220px",
    },

    {
      name: "Status",

      selector: (row) => row?.status,
      cell: (row) => (
        <p className='text-[#f5a142] font-medium text-[0.875rem]'>
          {row?.status}
        </p>
      ),
      minWidth: "250px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className='border rounded p-1 cursor-pointer'>
          <PDFDownloadLink
            fileName='billSlip.pdf'
            document={<VendorBillSlip data={row} />}>
            <Icon
              icon={"material-symbols:download"}
              className='text-[1.7rem] cursor-pointer rounded text-[#5E718D] '
              // onClick={() => Navigate("/admin")} //navigate to admin page on delete icon click
            />
          </PDFDownloadLink>
        </div>
      ),
    },
  ];
  return (
    <ThemeProvider value={theme}>
      <div className='flex flex-col gap-[2rem] w-full'>
        <div className='w-full flex justify-end items-center gap-[1rem] self-end'>
          <div className=' relative '>
            <button
              onClick={() => setRegenerateFilter(!regenerateBillsFilter)}
              style={{
                background:
                  "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
              }}
              className='text-white outline-none  max-w-[10rem] whitespace-nowrap shrink-0  rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[2rem] py-[0.5rem] '>
              Regenerate Bills
            </button>
            {regenerateBillsFilter && (
              <div className='absolute top-[100%]  right-[20px]  bg-white rounded-[0.5rem] shadow-xl w-fit p-[1rem] z-20'>
                <div className='flex flex-col gap-[1rem] w-full'>
                  <div className='flex flex-col gap-[0.5rem] sm:flex-row sm:items-center'>
                    <div className='flex flex-col gap-[0.5rem]'>
                      <label className='text-[0.875rem] text-[#3D4A5C]'>
                        From Month
                      </label>
                      <Flatpickr
                        options={{
                          dateFormat: "Y-m",
                        }}
                        className='border border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-auto cursor-pointer'
                        placeholder='Select Date'
                        value={regenerateBillFromDate}
                        onChange={(e) => {
                          setRegenerateBillFromDate(
                            moment(e[0]).format("YYYY-MM")
                          );
                        }}
                      />
                    </div>
                    <div className='flex flex-col gap-[0.5rem]'>
                      <label className='text-[0.875rem] text-[#3D4A5C]'>
                        To Month
                      </label>
                      <Flatpickr
                        options={{
                          dateFormat: "Y-m",
                        }}
                        className='border border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full cursor-pointer'
                        placeholder='Select Date'
                        value={regenerateBillToDate}
                        onChange={(e) => {
                          setRegenerateBillToDate(
                            moment(e[0]).format("YYYY-MM")
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col gap-[0.5rem] sm:flex-row sm:items-center w-full'>
                    <div className='flex flex-col gap-[0.5rem] w-full'>
                      <label className='text-[0.875rem] text-[#3D4A5C]'>
                        From Month Shift
                      </label>

                      <Select
                        label='Select shift'
                        value={regenerateBillFromDateShift}
                        onChange={(value) => {
                          setRegenerateBillFromDateShift(value);
                        }}
                        className='rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full cursor-pointer'>
                        <Option value={"1"}>1</Option>
                        <Option value={"2"}>2</Option>
                      </Select>
                    </div>
                    <div className='flex flex-col gap-[0.5rem] w-full'>
                      <label className='text-[0.875rem] text-[#3D4A5C]'>
                        To Month Shift
                      </label>
                      <Select
                        value={regenerateBillToDateShift}
                        onChange={(value) => {
                          setRegenerateBillToDateShift(value);
                        }}
                        label='Select shift'>
                        <Option value={"1"}>1</Option>
                        <Option value={"2"}>2</Option>
                      </Select>
                    </div>
                  </div>
                  <div className='self-end'>
                    {regenerateBillMutation?.isPending ? (
                      <ThreeDots
                        height='50'
                        width='50'
                        radius='9'
                        color='#9FB947'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{}}
                        wrapperClassName=''
                        visible={true}
                      />
                    ) : (
                      <button
                        onClick={async () => {
                          await regenerateBillMutation.mutate();
                        }}
                        type='button'
                        style={{
                          background:
                            "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                        }}
                        className='text-white outline-none  max-w-[10rem] whitespace-nowrap shrink-0  rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[2rem] py-[0.5rem] '>
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className=' relative '>
            <button
              onClick={() =>
                setRegenerateApproveFilter(!regenerateBillsApproveFilter)
              }
              style={{
                background:
                  "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
              }}
              className='text-white outline-none  max-w-[10rem] whitespace-nowrap shrink-0  rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[2rem] py-[0.5rem] '>
              Approve Bills
            </button>
            {regenerateBillsApproveFilter && (
              <div className='absolute top-[100%] right-[20px]  bg-white rounded-[0.5rem] shadow-xl w-fit z-20 p-[1rem]'>
                <div className='flex flex-col gap-[1rem] w-full'>
                  <div className='flex flex-col gap-[0.5rem] sm:flex-row sm:items-center'>
                    <div className='flex flex-col gap-[0.5rem]'>
                      <label className='text-[0.875rem] text-[#3D4A5C]'>
                        From Month
                      </label>
                      <Flatpickr
                        options={{
                          dateFormat: "Y-m",
                        }}
                        className='border border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-auto cursor-pointer'
                        placeholder='Select Date'
                        value={regenerateBillApproveFromDate}
                        onChange={(e) => {
                          setRegenerateBillApproveFromDate(
                            moment(e[0]).format("YYYY-MM")
                          );
                        }}
                      />
                    </div>
                    <div className='flex flex-col gap-[0.5rem]'>
                      <label className='text-[0.875rem] text-[#3D4A5C]'>
                        To Month
                      </label>
                      <Flatpickr
                        options={{
                          dateFormat: "Y-m",
                        }}
                        className='border border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-auto cursor-pointer'
                        placeholder='Select Date'
                        value={regenerateBillApproveToDate}
                        onChange={(e) => {
                          setRegenerateBillApproveToDate(
                            moment(e[0]).format("YYYY-MM")
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className='self-end'>
                    {approveRegeneratedBilllingReportsMutation?.isPending ? (
                      <ThreeDots
                        height='50'
                        width='50'
                        radius='9'
                        color='#9FB947'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{}}
                        wrapperClassName=''
                        visible={true}
                      />
                    ) : (
                      <button
                        onClick={async () => {
                          await approveRegeneratedBilllingReportsMutation.mutate();
                        }}
                        type='button'
                        style={{
                          background:
                            "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                        }}
                        className='text-white outline-none  max-w-[10rem] whitespace-nowrap shrink-0  rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[2rem] py-[0.5rem] '>
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isLoading ? (
          <div className='flex justify-center items-center p-[2.5rem]'>
            <LvreddyLoader />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={totalVendorBills}
            customStyles={customStyles}
            //progressPending={isLoading}
            pagination
            paginationComponent={() =>
              CustomPagination({
                rowsPerPage: 10,
                rowCount: totalData,
                currentPage: page,
                onChangePage: setPage,
              })
            }
            // selectableRows
            // selectableRowsComponent={CustomCheckbox}
            // onSelectedRowsChange={handleSelection}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default BillsRegeneration;
