import React, { useContext, useState, useEffect } from "react"
import { auth } from "../Firebase";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { authAPIUrls } from "../baseUrl/BaseUrl";
import OneSignal from "react-onesignal";
import _ from "lodash";
import NetworkIssuePage from "../components/helpers/NetworkIssuePage";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState();
  const [userInfo, setUserInfo] = useState();
  const [notificationCount, setNotificationCount] = useState(0);
  const [recaptchaVerifier, setrecaptchaVerifier] = useState({});
  const [confirmationResult, setConfirmationResult] = useState();

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleNetworkStatusChange = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleNetworkStatusChange);
    window.addEventListener("offline", handleNetworkStatusChange);

    return;

    window.location.reload();
    window.removeEventListener("online", handleNetworkStatusChange);
    window.removeEventListener("offline", handleNetworkStatusChange);
  }, []);

  let recaptchaVerifier2 = null;
  const setupRecaptcha = (phone) => {
    //console.log("captcha top", recaptchaVerifier);

    if (_.isEmpty(recaptchaVerifier)) {
      recaptchaVerifier2 = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" },
        auth
      );

      console.log(recaptchaVerifier);

      setrecaptchaVerifier(recaptchaVerifier2);
      recaptchaVerifier2.render();

      return recaptchaVerifier2;
    }
    return recaptchaVerifier;
  };

  async function loginWithPhoneNumber(phoneNumber) {
    console.log(phoneNumber, "auth");
    const res = setupRecaptcha(phoneNumber);
    //console.log(res);
    return await signInWithPhoneNumber(auth, phoneNumber, res);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function getAccessToken() {
    return currentUser.getIdToken(true);
  }
  function saveUserRole(value) {
    setUserRole(value);
  }
  const getUserRoleFunc = async (user) => {
    try {
      console.log(user?.accessToken);
      const result = await fetch(authAPIUrls?.fetchUserRole, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      const resultJson = await result.json();
      if (!result.ok) {
        throw new Error("Failed to login");
      }
      console.log(resultJson?.response?._id);
      await OneSignal.login(resultJson?.response?._id);
      setUserRole(resultJson?.response?.role);
      setUserInfo(resultJson?.response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      await getUserRoleFunc(user);

      setLoading(false);
    });
    console.log(unsubscribe);

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    getAccessToken,
    saveUserRole,
    userRole,
    setUserInfo,
    userInfo,
    notificationCount,
    setNotificationCount,
    setupRecaptcha,
    setConfirmationResult,

    confirmationResult,
    loginWithPhoneNumber,
  };

  if (!isOnline) {
    return <NetworkIssuePage />;
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

