import { Option, Select } from "@material-tailwind/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TiDelete } from "react-icons/ti";
import { ThreeDots } from "react-loader-spinner";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { queryClient } from "../../../../../../App";
import { AdminAuthorURL } from "../../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../../stores/AuthContext";

function EditPurchaseInformation({ close, id }) {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({ mode: "onTouched" });

  const [mobileNumber, setMobileNumber] = useState("");
  const [cropIds, setCropIds] = useState([]);

  const [cropId, setCropId] = useState({});

  const [flatpickrInstance, setFlatpickrInstance] = useState(null);

  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);

  const { getAccessToken } = useAuth();

  const updatePurchaseInformation = async (data) => {
    console.log(data, "updated data");
    try {
      const token = await getAccessToken();

      console.log("func called");
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.updatePurchaseInformation(
          id
        );

      console.log(url);
      const options = {
        method: "PUT",
        body: JSON.stringify(data),

        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();
      console.log(responseObj, "add crop information");
      if (response.ok) {
        toast.success(responseObj?.message);
        reset();
        close();
      } else {
        toast.error(responseObj?.message);
      }
    } catch (e) {
      console.log(e, "error");
      // handle error
    }
  };

  const fetchPurchaseDetails = async () => {
    try {
      const token = await getAccessToken();

      const url =
        AdminAuthorURL.fooderManagement.greenFooder.fetchPurchaseDetailsByPurchaseId(
          id
        );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(response);
      console.log(responseData, "response data of purchase details");

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      // handle error
    }
  };

  const updatePurchaseInformationMutation = useMutation({
    mutationFn: updatePurchaseInformation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchPurchasesList"] });
    },
  });

  const { data } = useQuery({
    queryKey: ["fetchPurchaseDetails"],
    queryFn: fetchPurchaseDetails,
  });

  const fetchCropsIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchCropIds(
        searchKey || "",
        ""
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.ok) {
        const updatedData = responseData?.response?.crops?.map((item) => ({
          label: `${item?.cropName}`,
          value: item?._id,
        }));

        console.log(updatedData, "updated data");

        setCropIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  useEffect(() => {
    fetchCropsIds("");
  }, []);

  const loadCropDetails = async (key) => {
    const options = await fetchCropsIds(key);

    return options;
  };

  const submitData = async (data) => {
    console.log(data, "data received");
    try {
      const updatedData = {
        feedType: data?.feedType?.label,
        vendorName: data?.vendorName,
        mobileNumber: data?.mobileNumber,
        address: data?.address,
        city: data?.city,
        state: data?.state,
        pincode: data?.pinCode || "",
        vehicleNumber: data?.vehicleNumber,
        paymentType: data?.paymentType,
        quantity: data?.quantity,

        unitPrice: data?.unitPrice,
        purchaseDate: data?.purchasingDate,
        otherExpenses: data?.otherExpenses,
        remarks: data?.remarks,
      };

      await updatePurchaseInformationMutation.mutate(updatedData);
    } catch (e) {
      console.log(e, "error");
      // throw new Error(e?.message);
    }
  };

  const quantity = watch("quantity");
  const unitPerPrice = watch("unitPrice");

  useEffect(() => {
    console.log(quantity, "quanityt");

    if (quantity && unitPerPrice) {
      const totalPrice = quantity * unitPerPrice;

      console.log(totalPrice);

      setValue("totalPrice", totalPrice);
    }
  }, [quantity, unitPerPrice]);

  useEffect(() => {
    if (data) {
      const selectedCrop = cropIds.find(
        (item) => item.label === data?.feedType
      );
      setCropId(selectedCrop);
      console.log(selectedCrop, "selected crop");
      setValue("feedType", {
        label: selectedCrop?.label,
        value: selectedCrop?.value,
      });
      setValue("vendorName", data?.vendorName);
      setValue("mobileNumber", data?.mobileNumber);
      setValue("address", data?.address);
      setValue("city", data?.city);
      setValue("state", data?.state);
      setValue("pinCode", data?.pincode);
      setValue("vehicleNumber", data?.vehicleNumber);
      setValue("paymentType", data?.paymentType);
      setValue("quantity", data?.quantity);
      setValue("unitPrice", data?.unitPrice);
      setValue("purchaseDate", data?.purchaseDate);
      setValue("otherExpenses", data?.otherExpenses);
      setValue("remarks", data?.remarks);
      setValue(
        "purchasingDate",
        moment(data?.purchaseDate).format("YYYY-MM-DD")
      );
    }
  }, [data]);

  return (
    <div className='h-screen py-10 w-screen px-10 flex justify-center items-center'>
      <div className='bg-[#fff] w-full lg:max-w-[60rem] max-h-[30rem] lg:max-h-[45rem] overflow-y-auto rounded-[0.4rem] '>
        <div className='flex flex-row justify-between items-center py-[1rem] lg:py-[2rem] px-[1.5rem] sm:pl-[2.5rem] sm:pr-[1.5rem]'>
          <h1 className='text-[#000000] font-[600] text-[18px] lg:text-[20px]'>
            Edit Purchase Information
          </h1>
          <button onClick={() => close()} className='text-[#E92215]'>
            <TiDelete className='w-[1.5rem] h-[1.5rem]' />
          </button>
        </div>
        <hr
          style={{
            background: "rgba(0, 0, 0, 0.20)",
          }}
          className='w-full h-[1px]'
        />

        <form
          onSubmit={handleSubmit(submitData)}
          className='px-[1.5rem] flex flex-col gap-[1.5rem] lg:gap-[2.5rem] py-[1.5rem] lg:py-[2.5rem]'>
          <div className='grid grid-cols-1 lg:grid-cols-2  gap-x-[4rem] gap-y-[2rem]   lg:gap-y-[1rem]'>
            <div className='flex flex-col gap-[0.5rem]'>
              <label
                htmlFor='landId'
                className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
                Land Code<span className=' text-[#f00]'>*</span>
              </label>

              <Controller
                name='feedType'
                value={cropId}
                rules={{
                  required: "*This field is required",
                }}
                control={control}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    menuPortalTarget={document.body}
                    menuPlacement='auto'
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        width: "auto",
                        minWidth: "100%",
                      }),
                      menuList: (provided, state) => ({
                        ...provided,
                        maxHeight: "200px",
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                        cursor: "pointer",
                      }),
                    }}
                    theme={(theme) => {
                      return {
                        ...theme,
                        borderRadius: 6,
                        colors: {
                          ...theme.colors,
                          primary25: "#e5e7eb",
                          primary50: "#e5e7eb",
                          primary: "#9FB947",
                        },
                      };
                    }}
                    // onChange={(e) => {
                    //   console.log(e);
                    //   handleChange(index, "employeeId", e?.value);
                    //   handleChange(index, "employeeName", e?.label);
                    // }}

                    className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
                    defaultOptions={cropIds}
                    loadOptions={loadCropDetails}
                  />
                )}
              />

              {errors?.landId && (
                <p className='text-[#E92215] text-sm'>
                  {errors?.landId?.message}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label
                htmlFor='vendorName'
                className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
                Vendor Name<span className=' text-[#f00]'>*</span>
              </label>
              <input
                id='vendorName'
                placeholder='Enter vendor name'
                type='text'
                name='vendorName'
                {...register("vendorName", {
                  required: "*This field is required",
                })}
                className='border w-full border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              />
              {errors?.vendorName && (
                <p className='text-[#E92215] text-sm'>
                  {errors?.vendorName?.message}
                </p>
              )}
            </div>

            <div className='flex flex-col gap-[0.5rem]'>
              <label
                htmlFor='customerName'
                className='text-[14px] text-[#3D4A5C]'>
                Mobile Number<span className=' text-[#f00]'>*</span>
              </label>
              <PhoneInputWithCountry
                initialValueFormat='national'
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C)] text-[0.9rem] placeholder-[#8897AE]'
                defaultCountry='IN'
                countries={["IN"]}
                disableCountrySelection={true}
                countrySelectProps={{ disabled: true }}
                placeholder='Enter your phone number'
                name='mobileNumber'
                rules={{
                  required: "*This field is required.",
                  validate: isValidPhoneNumber,
                }}
                control={control}
                id='mobileNumber'
                onChange={setMobileNumber}
                //value={formatPhoneNumberIntl(phoneNumber)}
              />
              {errors?.mobileNumber?.type === "required" && (
                <div className='text-sm text-[#E92215]'>
                  {errors.mobileNumber.message}
                </div>
              )}
              {errors?.mobileNumber?.type === "validate" && (
                <div className='text-sm  text-[#E92215]'>
                  Invalid Mobile Number
                </div>
              )}
            </div>

            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor='address' className='text-[14px] text-[#3D4A5C]'>
                Address
              </label>
              <input
                id='address'
                type='text'
                name='address'
                {...register("address")}
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='1-40/A, Hitech city'
              />
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor='city' className='text-[14px] text-[#3D4A5C]'>
                City
              </label>
              <input
                id='city'
                type='text'
                name='city'
                {...register("city")}
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='Chennai'
              />
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor='state' className='text-[14px] text-[#3D4A5C]'>
                State
              </label>
              <input
                id='state'
                type='text'
                name='state'
                {...register("state")}
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='Madhya Pradesh'
              />
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor='quantity' className='text-[14px] text-[#3D4A5C]'>
                Pincode
              </label>
              <input
                id='pinCode'
                type='text'
                name='pinCode'
                {...register("pinCode", {
                  min: {
                    value: 6,
                    message: "Pincode must be a minimum of 6 digits.",
                  },
                })}
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='535558'
              />
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label
                htmlFor='vehicleNumber'
                className='text-[14px] text-[#3D4A5C]'>
                Vehicle Number
              </label>
              <input
                id='vehicleNumber'
                type='text'
                name='vehicleNumber'
                {...register("vehicleNumber", {})}
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='535558'
              />
            </div>

            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor='feedType' className='text-[14px] text-[#3D4A5C]'>
                Payment Type<span className=' text-[#f00]'>*</span>
              </label>

              <Controller
                control={control}
                name='paymentType'
                rules={{
                  required: "*This field is required",
                }}
                render={({ field }) => (
                  <Select
                    id='paymentType'
                    {...field}
                    label='Select Payment Type'
                    className=''>
                    <Option value='Net Banking'>Net Banking</Option>
                    <Option value='Upi'>UPI</Option>
                    <Option value='Cash'>Cash</Option>
                  </Select>
                )}
              />
              {errors?.paymentType && (
                <p className='text-[#E92215] text-sm'>
                  {errors?.paymentType?.message}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor='quantity' className='text-[14px] text-[#3D4A5C]'>
                Quantity(Qun)<span className=' text-[#f00]'>*</span>
              </label>
              <input
                id='quantity'
                name='quantity'
                {...register("quantity", {
                  required: "*This field is required",
                  min: {
                    value: 0,
                    message: "Quantity should be greater than or equal to 0",
                  },
                })}
                type='number'
                step='any'
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='10 qun'
              />
              {errors?.quantity && (
                <p className='text-[#E92215] text-sm'>
                  {errors?.quantity?.message}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor='unitPrice' className='text-[14px] text-[#3D4A5C]'>
                Unit Price per 1 Qun<span className=' text-[#f00]'>*</span>
              </label>
              <input
                id='unitPrice'
                name='unitPrice'
                {...register("unitPrice", {
                  required: "*This field is required",
                  min: {
                    value: 0,
                    message: "Quantity should be greater than or equal to 0",
                  },
                })}
                type='number'
                step='any'
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='10 qun'
              />
              {errors?.unitPrice && (
                <p className='text-[#E92215] text-sm'>
                  {errors?.unitPrice?.message}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label
                htmlFor='totalPrice'
                className='text-[14px] text-[#3D4A5C]'>
                Total Price<span className=' text-[#f00]'>*</span>
              </label>
              <input
                id='totalPrice'
                name='totalPrice'
                {...register("totalPrice", {
                  required: "*This field is required",
                  min: {
                    value: 0,
                    message: "Quantity should be greater than or equal to 0",
                  },
                })}
                type='number'
                step='any'
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='10 qun'
              />
              {errors?.totalPrice && (
                <p className='text-[#E92215] text-sm'>
                  {errors?.totalPrice?.message}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label
                htmlFor='purchasingDate'
                className='text-[14px] text-[#3D4A5C]'>
                Purchase Date<span className=' text-[#f00]'>*</span>
              </label>
              <input
                id='purchasingDate'
                name='purchasingDate'
                {...register("purchasingDate", {
                  required: "*This field is required",
                })}
                type='date'
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='10 qun'
              />
              {errors?.purchasingDate && (
                <p className='text-[#E92215] text-sm'>
                  {errors?.purchasingDate?.message}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label
                htmlFor='otherExpenses'
                className='text-[14px] text-[#3D4A5C]'>
                Other Expenses
              </label>
              <input
                id='otherExpenses'
                name='otherExpenses'
                {...register("otherExpenses")}
                type='text'
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='transportation'
              />
            </div>
            <div className='flex flex-col gap-[0.5rem] lg:col-span-2'>
              <label htmlFor='remarks' className='text-[14px] text-[#3D4A5C] '>
                Remarks
              </label>
              <textarea
                rows='5'
                id='remarks'
                type='date'
                className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                placeholder='No Remarks'
                name='remarks'
                {...register("remarks")}
              />
            </div>
          </div>
          <div className='flex flex-row justify-end my-4'>
            {updatePurchaseInformationMutation?.isPending ? (
              <div className='flex justify-center items-center w-[147px]'>
                <ThreeDots
                  height='50'
                  width='50'
                  radius='9'
                  color='#9FB947'
                  ariaLabel='three-dots-loading'
                  wrapperStyle={{}}
                  wrapperClassName=''
                  visible={true}
                />
              </div>
            ) : (
              <button
                type='submit'
                style={{
                  background:
                    "linear-gradient(90deg, #9FB947 -10.36%, #739500 100%)",
                  borderRadius: "6px",
                }}
                className='flex flex-row items-center justify-center h-[53px] w-[147px] mt-[2rem] rounded border-none gap-2 text-[#fff]'>
                <span>Save</span>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditPurchaseInformation;
