import { configureStore } from "@reduxjs/toolkit";
import {
  animalManageSlice,
  appManagementSlice,
  filtrationManageSlice,
} from "./AnimalManageRedux";
import {
  animalRegistrationSlice,
  calfRegistrationSlice,
} from "./AnimalRegistrationRedux";
import { assetSlice } from "./AssetsLiabilities";
import { dataManageSlice } from "./DataRedux";
import { employeeSlice } from "./EmployeeRedux";
import { filterSlice } from "./FilterMangeRedux";
import { footerManagementSlice } from "./FooderManageRedux";
import { vendorSlice } from "./VendorManageRedux";
const store = configureStore({
  reducer: {
    animalRegistration: animalRegistrationSlice.reducer,
    calfRegistration: calfRegistrationSlice.reducer,
    animalManage: animalManageSlice.reducer,
    filtrationManage: filtrationManageSlice.reducer,
    appManagement: appManagementSlice.reducer,
    employee: employeeSlice.reducer,
    data: dataManageSlice.reducer,
    filterManage: filterSlice.reducer,
    assetsLiabilityStat: assetSlice.reducer,
    vendorManage: vendorSlice.reducer,
    leaseLand: footerManagementSlice.reducer,
  },
});

export const animalRegistrationActions = animalRegistrationSlice.actions;
export const calfRegistrationActions = calfRegistrationSlice.actions;
export const animalManageActions = animalManageSlice.actions;
export const filtrationManageActions = filtrationManageSlice.actions;
export const appManagementActions = appManagementSlice.actions;
export const employeeActions = employeeSlice.actions;
export const dataActions = dataManageSlice.actions;
export const filterManageActions = filterSlice.actions;
export const assetStateManageActions = assetSlice.actions;

export const vendorManageActions = vendorSlice.actions;

export const leaseLandRegistrationActions = footerManagementSlice.actions;

export default store;
