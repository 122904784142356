export // custom styling for all animals table
const customStyles = {
  headRow: {
    style: {
      fontWeight: "bold",
    },
  },
  head: {
    style: {
      boxShadow: "0px 0px 15px 0px #ddd, 0px 4px 16px rgba(0, 153, 112, 0.25) ",
      border: "none",
      marginBottom: "10px",
      color: "#1A3032",
      padding: "10px 20px",
      borderRadius: "5px",
      fontSize: "14px",
    },
  },
  header: {
    style: {
      borderRadius: "5px",
    },
  },
  rows: {
    style: {
      boxShadow: "0px 0px 20px 0px #ddd ",
      marginBottom: "4px",
      color: "#1A3032",
      padding: "10px 20px",
      borderRadius: "5px",
      fontSize: "14px",
    },
  },
  pagination: {
    style: {
      boxShadow: "10px 5px 5px #ddd",
      marginBottom: "5px",
    },
  },
  table: {
    style: {
      overflow: "visible",
      minWidth: "1100px",
    },
  },
  tableWrapper: {
    style: {
      overflow: "visible",
    },
  },
  responsiveWrapper: {
    style: {
      overflowX: "auto", // table scroll on x axis
      padding: "1rem", // for showing shadow
    },
  },
};
