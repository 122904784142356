import { Icon } from "@iconify/react";
import {
  Button,
  Option,
  Select,
  ThemeProvider,
} from "@material-tailwind/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Controller, useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { AdminAuthorURL } from "../../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../../stores/AuthContext";
import { theme } from "../../../../../helpers/CustomTheme";
import { queryClient } from "../../../../../../App";

const AddSaleInformation = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [cropIds, setCropIds] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onTouched" });
  const navigate = useNavigate();

  const { getAccessToken } = useAuth();
  const { id } = useParams();
  const addSaleInformation = async (data) => {
    try {
      const token = await getAccessToken();
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.addNewSaleInformation(id);

      const options = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(response, "add sale information");
      console.log(responseData, "add sale information");

      if (response.ok) {
        toast.success(responseData?.message);
        reset()
      } else {
        toast.error(responseData?.message);
      }
    } catch (e) {
      // handle error
      toast.error("Something went wrong");
    }
  };

  const addSaleInformationMutation = useMutation({
    mutationFn: addSaleInformation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchCropsList"] });
    },
  });

  const fetchCropsIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchCropIds(
        searchKey || "",
        id
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.ok) {
        const updatedData = responseData?.response?.crops?.map((item) => ({
          label: `${item?.cropName}`,
          value: item?._id,
        }));

        console.log(updatedData, "updated data");

        setCropIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  useEffect(() => {
    fetchCropsIds("");
  }, []);

  const loadCropDetails = async (key) => {
    const options = await fetchCropsIds(key);

    return options;
  };

  const submitData = async (data) => {
    try {
      console.log(data, "submitted data");

      const saleData = {
        feedType: data?.feedType?.label,
        customerName: data?.customerName,
        mobileNumber: data?.mobileNumber,
        address: data?.address,
        city: data?.city,
        state: data?.state,
        pincode: data?.pinCode,
        paymentType: data?.paymentType,
        quantity: data?.quantity,
        unitPrice: data?.unitPrice,
        saleDate: data?.saleDate[0],
        remarks: data?.remarks,
      };

      await addSaleInformationMutation.mutate(saleData);
    } catch (e) {
      // handle error
      toast.error("Something went wrong");
    }
  };

  const quantity = watch("quantity");

  const unitPrice = watch("unitPrice");

  useEffect(() => {
    if (unitPrice && quantity) {
      const totalPrice = quantity * unitPrice;

      setValue("totalPrice", totalPrice);
    }
  }, [unitPrice, quantity]);

  return (
    <ThemeProvider theme={theme}>
      <form
        onSubmit={handleSubmit(submitData)}
        action=''
        className='flex flex-col items-center px-[1rem]'>
        <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-[2rem] m-4 w-full md:w-[700px] lg:w-[800px]'>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='feedType' className='text-[14px]  text-[#3D4A5C]'>
              Type of Feed Selling<span className=' text-[#f00]'>*</span>
            </label>

            <Controller
              name='feedType'
              rules={{
                required: "*This field is required",
              }}
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  menuPortalTarget={document.body}
                  menuPlacement='auto'
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      width: "auto",
                      minWidth: "100%",
                    }),
                    menuList: (provided, state) => ({
                      ...provided,
                      maxHeight: "200px",
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                      cursor: "pointer",
                    }),
                  }}
                  theme={(theme) => {
                    return {
                      ...theme,
                      borderRadius: 6,
                      colors: {
                        ...theme.colors,
                        primary25: "#e5e7eb",
                        primary50: "#e5e7eb",
                        primary: "#9FB947",
                      },
                    };
                  }}
                  className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
                  defaultOptions={cropIds}
                  loadOptions={loadCropDetails}
                />
              )}
            />
            {errors?.feedType && (
              <p className='text-[#E92215] text-sm'>
                {errors?.feedType?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label
              htmlFor='customerName'
              className='text-[14px] text-[#3D4A5C]'>
              Customer Name<span className=' text-[#f00]'>*</span>
            </label>
            <input
              id='customerName'
              name='customerName'
              {...register("customerName", {
                required: "*This field is required",
              })}
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='Manikanta'
            />
            {errors?.customerName && (
              <p className='text-[#E92215] text-sm'>
                {errors?.customerName?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label
              htmlFor='customerName'
              className='text-[14px] text-[#3D4A5C]'>
              Mobile Number<span className=' text-[#f00]'>*</span>
            </label>
            <PhoneInputWithCountry
              initialValueFormat='national'
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C)] text-[0.9rem] placeholder-[#8897AE]'
              defaultCountry='IN'
              countries={["IN"]}
              disableCountrySelection={true}
              countrySelectProps={{ disabled: true }}
              placeholder='Enter your phone number'
              name='mobileNumber'
              rules={{
                required: "*This field is required.",
                validate: isValidPhoneNumber,
              }}
              control={control}
              id='mobileNumber'
              onChange={setMobileNumber}
              //value={formatPhoneNumberIntl(phoneNumber)}
            />
            {errors?.mobileNumber?.type === "required" && (
              <div className='text-sm text-[#E92215]'>
                {errors.mobileNumber.message}
              </div>
            )}
            {errors?.mobileNumber?.type === "validate" && (
              <div className='text-sm  text-[#E92215]'>
                Invalid Mobile Number
              </div>
            )}
          </div>

          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='address' className='text-[14px] text-[#3D4A5C]'>
              Address
            </label>
            <input
              id='address'
              type='text'
              name='address'
              {...register("address")}
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='1-40/A, Hitech city'
            />
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='city' className='text-[14px] text-[#3D4A5C]'>
              City
            </label>
            <input
              id='city'
              type='text'
              name='city'
              {...register("city")}
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='Chennai'
            />
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='state' className='text-[14px] text-[#3D4A5C]'>
              State
            </label>
            <input
              id='state'
              type='text'
              name='state'
              {...register("state")}
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='Madhya Pradesh'
            />
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='quantity' className='text-[14px] text-[#3D4A5C]'>
              Pincode
            </label>
            <input
              id='pinCode'
              type='text'
              name='pinCode'
              {...register("pinCode", {
                pattern: {
                  value: /^\d{6}$/,
                  message: "Invalid Pincode",
                },
              })}
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='535558'
            />
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='feedType' className='text-[14px] text-[#3D4A5C]'>
              Payment Type<span className=' text-[#f00]'>*</span>
            </label>

            <Controller
              control={control}
              name='paymentType'
              rules={{
                required: "*This field is required",
              }}
              render={({ field }) => (
                <Select
                  id='paymentType'
                  {...field}
                  label='Select Payment Type'
                  className=''>
                  <Option value='Net Banking'>Net Banking</Option>
                  <Option value='Upi'>UPI</Option>
                  <Option value='Cash'>Cash</Option>
                </Select>
              )}
            />
            {errors?.paymentType && (
              <p className='text-[#E92215] text-sm'>
                {errors?.paymentType?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='quantity' className='text-[14px] text-[#3D4A5C]'>
              Quantity(Qun)<span className=' text-[#f00]'>*</span>
            </label>
            <input
              id='quantity'
              name='quantity'
              {...register("quantity", {
                required: "*This field is required",
              })}
              type='number'
              step='any'
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='10 qun'
            />
            {errors?.quantity && (
              <p className='text-[#E92215] text-sm'>
                {errors?.quantity?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='unitPrice' className='text-[14px] text-[#3D4A5C]'>
              Unit Price per 1 Qun<span className=' text-[#f00]'>*</span>
            </label>
            <input
              id='unitPrice'
              name='unitPrice'
              {...register("unitPrice", {
                required: "*This field is required",
              })}
              type='number'
              step='any'
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='10 qun'
            />
            {errors?.unitPrice && (
              <p className='text-[#E92215] text-sm'>
                {errors?.unitPrice?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='totalPrice' className='text-[14px] text-[#3D4A5C]'>
              Total Price<span className=' text-[#f00]'>*</span>
            </label>
            <input
              id='totalPrice'
              name='totalPrice'
              {...register("totalPrice", {
                required: "*This field is required",
              })}
              type='number'
              step='any'
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='10 qun'
            />
            {errors?.totalPrice && (
              <p className='text-[#E92215] text-sm'>
                {errors?.totalPrice?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='saleDate' className='text-[14px] text-[#3D4A5C]'>
              Selling Date<span className=' text-[#f00]'>*</span>
            </label>
            <Controller
              id='saleDate'
              name='saleDate'
              control={control}
              rules={{ required: "*This field is required" }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Flatpickr
                    {...field}
                    options={{
                      dateFormat: "Y-m-d",
                    }}
                    className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                    placeholder='Select Date'
                  />
                </>
              )}
            />
            {errors?.saleDate && (
              <p className='text-[#E92215] text-sm'>
                {errors?.saleDate?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem] col-span-2'>
            <label htmlFor='remarks' className='text-[14px] text-[#3D4A5C] '>
              Remarks
            </label>
            <textarea
              rows='5'
              id='remarks'
              type='date'
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='No Remarks'
            />
          </div>
        </div>
        <div className='flex flex-row justify-end gap-4  my-20 w-full md:w-[700px] lg:w-[800px]'>
          <Button
            onClick={() => navigate(-1)}
            variant='outlined'
            type='button'
            color='green'
            className=' border-[#9FB947] w-[8rem] flex justify-center items-center  gap-3 capitalize '>
            <Icon
              icon='akar-icons:arrow-left'
              className='text-[1.5rem] text-[#8897AE] cursor-pointer '
            />
            <span className='text-[#8897AE]'>Cancel</span>
          </Button>
          {addSaleInformationMutation?.isPending ? (
            <div className='flex justify-center items-center w-[8rem]'>
              <ThreeDots
                height='50'
                width='50'
                radius='9'
                color='#9FB947'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            </div>
          ) : (
            <Button
              type='submit'
              color='green'
              className='w-[8rem] flex justify-center items-center capitalize  bg-[#9FB947] '>
              Save
            </Button>
          )}
        </div>
      </form>
    </ThemeProvider>
  );
};

export default AddSaleInformation;
