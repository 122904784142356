import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchKeyWord: "",
  searchDate: "",
  getInseminationByAnimalId: null,
};

const filtrationState = {
  overAllAnimal: {
    fromDate: "",
    toDate: "",
    lactationNumber: "",
    breed: "",
    employeeId: "",
    inseminationMinDays: "",
    inseminationMaxDays: "",
    lactationMinDays: "",
    lactationMaxDays: "",
  },
  calf: {
    fromDate: "",
    toDate: "",
    gender: "",
    age: "",
  },
  milkRecord: {
    fromDate: "",
    toDate: "",
    month: "",
    year: "",
    shift: "range",
    animalId: "",
    lactationNumber: "",
  },
  diagnosisRecord: {
    fromCollectionDate: "",
    toCollectionDate: "",
    fromResultDate: "",
    toResultDate: "",
    disease: "",
    status: "",
  },
  deworming: {
    fromDate: "",
    toDate: "",
  },

  lactation: {
    fromDateStart: "",
    toDateStart: "",
    fromDateEnd: "",
    toDateEnd: "",
    animalId: "",
    lactationNumber: "",
    employeeId: "",
  },
  dueDeworming: {
    dewormingName: "",
    lastDewormingDateFrom: "",
    lastDewormingDateTo: "",
    nextDewormingDateFrom: "",
    nextDewormingDateTo: "",
    gender: "",
    type: "",
    animalId: "",

    employeeId: "",
  },
  vaccination: {
    fromDate: "",
    toDate: "",
  },

  dueVaccination: {
    vaccineName: "",
    lastVaccinationDateFrom: "",
    lastVaccinationDateTo: "",
    nextVaccinationDateFrom: "",
    nextVaccinationDateTo: "",
    gender: "",
    type: "",
    animalId: "",

    employeeId: "",
  },
  insemination: {
    fromDate: "",
    toDate: "",
    month: "",
    year: "",
    status: "",
    shift: "range",
    animalId: "",
    employeeId: "",
    min: "",
    max: "",
  },
  purchase: {
    fromDate: "",
    toDate: "",
    month: "",
    year: "",
    type: "",
    gender: "",
    shift: "range",
  },
  health: {
    diseaseType: "",
    date: "",
    month: "",
    year: "",
    type: "",
    gender: "",
    shift: "date",
    animalId: "",
    employeeId: "",
  },
  sale: {
    date: "",
    month: "",
    year: "",
    shift: "date",
    gender: "",
    type: "",
  },
  death: {
    date: "",
    month: "",
    year: "",
    shift: "date",
    gender: "",
    type: "",
  },
  pregnancy: {
    fromDate: "",
    toDate: "",
    month: "",
    shift: "range",
    employeeId: "",
  },
  calving: {
    fromDate: "",
    toDate: "",
    month: "",
    shift: "range",
    employeeId: "",
  },
  dryOff: {
    fromDate: "",
    toDate: "",
    month: "",
    shift: "range",
    employeeId: "",
  },
  awaitingInsemination: {
    fromDate: "",
    toDate: "",
    month: "",
    shift: "range",
    employeeId: "",
  },
  pregnancyCheckup: {
    fromDate: "",
    toDate: "",
    month: "",
    shift: "range",
    employeeId: "",
  },
  milkMeter: {
    fromDate: "",
    toDate: "",

    fromMonth: "",
    toMonth: "",
    fromYear: "",
    toYear: "",
    employeeId: "",
    shift: "daily",
  },

  milkMeterState: "daily",
};

const appManagmentIntialState = {
  notification: {
    notifications: null,
    unreadNotifications: null,
    newNotifications: false,
  },
  downloadStatus: false,
  downloadButtonClickStatus: false,
  showEditButton: false,
  editEnabled: false,
};

export const animalManageSlice = createSlice({
  name: "animalManage",
  initialState,
  reducers: {
    updateKeyword(state, action) {
      state.searchKeyWord = action.payload;
    },
    updateDate(state, action) {
      state.searchDate = action.payload;
    },
    updateInseminationMethod(state, action) {
      state.getInseminationByAnimalId = action.payload;
    },
  },
});
export const filtrationManageSlice = createSlice({
  name: "filtrationManage",
  initialState: filtrationState,
  reducers: {
    updateOverAllAnimal(state, action) {
      state.overAllAnimal = {
        ...state.overAllAnimal,
        ...action.payload,
      };
    },
    updateCalf(state, action) {
      state.calf = {
        ...state.calf,
        ...action.payload,
      };
    },
    updateSale(state, action) {
      state.sale = {
        ...state.sale,
        date: "",
        month: "",
        year: "",
        ...action.payload,
      };
    },
    updateDeath(state, action) {
      state.death = {
        ...state.death,
        date: "",
        month: "",
        year: "",
        ...action.payload,
      };
    },
    updateInsemination(state, action) {
      const payload = action?.payload;

      let filterObj = {};
      if (Object.keys(payload).includes("shift")) {
        filterObj = {
          fromDate: "",
          toDate: "",
          month: "",
          year: "",
        };
      }

      state.insemination = {
        ...state.insemination,
        ...filterObj,

        ...action.payload,
      };
    },
    updatePurchase(state, action) {
      state.purchase = {
        ...state.purchase,
        fromDate: "",
        toDate: "",
        month: "",
        year: "",
        ...action.payload,
      };
    },
    updateMilk(state, action) {
      state.milkRecord = {
        ...state.milkRecord,
        fromDate: "",
        toDate: "",
        month: "",
        year: "",
        ...action.payload,
      };
    },
    updateLactation(state, action) {
      state.lactation = {
        ...state.lactation,

        ...action.payload,
      };
    },
    updateDeworming(state, action) {
      state.deworming = {
        ...state.deworming,

        ...action.payload,
      };
    },
    updateDueDeworming(state, action) {
      state.dueDeworming = {
        ...state.dueDeworming,

        ...action.payload,
      };
    },
    updateVaccination(state, action) {
      console.log(action.payload, "vaccination");
      state.vaccination = {
        ...state.vaccination,

        ...action.payload,
      };
    },
    updateDueVaccination(state, action) {
      state.dueVaccination = {
        ...state.dueVaccination,

        ...action.payload,
      };
    },
    updateHealth(state, action) {
      state.health = {
        ...state.health,
        date: "",
        month: "",
        year: "",
        ...action.payload,
      };
    },
    updatePregnancy(state, action) {
      state.pregnancy = {
        ...state.pregnancy,
        fromDate: "",
        toDate: "",
        month: "",

        ...action.payload,
      };
    },
    updateCalving(state, action) {
      state.calving = {
        ...state.calving,
        fromDate: "",
        toDate: "",
        month: "",

        ...action.payload,
      };
    },
    updateDryOff(state, action) {
      state.dryOff = {
        ...state.dryOff,
        fromDate: "",
        toDate: "",
        month: "",

        ...action.payload,
      };
    },
    updateAwaitingInsemination(state, action) {
      state.awaitingInsemination = {
        ...state.awaitingInsemination,
        fromDate: "",
        toDate: "",
        month: "",

        ...action.payload,
      };
    },
    updatePregnancyCheckup(state, action) {
      state.pregnancyCheckup = {
        ...state.pregnancyCheckup,
        fromDate: "",
        toDate: "",
        month: "",

        ...action.payload,
      };
    },
    updateDiagnosisReport(state, action) {
      state.diagnosisRecord = {
        ...state.diagnosisRecord,

        ...action.payload,
      };
    },
    updateMilkMeter(state, action) {
      const payload = action?.payload;

      let filterObj = {};

      if (Object.keys(payload).includes("shift")) {
        filterObj = {
          fromDate: "",
          toDate: "",

          fromMonth: "",
          toMonth: "",
          fromYear: "",
          toYear: "",
        };
      }

      state.milkMeter = {
        ...state.milkMeter,
        ...filterObj,
        ...action.payload,
      };
    },

    updateMilkMeterState(state, action) {
      state.milkMeterState = action.payload;
    },
    filterReset(state, action) {
      return filtrationState;
    },
  },
});

export const appManagementSlice = createSlice({
  name: "appManagement",
  initialState: appManagmentIntialState,
  reducers: {
    updateNotification(state, action) {
      state.notification = {
        ...state.notification,
        ...action.payload,
      };
    },

    filterReset(state, action) {
      return appManagmentIntialState;
    },
    handleDownloadStatus(state, action) {
      state.downloadStatus = action.payload;
    },
    handleDownloadButton(state, action) {
      state.downloadButtonClickStatus = action.payload;
    },
    updateEditEnable(state, action) {
      state.editEnabled = action.payload;
    },
    updateShowEdit(state, action) {
      state.showEditButton = action.payload;
    },
  },
});
