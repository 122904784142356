import { Icon } from "@iconify/react";
import { Button } from "@material-tailwind/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Controller, useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { queryClient } from "../../../../../../App";
import { AdminAuthorURL } from "../../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../../stores/AuthContext";

const EditYieldInformation = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });
  const navigate = useNavigate();
  const { id, yieldId } = useParams();

  const { landDetails } = useOutletContext();

  const { getAccessToken } = useAuth();

  const [cropIds, setCropIds] = useState([]);

  const [initialGroupIds, setInitialGroupIds] = useState([]);

  const [cropDetails, setCropDetails] = useState({});

  const updateYieldInformation = async ({ data, cropId }) => {
    console.log(data, "909");
    console.log(cropId, "crop id");
    try {
      const token = await getAccessToken();
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.updateYieldInformation(
          yieldId
        );

      console.log(url, "url");
      const options = {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(response, "update yield information");
      console.log(responseData, "update yield information response");

      if (response.ok) {
        toast.success(responseData?.message);
      } else {
        toast.error(responseData?.message);
      }
    } catch (e) {
      // handle error

      console.log(e);
      throw new Error(e.message);
    }
  };

  const updateYieldInformationMutation = useMutation({
    mutationFn: updateYieldInformation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchYieldsList"] });
    },
  });

  const submitData = async (data) => {
    try {
      const addYieldData = {
        fieldSize: data?.landInAcres,
        yieldAmount: data?.yieldInQuintals,
        yieldDate:
          typeof data?.yieldDate === "string"
            ? data?.yieldDate
            : moment(data?.yieldDate[0]).format("YYYY-MM-DD"),
        pricePerUnit: data?.pricePerQuintal,
        remarks: data?.remarks,
      };

      await updateYieldInformationMutation?.mutate({
        data: addYieldData,
        cropId: data.cropId?.value,
      });
    } catch (e) {
      // handle error
    }
    console.log(data, "data received");
  };

  const quintal = watch("yieldInQuintals");
  const pricePerQuintal = watch("pricePerQuintal");

  useEffect(() => {
    if (pricePerQuintal && quintal) {
      const totalPrice = quintal * pricePerQuintal;
      console.log(totalPrice, "total price");
      setValue("totalPrice", totalPrice);
    }
  }, [quintal, pricePerQuintal]);

  const fetchCropsIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchCropIds(
        searchKey || "",
        landDetails?._id
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.ok) {
        const updatedData = responseData?.response?.crops?.map((item) => ({
          label: `${item?.id}-${item?.cropName}`,
          value: item?._id,
        }));

        console.log(updatedData, "updated data");

        setInitialGroupIds(responseData?.response?.crops);

        setCropIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  const fetchCropsDetails = async () => {
    try {
      const token = await getAccessToken();
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.fetchYieldDetailsByYieldId(
          yieldId
        );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      console.log(responseData, "yeild details by yield id");
      console.log(response, "fetch crops details");

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      // handle error
    }
  };

  useEffect(() => {
    fetchCropsIds("");
  }, []);

  const loadCropDetails = async (key) => {
    const options = await fetchCropsIds(key);

    return options;
  };

  const { data, isLoading } = useQuery({
    queryKey: ["fetchCropsDetails", yieldId],
    queryFn: fetchCropsDetails,
  });

  useEffect(() => {
    if (data) {
      const selectedCropId = initialGroupIds?.find(
        (item) => item?.id === data?.cropId
      );
      console.log(selectedCropId, "selected crop id");

      setCropDetails(
        selectedCropId
          ? {
              label: `${selectedCropId?.id}-${selectedCropId?.cropName}`,
              value: selectedCropId?._id,
            }
          : {}
      );

      setValue("landInAcres", data?.fieldSize);
      setValue("yieldInQuintals", data?.yieldAmount);
      setValue("yieldDate", moment(data?.yieldDate).format("YYYY-MM-DD"));
      setValue("pricePerQuintal", data?.pricePerUnit);
      setValue("remarks", data?.remarks);
      setValue("totalPrice", data?.totalPrice);
    }
  }, [data]);

  return (
    <form
      onSubmit={handleSubmit(submitData)}
      action=''
      className='flex flex-col items-center px-[1rem]'>
      <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-[2rem] m-4 w-full md:w-[700px] lg:w-[800px]'>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='crop-name' className='text-[14px] text-[#3D4A5C]'>
            Crop Name<span className=' text-[#f00]'>*</span>
          </label>

          <div className=''>
            <Controller
              name='cropId'
              rules={{
                required: "*This field is required",
              }}
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  menuPortalTarget={document.body}
                  menuPlacement='auto'
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      width: "auto",
                      minWidth: "100%",
                    }),
                    menuList: (provided, state) => ({
                      ...provided,
                      maxHeight: "200px",
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                      cursor: "pointer",
                    }),
                  }}
                  value={cropDetails}
                  theme={(theme) => {
                    return {
                      ...theme,
                      borderRadius: 6,
                      colors: {
                        ...theme.colors,
                        primary25: "#e5e7eb",
                        primary50: "#e5e7eb",
                        primary: "#9FB947",
                      },
                    };
                  }}
                  // onChange={(e) => {
                  //   console.log(e);
                  //   handleChange(index, "employeeId", e?.value);
                  //   handleChange(index, "employeeName", e?.label);
                  // }}
                  className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
                  isClearable
                  defaultOptions={cropIds}
                  loadOptions={loadCropDetails}
                />
              )}
            />
          </div>
          {errors?.cropId && (
            <p className='text-[#E92215] text-sm'>{errors?.cropId?.message}</p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='landInAcres' className='text-[14px] text-[#3D4A5C]'>
            Land(Acres)<span className=' text-[#f00]'>*</span>
          </label>
          <input
            id='landInAcres'
            type='number'
            step='any'
            name='landInAcres'
            {...register("landInAcres", {
              required: "*This field is required",
              min: {
                value: 0,
                message: "Land size can't be negative",
              },
            })}
            className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
            placeholder='15acres'
          />
          {errors?.landInAcres && (
            <p className='text-[#E92215] text-sm'>
              {errors?.landInAcres?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='yieldDate' className='text-[14px] text-[#3D4A5C]'>
            Yield Date<span className=' text-[#f00]'>*</span>
          </label>
          <Controller
            id='yieldDate'
            name='yieldDate'
            control={control}
            rules={{ required: "*This field is required" }}
            render={({ field, fieldState: { error } }) => (
              <>
                <Flatpickr
                  {...field}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                  className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                  placeholder='Select Date'
                />
              </>
            )}
          />
          {errors?.yieldDate && (
            <p className='text-[#E92215] text-sm'>
              {errors?.yieldDate?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label
            htmlFor='yieldInQuintals'
            className='text-[14px] text-[#3D4A5C]'>
            Yield(Qun)<span className=' text-[#f00]'>*</span>
          </label>
          <input
            id='yieldInQuintals'
            type='number'
            step='any'
            name='yieldInQuintals'
            {...register("yieldInQuintals", {
              required: "*This field is required",
              min: {
                value: 0,
                message: "Land size can't be negative",
              },
            })}
            className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
            placeholder='5tonnes'
          />
          {errors?.yieldInQuintals && (
            <p className='text-[#E92215] text-sm'>
              {errors?.yieldInQuintals?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label
            htmlFor='pricePerQuintal'
            className='text-[14px] text-[#3D4A5C]'>
            Approx Price Per 1 Qun<span className=' text-[#f00]'>*</span>
          </label>
          <input
            id='pricePerQuintal'
            type='number'
            step='any'
            name='pricePerQuintal'
            {...register("pricePerQuintal", {
              required: "*This field is required",
              min: {
                value: 0,
                message: "Land size can't be negative",
              },
            })}
            className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
            placeholder='₹2000'
          />
          {errors?.pricePerQuintal && (
            <p className='text-[#E92215] text-sm'>
              {errors?.pricePerQuintal?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor='totalPrice' className='text-[14px] text-[#3D4A5C]'>
            Total Price<span className=' text-[#f00]'>*</span>
          </label>
          <input
            id='totalPrice'
            type='number'
            step='any'
            name='totalPrice'
            {...register("totalPrice", {
              required: "*This field is required",
              min: {
                value: 0,
                message: "Land size can't be negative",
              },
            })}
            className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
            placeholder='₹20000'
          />
          {errors?.totalPrice && (
            <p className='text-[#E92215] text-sm'>
              {errors?.totalPrice?.message}
            </p>
          )}
        </div>
        <div className='flex flex-col gap-[0.5rem] lg:col-span-2'>
          <label htmlFor='remarks' className='text-[14px] text-[#3D4A5C]'>
            Remarks
          </label>
          <textarea
            rows='5'
            id='remarks'
            name='remarks'
            {...register("remarks")}
            type='date'
            className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
            placeholder='No Remarks'
          />
        </div>
      </div>
      <div className='flex flex-row justify-end gap-4  my-20 w-full md:w-[700px] lg:w-[800px]'>
        <Button
          onClick={() => navigate(-1)}
          variant='outlined'
          type='button'
          color='green'
          className=' border-[#9FB947] w-[8rem] flex justify-center items-center  gap-3 capitalize '>
          <Icon
            icon='akar-icons:arrow-left'
            className='text-[1.5rem] text-[#8897AE] cursor-pointer '
          />
          <span className='text-[#8897AE]'>Cancel</span>
        </Button>
        {updateYieldInformationMutation?.isPending ? (
          <div className='flex justify-center items-center w-[8rem]'>
            <ThreeDots
              height='50'
              width='50'
              radius='9'
              color='#9FB947'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClassName=''
              visible={true}
            />
          </div>
        ) : (
          <Button
            type='submit'
            color='green'
            className='w-[8rem] flex justify-center items-center capitalize  bg-[#9FB947] '>
            Save
          </Button>
        )}
      </div>
    </form>
  );
};

export default EditYieldInformation;
