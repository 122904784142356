import { RouterProvider } from "react-router-dom";
import { router } from "./routes/Route";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./stores/AuthContext";
import "flatpickr/dist/themes/material_green.css";
import "./App.css";
import OneSignal from "react-onesignal";
import {
  startTransition,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { database } from "./Firebase";
import { onValue, ref } from "firebase/database";
import {
  PlayNotificationSound,
  updateNotificationStatus,
} from "./components/helpers/HelperFunction";
import { useDispatch, useSelector } from "react-redux";
import { appManagementActions } from "./stores/IndexRedux";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import Loader from "./components/loaders/Loader";
import LvreddyLoader from "./components/loaders/LvreddyLoader";

export const queryClient = new QueryClient();

function App() {
  const { userInfo } = useAuth();
  const dispatch = useDispatch();
  const { notifications } = useSelector(
    (state) => state.appManagement.notification
  );

  const [showLazy, setShowLazy] = useState(false);

  useEffect(() => {
    startTransition(() => {
      setShowLazy(true);
    });
  }, []);

  

  const onesignalInitiate = async () => {
    await OneSignal.init({ appId: "ca1c9770-a2f4-4184-8b4d-9d6df1c5c88b" });
  };

  useEffect(() => {
    onesignalInitiate();
  }, []);

  useEffect(() => {
    // Path to your notifications in the database
    const notificationsPath = `users/${userInfo?._id?.toString()}/notifications`;

    // Create a reference to the notifications path
    const notificationsRef = ref(database, notificationsPath);

    // Set up a listener for changes in the notifications path
    onValue(notificationsRef, (snapshot) => {
      if (snapshot.exists()) {
        // If data exists at the specified path, update the state with the data
        dispatch(
          appManagementActions.updateNotification({
            notifications: snapshot.val(),
          })
        );
      } else {
        // Handle the case where no data exists at the specified path
        dispatch(
          appManagementActions.updateNotification({ notifications: null })
        );
      }
    });
  }, []);

  const calculateUnread = (notifications) => {
    let unread = {
      allNotifications: 0,
      animalNotifications: 0,
      employeeNotifications: 0,
    };
    let newNotifications = 0;

    const notificationIds = notifications ? Object.keys(notifications) : null;

    // Using reverse() to process notifications in reverse order
    notificationIds
      ?.slice(0)
      .reverse()
      .map((notificationId) => {
        const notification = { ...notifications[notificationId] };
        notification.id = notificationId;

        if (notification.status === "sent") {
          PlayNotificationSound();
          updateNotificationStatus(
            userInfo._id?.toString(),
            notificationId,
            "unread"
          );
          dispatch(
            appManagementActions.updateNotification({ newNotifications: true })
          );
        }

        if (notification.status === "unread") {
          unread.allNotifications += 1;
        }

        if (
          notification.type === "animal" &&
          notification.status === "unread"
        ) {
          unread.animalNotifications += 1;
        } else if (
          notification.type === "employee" &&
          notification.status === "unread"
        ) {
          unread.employeeNotifications += 1;
        }
      });

    dispatch(
      appManagementActions.updateNotification({ unreadNotifications: unread })
    );
    return unread;
  };

  const unread = useMemo(() => calculateUnread(notifications), [notifications]);

  return (
    <div className='font-poppins'>
      <QueryClientProvider client={queryClient}>
      
            <RouterProvider router={router}></RouterProvider>
         
    
      </QueryClientProvider>
    </div>
  );
}

export default App;
