import React, { Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import AnimalManageEmployeeMilkMeter from "../components/adminPanel/animalModule/animalManageLists/AnimalManageEmployeeMilkMeter";
import EmployeeOvertimeAllowance from "../components/adminPanel/employeeModule/employeePayrollList/EmployeeOvertimeAllowance";
import EmployeeSalaryGeneration from "../components/adminPanel/employeeModule/employeePayrollList/EmployeeSalaryGeneration";
import LeaseLandList from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/LeaseLandList";
import AddCropInformation from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/AddCropInformation";
import AddExpenseInformation from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/AddExpenseInformation";
import AddSaleInformation from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/AddSaleInformation";
import AddYieldInformation from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/AddYieldInformation";
import EditCropInformation from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/EditCropInformation";
import EditExpenseInformation from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/EditExpenseInformation";
import EditYieldInformation from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/EditYieldInformation";
import SaleInformationEdit from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/SaleInformationEdit";
import PurchaseInformationView from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/PurchaseInformationView";
import SaleInformationView from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/SaleInformationView";
import BillsRegeneration from "../components/adminPanel/vendorManagement/billingReports/BillsRegenration";
import TotalBills from "../components/adminPanel/vendorManagement/billingReports/TotalBills";
import BulkMilkSaleUpload from "../components/adminPanel/vendorManagement/BulkUploadList";
import VendorAddPayment from "../components/adminPanel/vendorManagement/manageVendorList/vendorViews/AddVendorPayment";
import EditVendorPayment from "../components/adminPanel/vendorManagement/manageVendorList/vendorViews/EditVendorPayment";
import MilkPriceRevision from "../components/adminPanel/vendorManagement/manageVendorList/vendorViews/MilkPriceRevision";
import MilkPriceRevisionAdd from "../components/adminPanel/vendorManagement/manageVendorList/vendorViews/MilkPriceRevisionAdd";
import MilkPriceRevisionEdit from "../components/adminPanel/vendorManagement/manageVendorList/vendorViews/MilkPriceRevisionEdit";
import VendorBillingReports from "../components/adminPanel/vendorManagement/VendorBillingReports";
import PageNotFound from "../components/helpers/PageNotFound";
import Loader from "../components/loaders/Loader";
import { useAuth } from "../stores/AuthContext";
import EditSaleInformation from "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/EditSaleInformation";
import DailySalesReports from "../components/adminPanel/reports/reportManage/vendorSalesReports/DailySalesReports";

const AdminPanelAnimalRegistration = React.lazy(() =>
  import("../components/adminPanel/AdminPanelAnimalRegistration")
);
const AdminPanelAnimalManage = React.lazy(() =>
  import("../components/adminPanel/AdminPanelAnimalManage")
);
const AdminPanelAnimalGlobalSettings = React.lazy(() =>
  import("../components/adminPanel/AdminPanelAnimalGlobalSettings")
);
const AdminPanelAnimalViewAll = React.lazy(() =>
  import("../components/adminPanel/AdminPanelAnimalViewAll")
);
const AdminPanelAnimalArchive = React.lazy(() =>
  import("../components/adminPanel/AdminPanelAnimalArchive")
);
const AdminPanelEmployeeRegister = React.lazy(() =>
  import("../components/adminPanel/AdminPanelEmployeeRegister")
);
const AdminPanelEmployeeManage = React.lazy(() =>
  import("../components/adminPanel/AdminPanelEmployeeManage")
);
const AdminPanelEmployeeAttendance = React.lazy(() =>
  import("../components/adminPanel/AdminPanelEmployeeAttendance")
);
const AdminPanelEmployeeAdvance = React.lazy(() =>
  import("../components/adminPanel/AdminPanelEmployeePayroll")
);
const AdminPanelEmployeeArchive = React.lazy(() =>
  import("../components/adminPanel/AdminPanelEmployeeArchive")
);
const AdminPanelRevenue = React.lazy(() =>
  import("../components/adminPanel/AdminPanelRevenue")
);
const AdminPanelReports = React.lazy(() =>
  import("../components/adminPanel/AdminPanelReports")
);
const AdminPanelSubAdmin = React.lazy(() =>
  import("../components/adminPanel/AdminPanelSubAdmin")
);
const AdminPanelAssetsAndLiabilities = React.lazy(() =>
  import("../components/adminPanel/AdminPanelAssetsAndLiabilities")
);
const AdminPanelCharityManagement = React.lazy(() =>
  import("../components/adminPanel/AdminPanelCharityManagement")
);
const AdminPanelOutletManagement = React.lazy(() =>
  import("../components/adminPanel/AdminPanelOutletManagement")
);
const AdminpanelNotificatons = React.lazy(() =>
  import("../components/adminPanel/AdminpanelNotificatons")
);
const AdminPanelSettings = React.lazy(() =>
  import("../components/adminPanel/AdminPanelSettings")
);
const AdminPanelHelp = React.lazy(() =>
  import("../components/adminPanel/AdminPanelHelp")
);
const AdminLogin = React.lazy(() => import("../pages/login/AdminLogin"));
const EmployeeLogin = React.lazy(() => import("../pages/login/EmployeeLogin"));
const ForgotPassword = React.lazy(() =>
  import("../pages/login/ForgotPassword")
);
const AdminPanelLayout = React.lazy(() =>
  import("../layouts/AdminPanelLayout")
);
const AdminPanelHome = React.lazy(() =>
  import("../components/adminPanel/AdminPanelHome")
);
const AnimalManageAllAnimalLists = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageAllAnimalsLists"
  )
);
const AnimalManageCalfList = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageCalfList"
  )
);
const AnimalManageDeathHistoryList = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageDeathHistoryList"
  )
);
const AnimalManageSoldHistoryList = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageSoldHistoryList"
  )
);
const AnimalManageBuffaloView = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageBuffaloView"
  )
);
const AnimalManageCalfView = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageCalfView"
  )
);
const AnimalManageSaleHistoryView = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageSaleHistoryView"
  )
);
const AnimalManageSaleHistoryAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageSaleHistoryAdd"
  )
);
const AnimalManageDeathHistoryView = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageDeathHistoryView"
  )
);
const AnimalManageDeathHistoryAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageDeathHistoryAdd"
  )
);
const BasicInformationEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/BasicInformationEdit"
  )
);
const EmployeeBasicInformationEdit = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeeEdit/BasicInformationEdit"
  )
);
const CalfBasicInformationEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/calfEdit/BasicInformationEdit"
  )
);
const ManageCalfHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/ManageCalfHistory"
  )
);
const ManageLactationHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/ManageLactationHistory"
  )
);
const ManageInseminationHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/ManageInseminationHistory"
  )
);
const ManageDailyMilkRecord = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/ManageDailyMilkRecord"
  )
);
const ManagePurchaseHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/ManagePurchaseHistory"
  )
);
const CalfManagePurchaseHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/calfEdit/ManagePurchaseHistory"
  )
);
const ManageHealthHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/ManageHealthHistory"
  )
);
const CalfManageHealthHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/calfEdit/ManageHealthHistory"
  )
);
const ManageSaleHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/ManageSaleHistory"
  )
);
const CalfManageSaleHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/calfEdit/ManageSaleHistory"
  )
);
const ManageDeathHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/ManageDeathHistory"
  )
);
const CalfManageDeathHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/calfEdit/ManageDeathHistory"
  )
);
const CalfHistoryAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/CalfHistoryAdd"
  )
);
const CalfHistoryEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/CalfHistoryEdit"
  )
);
const DailyMilkRecordAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/DailyMilkRecordAdd"
  )
);
const DailyMilkRecordEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/DailyMilkRecordEdit"
  )
);
const LactationHistoryAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/LactationHistoryAdd"
  )
);
const LactationHistoryEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/LactationHistoryEdit"
  )
);
const InseminationHistoryAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/InseminationHistoryAdd"
  )
);
const InseminationHistoryEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/InseminationHistoryEdit"
  )
);
const HealthHistoryAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/HealthHistoryAdd"
  )
);
const CalfHealthHistoryAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/calfEdit/HealthHistoryAdd"
  )
);
const HealthHistoryEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/HealthHistoryEdit"
  )
);
const CalfHealthHistoryEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/calfEdit/HealthHistoryEdit"
  )
);
const Assets = React.lazy(() =>
  import(
    "../components/adminPanel/assetsAndLiabilities/assetsAndLiabilitiesManage/Assets"
  )
);
const Liabilities = React.lazy(() =>
  import(
    "../components/adminPanel/assetsAndLiabilities/assetsAndLiabilitiesManage/Liabilities"
  )
);
const EmployeeReport = React.lazy(() =>
  import("../components/adminPanel/reports/reportManage/EmployeeReport")
);
const AnimalReport = React.lazy(() =>
  import("../components/adminPanel/reports/reportManage/AnimalReport")
);
const ExpenseReport = React.lazy(() =>
  import("../components/adminPanel/reports/reportManage/ExpenseReport")
);
const VendorSalesReport = React.lazy(() =>
  import("../components/adminPanel/reports/reportManage/VendorSalesReport")
);
const FeedReport = React.lazy(() =>
  import("../components/adminPanel/reports/reportManage/FeedReport")
);
const AssetView = React.lazy(() =>
  import("../components/adminPanel/assetsAndLiabilities/AssetView")
);
const LiabilityView = React.lazy(() =>
  import("../components/adminPanel/assetsAndLiabilities/LiabilityView")
);
const CharityView = React.lazy(() =>
  import("../components/adminPanel/charityManagement/CharityView")
);
const AddNewDocument = React.lazy(() =>
  import("../components/adminPanel/documents/AddNew")
);
const ManageDocuments = React.lazy(() =>
  import("../components/adminPanel/documents/Manage")
);
const ArchiveDocuments = React.lazy(() =>
  import("../components/adminPanel/documents/Archive")
);
const AllNotification = React.lazy(() =>
  import("../components/notifications/AllNotification")
);
const AnimalNotifications = React.lazy(() =>
  import("../components/notifications/AnimalNotifications")
);
const EmployeeNotifications = React.lazy(() =>
  import("../components/notifications/EmployeeNotifications")
);
const NotificationView = React.lazy(() =>
  import("../components/notifications/NotificationView")
);
const VendorArchive = React.lazy(() =>
  import("../components/adminPanel/vendorManagement/VendorArchive")
);
const VendorRegister = React.lazy(() =>
  import("../components/adminPanel/vendorManagement/VendorRegister")
);
const ManageVendor = React.lazy(() =>
  import("../components/adminPanel/vendorManagement/ManageVendor")
);
const VendorBasicInformation = React.lazy(() =>
  import(
    "../components/adminPanel/vendorManagement/vendorRegisterForm/BasicInformation"
  )
);
const VendorIdentificationDocument = React.lazy(() =>
  import(
    "../components/adminPanel/vendorManagement/vendorRegisterForm/IdentificationDocuments"
  )
);
const VendorDepositInformation = React.lazy(() =>
  import(
    "../components/adminPanel/vendorManagement/vendorRegisterForm/DepositInformation"
  )
);
const VendorEdit = React.lazy(() =>
  import(
    "../components/adminPanel/vendorManagement/manageVendorList/VendorEdit"
  )
);
const VendorViewDailySSales = React.lazy(() =>
  import(
    "./../components/adminPanel/vendorManagement/manageVendorList/vendorViews/DailySales"
  )
);
const VendorViewBillingReports = React.lazy(() =>
  import(
    "./../components/adminPanel/vendorManagement/manageVendorList/vendorViews/BillingReports"
  )
);
const VendorViewPayments = React.lazy(() =>
  import(
    "./../components/adminPanel/vendorManagement/manageVendorList/vendorViews/Payments"
  )
);
const VendorViewBasicInformation = React.lazy(() =>
  import(
    "./../components/adminPanel/vendorManagement/manageVendorList/vendorViews/BasicInformation"
  )
);
const VendorViewIdentificationDocuments = React.lazy(() =>
  import(
    "./../components/adminPanel/vendorManagement/manageVendorList/vendorViews/IdentificationDocuments"
  )
);
const VendorViewDepositInformation = React.lazy(() =>
  import(
    "./../components/adminPanel/vendorManagement/manageVendorList/vendorViews/DepositInformation"
  )
);
const EmployeeManageView = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/EmployeeManageView"
  )
);
const EmployeesMangeList = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/EmployeesMangeList"
  )
);
const Payslip = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeeEdit/Payslip"
  )
);
const EmploymentInformationEdit = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeeEdit/EmploymentInformationEdit"
  )
);
const IdentificationDocumentEdit = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeeEdit/IdentificationDocumentEdit"
  )
);
const BankInformationEdit = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeeEdit/BankInformationEdit"
  )
);
const AnimalAssigned = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeeEdit/AnimalAssigned"
  )
);
const AnimalManageCalfAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageCalfAdd"
  )
);
const AddMilkRecord = React.lazy(() =>
  import("../components/adminPanel/dashboard/AddMilkRecord")
);
const BulkMilkRecords = React.lazy(() =>
  import("../components/adminPanel/dashboard/BulkMilkRecords")
);
const MilkRecords = React.lazy(() =>
  import("../components/adminPanel/dashboard/MilkRecords")
);
const Attendance = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeeEdit/Attendance"
  )
);
const LeaseInformation = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/GreenFooder"
  )
);
const LeaseLandRegistrationPage = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandRegistration/LeaseLandRegistrationPage"
  )
);
const LeaseInformationForm = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandRegistration/LeaseLandRegistrationPage"
  )
);
const FarmerInformationForm = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandRegistration/LeaseLandRegistrationPage"
  )
);
const DryFooder = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/dryFooder/DryFooder"
  )
);
const PurchaseDryFooderRegistrationForm = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/dryFooder/purchasedDryFooderNewRegistrationPage/PurchaseDryFooderRegistrationForm"
  )
);
const PurchaseHistory = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/concentrateFeed/purchaseHistory/PurchaseHistory"
  )
);
const LeaseLandInformationView = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/LeaseLandList"
  )
);
const YieldInformationView = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/YieldInformationView"
  )
);
const CropInformationView = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/CropInformationView"
  )
);
const ExpenseInformationView = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/ExpenseInformationView"
  )
);
const LeaseLandView = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/LeaseLandView"
  )
);
const LeaseLandEditForm = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/LeaseLandEditForm"
  )
);
const CropInformationEdit = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/CropInformationEdit"
  )
);
const YieldInformationEdit = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/YieldInformationEdit"
  )
);
const ExpenseInformationEdit = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/ExpenseInformationEdit"
  )
);
const FarmerInformationEdit = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/greenFooder/leaseLandView/FarmerInformationEdit"
  )
);
const PurchaseDryFooder = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/dryFooder/purchaseDryFooderView/PurchaseDryFooder"
  )
);
const DryFooderExpenseInformationForm = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/dryFooder/purchaseDryFooderView/DryFooderExpenseInformationForm"
  )
);
const DryFooderFarmerInformationForm = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/dryFooder/purchaseDryFooderView/DryFooderFarmerInformationForm"
  )
);
const DryFooderFeedInformationForm = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/fooderManagement/dryFooder/purchaseDryFooderView/DryFooderFeedInformationForm"
  )
);
const DailyUsageFeed = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/concentrateFeed/dailyUsage/DailyUsageFeed"
  )
);
const DailyUsageFeedData = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/concentrateFeed/dailyUsage/DailyUsageFeedData"
  )
);
const TotalFeedStock = React.lazy(() =>
  import(
    "../components/adminPanel/feedManagement/concentrateFeed/dailyUsage/TotalFeedStock"
  )
);
const EmployeeLayout = React.lazy(() => import("../layouts/EmployeeLayout"));
const EmployeeDashboard = React.lazy(() =>
  import("../components/employeePanel/EmployeeDashboard/EmployeeDashboard")
);
const EmployeePanelMilkingReport = React.lazy(() =>
  import(
    "../components/employeePanel/employeeMilkingReport/EmployeePanelMilkingReport"
  )
);
const EmployeeAttendancePage = React.lazy(() =>
  import(
    "../components/employeePanel/employeeAttendancePage/EmployeeAttendancePage"
  )
);
const AllAnimalListView = React.lazy(() =>
  import("../components/employeePanel/AllAnimalList/AllAnimalListView")
);
const MyAnimalListAnimalView = React.lazy(() =>
  import("../components/employeePanel/AllAnimalList/MyAnimalListAnimalView")
);
const MyAnimalListBuffaloView = React.lazy(() =>
  import("../components/employeePanel/AllAnimalList/MyAnimalListBuffaloView")
);
const MyAnimalListCalfsView = React.lazy(() =>
  import("../components/employeePanel/AllAnimalList/MyAnimalListCalfsView")
);
const MyAnimalListBullsView = React.lazy(() =>
  import("../components/employeePanel/AllAnimalList/MyAnimalListBullsView")
);
const DashboardBullsView = React.lazy(() =>
  import("../components/employeePanel/EmployeeDashboard/DashboardBullsView")
);
const DashboardBuffaloView = React.lazy(() =>
  import("../components/employeePanel/EmployeeDashboard/DashboardBuffaloView")
);
const DashboardCalfsView = React.lazy(() =>
  import("../components/employeePanel/EmployeeDashboard/DashboardCalfsView")
);
const AllAnimalsView = React.lazy(() =>
  import("../components/employeePanel/EmployeeDashboard/AllAnimalsView")
);
const EmployeePanelHelp = React.lazy(() =>
  import("../components/employeePanel/EmployeePanelHelp")
);
const EmployeePanelSettings = React.lazy(() =>
  import("../components/employeePanel/EmployeePanelSettings")
);
const EmployeePanelNotifications = React.lazy(() =>
  import("../components/employeePanel/EmployeePanelNotifications")
);
const LoginWrapper = React.lazy(() => import("../pages/login/LoginWrapper"));
const EmployeeAttendanceList = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeAttendanceList/EmployeeAttendanceList"
  )
);
const EmployeeCreateAttendance = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeAttendanceList/EmployeeCreateAttendance"
  )
);
const AdminPanelEmployeePayroll = React.lazy(() =>
  import("../components/adminPanel/AdminPanelEmployeePayroll")
);
const EmployeeEditAttendance = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeAttendanceList/EmployeeEditAttendance"
  )
);
const EmployeePayrollAdvance = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeePayrollList/EmployeePayrollAdvance"
  )
);
const EmployeeCreatePayrollAdvance = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeePayrollList/EmployeeCreatePayrollAdvance"
  )
);
const EmployeeEditPayrollAdvance = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeePayrollList/EmployeeEditPayrollAdvance"
  )
);
const EmployeePayrollSalary = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeePayrollList/EmployeePayrollSalary"
  )
);
const EmployeeCreatePayrollSalary = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeePayrollList/EmployeeCreatePayrollSalary"
  )
);
const EmployeeEditPayrollSalary = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeePayrollList/EmployeeEditPayrollSalary"
  )
);
const EmployeePanelAttendance = React.lazy(() =>
  import("../components/employeePanel/EmployeePanelAttendance")
);
const EmployeePanelEditAttendance = React.lazy(() =>
  import(
    "../components/employeePanel/employeeAttendancePage/EmployeePanelEditAttendance"
  )
);
const EmployeePayrollListWrapper = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeePayrollList/EmployeePayrollListWrapper"
  )
);
const EmployeeBulkMilkRecords = React.lazy(() =>
  import(
    "../components/employeePanel/employeeMilkingReport/EmployeeBulkMilkRecords"
  )
);
const AnimalManageLactations = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageLactations"
  )
);
const AnimalManageInseminations = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageInseminations"
  )
);
const AnimalManagePurchaseHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManagePurchaseHistory"
  )
);
const AnimalManageHealthRecords = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageHealthRecords"
  )
);
const AnimalManageMilkRecords = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageMilkRecords"
  )
);
const Vaccination = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/Vaccination"
  )
);
const Dewarming = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/Dewarming"
  )
);
const DewarmingAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/DewarmingAdd"
  )
);
const DewarmingEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/DewarmingEdit"
  )
);
const VaccinationAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/VaccinationAdd"
  )
);
const VaccinationEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/VaccinationEdit"
  )
);
const AnimalReports = React.lazy(() =>
  import(
    "../components/adminPanel/reports/reportManage/animalReports/AnimalReports"
  )
);
const PregnancyList = React.lazy(() =>
  import(
    "../components/adminPanel/reports/reportManage/animalReports/PregnancyList"
  )
);
const DryOffRecommended = React.lazy(() =>
  import(
    "../components/adminPanel/reports/reportManage/animalReports/DryOffRecommended"
  )
);
const AwaitingInsemination = React.lazy(() =>
  import(
    "../components/adminPanel/reports/reportManage/animalReports/AwaitingInsemination"
  )
);
const PregnancyCheckList = React.lazy(() =>
  import(
    "../components/adminPanel/reports/reportManage/animalReports/PregnancyCheckList"
  )
);
const CloseToCalving = React.lazy(() =>
  import(
    "../components/adminPanel/reports/reportManage/animalReports/CloseToCalving"
  )
);
const ManageDiagnosticReports = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/ManageDiagnosticReports"
  )
);
const DiagnosticReportAdd = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/DiagnosticReportAdd"
  )
);
const DiagnosticReportEdit = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/DiagnosticReportEdit"
  )
);
const AnimalManageVaccination = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageVaccination"
  )
);
const AnimalManagePregnancy = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageDewarming"
  )
);
const AnimalManageDiagnosis = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageDiagnosis"
  )
);
const AnimalManageDewarming = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/AnimalManageDewarming"
  )
);
const VaccinationReport = React.lazy(() =>
  import(
    "../components/adminPanel/reports/reportManage/animalReports/VaccinationReports"
  )
);
const DewormingReports = React.lazy(() =>
  import(
    "../components/adminPanel/reports/reportManage/animalReports/DewormingReport"
  )
);
const EmployeeBulkAttendanceList = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeBulkAttendance/EmployeeBulkAttendance"
  )
);
const EmployeeBulkAttendance = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeBulkAttendance/EmployeeBulkAttendance"
  )
);
const BulkAttendanceList = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeBulkAttendance/BulkEmployeeList"
  )
);
const BulkAdvanceList = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeBulkAdvance/BulkAdvanceList"
  )
);
const EmployeeBulkAdvance = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeBulkAdvance/EmployeeBulkAdvance"
  )
);
const EmployeementStatusHistory = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/EmployeementStatusHistory"
  )
);
const EmployeementHistoryStatusAdd = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeementHistory/EmployeementHistoryStatusAdd"
  )
);
const EmployeementHistoryStatusEdit = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeementHistory/EmployeementHistoryStatusEdit"
  )
);
const AdminPanelTodoList = React.lazy(() =>
  import("../components/adminPanel/AdminPanelTodoList")
);
const TodoListView = React.lazy(() =>
  import("../components/adminPanel/todoList/TodoListView")
);
const TodoParticularView = React.lazy(() =>
  import("../components/adminPanel/todoList/TodoParticularView")
);
const Increment = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeeEdit/Increment"
  )
);
const IncrementAdd = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeeEdit/IncrementAdd"
  )
);
const BulkOverTimeAllowanceList = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeBulkAdvance/BulkOverTimeAllowanceList"
  )
);
const IncrementEdit = React.lazy(() =>
  import(
    "../components/adminPanel/employeeModule/employeeManageList/employeeEdit/IncrementEdit"
  )
);
const EmployeeAssignmentHistory = React.lazy(() =>
  import(
    "../components/adminPanel/animalModule/animalManageLists/buffaloEdit/EmployeeAssignmentHistory"
  )
);
const RoleForm = React.lazy(() =>
  import("../components/adminPanel/subAdmin/RoleForm")
);
const EditSubAdmin = React.lazy(() =>
  import("../components/adminPanel/subAdmin/EditSubAdmin")
);
const EditDocument = React.lazy(() =>
  import("../components/adminPanel/documents/EditDocument")
);
const OutletManage = React.lazy(() =>
  import("../components/adminPanel/outletManagement/OutletManage")
);
const ProductionUnit = React.lazy(() =>
  import("../components/adminPanel/outletManagement/ProductionUnit")
);

const StockManage = React.lazy(() =>
  import("../components/adminPanel/outletManagement/StockManage")
);
const BasicInformationForm = React.lazy(() =>
  import(
    "../components/adminPanel/outletManagement/outletRegisterForm/BasicInformationForm"
  )
);
const OutletRegisterForm = React.lazy(() =>
  import("../components/adminPanel/outletManagement/OutletRegisterForm")
);

const AddDailySales = React.lazy(() =>
  import(
    "../components/adminPanel/vendorManagement/manageVendorList/vendorViews/AddDailySales"
  )
);

const EditDailySales = React.lazy(() =>
  import(
    "../components/adminPanel/vendorManagement/manageVendorList/vendorViews/EditDailySales"
  )
);

const VendorPayments = React.lazy(() =>
  import("../components/adminPanel/vendorManagement/VendorPayments")
);

const PrivateRoute = ({ component: Component }) => {
  const { currentUser, userRole } = useAuth();
  return (
    <>
      {(currentUser && userRole?.admin) || userRole?.subAdmin ? (
        <Component />
      ) : (
        <Navigate to='/login' replace />
      )}
    </>
  );
};

const EmployeePrivateRoute = ({ component: Component }) => {
  const { currentUser, userRole } = useAuth();
  return (
    <>
      {currentUser && userRole?.employee ? (
        <Suspense fallback={<Loader />}>
          <Component />
        </Suspense>
      ) : (
        <Navigate to='/login' replace />
      )}
    </>
  );
};

const RoleBasedLayout = ({ component: Component }) => {
  const { currentUser, userRole } = useAuth();
  return (
    <>
      {currentUser && userRole?.employee ? (
        <Component />
      ) : (
        <Navigate to='/login' replace />
      )}
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <Suspense fallback={""}>
        <LoginWrapper />
      </Suspense>
    ),
  },
  {
    path: "/forgotPassword",
    element: (
      <Suspense fallback={""}>
        <ForgotPassword />
      </Suspense>
    ),
  },

  {
    path: "/",
    element: (
      <Suspense fallback={""}>
        <PrivateRoute component={AdminPanelLayout} />
      </Suspense>
    ),
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelHome} />
          </Suspense>
        ),
      },

      {
        path: "milks",
        element: <PrivateRoute component={MilkRecords} />,
        children: [
          {
            path: "add-milk-record",
            element: <PrivateRoute component={AddMilkRecord} />,
          },
          {
            path: "add-bulk-milks",
            element: <PrivateRoute component={BulkMilkRecords} />,
          },
        ],
      },

      {
        path: "animals-registration",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelAnimalRegistration} />
          </Suspense>
        ),
      },
      {
        path: "animals-manage",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelAnimalManage} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageAllAnimalLists} />
              </Suspense>
            ),
          },
          {
            path: "buffaloes",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageAllAnimalLists} />
              </Suspense>
            ),
          },
          {
            path: "bulls",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageAllAnimalLists} />
              </Suspense>
            ),
          },
          {
            path: "calves",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageCalfList} />
              </Suspense>
            ),
          },
          {
            path: "soldHistory",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageSoldHistoryList} />
              </Suspense>
            ),
          },
          {
            path: "deathHistory",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageDeathHistoryList} />
              </Suspense>
            ),
          },
          {
            path: "lactations",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageLactations} />
              </Suspense>
            ),
          },
          {
            path: "inseminations",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageInseminations} />
              </Suspense>
            ),
          },
          {
            path: "purchases",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManagePurchaseHistory} />
              </Suspense>
            ),
          },
          {
            path: "health-records",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageHealthRecords} />
              </Suspense>
            ),
          },
          {
            path: "milk-records",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageMilkRecords} />
              </Suspense>
            ),
          },
          {
            path: "vaccination",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageVaccination} />
              </Suspense>
            ),
          },
          {
            path: "dewarming",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageDewarming} />
              </Suspense>
            ),
          },
          {
            path: "diagnosis",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageDiagnosis} />
              </Suspense>
            ),
          },
          {
            path: "employee-milkmeter",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalManageEmployeeMilkMeter} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "all-animals-view/:id",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AnimalManageBuffaloView} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={BasicInformationEdit} />
              </Suspense>
            ),
          },
          {
            path: "calf-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageCalfHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={CalfHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-calf/:calfId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={CalfHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "daily-milk-record",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDailyMilkRecord} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DailyMilkRecordAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-daily-milk-record/:milkRecordId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DailyMilkRecordEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "lactation-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageLactationHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={LactationHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-lactation/:lactationId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={LactationHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "insemination-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageInseminationHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={InseminationHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-insemination/:inseminationId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={InseminationHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "purchase-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManagePurchaseHistory} />
              </Suspense>
            ),
          },
          {
            path: "health-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageHealthHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={HealthHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-health/:healthId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={HealthHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/deworming",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={Dewarming} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DewarmingAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:dewormingId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DewarmingEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/vaccination",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={Vaccination} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VaccinationAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:vaccineId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VaccinationEdit} />
                  </Suspense>
                ),
              },
            ],
          },

          {
            path: "health-history/diagnostic-reports",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDiagnosticReports} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DiagnosticReportAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-diagnostic/:reportId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DiagnosticReportEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/diagnostic-reports",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDiagnosticReports} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DiagnosticReportAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-diagnostic/:id",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DiagnosticReportEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "sale-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageSaleHistory} />
              </Suspense>
            ),
          },
          {
            path: "death-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDeathHistory} />
              </Suspense>
            ),
          },
          {
            path: "employee-assignment-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeAssignmentHistory} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "buffalo-view/:id",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AnimalManageBuffaloView} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={BasicInformationEdit} />
              </Suspense>
            ),
          },
          {
            path: "calf-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageCalfHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={CalfHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-calf/:calfId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={CalfHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "daily-milk-record",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDailyMilkRecord} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DailyMilkRecordAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-daily-milk-record/:milkRecordId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DailyMilkRecordEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "lactation-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageLactationHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={LactationHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-lactation/:lactationId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={LactationHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "insemination-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageInseminationHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={InseminationHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-insemination/:inseminationId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={InseminationHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "purchase-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManagePurchaseHistory} />
              </Suspense>
            ),
          },
          {
            path: "health-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageHealthHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={HealthHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-health/:healthId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={HealthHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/deworming",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={Dewarming} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DewarmingAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:dewormingId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DewarmingEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/vaccination",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={Vaccination} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VaccinationAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:vaccineId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VaccinationEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/diagnostic-reports",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDiagnosticReports} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DiagnosticReportAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-diagnostic/:reportId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DiagnosticReportEdit} />
                  </Suspense>
                ),
              },
            ],
          },

          {
            path: "sale-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageSaleHistory} />
              </Suspense>
            ),
          },
          {
            path: "death-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDeathHistory} />
              </Suspense>
            ),
          },
          {
            path: "employee-assignment-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeAssignmentHistory} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "bull-view/:id",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AnimalManageBuffaloView} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={BasicInformationEdit} />
              </Suspense>
            ),
          },
          {
            path: "calf-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageCalfHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={CalfHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-calf/:calfId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={CalfHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "daily-milk-record",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDailyMilkRecord} />
              </Suspense>
            ),
          },
          {
            path: "lactation-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageLactationHistory} />
              </Suspense>
            ),
          },
          {
            path: "insemination-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageInseminationHistory} />
              </Suspense>
            ),
          },
          {
            path: "purchase-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManagePurchaseHistory} />
              </Suspense>
            ),
          },

          {
            path: "health-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageHealthHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={HealthHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-health/:healthId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={HealthHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/deworming",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={Dewarming} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DewarmingAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:dewormingId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DewarmingEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/vaccination",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={Vaccination} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VaccinationAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:vaccineId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VaccinationEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/diagnostic-reports",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDiagnosticReports} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DiagnosticReportAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-diagnostic/:reportId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DiagnosticReportEdit} />
                  </Suspense>
                ),
              },
            ],
          },

          {
            path: "sale-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageSaleHistory} />
              </Suspense>
            ),
          },
          {
            path: "death-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDeathHistory} />
              </Suspense>
            ),
          },
          {
            path: "employee-assignment-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeAssignmentHistory} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "calf-view/:id",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AnimalManageCalfView} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={CalfBasicInformationEdit} />
              </Suspense>
            ),
          },
          {
            path: "purchase-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={CalfManagePurchaseHistory} />
              </Suspense>
            ),
          },
          {
            path: "health-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={CalfManageHealthHistory} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={CalfHealthHistoryAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-health/:healthId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={CalfHealthHistoryEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/deworming",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={Dewarming} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DewarmingAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:dewormingId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DewarmingEdit} />
                  </Suspense>
                ),
              },
            ],
          },

          {
            path: "health-history/vaccination",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={Vaccination} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VaccinationAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:vaccineId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VaccinationEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "health-history/diagnostic-reports",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDiagnosticReports} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DiagnosticReportAdd} />
                  </Suspense>
                ),
              },
              {
                path: "edit-diagnostic/:reportId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DiagnosticReportEdit} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "sale-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={CalfManageSaleHistory} />
              </Suspense>
            ),
          },
          {
            path: "death-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={CalfManageDeathHistory} />
              </Suspense>
            ),
          },
          {
            path: "employee-assignment-history",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeAssignmentHistory} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "register-calf",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AnimalManageCalfAdd} />
          </Suspense>
        ),
      },
      {
        path: "sale-history-view/:id",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AnimalManageSaleHistoryView} />
          </Suspense>
        ),
      },
      {
        path: "sale-history-add",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AnimalManageSaleHistoryAdd} />
          </Suspense>
        ),
      },
      {
        path: "death-history-view/:id",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AnimalManageDeathHistoryView} />
          </Suspense>
        ),
      },
      {
        path: "death-history-add",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AnimalManageDeathHistoryAdd} />
          </Suspense>
        ),
      },
      {
        path: "animals-globalSettings",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelAnimalGlobalSettings} />
          </Suspense>
        ),
      },
      {
        path: "animals-viewAll",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelAnimalViewAll} />
          </Suspense>
        ),
      },
      {
        path: "animals-archive",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelAnimalArchive} />
          </Suspense>
        ),
      },
      {
        path: "vendorManagement",
        children: [
          {
            path: "register",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={VendorRegister} />
              </Suspense>
            ),
          },
          {
            path: "manage",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageVendor} />
              </Suspense>
            ),
          },
          {
            path: "bulk-upload",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={BulkMilkSaleUpload} />
              </Suspense>
            ),
          },
          {
            path: "payments",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={VendorPayments} />
              </Suspense>
            ),
          },
          {
            path: "billing-reports",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={VendorBillingReports} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={TotalBills} />
                  </Suspense>
                ),
              },
              {
                path: "bills-regeneration",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={BillsRegeneration} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "archive",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={VendorArchive} />
              </Suspense>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={VendorEdit} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VendorViewDailySSales} />
                  </Suspense>
                ),
                children: [
                  {
                    path: "add",
                    element: (
                      <Suspense fallback={""}>
                        <PrivateRoute component={AddDailySales} />
                      </Suspense>
                    ),
                  },
                  {
                    path: "dailySalesEdit/:saleId",
                    element: (
                      <Suspense fallback={""}>
                        <PrivateRoute component={EditDailySales} />
                      </Suspense>
                    ),
                  },
                ],
              },

              {
                path: "billingReports",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VendorViewBillingReports} />,
                  </Suspense>
                ),
              },
              {
                path: "payments",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VendorViewPayments} />
                  </Suspense>
                ),
                children: [
                  {
                    path: "add",
                    element: (
                      <Suspense fallback={""}>
                        <PrivateRoute component={VendorAddPayment} />
                      </Suspense>
                    ),
                  },
                  {
                    path: "edit/:paymentId",
                    element: (
                      <Suspense fallback={""}>
                        <PrivateRoute component={EditVendorPayment} />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "basicInformation",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VendorViewBasicInformation} />
                  </Suspense>
                ),
              },
              {
                path: "identificationDocuments",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute
                      component={VendorViewIdentificationDocuments}
                    />
                  </Suspense>
                ),
              },
              {
                path: "depositInformation",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VendorViewDepositInformation} />
                  </Suspense>
                ),
              },
              {
                path: "milkPriceRevision",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={MilkPriceRevision} />
                  </Suspense>
                ),
                children: [
                  {
                    path: "add",
                    element: (
                      <Suspense fallback={""}>
                        <PrivateRoute component={MilkPriceRevisionAdd} />
                      </Suspense>
                    ),
                  },
                  {
                    path: "edit/:milkRevisionId",
                    element: (
                      <Suspense fallback={""}>
                        <PrivateRoute component={MilkPriceRevisionEdit} />
                      </Suspense>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: "employee-register",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelEmployeeRegister} />
          </Suspense>
        ),
      },
      {
        path: "employee-manage",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelEmployeeManage} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeesMangeList} />
              </Suspense>
            ),
          },
          {
            path: "employee-view/:id",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeManageView} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EmployeeBasicInformationEdit} />
                  </Suspense>
                ),
              },
              {
                path: "attendance",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={Attendance} />
                  </Suspense>
                ),
              },
              {
                path: "employment-status-history",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EmployeementStatusHistory} />
                  </Suspense>
                ),
                children: [
                  {
                    path: "",
                    element: (
                      <Suspense fallback={""}>
                        <PrivateRoute
                          component={EmployeementHistoryStatusAdd}
                        />
                      </Suspense>
                    ),
                  },
                  {
                    path: "edit/:historyId",
                    element: (
                      <Suspense fallback={""}>
                        <PrivateRoute
                          component={EmployeementHistoryStatusEdit}
                        />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "increment",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={Increment} />
                  </Suspense>
                ),
                children: [
                  {
                    path: "",
                    element: (
                      <Suspense fallback={""}>
                        <PrivateRoute component={IncrementAdd} />
                      </Suspense>
                    ),
                  },
                  {
                    path: "edit/:salaryId",
                    element: (
                      <Suspense fallback={""}>
                        <PrivateRoute component={IncrementEdit} />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "payslip",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={Payslip} />,
                  </Suspense>
                ),
              },
              {
                path: "employment-info",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EmploymentInformationEdit} />
                  </Suspense>
                ),
              },
              {
                path: "identification-documents",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={IdentificationDocumentEdit} />
                  </Suspense>
                ),
              },
              {
                path: "bank-account-info",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={BankInformationEdit} />
                  </Suspense>
                ),
              },
              {
                path: "animals-assigned",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={AnimalAssigned} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "employee-view/:id/attendance/edit/:date",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeePanelEditAttendance} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "employee-attendance",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelEmployeeAttendance} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeAttendanceList} />
              </Suspense>
            ),
          },
          {
            path: "create",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeCreateAttendance} />
              </Suspense>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeEditAttendance} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "employee-payroll",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelEmployeePayroll} />
          </Suspense>
        ),
        children: [
          {
            path: "list",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeePayrollListWrapper} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EmployeePayrollAdvance} />
                  </Suspense>
                ),
              },
              {
                path: "overtime-allowance",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EmployeeOvertimeAllowance} />
                  </Suspense>
                ),
              },
              {
                path: "salary",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EmployeePayrollSalary} />
                  </Suspense>
                ),
              },
              {
                path: "increment",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EmployeePayrollSalary} />
                  </Suspense>
                ),
              },
              {
                path: "salary-generation",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EmployeeSalaryGeneration} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "advance/create",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeCreatePayrollAdvance} />
              </Suspense>
            ),
          },
          {
            path: "advance/edit/:id",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeEditPayrollAdvance} />
              </Suspense>
            ),
          },

          {
            path: "salary/create",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeCreatePayrollSalary} />
              </Suspense>
            ),
          },
          {
            path: "salary/edit/:id",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeEditPayrollSalary} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "bulk-attendance",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={EmployeeBulkAttendance} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={BulkAttendanceList} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "bulk-advance",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={EmployeeBulkAdvance} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={BulkAdvanceList} />
              </Suspense>
            ),
          },
          {
            path: "overtime-allowance",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={BulkOverTimeAllowanceList} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "employee-archive",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelEmployeeArchive} />
          </Suspense>
        ),
      },
      {
        path: "revenue",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelRevenue} />
          </Suspense>
        ),
      },
      {
        path: "reports",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelReports} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeReport} />
              </Suspense>
            ),
          },
          {
            path: "animal-report",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalReports} />
              </Suspense>
            ),

            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={PregnancyList} />
                  </Suspense>
                ),
              },
              {
                path: "close-to-calving",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={CloseToCalving} />
                  </Suspense>
                ),
              },

              {
                path: "dryoff-recommendation",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DryOffRecommended} />
                  </Suspense>
                ),
              },
              {
                path: "awaiting-insemination",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={AwaitingInsemination} />
                  </Suspense>
                ),
              },
              {
                path: "pregnancy-checkup",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={PregnancyCheckList} />
                  </Suspense>
                ),
              },
              {
                path: "vaccination",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VaccinationReport} />
                  </Suspense>
                ),
              },
              {
                path: "deworming",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DewormingReports} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "expenseReport",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ExpenseReport} />
              </Suspense>
            ),
          },
          {
            path: "vendorSalesReport",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={VendorSalesReport} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DailySalesReports} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "feedReport",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={FeedReport} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "subAdmin",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelSubAdmin} />
          </Suspense>
        ),

        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={RoleForm} />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EditSubAdmin} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "assetsAndLiabilities",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelAssetsAndLiabilities} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={Assets} />
              </Suspense>
            ),
          },
          {
            path: "liabilities",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={Liabilities} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "assetsAndLiabilities/assetView/:id",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AssetView} />
          </Suspense>
        ),
      },
      {
        path: "assetsAndLiabilities/liabilityView/:id",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={LiabilityView} />
          </Suspense>
        ),
      },
      {
        path: "charityManagement",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelCharityManagement} />
          </Suspense>
        ),
        children: [],
      },
      {
        path: "charityManagement/charityView/:id",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={CharityView} />
          </Suspense>
        ),
      },
      {
        path: "outletManagement",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelOutletManagement} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={OutletManage} />
              </Suspense>
            ),
          },
          {
            path: "production",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ProductionUnit} />
              </Suspense>
            ),
          },
          {
            path: "stock",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={StockManage} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "outletManagement/create",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={OutletRegisterForm} />
          </Suspense>
        ),
      },

      {
        path: "documents",
        children: [
          {
            path: "addNew",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AddNewDocument} />,
              </Suspense>
            ),
          },
          {
            path: "manage",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ManageDocuments} />
              </Suspense>
            ),
          },
          {
            path: "archive",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ArchiveDocuments} />
              </Suspense>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EditDocument} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "notifications",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminpanelNotificatons} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AllNotification} />
              </Suspense>
            ),
          },
          {
            path: "animal",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={AnimalNotifications} />
              </Suspense>
            ),
          },
          {
            path: "employee",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={EmployeeNotifications} />
              </Suspense>
            ),
          },
          {
            path: "view/:id",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={NotificationView} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "settings",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelSettings} />
          </Suspense>
        ),
      },
      {
        path: "helps",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelHelp} />
          </Suspense>
        ),
      },
      {
        path: "greenFooder",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={LeaseInformation} />,
          </Suspense>
        ),

        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={LeaseLandList} />
              </Suspense>
            ),
          },
          {
            path: "yield-information",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={YieldInformationView} />
              </Suspense>
            ),
          },
          {
            path: "crop-information",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={CropInformationView} />
              </Suspense>
            ),
          },
          {
            path: "expense-information",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ExpenseInformationView} />
              </Suspense>
            ),
          },
          {
            path: "sale-information",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={SaleInformationView} />
              </Suspense>
            ),
          },
          {
            path: "purchase-information",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={PurchaseInformationView} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "greenFooder/lease-land-view/:id",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={LeaseLandView} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={LeaseLandEditForm} />
              </Suspense>
            ),
          },
          {
            path: "farmer-information-edit",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={FarmerInformationEdit} />
              </Suspense>
            ),
          },
          {
            path: "crop-information-edit/",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={CropInformationEdit} />
              </Suspense>
            ),
            children: [
              {
                path: "add",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={AddCropInformation} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:cropId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EditCropInformation} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "yield-information-edit",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={YieldInformationEdit} />
              </Suspense>
            ),
            children: [
              {
                path: "add",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={AddYieldInformation} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:yieldId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EditYieldInformation} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "expense-information-edit",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={ExpenseInformationEdit} />
              </Suspense>
            ),
            children: [
              {
                path: "add",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={AddExpenseInformation} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:expenseId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EditExpenseInformation} />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "sale-information-edit",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={SaleInformationEdit} />
              </Suspense>
            ),
            children: [
              {
                path: "add",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={AddSaleInformation} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:saleId",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={EditSaleInformation} />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },

      {
        path: "new-lease-land-registration",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={LeaseLandRegistrationPage} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={LeaseInformationForm} />
              </Suspense>
            ),
          },
          {
            path: "farm-registration-form",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={FarmerInformationForm} />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: "dry-fooder",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={DryFooder} />
          </Suspense>
        ),
        children: [],
      },
      {
        path: "purchase-dry-fooder-view",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={PurchaseDryFooder} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={DryFooderFeedInformationForm} />
              </Suspense>
            ),
          },
          {
            path: "farmer-information-form",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={DryFooderFarmerInformationForm} />
              </Suspense>
            ),
          },
          {
            path: "expense-information-form",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={DryFooderExpenseInformationForm} />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: "purchase-history",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={PurchaseHistory} />
          </Suspense>
        ),
      },

      {
        path: "daily-usage",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={DailyUsageFeed} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={DailyUsageFeedData} />
              </Suspense>
            ),
          },
          {
            path: "total-feed-stock",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={TotalFeedStock} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "dry-fooder-registration-form",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={PurchaseDryFooderRegistrationForm} />
          </Suspense>
        ),
      },
      {
        path: "purchase-history",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={PurchaseHistory} />
          </Suspense>
        ),
      },
      {
        path: "todo-list",
        element: (
          <Suspense fallback={""}>
            <PrivateRoute component={AdminPanelTodoList} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={TodoListView} />
              </Suspense>
            ),
          },
          {
            path: "view",
            element: (
              <Suspense fallback={""}>
                <PrivateRoute component={TodoParticularView} />
              </Suspense>
            ),
            children: [
              {
                path: "pregnancy-list",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={PregnancyList} />
                  </Suspense>
                ),
              },
              {
                path: "close-to-calving",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={CloseToCalving} />
                  </Suspense>
                ),
              },

              {
                path: "dryoff-recommendation",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DryOffRecommended} />
                  </Suspense>
                ),
              },
              {
                path: "awaiting-insemination",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={AwaitingInsemination} />
                  </Suspense>
                ),
              },
              {
                path: "pregnancy-checkup",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={PregnancyCheckList} />
                  </Suspense>
                ),
              },
              {
                path: "vaccination",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={VaccinationReport} />
                  </Suspense>
                ),
              },
              {
                path: "deworming",
                element: (
                  <Suspense fallback={""}>
                    <PrivateRoute component={DewormingReports} />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },

  // employee panel routes

  {
    path: "/emp",
    element: (
      <Suspense fallback={""}>
        <EmployeePrivateRoute component={EmployeeLayout} />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={""}>
            <EmployeePrivateRoute component={EmployeeDashboard} />
          </Suspense>
        ),

        children: [
          {
            path: "",

            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={AllAnimalsView} />
              </Suspense>
            ),
          },
          {
            path: "buffalos",

            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={DashboardBuffaloView} />
              </Suspense>
            ),
          },
          {
            path: "bulls",

            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={DashboardBullsView} />
              </Suspense>
            ),
          },
          {
            path: "calfs",

            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={DashboardCalfsView} />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: "milk-report",
        element: (
          <Suspense fallback={""}>
            <EmployeePrivateRoute component={EmployeePanelMilkingReport} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={EmployeeBulkMilkRecords} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "attendance",
        element: (
          <Suspense fallback={""}>
            <EmployeePrivateRoute component={EmployeePanelAttendance} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={EmployeeAttendancePage} />
              </Suspense>
            ),
          },
          {
            path: "edit/:date",
            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={EmployeePanelEditAttendance} />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: "animals",

        element: (
          <Suspense fallback={""}>
            <EmployeePrivateRoute component={AllAnimalListView} />
          </Suspense>
        ),

        children: [
          {
            path: "",

            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={MyAnimalListAnimalView} />
              </Suspense>
            ),
          },
          {
            path: "buffalos",

            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={MyAnimalListBuffaloView} />
              </Suspense>
            ),
          },
          {
            path: "bulls",

            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={MyAnimalListBullsView} />
              </Suspense>
            ),
          },
          {
            path: "calfs",

            element: (
              <Suspense fallback={""}>
                <EmployeePrivateRoute component={MyAnimalListCalfsView} />
              </Suspense>
            ),
          },
        ],
      },
      // {
      //   path: "notifications",
      //   element: <EmployeePanelNotifications />,

      //   children: [
      //     {
      //       path: "",
      //       element: <EmployeePanelAllNotifications />,
      //     },
      //     {
      //       path: "animal-notification",
      //       element: <EmployeePanelAnimalNotification />,
      //     },
      //     {
      //       path: "employee-notification",
      //       element: <EmployeePanelEmployeeNotification />,
      //     },
      //   ],
      // },
      // {
      //   path: "notifications",

      //   element: (
      //     <EmployeePrivateRoute component={EmployeePanelNotifications} />
      //   ),

      //   children: [
      //     // {
      //     //   path: "",

      //     //   element: (
      //     //     // <EmployeePrivateRoute component={EmployeePanelAllNotifications} />
      //     //   ),
      //     // },
      //     {
      //       path: "animal",

      //       element: (
      //         <EmployeePrivateRoute
      //           component={EmployeePanelAnimalNotification}
      //         />
      //       ),
      //     },
      //     {
      //       path: "employee",

      //       element: (
      //         <EmployeePrivateRoute
      //           component={EmployeePanelEmployeeNotification}
      //         />
      //       ),
      //     },
      //   ],
      // },
      {
        path: "help",

        element: (
          <Suspense fallback={""}>
            <EmployeePrivateRoute component={EmployeePanelHelp} />
          </Suspense>
        ),
      },
      {
        path: "settings",

        element: (
          <Suspense fallback={""}>
            <EmployeePrivateRoute component={EmployeePanelSettings} />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={""}>
        <PageNotFound />
      </Suspense>
    ),
  },
]);
