import { Icon } from "@iconify/react";
import { Button, ThemeProvider } from "@material-tailwind/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Controller, useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { AdminAuthorURL } from "../../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../../stores/AuthContext";
import { theme } from "../../../../../helpers/CustomTheme";
import { queryClient } from "../../../../../../App";

const EditExpenseInformation = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [cropIds, setCropIds] = useState([]);

  const [selectedCrop, setSelectedCrop] = useState({});

  const { getAccessToken } = useAuth();

  const { id, expenseId } = useParams();

  const fetchExpenseInformationDetails = async () => {
    try {
      const token = await getAccessToken();
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.fetchExpenseDetailsByExpenseId(
          expenseId
        );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(responseData, "expense data");

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      // handle error
    }
  };

  const updateExpenseInformation = async (data) => {
    try {
      const token = await getAccessToken();

      console.log(data, "expense data");

      const url =
        AdminAuthorURL.fooderManagement.greenFooder.updateExpense(expenseId);
      const options = {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();

      console.log(responseObj, "expense response");
      console.log(response, "expense response");

      if (response.ok) {
        toast.success(responseObj?.message);
     
      } else {
        toast.error(responseObj?.message);
      }
    } catch (e) {
      // handle error
    }
  };

  const updateExpenseInformationMutation = useMutation({
    mutationFn: updateExpenseInformation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchExpensesList"] });
    },
  });

  const fetchCropsIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchCropIds(
        searchKey || "",
        id
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.ok) {
        const updatedData = responseData?.response?.crops?.map((item) => ({
          label: `${item?.id}-${item?.cropName}`,
          value: item?._id,
          id: item?.id,
        }));

        console.log(updatedData, "updated data");

        setCropIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  useEffect(() => {
    fetchCropsIds("");
  }, []);

  const loadCropDetails = async (key) => {
    const options = await fetchCropsIds(key);

    return options;
  };

  const submitData = async (data) => {
    try {
      const expenseData = {
        expenseType: data?.expenseType,
        expenseAmount: data?.expenseAmount,
        expenseDate:
          typeof data?.expenseDate === "string"
            ? data?.expenseDate
            : moment(data?.expenseDate[0]).format("YYYY-MM-DD"),

        remarks: data?.remarks,
      };
      await updateExpenseInformationMutation.mutate(expenseData);
    } catch (e) {
      // handle error
    }
  };

  const { data } = useQuery({
    queryKey: ["fetchExpenseInformationDetails", expenseId],
    queryFn: fetchExpenseInformationDetails,
  });

  useEffect(() => {
    if (data) {

      console.log(cropIds,
        
      )
      const selectedCrop = cropIds.find((item) => item?.id === data?.cropCode);
      setSelectedCrop({
        label: selectedCrop?.label,
        value: selectedCrop?.value,
      });

      console.log(selectedCrop, "selected crop");

      setValue("expenseType", data?.expenseType);
      setValue("expenseAmount", data?.expenseAmount);
      setValue("expenseDate", moment(data?.expenseDate).format("YYYY-MM-DD"));
      setValue("remarks", data?.remarks);
      console.log(data, "expense data");
    }
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      <form
        onSubmit={handleSubmit(submitData)}
        action=''
        className='flex flex-col items-center px-[1rem]'>
        <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-[2rem] m-4 w-full md:w-[700px] lg:w-[800px]'>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='crop-name' className='text-[14px] text-[#3D4A5C]'>
              Crop Name<span className=' text-[#f00]'>*</span>
            </label>

            <div className=''>
              <Controller
                name='cropId'
             
                control={control}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    menuPortalTarget={document.body}
                    menuPlacement='auto'
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        width: "auto",
                        minWidth: "100%",
                      }),
                      menuList: (provided, state) => ({
                        ...provided,
                        maxHeight: "200px",
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                        cursor: "pointer",
                      }),
                    }}
                    theme={(theme) => {
                      return {
                        ...theme,
                        borderRadius: 6,
                        colors: {
                          ...theme.colors,
                          primary25: "#e5e7eb",
                          primary50: "#e5e7eb",
                          primary: "#9FB947",
                        },
                      };
                    }}
                    // onChange={(e) => {
                    //   console.log(e);
                    //   handleChange(index, "employeeId", e?.value);
                    //   handleChange(index, "employeeName", e?.label);
                    // }}

                    value={selectedCrop}
                    className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
                    isClearable
                    defaultOptions={cropIds}
                    loadOptions={loadCropDetails}
                  />
                )}
              />
            </div>
            {errors?.cropId && (
              <p className='text-[#E92215] text-sm'>
                {errors?.cropId?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='crop-name' className='text-[14px] text-[#3D4A5C] '>
              Expense Type<span className=' text-[#f00]'>*</span>
            </label>

            <input
              id='crop-name'
              type='text'
              name='expenseType'
              {...register("expenseType", {
                required: "*This field is required",
              })}
              className='border border-[#AFBACA]  rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C]text-[0.9rem] placeholder-[#8897AE]'
              placeholder='Supernapler'
            />
            {errors?.expenseType && (
              <p className='text-[#E92215] text-sm'>
                {errors?.expenseType?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label
              htmlFor='expenseDate'
              className='text-[14px] text-[#3D4A5C] '>
              Expense Date<span className=' text-[#f00]'>*</span>
            </label>
            <Controller
              id='expenseDate'
              name='expenseDate'
              control={control}
              rules={{ required: "*This field is required" }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Flatpickr
                    {...field}
                    options={{
                      dateFormat: "Y-m-d",
                    }}
                    className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
                    placeholder='Select Date'
                  />
                </>
              )}
            />
            {errors?.expenseDate && (
              <p className='text-[#E92215] text-sm'>
                {errors?.expenseDate?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label
              htmlFor='expenseAmount'
              className='text-[14px] text-[#3D4A5C]'>
              Expense(Rs)<span className=' text-[#f00]'>*</span>
            </label>
            <input
              id='expenseAmount'
              type='number'
              step='any'
              name='expenseAmount'
              {...register("expenseAmount", {
                required: "*This field is required",
              })}
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='₹2000'
            />
            {errors?.expenseAmount && (
              <p className='text-[#E92215] text-sm'>
                {errors?.expenseAmount?.message}
              </p>
            )}
          </div>

          <div className='flex flex-col gap-[0.5rem] lg:col-span-2'>
            <label htmlFor='remarks' className='text-[14px] text-[#3D4A5C]'>
              Remarks
            </label>
            <textarea
              rows='5'
              name='remarks'
              {...register("remarks")}
              id='remarks'
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='No Remarks'
            />
          </div>
        </div>
        <div className='flex flex-row justify-end gap-4  my-20 w-full md:w-[700px] lg:w-[800px]'>
          <Button
            onClick={() => navigate(-1)}
            variant='outlined'
            type='button'
            color='green'
            className=' border-[#9FB947] w-[8rem] flex justify-center items-center  gap-3 capitalize '>
            <Icon
              icon='akar-icons:arrow-left'
              className='text-[1.5rem] text-[#8897AE] cursor-pointer '
            />
            <span className='text-[#8897AE]'>Cancel</span>
          </Button>
          {updateExpenseInformationMutation?.isPending ? (
            <div className='flex justify-center items-center w-[8rem]'>
              <ThreeDots
                height='50'
                width='50'
                radius='9'
                color='#9FB947'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            </div>
          ) : (
            <Button
              type='submit'
              color='green'
              className='w-[8rem] flex justify-center items-center capitalize  bg-[#9FB947] '>
              Save
            </Button>
          )}
        </div>
      </form>
    </ThemeProvider>
  );
};

export default EditExpenseInformation;
