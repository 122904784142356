import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  basicInformation: {
    id: "",
    name: "",
    mobileNumber: "",
    email: "",
    joiningDate: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    dailyCapacity: null,
    priceConfigurationPerLitre: null,
    profilePicture: null,
  },
  identificationDocument: {
    aadhaarNumber: null,
    aadhaarPictures: [],
    panCardNumber: null,
    pancardPictures: "",
  },
  depositInformation: {
    amountValue: null,
    date: "",
    time: "",
    paymentMode: "",
    paymentStatus: "",
  },
};

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    updateBasicInformation(state, action) {
      state.basicInformation = {
        ...action.payload,
      };
    },
    updateIdentificationDocument(state, action) {
      state.identificationDocument = {
        ...action.payload,
      };
    },
    updateDepositInformation(state, action) {
      state.depositInformation = {
        ...action.payload,
      };
    },
  },
});
