import { Icon } from "@iconify/react";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  ThemeProvider,
} from "@material-tailwind/react";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { APIUrls } from "../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../stores/AuthContext";
import {
  dataActions,
  filtrationManageActions,
} from "../../../../stores/IndexRedux";
import { customStyles } from "../../../helpers/CustomDataTableStyles";
import LvreddyLoader from "../../../loaders/LvreddyLoader";
import CustomPagination from "./CustomPagination";

const theme = {
  tabsHeader: {
    defaultProps: {
      className: "",
      width: "500px",
    },
    styles: {
      base: {
        display: "flex",
        position: "relative",
        bg: "bg-blue-gray-50",
        bgOpacity: "bg-opacity-60",
        borderRadius: "rounded-lg",
        p: "p-1",
        width: "500px",
        maxWidth: "500px",
      },
      horizontal: {
        flexDirection: "flex-row",
        justifyContent: "center",
      },
      vertical: {
        flexDirection: "flex-col",
      },
    },
  },
  tab: {
    defaultProps: {
      className: "",
      activeClassName: "",
      disabled: false,
    },
    styles: {
      base: {
        tab: {
          initial: {
            display: "flex",
            alignItems: "items-center",
            justifyContent: "justify-center",
            textAlign: "text-center",
            width: "w-[200px]",
            height: "h-full",
            position: "relative",
            bg: "bg-transparent",
            py: "py-1",
            px: "px-2",
            color: "text-slate-400",

            fontFamily: "font-sans",
            fontSize: "text-sm",
            fontWeight: "font-normal",
            lineHeight: "leading-relaxed",
            userSelect: "select-none",
            cursor: "cursor-pointer",
          },
          disabled: {
            opacity: "opacity-50",
            cursor: "cursor-not-allowed",
            pointerEvents: "pointer-events-none",
            userSelect: "select-none",
          },
        },
        active: {
          color: "text-white",
          fontWeight: "font-bold",
        },
        indicator: {
          position: "absolute",
          inset: "inset-0",
          zIndex: "z-10",
          height: "h-full",
          color: "text-white",
          bg: "bg-[#9FB947]",

          borderRadius: "rounded-md",
          boxShadow: "shadow",
        },
      },
    },
  },
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const AnimalManageEmployeeMilkMeter = () => {
  const [page, setPage] = useState(1);

  const [milkMeterData, setMilkMeterData] = useState([]);
  const {
    fromDate,
    toDate,
    fromMonth,
    toMonth,
    fromYear,
    toYear,

    employeeId,
    shift,
  } = useSelector((state) => state.filtrationManage.milkMeter);
  const [totalData, setTotalData] = useState();
  const [slot, setSlot] = useState("daily");
  const state = useSelector((state) => state.filtrationManage.milkMeterState);

  const { searchKeyWord, searchDate } = useSelector(
    (state) => state.animalManage
  );

  const dispatch = useDispatch();

  const { getAccessToken } = useAuth();

  const columns = [
    // table columns for employees milk meter
    {
      name: "S.No.",
      id: "sno",
      selector: (row, index) => (page - 1) * 10 + index + 1,
      grow: 1,
    },
    {
      name: "Employee Id",
      id: "employeeId",
      selector: (row) => row.employeeId,
      width: "200px",
    },
    {
      name: "Employee Name",
      id: "employeeId",
      selector: (row) => row.employeeName,
      width: "230px",
    },
    {
      name: slot === "daily" ? "Date" : slot === "monthly" ? "Month" : "Year",
      width: "200px",

      selector: (row) =>
        slot === "daily"
          ? moment(row?.date).format("DD-MM-YYYY")
          : slot === "monthly"
          ? months[row?.month - 1] + " " + row?.year
          : row?.year,
    },
    {
      name: "Morning Milk",
      selector: (row) =>
        row?.morningMilk ? _.round(row?.morningMilk, 2) : "N.A",
      width: "210px",
    },
    {
      name: "Morning Milk Variation",

      cell: (row) => {
        return row?.morningMilkProgress ? (
          <div className='flex flex-row items-center gap-1'>
            <span>{_.round(row?.morningMilkProgress)}</span>

            {row?.morningMilkProgress > 0 ? (
              <Icon
                icon='ph:trend-up-bold'
                width='24'
                height='24'
                style={{ color: "#4fab36" }}
              />
            ) : (
              <Icon
                icon='ph:trend-down-fill'
                width='24'
                height='24'
                style={{ color: "#d21e1e" }}
              />
            )}
          </div>
        ) : (
          <span>N.A</span>
        );
      },
      width: "210px",
    },
    {
      name: "Evening Milk",
      selector: (row) =>
        row?.eveningMilk ? _.round(row?.eveningMilk, 2) : "N.A",
      width: "210px",
    },
    {
      name: "Evening Milk Variation",
      cell: (row) => {
        return row?.eveningMilkProgress ? (
          <div className='flex flex-row items-center gap-1'>
            <span>{_.round(row?.eveningMilkProgress)}</span>

            {row?.eveningMilkProgress > 0 ? (
              <Icon
                icon='ph:trend-up-bold'
                width='24'
                height='24'
                style={{ color: "#4fab36" }}
              />
            ) : (
              <Icon
                icon='ph:trend-down-fill'
                width='24'
                height='24'
                style={{ color: "#d21e1e" }}
              />
            )}
          </div>
        ) : (
          "N.A"
        );
      },
      width: "210px",
    },
    {
      name: "Total Milk",
      selector: (row) => (row?.totalMilk ? _.round(row?.totalMilk, 2) : "N.A"),
      width: "210px",
    },
    {
      name: "Total Milk Variation",
      cell: (row) => {
        return row?.totalMilkProgress ? (
          <div className='flex flex-row items-center gap-1'>
            <span>{_.round(row?.totalMilkProgress)}</span>

            {row?.totalMilkProgress > 0 ? (
              <Icon
                icon='ph:trend-up-bold'
                width='24'
                height='24'
                style={{ color: "#4fab36" }}
              />
            ) : (
              <Icon
                icon='ph:trend-down-fill'
                width='24'
                height='24'
                style={{ color: "#d21e1e" }}
              />
            )}
          </div>
        ) : (
          "N.A"
        );
      },
      width: "210px",
    },
  ];

  const tabsData = [
    {
      label: "Daily",
      value: "daily",
      columns: columns,
      data: milkMeterData,
    },
    {
      label: "Monthly",
      value: "monthly",
      columns: columns,
      data: milkMeterData,
    },
    {
      label: "Yearly",
      value: "yearly",
      columns: columns,
      data: milkMeterData,
    },
  ];

  console.log(fromDate, toDate, "date filter");

  const fetchMilkMetersData = async (download) => {
    try {
      const token = await getAccessToken();
      const url = APIUrls.fetchMilkProgress(
        page,
        searchKeyWord ? searchKeyWord : "",
        download,
        fromDate,
        toDate,
        employeeId ? employeeId?.value : "",
        fromMonth,
        toMonth,
        fromYear,
        toYear,
        shift
      );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);

      const responseData = await response.json();

      console.log(response);
      console.log(responseData, "meters data response");

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error("Failed to fetch milk meters data");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchMilkMetersData",
      page,
      fromDate,
      toDate,
      searchKeyWord,
      employeeId,
      fromMonth,
      toMonth,
      fromYear,
      toYear,
      shift,
    ],
    queryFn: async () => await fetchMilkMetersData(false),
  });
  const { data: downloadD } = useQuery({
    queryKey: [
      "fetchMilkMetersData",

      fromDate,
      toDate,
      searchKeyWord,
      employeeId,
      fromMonth,
      toMonth,
      fromYear,
      toYear,
      shift,
    ],
    queryFn: async () => await fetchMilkMetersData(true),
  });

  useEffect(() => {
    if (data) {
      setMilkMeterData(data?.limitedData);
      setTotalData(data.totalData);
    }
  }, [data]);

  useEffect(() => {
    if (downloadD) {
      let updatedData = [];
      let totalMorningMilk = 0;
      let totalMorningMilkVariation = 0;
      let totalEvening = 0;
      let totalEveningMilkVariation = 0;
      let totalTotalMilk = 0;
      let totalTotalMilkVariation = 0;

      downloadD?.limitedData?.forEach((item) => {
        totalMorningMilk += item?.morningMilk;
        totalMorningMilkVariation += item?.morningMilkProgress;
        totalEvening += item?.eveningMilk;
        totalEveningMilkVariation += item?.eveningMilkProgress;
        totalTotalMilk += item?.totalMilk;
        totalTotalMilkVariation += item?.totalMilkProgress;
      });

      if (shift === "daily") {
        updatedData = downloadD?.limitedData?.map((item) => ({
          ...item,
          date: moment(item?.date).format("DD-MM-YYYY"),
          morningMilk: item?.morningMilk ? _.round(item?.morningMilk, 2) :"N.A", 
          eveningMilk: item?.eveningMilk ? _.round(item?.eveningMilk, 2) : "N.A",
          morningMilkProgress:item?.morningMilkProgress ?  _.round(item?.morningMilkProgress, 2) : "N.A",
          eveningMilkProgress: item?.eveningMilkProgress ? _.round(item?.eveningMilkProgress, 2) : "N.A",
          totalMilk: item?.totalMilk ? _.round(item?.totalMilk, 2) : "N.A",
          totalMilkProgress: item?.totalMilkProgress ? _.round(item?.totalMilkProgress, 2) : "N.A",
        }));
      } else if (shift === "monthly") {
        updatedData = downloadD?.limitedData?.map((item) => ({
          ...item,
          date: `${months[item?.month - 1]} ${item?.year}`,
          morningMilk: item?.morningMilk ? _.round(item?.morningMilk, 2) : "N.A",
          eveningMilk: item?.eveningMilk ? _.round(item?.eveningMilk, 2) : "N.A",
          morningMilkProgress:item?.morningMilkProgress ?  _.round(item?.morningMilkProgress, 2) : "N.A",
          eveningMilkProgress:item?.eveningMilkProgress ?  _.round(item?.eveningMilkProgress, 2) : "N.A",
          totalMilk: item?.totalMilk ? _.round(item?.totalMilk, 2) : "N.A",
          totalMilkProgress: item?.totalMilkProgress ? _.round(item?.totalMilkProgress, 2) : "N.A",
        }));
      } else if (shift === "yearly") {
        updatedData = downloadD?.limitedData?.map((item) => ({
          ...item,
          date: `${item?.year}`,
          morningMilk: item?.morningMilk
            ? _.round(item?.morningMilk, 2)
            : "N.A",
          eveningMilk: item?.eveningMilk
            ? _.round(item?.eveningMilk, 2)
            : "N.A",
          morningMilkProgress: item?.morningMilkProgress
            ? _.round(item?.morningMilkProgress, 2)
            : "N.A",
          eveningMilkProgress: item?.eveningMilkProgress
            ? _.round(item?.eveningMilkProgress, 2)
            : "N.A",
          totalMilk: item?.totalMilk ? _.round(item?.totalMilk, 2) : "N.A",
          totalMilkProgress: item?.totalMilkProgress
            ? _.round(item?.totalMilkProgress, 2)
            : "N.A",
        }));
      }

      dispatch(dataActions.updateData(updatedData));
      dispatch(
        dataActions.updateTotalStats([
          _.round(totalMorningMilk, 2),
          _.round(totalMorningMilkVariation, 2),
          _.round(totalEvening, 2),
          _.round(totalEveningMilkVariation, 2),
          _.round(totalTotalMilk, 2),
          _.round(totalTotalMilkVariation, 2),
        ])
      );
      dispatch(dataActions.updatePageStatus("milk-meter"));

      console.log(downloadD, "download data");
    }
  }, [downloadD]);

  return (
    <div className='p-3 pt-0 w-full  flex flex-col gap-[1rem]'>
      <ThemeProvider value={theme}>
        <div className='m-2'>
          <Tabs id='custom-animation' value='daily'>
            <div className='flex justify-center w-full'>
              <TabsHeader
                style={{
                  maxWidth: "500px",
                }}>
                {tabsData.map(({ label, value }) => (
                  <Tab
                    className={`${slot === "value" && "active-milk-meter-tab"}`}
                    onClick={() => {
                      console.log(value, "value");
                      dispatch(
                        filtrationManageActions.updateMilkMeter({
                          shift: value,
                        })
                      );
                      setSlot(value);
                      setPage(1);
                    }}
                    key={value}
                    value={value}>
                    <span
                      style={{
                        color: slot === value && "#ffffff",
                      }}>
                      {label}
                    </span>
                  </Tab>
                ))}
              </TabsHeader>
            </div>
            <TabsBody
              animate={{
                initial: { y: 250 },
                mount: { y: 0 },
                unmount: { y: 250 },
              }}>
              {isLoading ? (
                <div className='p-[2.5rem] flex justify-center items-center'>
                  <LvreddyLoader />
                </div>
              ) : (
                tabsData.map(({ value, columns, data }) => (
                  <TabPanel key={value} value={value}>
                    <DataTable
                      title={`${
                        value.charAt(0).toUpperCase() + value.slice(1)
                      } Data`}
                      columns={columns}
                      customStyles={customStyles}
                      data={data}
                      pagination
                      paginationComponent={() =>
                        CustomPagination({
                          rowsPerPage: 10,
                          rowCount: totalData,
                          currentPage: page,
                          onChangePage: setPage,
                        })
                      }
                      highlightOnHover
                    />
                  </TabPanel>
                ))
              )}
            </TabsBody>
          </Tabs>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default AnimalManageEmployeeMilkMeter;
