import { useNavigate, useOutletContext } from "react-router-dom";

import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { queryClient } from "../../../../../App";
import { AdminAuthorURL } from "../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../stores/AuthContext";
import { dataActions } from "../../../../../stores/IndexRedux";
import { customStyles } from "../../../../helpers/CustomDataTableStyles";
import LvreddyLoader from "../../../../loaders/LvreddyLoader";
import CustomPagination from "../../../animalModule/animalManageLists/CustomPagination";

const SaleInformationView = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [salesData, setSalesData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const { fromDate, toDate } = useSelector(
    (state) => state?.leaseLand?.saleInformationList
  );

  const { searchKey } = useOutletContext();

  const { getAccessToken } = useAuth();

  const dispatch = useDispatch();

  function formatNumber(num) {
    const isNegative = num < 0;
    const absNum = Math.abs(num);
    let formattedNum;

    if (absNum >= 1000000000) {
      formattedNum = (absNum / 1000000000).toPrecision(3) + "B";
    } else if (absNum >= 1000000) {
      formattedNum = (absNum / 1000000).toPrecision(3) + "M";
    } else if (absNum >= 1000) {
      formattedNum = (absNum / 1000).toPrecision(4) + "K";
    } else {
      formattedNum = absNum.toString();
    }

    return isNegative ? "-" + formattedNum : formattedNum;
  }

  const fetchSaleInformationList = async (download) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchAllSalesList(
        page,
        searchKey,
        download,
        fromDate,
        toDate
      );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(responseData, "responseData");

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData?.message);
      }
    } catch (e) {
      // handle error
    }
  };

  const deleteSpecificSaleInformation = async (saleId) => {
    try {
      const token = await getAccessToken();
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.deleteSaleBySaleId(saleId);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();

      if (response.ok) {
        toast.success(responseObj?.message);
      } else {
        toast.error(responseObj?.message);
      }
    } catch (e) {
      toast.error(`Error deleting sale information: ${e.message}`);
    }
  };

  const deleteSaleInformationMutation = useMutation({
    mutationFn: deleteSpecificSaleInformation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchSaleInformationList"] });
    },
  });

  const columns = [
    // table columns
    {
      name: "S.No",

      id: "s.no",
      cell: (row, index) => index + 1,
    },
    {
      name: "Land Code",
      id: "landId",
      selector: (row) => (row.landId ? row?.landId : "N/A"),
    },

    {
      name: "Feed Type",
      id: "feedType",
      selector: (row) => row.feedType,
    },
    {
      name: "Customer Name",
      id: "customerName",
      selector: (row) => row.customerName,
      width: "200px",
    },
    {
      name: "Mobile Number",
      id: "mobileNumber",
      selector: (row) => row.mobileNumber,
      width: "200px",
    },
    {
      name: "Address",
      id: "address",
      selector: (row) => row.address,
    },
    {
      name: "City",
      id: "city",
      selector: (row) => row.city,
    },
    {
      name: "State",
      id: "state",
      selector: (row) => row.state,
      width: "190px",
    },

    {
      name: "Quantity(Qun)",
      id: "quantity",
      selector: (row) => _.round(row.quantity, 2),
      width: "190px",
    },
    {
      name: "Unit Price1 (Qun)",
      id: "unitPrice",
      selector: (row) => _.round(row.unitPrice, 2),
      width: "200px",
    },
    {
      name: "Total Price",
      id: "totalPrice",
      selector: (row) => _.round(row.totalPrice, 2),
      width: "170px",
    },
    {
      name: "Payment Type",
      id: "paymentType",
      selector: (row) => row?.paymentType,

      width: "180px",
    },
    {
      name: "Sale Date",
      id: "date",
      selector: (row) => moment(row?.saleDate).format("DD-MM-YYYY"),
    },
    {
      name: "Remarks",
      id: "remarks",
      selector: (row) => row.remarks,
    },

    {
      name: "Action",
      id: "action",
      cell: (row) => (
        <div className='flex space-x-2'>
          <div className='border rounded p-1 cursor-pointer'>
            <RiDeleteBinLine
              onClick={async () => {
                await deleteSaleInformationMutation.mutate(row?._id);
              }}
              className='text-[1.7rem] cursor-pointer rounded text-[#5E718D] '
            />
          </div>
        </div>
      ),
    },
  ];

  const { data: downloadD } = useQuery({
    queryKey: ["fetchSaleInformationList", page, searchKey, fromDate, toDate],
    queryFn: async () => await fetchSaleInformationList(false),
  });

  const { data, isLoading } = useQuery({
    queryKey: ["fetchSaleInformationList", page, searchKey, fromDate, toDate],
    queryFn: async () => await fetchSaleInformationList(false),
  });

  useEffect(() => {
    if (data) {
      setSalesData(data?.data);
      setTotalData(data?.totalData);
    } else {
      setSalesData([]);
      setTotalData();
    }
  }, [data]);

  useEffect(() => {
    if (downloadD) {
      const updatedData = downloadD?.data?.map((item) => ({
        ...item,
        saleDate: moment(item.saleDate).format("DD-MM-YYYY"),
        totalPrice: _.round(item.totalPrice, 2),
        unitPrice: _.round(item.unitPrice, 2),
        quantity: _.round(item.quantity, 2),

        remarks: item.remarks,
      }));

      const totalTotalAmount = downloadD?.data?.reduce(
        (a, b) => (b?.totalPrice !== null ? a + b?.totalPrice : a),
        0
      );

      const totalQuantity = downloadD?.data?.reduce(
        (a, b) => (b?.quantity !== null ? a + b?.quantity : a),
        0
      );

      console.log(totalTotalAmount, "totaltotal amount");

      dispatch(dataActions.updateData(updatedData));

      dispatch(
        dataActions.updateTotalStats([
          formatNumber(totalQuantity),
          "",
          `₹ ${formatNumber(totalTotalAmount)}`,
        ])
      );
    } else {
      dispatch(dataActions.updateData([]));

      dispatch(dataActions.updateTotalStats([]));
    }
  }, [downloadD]);

  return (
    <div className='p-3 pt-0 animal-table '>
      <div className='m-2 '>
        {isLoading ? (
          <div className='flex justify-center items-center p-[2.5rem]'>
            <LvreddyLoader />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={salesData}
            customStyles={customStyles}
            pagination
            paginationComponent={() =>
              CustomPagination({
                rowsPerPage: 10,
                rowCount: totalData,
                currentPage: page,
                onChangePage: setPage,
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default SaleInformationView;
