import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useRef } from "react";
import letterHeadFooterFrame from "../../assets/Frame 1171275330.png";
import reddyLogo from "../../assets/LV Reddy Farm Logo 1.jpg";
import letterHeadSideBlock from "../../assets/Vector 7 (1).jpg";
import mail from "../../assets/mail.png";
import network from "../../assets/network.png";
import telephone from "../../assets/old-typical-phone.png";
import serif from "./PTSerif-Bold.ttf";
import roboto from "./Roboto-Bold.ttf";

Font.register({
  family: "Bree Serif",
  fonts: [{ src: serif, fontWeight: 700 }],
});

Font.register({
  family: "Roboto",
  fonts: [{ src: roboto, fontWeight: 800 }],
});

const ITEMS_PER_PAGE = 10;
const PdfDownload = ({
  headersArray,
  data,
  keys,
  title,
  dailyMilkRecords,
  salaryStats,
  advanceStats,
  totalDonations,
  totalAssets,
  totalLiabilities,

  totalVendorSales,
  salesStats,
  totalRow,
}) => {
  const orientationMode = headersArray?.length > 5 ? "landscape" : "portrait";

  console.log(totalRow, "total row");

  const pageHeight = 600;

  const pageCount = Math.ceil(data?.length / ITEMS_PER_PAGE);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      position: "relative",

      width: "100%",
      height: pageHeight,

      paddingTop: 7,

      paddingBottom: 0,
    },
    section: {
      width: "100%",

      flexGrow: 1,
    },

    backgroundImage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: "20%",
      left: 0,
      right: 0,
      bottom: "25%",
      opacity: 0.1,
      height: "60vh",
    },
    contactText: {
      fontSize: "11px",
      fontWeight: 800,
      color: "#00A15C",
    },
  });

  const dataRef = useRef(null);

  return (
    <Document>
      <Page wrap size='A4' orientation={orientationMode} style={styles.page}>
        <View fixed style={styles.backgroundImage}>
          <Image
            fixed
            src={reddyLogo}
            style={{
              height: "auto",
              width: "400px",
            }}
          />
        </View>
        <View style={styles.section}>
          <View
            fixed
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "auto",
              width: "100%",
              marginBottom: "20px",

              paddingRight: 10,
              paddingLeft: 10,
              flexShrink: 0,
            }}>
            <Image
              style={{
                height: "60px",
                width: "85PX",

                flexShrink: 0,
                marginTop: "6px",
              }}
              src={reddyLogo}
            />

            <View
              style={{
                alignSelf: "center",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                flexShrink: 0,
              }}>
              <Text
                style={{
                  fontSize: "23px",
                  alignSelf: "center",
                  color: "#00A15C",

                  fontFamily: "Bree Serif",

                  paddingBottom: "2px",
                }}>
                LV REDDY DAIRY FARM
              </Text>
              <View
                style={{
                  height: "4px",
                  width: "100%",
                  backgroundColor: "#060606",
                  marginLeft: "20px",
                  borderTopLeftRadius: "100%",
                  borderTopRightRadius: "100%",
                }}></View>

              <View style={{ alignSelf: "center" }}>
                <Text
                  style={{
                    fontSize: "12px",
                    fontFamily: "Roboto",
                    color: "#000",
                    paddingTop: "4px",
                    fontWeight: "bold",
                  }}>
                  GST: 37AAJFL5107A1ZP
                </Text>
              </View>
            </View>

            <View
              style={{
                alignSelf: "flex-end",
                marginTop: "-10px",
              }}>
              <Image
                src={letterHeadSideBlock}
                style={{ height: "auto", width: "200px" }}
              />
            </View>
          </View>

          <Text
            style={{
              fontFamily: "Roboto",
              textAlign: "center",
              fontSize: "20px",
              marginVertical: "15px",
              textDecoration: "underline",
            }}>
            {title}
          </Text>

          <View
            style={{
              display: "flex",

              flexDirection: "column",
              flexGrow: 1,
              flexShrink: 1,
              width: "100%",
            }}>
            <View
              fixed
              style={{
                display: "flex",
                flexDirection: "row",
                flexShrink: 0,

                backgroundColor: "green",
                marginBottom: "10px",
                paddingTop: "10px",
                paddingBottom: "20px",
                paddingRight: "10px",
                paddingLeft: "10px",
                height: "auto",
              }}>
              {headersArray?.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      fontSize: "9px",

                      color: "#fff",
                      width: item?.width,

                      textAlign: "center",
                      fontFamily: "Roboto",
                    }}>
                    <Text>{item.title}</Text>
                  </View>
                );
              })}
            </View>
            <View
              ref={dataRef}
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}>
              {data?.map((item, i) => {
                return (
                  <View
                    key={i}
                    style={{
                      display: "flex",
                      flexDirection: "row",

                      padding: "10px",
                      fontSize: "10px",
                      fontFamily: "Roboto",
                    }}>
                    <Text
                      style={{
                        width: "5%",
                        textAlign: "center",
                      }}>
                      {i + 1}
                    </Text>
                    {keys?.map((i, index) => {
                      if (typeof item[i] === "object") {
                        if (item[i]?.length === 0) {
                          return (
                            <Text
                              style={{
                                width: headersArray[index + 1]?.width,
                                textAlign: "center",
                              }}>
                              N.A
                            </Text>
                          );
                        } else {
                          return (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: headersArray[index + 1]?.width,

                                gap: "3px",
                              }}>
                              {item[i]?.map((cell) => {
                                if (typeof cell === "object") {
                                  Object.keys(cell).forEach((item) => {
                                    return (
                                      <Text
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          flexShrink: 0,
                                          lineClamp: 3,
                                        }}>
                                        {cell[item]}
                                      </Text>
                                    );
                                  });
                                } else {
                                  return (
                                    <Text
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        flexShrink: 0,
                                      }}>
                                      {" "}
                                      {cell}{" "}
                                    </Text>
                                  );
                                }
                              })}
                            </View>
                          );
                        }
                      }
                      return (
                        <Text
                          style={{
                            width: headersArray[index + 1]?.width,
                            textAlign: "center",

                            whiteSpace: "pre",
                            height: "auto",
                          }}>
                          {item[i] !== "" ? item[i] : "N.A"}
                        </Text>
                      );
                    })}
                  </View>
                );
              })}

              {dailyMilkRecords && (
                <View
                  style={{
                    backgroundColor: "green",
                    height: "30px",
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                  }}>
                  {headersArray?.map((item, index) => {
                    if (index === 1) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          Total
                        </Text>
                      );
                    } else if (index > 1 && index < 5) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          {dailyMilkRecords[index - 2]} L
                        </Text>
                      );
                    } else {
                      return <Text style={{ width: item?.width }}></Text>;
                    }
                  })}

                  {/* <View
                    style={{
                      width: "160px",

                      textAlign: "left",
                      padding: "10px",
                    }}>
                    <Text>{dailyMilkRecords?.totalMorningMilk} L</Text>
                  </View>
                  <View
                    style={{
                      width: "160px",

                      textAlign: "left",
                      padding: "10px",
                    }}>
                    <Text>{dailyMilkRecords?.totalEveningMilk} L</Text>
                  </View>
                  <View
                    style={{
                      width: "160px",

                      textAlign: "left",
                      padding: "10px",
                    }}>
                    <Text>{dailyMilkRecords?.totalCombinedMilk}</Text>
                  </View> */}
                </View>
              )}

              {salaryStats?.length > 0 && (
                <View
                  style={{
                    backgroundColor: "green",
                    height: "30px",
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    marginTop: "40px",
                    width: "100%",
                  }}>
                  {headersArray?.map((item, index) => {
                    if (index === 1) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          Total
                        </Text>
                      );
                    } else if (index > 3 && index < 11) {
                      return (
                        <Text
                          style={{
                            width: item?.width,
                            fontSize: "11px",

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          {salaryStats[index - 4]}
                        </Text>
                      );
                    } else {
                      return <Text style={{ width: item?.width }}></Text>;
                    }
                  })}
                </View>
              )}
              {advanceStats?.length > 0 && (
                <View
                  style={{
                    backgroundColor: "green",
                    height: "30px",
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                  }}>
                  {headersArray?.map((item, index) => {
                    if (index === 1) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          Total
                        </Text>
                      );
                    } else if (index > 3 && index < 6) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          {advanceStats[index - 4]}
                        </Text>
                      );
                    } else {
                      return <Text style={{ width: item?.width }}></Text>;
                    }
                  })}
                </View>
              )}

              {totalDonations && (
                <View
                  style={{
                    backgroundColor: "green",
                    height: "30px",
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                  }}>
                  {headersArray?.map((item, index) => {
                    if (index === 1) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          Total
                        </Text>
                      );
                    } else if (index > 2 && index < 4) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          {totalDonations}
                        </Text>
                      );
                    } else {
                      return <Text style={{ width: item?.width }}></Text>;
                    }
                  })}
                </View>
              )}
              {totalAssets && (
                <View
                  style={{
                    backgroundColor: "green",
                    height: "30px",
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                  }}>
                  {headersArray?.map((item, index) => {
                    if (index === 1) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          Total
                        </Text>
                      );
                    } else if (index > 5 && index < 7) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          {totalAssets}
                        </Text>
                      );
                    } else {
                      return <Text style={{ width: item?.width }}></Text>;
                    }
                  })}
                </View>
              )}
              {totalLiabilities?.length > 0 && (
                <View
                  style={{
                    backgroundColor: "green",
                    height: "30px",
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                  }}>
                  {headersArray?.map((item, index) => {
                    if (index === 1) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          Total
                        </Text>
                      );
                    } else if (index > 2 && index < 5) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          {totalLiabilities[index - 3]}
                        </Text>
                      );
                    } else {
                      return <Text style={{ width: item?.width }}></Text>;
                    }
                  })}
                </View>
              )}

              {totalVendorSales?.length > 0 && (
                <View
                  style={{
                    backgroundColor: "green",
                    height: "30px",
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                  }}>
                  {headersArray?.map((item, index) => {
                    if (index === 1) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          Total
                        </Text>
                      );
                    } else if (index > 3 && index < 7) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          {totalVendorSales[index - 4]}
                        </Text>
                      );
                    } else {
                      return <Text style={{ width: item?.width }}></Text>;
                    }
                  })}
                </View>
              )}
              {salesStats?.length > 0 && (
                <View
                  style={{
                    backgroundColor: "green",
                    height: "30px",
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                  }}>
                  {headersArray?.map((item, index) => {
                    if (index === 1) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          Total
                        </Text>
                      );
                    } else if (index > 4 && index < 7) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          {salesStats[index - 5]}
                        </Text>
                      );
                    } else {
                      return <Text style={{ width: item?.width }}></Text>;
                    }
                  })}
                </View>
              )}

              {totalRow && (
                <View
                  style={{
                    backgroundColor: "green",
                    height: "30px",
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "11px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                  }}>
                  {headersArray?.map((item, index) => {
                    if (index === 1) {
                      return (
                        <Text
                          style={{
                            width: item?.width,

                            textAlign: "center",
                            padding: "10px",
                          }}>
                          Total
                        </Text>
                      );
                    } else if (
                      index > totalRow?.index - 1 &&
                      index < totalRow?.index + totalRow?.data?.length
                    ) {
                      return (
                        <Text
                          style={{
                            width: item?.width,
                            fontSize: "10px",

                            textAlign: "center",
                            padding: "10px",
                            whiteSpace: "wrap",
                          }}>
                          {totalRow?.data[index - totalRow?.index]}
                        </Text>
                      );
                    } else {
                      return <Text style={{ width: item?.width }}></Text>;
                    }
                  })}
                </View>
              )}
            </View>
          </View>
        </View>

        <View
          fixed
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            boxSizing: "content-box",
            width: "100%",
            paddingTop: 20,
            flexShrink: 0,
          }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontFamily: "Roboto",
              paddingLeft: 10,
              paddingRight: 10,
            }}>
            <View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              <View
                style={{
                  backgroundColor: "#00A15C",
                  height: "24px",
                  width: "24px",
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Image
                  src={telephone}
                  style={{ height: "16px", width: "16px" }}
                />
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1px",
                }}>
                <Text style={styles.contactText}>+91 9666995008</Text>
                <Text style={styles.contactText}>+91 9666109229</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              <View
                style={{
                  backgroundColor: "grey",
                  height: "24px",
                  width: "24px",
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Image
                  src={network}
                  style={{ height: "16px", width: "16px" }}
                />
              </View>
              <View
                style={{
                  alignSelf: "center",
                }}>
                <Text style={styles.contactText}>lvreddydairyfarm.com</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              <View
                style={{
                  backgroundColor: "#00A15C",
                  height: "24px",
                  width: "24px",
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Image src={mail} style={{ height: "16px", width: "16px" }} />
              </View>
              <View
                style={{
                  alignSelf: "center",
                }}>
                <Text style={styles.contactText}>
                  info@lvreddydairyfarm.com
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              height: "2px",
              width: "100%",
              backgroundColor: "#00A15C",
              marginTop: "10px",
              marginBottom: "10px",
              boxSizing: "content-box",
            }}></View>

          <View
            style={{
              width: "100%",
            }}>
            <Text
              style={{
                fontSize: "14px",
                color: "green",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "Roboto",
              }}>
              3-180,Bhupal nagar village,Nandanapalli Post, Kurnool Dist ,
              Andhra Pradesh-518452
            </Text>
          </View>

          <View style={{ marginTop: "10px" }}>
            <Image
              src={letterHeadFooterFrame}
              style={{
                height: "44px",
                width: "100%",
              }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDownload;
