import { Icon } from "@iconify/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useMutation, useQuery } from "@tanstack/react-query";
import _, { round } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Flatpickr from "react-flatpickr";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { queryClient } from "../../../../App";
import { AdminAuthorURL } from "../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../stores/AuthContext";
import { dataActions } from "../../../../stores/IndexRedux";
import { customStyles } from "../../../helpers/CustomDataTableStyles";
import PayslipDocument from "../../../helpers/PayslipDocument";
import LvreddyLoader from "../../../loaders/LvreddyLoader";
import CustomCheckbox from "../../animalModule/animalManageLists/CustomCheckbox";
import CustomPagination from "../../animalModule/animalManageLists/CustomPagination";
import "../../employeeModule/styles.css";

const EmployeeSalaryGeneration = () => {
  const { keyword } = useOutletContext();

  const { employeeName, fromDate, toDate, status } = useSelector(
    (state) => state.employee.salaryGenerationFilters
  );
  const dispatch = useDispatch();
  const [regenerateSalaryFilter, setRegenerateFilter] = useState(false);
  const [approveFilter, setApproveFilter] = useState(false);
  const [selectId, setSelectId] = useState([]);
  const [page, setPage] = useState(1);
  const [salaryData, setSalaryData] = useState([]);
  const [totalData, setTotalData] = useState();
  const [downloadSalaryData, setDownloadSalaryData] = useState([]);
  const [regenerateSalaryFromDate, setRegenerateSalaryFromDate] = useState("");
  const [regenerateSalaryToDate, setRegenerateSalaryToDate] = useState("");

  const [approveSalaryFromDate, setApproveSalaryFromDate] = useState("");
  const [approveSalaryToDate, setApproveSalaryToDate] = useState("");

  const [path, setPath] = useState();

  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === "/employee-payroll/list/salary") {
      setPath("salary");
    } else if (location.pathname === "/employee-payroll/list/increment") {
      setPath("increment");
    } else {
      setPath("advance");
    }
  }, [location]);
  const { getAccessToken } = useAuth();

  const months = [
    "January",
    "Febraury",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const salaryColumns = [
    {
      name: "Employee Code",
      selector: (row) => row?.employeeId,
      id: "employeeId",
      minWidth: "150px",
    },
    {
      name: "Employee Name",
      selector: (row) => row?.employeeName,
      id: "employeeName",
      minWidth: "200px",
    },
    {
      name: "Month",
      id: "month",
      minWidth: "200px",
      cell: (row) => {
        const monthIndex =
          row?.month?.split("")[0] === "0"
            ? parseInt(row?.month?.split("")[1])
            : parseInt(row?.month);

        return <p>{`${months[monthIndex - 1]},${row?.year}`}</p>;
      },
    },
    {
      name: "Salary Per Month",
      selector: (row) => row?.salaryPerMonth,
      id: "salaryPerMonth",
      minWidth: "200px",
    },
    {
      name: "Payable Days",
      selector: (row) => row?.payableDays,
      id: "payableDays",
      minWidth: "200px",
    },
    {
      name: "Net Pay",
      cell: (row) => <p>{round(row?.netPay, 2)} </p>,
      id: "netPay",
    },
    {
      name: "Gross Pay",
      selector: (row) => round(row?.GrossPay, 2),
      id: "grossPay",
    },
    {
      name: "Total Advances",
      selector: (row) => row?.totalAdvances,
      id: "totalAdvances",
      minWidth: "200px",
    },
    {
      name: "Total Overtime Allowances",
      selector: (row) => row?.totalOverTimeAllowances,
      id: "totalOverTimeAllowances",
      minWidth: "270px",
    },
    {
      name: "Carried Forward Pay",
      selector: (row) => round(row?.carriedForwardPay, 2),
      id: "carriedForwardPay",
      minWidth: "250px",
    },
    {
      name: "Status",
      cell: (row) => (
        <p className='text-[#f5a142] font-medium text-[0.875rem]'>
          {row?.status}
        </p>
      ),

      minWidth: "250px",
    },
    {
      name: "Action",
      id: "action",
      cell: (row) => (
        <div className='flex space-x-2'>
          {/* <div className='border rounded p-1 cursor-pointer'>
            <Link
              to={`${path === "salary" ? "../../salary" : "../advance"}/edit/${
                row._id
              }`}>
              <MdModeEditOutline className='text-[1.7rem] cursor-pointer text-[#5E718D] ' />
            </Link>
          </div> */}
          <div className='border rounded p-1 cursor-pointer'>
            <PDFDownloadLink
              fileName='payslip.pdf'
              document={<PayslipDocument data={row} />}>
              <Icon
                icon={"material-symbols:download"}
                className='text-[1.7rem] cursor-pointer rounded text-[#5E718D] '
                // onClick={() => Navigate("/admin")} //navigate to admin page on delete icon click
              />
            </PDFDownloadLink>
          </div>
        </div>
      ),
    },
  ];

  const fetchEmployeesSalaries = async (download) => {
    try {
      const token = await getAccessToken();

      const url = AdminAuthorURL.payroll.fetchSalaries(
        fromDate,
        toDate,

        employeeName ? employeeName?.value : keyword,
        "PREPARED",
        page,
        download
      );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(response, "response");
      console.log(responseData);
      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRegenerateSalary = async () => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.employee.regenerateSalaryRecords(
        regenerateSalaryFromDate,
        regenerateSalaryToDate
      );

      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();
      console.log(responseObj, "regenerate salary response");
      console.log(response);

      if (response.ok) {
        toast.success(responseObj.message);
        setRegenerateSalaryFromDate("");
        setRegenerateSalaryToDate("");
      } else {
        toast.error(responseObj.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleApproveSalary = async () => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.employee.approveSalaryRecords(
        approveSalaryFromDate,
        approveSalaryToDate
      );
      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const responseObj = await response.json();
      console.log(responseObj, "apprive salary response");
      console.log(response);

      if (response.ok) {
        toast.success(responseObj.message);
        setApproveSalaryFromDate("");
        setApproveSalaryToDate("");
      } else {
        toast.error(responseObj.message);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const { data: downloadD } = useQuery({
    queryKey: [
      "fetchEmployeesSalaries",
      fromDate,
      toDate,
      employeeName,
      keyword,
      page,
      status,
    ],
    queryFn: async () => fetchEmployeesSalaries(true),
  });

  // const { data, isLoading } = useQuery({
  //   queryKey: [
  //     "fetchEmployeesSalaries",
  //     fromDate,
  //     toDate,
  //     employeeName,
  //     keyword,
  //     page,
  //     status,
  //   ],
  //   queryFn: async () => fetchEmployeesSalaries(false),
  // });




  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchEmployeesSalaries",
      fromDate,
      toDate,
      employeeName,
      keyword,
      page,
      status,
    ],
    queryFn: async () => fetchEmployeesSalaries(false),
  });

 
  const regenerateSalaryRecordsMutation = useMutation({
    mutationFn: handleRegenerateSalary,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchEmployeesSalaries"] });
    },
  });

  const approveSalaryRecordsMutation = useMutation({
    mutationFn: handleApproveSalary,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchEmployeesSalaries"] });
    },
  });

  // handle selection of rows in all animals table
  const handleSelection = ({ selectedRows }) => {
    console.log(selectedRows); // selected rows data
    setSelectId(selectedRows);
  };

  useEffect(() => {
    if (data) {
      setSalaryData(data?.limitedData);
      setTotalData(data?.totalData);
    } else {
      setSalaryData([]);
    }
  }, [data, fromDate, toDate, employeeName, keyword, page]);

  useEffect(() => {
    if (downloadD) {

      console.log(downloadD, "download data");
      setDownloadSalaryData(downloadD?.limitedData);

      let totalSalaryPerMonth = 0;
      let totalPayableDays = 0;
      let totalNetPay = 0;
      let totalgrossPay = 0;
      let totalTotalAdvances = 0;
      let totalTotalOverTimeAllowances = 0;
      let totalCarriedForwardPay = 0;

      const salaryData = downloadD.limitedData?.map((item) => {
        totalSalaryPerMonth += parseInt(item?.salaryPerMonth);
        totalPayableDays += parseInt(item?.payableDays);
        totalNetPay += parseInt(item?.netPay);
        totalgrossPay += parseInt(item?.GrossPay);
        totalTotalAdvances += parseInt(item?.totalAdvances);
        totalTotalOverTimeAllowances += parseInt(item?.totalOverTimeAllowances);
        totalCarriedForwardPay += parseInt(item?.carriedForwardPay);

        const monthIndex =
          item?.month?.split("")[0] === "0"
            ? parseInt(item?.month?.split("")[1])
            : parseInt(item?.month);
        const month = `${months[monthIndex - 1]},${item?.year}`;

        const newItem = {
          ...item,
          month: month,
          GrossPay: round(item?.GrossPay, 2),
          netPay: round(item?.netPay, 2),
          carriedForwardPay: round(item?.carriedForwardPay, 2),
        };

        return newItem;
      });

      const updatedData = downloadD.limitedData.map((item, index) => ({
        ...item,
        date: `${item?.month - 1}, ${item?.year}`,
        netPay: _.round(item?.netPay, 2),

        GrossPay: _.round(item?.GrossPay, 2),
        carriedForwardPay: _.round(item?.carriedForwardPay, 2),
      }));
      dispatch(
        dataActions.updateData({
          data: updatedData,
          stats: [
            totalSalaryPerMonth,
            totalPayableDays,
            totalNetPay,
            totalgrossPay,
            totalTotalAdvances,
            totalTotalOverTimeAllowances,
            totalCarriedForwardPay,
          ],
        })
      );
    } else {
      setDownloadSalaryData([]);
    }
  }, [data, fromDate, toDate, employeeName, keyword, page]);

  console.log(regenerateSalaryFromDate, "fromDate");
  console.log(regenerateSalaryToDate, "toDate");

  return (
    <div className='w-full flex flex-col'>
      <div className='self-end flex flex-row  items-center gap-[1rem]'>
        <div className='w-full relative'>
          <button
            onClick={() => setRegenerateFilter(!regenerateSalaryFilter)}
            style={{
              background:
                "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
            }}
            className='text-white outline-none w-full max-w-[10rem] whitespace-nowrap shrink-0  rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[2rem] py-[0.5rem] '>
            Regenerate Salary
          </button>

          {regenerateSalaryFilter && (
            <div className='bg-white p-[1rem]  shadow-lg absolute top-[50px] right-[10%]  z-10 rounded-[0.5rem] w-auto'>
              <div className='flex flex-col gap-[1rem]'>
                <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
                  <div className='flex flex-col gap-[0.5rem]'>
                    <label className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
                      From Date
                    </label>
                    <Flatpickr
                      type='date'
                      className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
                      placeholder={"Select Start date"}
                      options={{
                        dateFormat: "Y-m",
                        maxDate: regenerateSalaryToDate,
                      }}
                      value={regenerateSalaryFromDate}
                      onChange={(e) => {
                        console.log(e[0]);
                        setRegenerateSalaryFromDate(
                          moment(e[0]).format("YYYY-MM")
                        );
                      }}
                    />
                  </div>
                  <div className='flex flex-col gap-[0.5rem]'>
                    <label className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
                      To Date
                    </label>

                    <Flatpickr
                      type='date'
                      className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
                      placeholder={"Select to date"}
                      options={{
                        dateFormat: "Y-m",
                        minDate: regenerateSalaryFromDate,
                      }}
                      value={regenerateSalaryToDate}
                      onChange={(e) => {
                        setRegenerateSalaryToDate(
                          moment(e[0]).format("YYYY-MM")
                        );
                      }}
                    />
                  </div>
                </div>
                <div className='self-end'>
                  {regenerateSalaryRecordsMutation?.isPending ? (
                    <ThreeDots
                      height='50'
                      width='50'
                      radius='9'
                      color='#9FB947'
                      ariaLabel='three-dots-loading'
                      wrapperStyle={{}}
                      wrapperClassName=''
                      visible={true}
                    />
                  ) : (
                    <button
                      onClick={async () => {
                        await regenerateSalaryRecordsMutation.mutate();
                      }}
                      style={{
                        background:
                          "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                      }}
                      className='text-white outline-none  rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[2rem] py-[0.5rem] '>
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='w-full relative'>
          <button
            onClick={() => setApproveFilter(!approveFilter)}
            style={{
              background:
                "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
            }}
            className='text-white outline-none w-full max-w-[10rem] shrink-0  rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[2rem] py-[0.5rem] '>
            Approve
          </button>
          {approveFilter && (
            <div className='bg-white p-[1rem]  shadow-lg absolute top-[50px] right-[10%]  z-10 rounded-[0.5rem] w-auto'>
              <div className='flex flex-col gap-[1rem]'>
                <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
                  <div className='flex flex-col gap-[0.5rem]'>
                    <label className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
                      From Date
                    </label>
                    <Flatpickr
                      type='date'
                      className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
                      placeholder={"Select Start date"}
                      options={{
                        dateFormat: "Y-m",
                        maxDate: approveSalaryToDate,
                      }}
                      value={approveSalaryFromDate}
                      onChange={(e) => {
                        setApproveSalaryFromDate(
                          moment(e[0]).format("YYYY-MM")
                        );
                      }}
                    />
                  </div>
                  <div className='flex flex-col gap-[0.5rem]'>
                    <label className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
                      To Date
                    </label>

                    <Flatpickr
                      type='date'
                      className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
                      placeholder={"Select to date"}
                      options={{
                        dateFormat: "Y-m",
                        minDate: approveSalaryFromDate,
                      }}
                      value={approveSalaryToDate}
                      onChange={(e) => {
                        setApproveSalaryToDate(moment(e[0]).format("YYYY-MM"));
                      }}
                    />
                  </div>
                </div>
                <div className='self-end'>
                  {approveSalaryRecordsMutation?.isPending ? (
                    <ThreeDots
                      height='50'
                      width='50'
                      radius='9'
                      color='#9FB947'
                      ariaLabel='three-dots-loading'
                      wrapperStyle={{}}
                      wrapperClassName=''
                      visible={true}
                    />
                  ) : (
                    <button
                      onClick={async () => {
                        await approveSalaryRecordsMutation.mutate();
                      }}
                      style={{
                        background:
                          "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                      }}
                      className='text-white outline-none  rounded-md text-center justify-center  flex flex-row gap-[0.5rem] px-[2rem] py-[0.5rem] '>
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className='flex justify-center items-center p-[2.5rem]'>
          <LvreddyLoader />
        </div>
      ) : (
        <DataTable
          columns={salaryColumns}
          data={salaryData}
          customStyles={customStyles}
          //progressPending={isLoading}
          pagination
          paginationComponent={() =>
            CustomPagination({
              rowsPerPage: 10,
              rowCount: totalData,
              currentPage: page,
              onChangePage: setPage,
            })
          }
          selectableRows
          selectableRowsComponent={CustomCheckbox}
          onSelectedRowsChange={handleSelection}
        />
      )}
    </div>
  );
};

export default EmployeeSalaryGeneration;
