import { useRouteError } from "react-router-dom";

import { useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";

const PageNotFound = () => {
  return (
    <div className='bg-[#fffff] p-[1rem] sm:p-[2rem] h-screen font-poppins '>
      <Link to={""}>
        <img alt='' className='w-[4rem] lg:w-[5.5rem] h-auto' src={logo} />
      </Link>

      <div className='flex flex-col  justify-center items-center  grow h-auto max-h-[80%]  mt-[2rem] w-full gap-[1rem] text-[#1A3032]'>
        <img
          className='w-[80%] h-auto max-w-[58.93rem] max-h-[9.6rem]'
          src='https://res.cloudinary.com/dhsfan19o/image/upload/v1718708658/EJOYSHOP-USER/Group_1_rzjpiq.svg'
        />

        <img
          className='h-[3rem] w-auto lg:h-[6rem]'
          src='https://res.cloudinary.com/dhsfan19o/image/upload/v1718708610/EJOYSHOP-USER/Group_oxy1ug.svg'
        />

        <p className='font-normal text-[1rem] lg:text-[1.2rem] mt-0'>
          Oops!, Page not Found
        </p>
        <p className='text-center font-nunito text-[1rem] lg:text-[1.1rem] font-normal'>
          We're sorry, the page you requested could not be found <br /> please
          go back to the homepage
        </p>
        <Link to={""}>
          <button
            style={{
              background:
                "var(--Linear, linear-gradient(90deg, #9FB947 -15.87%, #739500 100%))",
            }}
            type='button'
            className='px-[1.5rem] py-[0.8rem] rounded-[1.5rem] text-white text-[0.75rem] font-nunito font-semibold'>
            GO BACK HOME
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
