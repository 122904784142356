import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  downloadData: [],
  totalStats: [],

  downloadPage: "",
  dailyMilkRecords: [],
  calfView: {
    basicInformation: {},
    purchaseHistory: {},
    saleHistory: {},
    deathHistory: {},
  },
};

export const dataManageSlice = createSlice({
  name: "downloadData",
  initialState,
  reducers: {
    updateData(state, action) {
      console.log(action.payload, "up0m");
      state.downloadData = action.payload;
    },
    updateTotalStats(state, action) {
      state.totalStats = action.payload;
    },
    updatePageStatus(state, action) {
      state.downloadPage = action.payload;
    },
    updateCalfView(state, action) {
      state.calfView = action.payload;
    },
    updateDailyMilkRecordsData(state, action) {
      state.dailyMilkRecords = action.payload;
    },
  },
});
