import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stats: {},
};

export const assetSlice = createSlice({
  name: "assetsLiabilityStat",
  initialState,

  reducers: {
    updateStats(state, action) {
      state.stats = action.payload;
    },
  },
});
