import { Icon } from "@iconify/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AdminAuthorURL } from "../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../stores/AuthContext";
import {
  dataActions,
  filterManageActions,
} from "../../../../stores/IndexRedux";
import { customStyles } from "../../../helpers/CustomDataTableStyles";
import VendorBillSlip from "../../../helpers/VendorBillSlip";
import LvreddyLoader from "../../../loaders/LvreddyLoader";
import CustomPagination from "../../animalModule/animalManageLists/CustomPagination";

const TotalBills = () => {
  const { searchKey } = useOutletContext();
  const [totalVendorBills, setTotalVendorBills] = useState([]);
  const [totalDownload, setDownloadData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const { fromDate, toDate, vendorId } = useSelector(
    (state) => state.filterManage?.vendorBills
  );

  const dispatch = useDispatch();

  const { getAccessToken } = useAuth();

  const navigate = useNavigate();

  function formatNumber(num) {
    const isNegative = num < 0;
    const absNum = Math.abs(num);
    let formattedNum;

    if (absNum >= 1000000000) {
      formattedNum = (absNum / 1000000000).toPrecision(3) + "B";
    } else if (absNum >= 1000000) {
      formattedNum = (absNum / 1000000).toPrecision(3) + "M";
    } else if (absNum >= 1000) {
      formattedNum = (absNum / 1000).toPrecision(4) + "K";
    } else {
      formattedNum = absNum.toString();
    }

    return isNegative ? "-" + formattedNum : formattedNum;
  }
  const fetchVendorBills = async (download) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchBills(
        vendorId ? vendorId?.value : "",
        fromDate,
        toDate,
        searchKey,
        "APPROVED",
        page,
        download
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}  `,
        },
      };

      console.log(url, "url received");

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(response, "bill response");
      console.log(responseData);

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["fetchVendorBills", fromDate, toDate, vendorId, searchKey, page],
    queryFn: async () => await fetchVendorBills(false),
  });

  const { data: downloadData } = useQuery({
    queryKey: ["fetchVendorBills", fromDate, toDate, vendorId, searchKey],
    queryFn: async () => await fetchVendorBills(true),
  });

  useEffect(() => {
    if (data) {
      setTotalVendorBills(data?.limitedData);
      setTotalData(data?.totalData);
      console.log(data, "data");
    }
  }, [data]);

  useEffect(() => {
    if (downloadData) {
      let totalNetPayment = 0;
      let pricePerLitre = 0;
      let subTotalMilkCost = 0;
      let totalMilkQuantity = 0;
      let totalCarriedForwardPayment = 0;
      let totalPaidAmount = 0;

      downloadData?.limitedData?.forEach((item) => {
        totalNetPayment += item?.netPayment;
        pricePerLitre += item?.pricePerLitre;
        subTotalMilkCost += item?.subTotalMilkCost;
        totalMilkQuantity += item?.totalMilkQuantity;
        totalCarriedForwardPayment += item?.carriedForwardPayment;

        totalPaidAmount += item?.totalPaidAmount;
      });

      const updatedData = downloadData?.limitedData?.map((item) => ({
        ...item,
        startDate: moment(item?.cStartDate).format("DD-MM-YYYY"),
        endDate: moment(item?.cEndDate).format("DD-MM-YYYY"),
        netPayment: _.round(item?.netPayment, 2),
        subTotalMilkCost: _.round(item?.subTotalMilkCost, 2),
        totalMilkQuantity: _.round(item?.totalMilkQuantity, 2),
        totalPaidAmount: _.round(item?.totalPaidAmount, 2),
        carriedForwardPayment: _.round(item?.carriedForwardPayment, 2),
      }));

      dispatch(dataActions.updateData(updatedData));

      dispatch(
        dataActions?.updateTotalStats([
          `₹ ${formatNumber(_.round(totalNetPayment, 2))}`,
          ``,
          `₹ ${formatNumber(_.round(subTotalMilkCost, 2))}`,
          `${formatNumber(_.round(totalMilkQuantity, 2))} L`,
          `₹ ${formatNumber(_.round(totalCarriedForwardPayment, 2))}`,
          `₹ ${formatNumber(_.round(totalPaidAmount, 2))}`,
        ])
      );

      setDownloadData(downloadData?.limitedData);
    } else {
      dispatch(dataActions.updateData([]));

      dispatch(dataActions?.updateTotalStats([]));
    }
  }, [downloadData]);

  const columns = [
    {
      name: "Vendor Id",
      id: "vendorId",
      selector: (row) => row?.vendorId,
    },
    {
      name: "Vendor Name",

      cell: (row) => (
        <div
          onClick={() => {
            navigate("/reports/vendorSalesReport");
            dispatch(
              filterManageActions.updateDailySalesReports({
                vendorId: {
                  label: row?.vendorName,
                  value: row?.vendorMongoId,
                },
              })
            );
          }}>
          {row?.vendorName}
        </div>
      ),
      width: "200px",
    },
    {
      name: "Start Date",

      cell: (row) => (
        <div
          onClick={() => {
            navigate("/reports/vendorSalesReport");
            dispatch(
              filterManageActions.updateDailySalesReports({
                fromDate: moment(row?.cStartDate).format("YYYY-MM-DD"),
                toDate: moment(row?.cEndDate).format("YYYY-MM-DD"),
              })
            );
          }}>
          <p className='hover:underline'>
            {row?.cStartDate
              ? moment(row?.cStartDate).format("DD-MM-YYYY")
              : "N.A"}
          </p>
        </div>
      ),
      width: "210px",
    },
    {
      name: "End Date",
      id: "endDate",

      cell: (row) => (
        <div
          onClick={() => {
            navigate("/reports/vendorSalesReport");

            dispatch(
              filterManageActions.updateDailySalesReports({
                fromDate: moment(row?.cStartDate).format("YYYY-MM-DD"),
                toDate: moment(row?.cEndDate).format("YYYY-MM-DD"),
              })
            );
          }}>
          <p className='hover:underline'>
            {row?.cEndDate ? moment(row?.cEndDate).format("DD-MM-YYYY") : "N.A"}
          </p>
        </div>
      ),
      width: "210px",
    },
    {
      name: "Milk Collection Days",

      selector: (row) => row?.totalCollectionDays,
      width: "200px",
    },
    {
      name: "Net Payment",
      id: "netPayment",
      selector: (row) =>
        row?.currency?.symbol + " " + _.round(row?.netPayment, 2),
      width: "180px",
    },
    {
      name: "Price Per Litre",
      id: "pricePerLitre",
      selector: (row) => row?.currency?.symbol + row?.pricePerLitre,
      width: "220px",
    },
    {
      name: "Sub-Total Milk Cost",
      selector: (row) =>
        row?.currency?.symbol + " " + _.round(row?.subTotalMilkCost, 2),
      width: "180px",
    },
    {
      name: "Total Milk Quantity",
      id: "totalMilkQuantity",
      selector: (row) => _.round(row?.totalMilkQuantity, 2),
      width: "180px",
    },
    {
      name: "Carried Forward Payment",
      id: "carriedForwardPayment",
      selector: (row) =>
        row?.currency?.symbol + " " + _.round(row?.carriedForwardPayment, 2),
      width: "220px",
    },
    {
      name: "Total Paid Amount",
      id: "totalPaidAmount",
      selector: (row) =>
        row?.currency?.symbol + " " + _.round(row?.totalPaidAmount, 2),
      width: "220px",
    },
    {
      name: "Status",

      selector: (row) => row?.status,
      cell: (row) => (
        <p className='text-[green] text-[0.875rem] font-medium'>
          {row?.status}
        </p>
      ),
      minWidth: "250px",
    },

    {
      name: "Action",
      cell: (row) => (
        <div className='border rounded p-1 cursor-pointer'>
          <PDFDownloadLink
            fileName='billSlip.pdf'
            document={<VendorBillSlip data={row} />}>
            <Icon
              icon={"material-symbols:download"}
              className='text-[1.7rem] cursor-pointer rounded text-[#5E718D] '
              // onClick={() => Navigate("/admin")} //navigate to admin page on delete icon click
            />
          </PDFDownloadLink>
        </div>
      ),
    },
  ];
  return (
    <>
      {isLoading ? (
        <div className='flex justify-center items-center p-[2.5rem]'>
          <LvreddyLoader />
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={totalVendorBills}
          customStyles={customStyles}
          //progressPending={isLoading}
          pagination
          paginationComponent={() =>
            CustomPagination({
              rowsPerPage: 10,
              rowCount: totalData,
              currentPage: page,
              onChangePage: setPage,
            })
          }
          // selectableRows
          // selectableRowsComponent={CustomCheckbox}
          // onSelectedRowsChange={handleSelection}
        />
      )}
    </>
  );
};

export default TotalBills;
