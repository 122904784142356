import { useNavigate, useOutletContext } from "react-router-dom";

import { Icon } from "@iconify/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { RiDeleteBinLine } from "react-icons/ri";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { queryClient } from "../../../../../App";
import { AdminAuthorURL } from "../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../stores/AuthContext";
import { dataActions } from "../../../../../stores/IndexRedux";
import { customStyles } from "../../../../helpers/CustomDataTableStyles";
import LvreddyLoader from "../../../../loaders/LvreddyLoader";
import CustomPagination from "../../../animalModule/animalManageLists/CustomPagination";
import EditPurchaseInformation from "./modals/EditPurchaseInformation";

const PurchaseInformationView = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const [purchasesList, setPurchaseList] = useState([]);
  const [totalData, setTotalData] = useState(0);

  const { searchKey } = useOutletContext();

  const { fromDate, toDate } = useSelector(
    (state) => state?.leaseLand?.purchasesList
  );

  const dispatch = useDispatch();

  const deleteSpecificPurchaseDetails = async (id) => {
    try {
      const token = await getAccessToken();
      const url =
        AdminAuthorURL.fooderManagement.greenFooder.deletePurchaseByPurchaseId(
          id
        );

      const options = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();

      if (response.ok) {
        toast.success(responseObj?.message);
      } else {
        toast.error(responseObj?.message);
      }
    } catch (e) {
      // throw new Error(e?.message);
    }
  };

  const deletePurchaseInformationMutation = useMutation({
    mutationFn: deleteSpecificPurchaseDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchPurchasesList"] });
    },
  });

  const columns = [
    // table columns
    {
      name: "S.No",

      id: "s.no",
      cell: (row, index) => index + 1,
    },
    {
      name: "Purchase Code",
      id: "id",
      selector: (row) => row.id,
      width: "150px",
    },

    {
      name: "Feed Type",
      id: "feedType",
      selector: (row) => row.feedType,
      width: "150px",
    },
    {
      name: "Vendor Name",
      id: "vendorName",
      selector: (row) => row.vendorName,
      grow: 1,
      width: "180px",
    },

    {
      name: "Mobile Number",
      id: "mobileNumber",
      selector: (row) => row.mobileNumber,
      width: "200px",
    },
    {
      name: "Address",
      id: "address",
      selector: (row) => row.address,
      width: "200px",
    },
    {
      name: "City",
      id: "city",
      selector: (row) => row.city,
      width: "200px",
    },
    {
      name: "Pincode",
      id: "pincode",
      selector: (row) => row.pincode,
      width: "200px",
    },
    {
      name: "State",
      id: "state",
      selector: (row) => row.state,
      width: "200px",
    },
    {
      name: "Payment Type",
      id: "paymentType",
      selector: (row) => row.paymentType,
      width: "180px",
    },
    {
      name: "Quantity(Qun)",
      id: "quantity",
      selector: (row) => row.quantity,
      width: "190px",
    },
    {
      name: "Unit Price1 (Qun)",
      id: "unitPrice",
      selector: (row) => row.unitPrice,
      width: "190px",
    },
    {
      name: "Total Price",
      id: "totalCost",
      selector: (row) => row.totalCost,
      width: "200px",
    },

    {
      name: "Date",
      id: "date",
      selector: (row) => moment(row?.purchaseDate).format("DD-MM-YYYY"),

      width: "180px",
    },
    {
      name: "Other Expenses",
      id: "otherExpenses",
      selector: (row) => row.otherExpenses,
      width: "180px",
    },
    {
      name: "Vehicle Number",
      id: "vehicleNumber",
      selector: (row) => row.vehicleNumber,
      grow: 1,
      width: "180px",
    },
    {
      name: "remarks",
      id: "remarks",
      selector: (row) => row.remarks,
    },
    {
      name: "Action",
      id: "action",
      cell: (row) => (
        <div className='flex space-x-2'>
          <Popup
            modal
            position={"center center"}
            contentStyle={{
              width: "auto",
              background: "transparent",
            }}
            trigger={
              <div className='border rounded p-1 cursor-pointer'>
                <Icon
                  icon={"material-symbols:edit"}
                  className='text-[1.7rem] cursor-pointer text-[#5E718D]  '
                  //onClick={() => navigate("/lease-land-view", { relative: "path" })}
                  // navigate to all animals view page on edit icon click
                />
              </div>
            }>
            {(close) => <EditPurchaseInformation close={close} id={row?._id} />}
          </Popup>

          <div className='border rounded p-1 cursor-pointer'>
            <RiDeleteBinLine
              onClick={async () => {
                await deletePurchaseInformationMutation.mutate(row?._id);
              }}
              className='text-[1.7rem] cursor-pointer rounded text-[#5E718D] '
            />
          </div>
        </div>
      ),
    },
  ];

  const { getAccessToken } = useAuth();

  const fetchPurchasesList = async (download) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchPurchaseList(
        page,
        searchKey,
        download,
        fromDate,
        toDate
      );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}  `,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(responseData, "purchases list");
      console.log(response);

      if (response.ok) {
        return responseData.response;
      } else {
        throw new Error(responseData.message);
      }
    } catch (e) {
      // handle error here
    }
  };

  const { data: downloadD } = useQuery({
    queryKey: ["fetchPurchasesList", fromDate, toDate, searchKey],
    queryFn: async () => await fetchPurchasesList(true),
  });

  const { data, isLoading } = useQuery({
    queryKey: ["fetchPurchasesList", fromDate, toDate, page, searchKey],
    queryFn: async () => await fetchPurchasesList(false),
  });

  useEffect(() => {
    if (data) {
      setPurchaseList(data?.data);
      setTotalData(data?.totalData);
    } else {
      setPurchaseList([]);
    }
  }, [data]);

  useEffect(() => {
    if (downloadD) {
      const updatedData = downloadD?.data?.map((item) => ({
        ...item,
        purchaseDate: moment(item?.purchaseDate).format("DD-MM-YYYY"),
        totalCost: _.round(item?.totalCost, 2),
        unitPrice: _.round(item?.unitPrice, 2),
        quantity: _.round(item?.quantity, 2),
        otherExpenses: _.round(item?.otherExpenses, 2),
      }));

      const totalQuanity = downloadD?.data?.reduce(
        (a, b) => (b?.quantity !== null ? a + b?.quantity : a),
        0
      );

      const totalTotalCost = downloadD?.data?.reduce(
        (a, b) => (b?.totalCost !== null ? a + b?.totalCost : a),
        0
      );

      const totalOtherExpenses = downloadD?.data?.reduce(
        (a, b) => (b?.otherExpenses !== null ? a + b?.otherExpenses : a),
        0
      );

      dispatch(dataActions.updateData(updatedData));
      dispatch(
        dataActions.updateTotalStats([
          totalQuanity,
          "",
          totalTotalCost,
          totalOtherExpenses,
        ])
      );
      
      
    } else {
      dispatch(dataActions.updateData([]));
      dispatch(dataActions.updateTotalStats([]));
    }
  }, [downloadD]);

  return (
    <div className='p-3 pt-0 animal-table '>
      <div className='m-2 '>
        {isLoading ? (
          <div className='flex justify-center items-center p-[2.5rem]'>
            <LvreddyLoader />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={purchasesList}
            customStyles={customStyles}
            pagination
            paginationComponent={() =>
              CustomPagination({
                rowsPerPage: 10,
                rowCount: totalData,
                currentPage: page,
                onChangePage: setPage,
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default PurchaseInformationView;
