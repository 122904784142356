import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyDsr64keAIUXek_NVY7PjzrvLJcjvbAm-4",
  authDomain: "lv-reddy-dairy-farm.firebaseapp.com",
  projectId: "lv-reddy-dairy-farm",
  storageBucket: "lv-reddy-dairy-farm.appspot.com",
  messagingSenderId: "975927999403",
  appId: "1:975927999403:web:54bdba7cf67887b6fdf0ee",
  measurementId: "G-2VV8B9E29G",
  databaseURL:"https://lv-reddy-dairy-farm-default-rtdb.asia-southeast1.firebasedatabase.app/"
};


const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
const analytics = getAnalytics(app);
export const database = getDatabase(app);

export default app;