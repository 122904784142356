import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  basicInformation: {
    firstName: null,
    lastName: null,
    nationality: null,
    email: null,
    mobileNumber: null,
    alternativeMobileNumber: null,
    dob: null,
    gender: null,
    address: null,
    city: null,
    state: null,
    pinCode: null,
    profilePicture: [],
  },
  employmentInformation: {
    employeeId: null,
    jobTitle: null,
    dateOfJoining: null,
    salaryPerMonth: null,
    agreement: [],
  },
  identificationDocument: {
    aadhaarNumber: null,
    aadhaarFile: [],
    panCardNumber: null,
    panCardFile: [],
  },
  bankAccountInformation: {
    accountHolderName: null,
    _IFSCCode: null,
    accountNumber: null,
    bankName: null,
    bankCity: null,
    passbook: [],
  },
  formSaveLoaderBtn: false,
  attendance: {
    selectedDate: null,
  },
  salaryFilters: {
    startMonth: "",
    endMonth: "",
    employeeName: "",
  },
  salaryGenerationFilters: {
    employeeName: "",
    fromDate: "",
    toDate: "",
    status: "",
  },
  attendanceFilter: {
    employeeName: "",
    fromDate: "",
    toDate: "",
    status: "",
    date: "",
    shift: "date",
  },
  advanceFilter: {
    employeeName: "",
    fromDate: "",
    toDate: "",

    date: "",
    shift: "date",
  },
  overtimeAllowanceFilter: {
    employeeName: "",
    fromDate: "",
    toDate: "",

    date: "",
    shift: "date",
  },
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    updateBasicInformation(state, action) {
      state.basicInformation = { ...state.basicInformation, ...action.payload };
    },
    updateEmploymentInformation(state, action) {
      state.employmentInformation = {
        ...state.employmentInformation,
        ...action.payload,
      };
    },
    updateIdentificationDocument(state, action) {
      state.identificationDocument = {
        ...state.identificationDocument,
        ...action.payload,
      };
    },
    updateBankInformation(state, action) {
      state.bankAccountInformation = {
        ...state.bankAccountInformation,
        ...action.payload,
      };
    },
    updateSelectedDate(state, action) {
      state.attendance.selectedDate = action.payload.selectedDate;
    },
    updateSalaryFilter(state, action) {
      state.salaryFilters = { ...state.salaryFilters, ...action.payload };
    },
    updateSalaryGenerationFilter(state, action) {
      state.salaryGenerationFilters = {
        ...state.salaryGenerationFilters,
        ...action.payload,
      };
    },

    updateAttendanceFilter(state, action) {
      const payload = action?.payload;

      let filterObj = {};
      if (payload["shift"] === "range") {
        filterObj = {
          date: "",
        };
      } else if (payload["shift"] === "date") {
        filterObj = {
          fromDate: "",
          toDate: "",
        };
      }

      console.log(payload, "attendance payload");
      state.attendanceFilter = {
        ...state.attendanceFilter,
        ...filterObj,
        ...action.payload,
      };
    },
    updateAdvanceFilter(state, action) {
      const payload = action?.payload;

      let filterObj = {};
      if (payload["shift"] === "range") {
        filterObj = {
          date: "",
        };
      } else if (payload["shift"] === "date") {
        filterObj = {
          fromDate: "",
          toDate: "",
        };
      }

      console.log(payload, "attendance payload");
      state.advanceFilter = {
        ...state.advanceFilter,
        ...filterObj,
        ...action.payload,
      };
    },
    updateOvertimeAllowanceFilter(state, action) {
      const payload = action?.payload;

      let filterObj = {};
      if (payload["shift"] === "range") {
        filterObj = {
          date: "",
        };
      } else if (payload["shift"] === "date") {
        filterObj = {
          fromDate: "",
          toDate: "",
        };
      }

      console.log(payload, "attendance payload");
      state.overtimeAllowanceFilter = {
        ...state.overtimeAllowanceFilter,
        ...filterObj,
        ...action.payload,
      };
    },

    deleteProfilePicture(state, action) {
      state.basicInformation = {
        ...state.basicInformation,
        profilePicture: null,
      };
    },
    deleteAadhaarFirstCopy(state, action) {
      state.identificationDocument = {
        ...state.identificationDocument,
        aadhaarFirstCopy: null,
      };
    },
    deleteAadhaarSecondCopy(state, action) {
      state.identificationDocument = {
        ...state.identificationDocument,
        aadhaarSecondCopy: null,
      };
    },
    deletePanCardFile(state, action) {
      state.identificationDocument = {
        ...state.identificationDocument,
        panCardFile: null,
      };
    },
    deleteAgreement(state, action) {
      state.identificationDocument = {
        ...state.identificationDocument,
        agreement: null,
      };
    },
    deletePassbook(state, action) {
      state.identificationDocument = {
        ...state.identificationDocument,
        passbook: null,
      };
    },
    formSaveLoaderBtnUpdate(state, action) {
      state.formSaveLoaderBtn = action.payload.buttonState;
    },
    resetForm(state, action) {
      return initialState;
    },
  },
});
