import {
  Button,
  Option,
  Select,
  ThemeProvider,
} from "@material-tailwind/react";

import { Icon } from "@iconify/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import Flatpickr from "react-flatpickr";
import { Controller, useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { queryClient } from "../../../../../App";
import { AdminAuthorURL } from "../../../../../baseUrl/BaseUrl";
import { useAuth } from "../../../../../stores/AuthContext";
import { theme } from "../../../../helpers/CustomTheme";

const VendorAddPayment = () => {
  const { id } = useParams();
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = useForm({
    mode: "On Touched",
  });

  const [date, setDate] = useState("");

  const { getAccessToken } = useAuth();

  const navigate = useNavigate();
  const addNewPayment = async (data) => {
    try {
      const token = await getAccessToken();

      const url = AdminAuthorURL.vendor.addNewPayment(id);

      const options = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      const responseObj = await response.json();

      console.log(response, "payment add response");
      console.log(responseObj, "payment add");

      if (response.ok) {
        toast.success(responseObj.message);
      } else {
        toast.error(responseObj.message);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const createPaymentMutation = useMutation({
    mutationFn: addNewPayment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchVendorPayments"] });
      reset();
    },
  });

  const submitData = async (data) => {
    try {
      const updatedData = {
        ...data,
        date: data?.date[0],
      };

      await createPaymentMutation.mutate(updatedData);
    } catch (e) {
      console.log(e);
    }
    console.log(data, "data ");
  };

  console.log(errors, "errors");
  return (
    <ThemeProvider value={theme}>
      <form
        onSubmit={handleSubmit(submitData)}
        className='flex flex-col items-center px-[1rem]'>
        <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-[2rem] m-4 w-full md:w-[700px] lg:w-[800px]'>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='first-name' className='text-[14px] text-[#3D4A5C] '>
              Date<span className=' text-[#f00]'>*</span>
            </label>

            <Controller
              name='date'
              control={control}
              rules={{ required: "*This field is required" }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Flatpickr
                    {...field}
                    options={{
                      dateFormat: "Y-m-d",
                    }}
                    className='border border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full cursor-pointer'
                    placeholder='Select Date'
                  />
                </>
              )}
            />
            {errors?.date && (
              <p className='text-[#E92215] text-sm'>
                {errors?.amount?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='first-name' className='text-[14px] text-[#3D4A5C]'>
              Amount<span className=' text-[#f00]'>*</span>
            </label>
            <input
              name='amount'
              {...register("amount", {
                required: "*This field is required",
              })}
              type='number'
              step='any'
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
              placeholder='90000'
            />
            {errors?.amount && (
              <p className='text-[#E92215] text-sm'>
                {errors?.amount?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor='first-name' className='text-[14px]'>
              Payment Mode<span className=' text-[#f00]'>*</span>
            </label>
            <Controller
              name='paymentMode'
              rules={{
                required: "*This field is required",
              }}
              control={control}
              render={({ field }) => (
                <Select label='Select payment type' {...field}>
                  <Option value={"Cash"}>Cash</Option>
                  <Option value={"Cheque"}>Cheque</Option>
                  <Option value={"Bank Transfer"}>Bank Transfer</Option>
              
                </Select>
              )}
            />{" "}
            {errors?.paymentMode && (
              <p className='text-[#E92215] text-sm'>
                {errors?.paymentMode?.message}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem] col-span-2'>
            <label htmlFor='first-name' className='text-[14px]'>
              Remarks
            </label>
            <textarea
              name='remarks'
              {...register("remarks")}
              placeholder='Write here...'
              rows={"5"}
              className='border border-[#AFBACA] rounded-md outline-none px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE]'
            />
          </div>
          <div className='flex flex-row justify-end gap-4 md:col-start-2 md:col-end-3 my-10'>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              type='button'
              variant='outlined'
              color='green'
              className='border-[#9FB947] flex flex-row justify-center  items-center gap-3 w-[8rem] capitalize '>
              <Icon
                icon='akar-icons:arrow-left'
                className='text-[1.5rem] text-[#8897AE] cursor-pointer '
              />
              <span className='text-[#8897AE]'>Cancel</span>
            </Button>

            {createPaymentMutation?.isPending ? (
              <div className='w-[8rem] flex justify-center items-center'>
                <ThreeDots
                  height='50'
                  width='50'
                  radius='9'
                  color='#9FB947'
                  ariaLabel='three-dots-loading'
                  wrapperStyle={{}}
                  wrapperClassName=''
                  visible={true}
                />
              </div>
            ) : (
              <Button
                type='submit'
                color='green'
                className='w-[8rem] capitalize flex flex-row justify-center  items-center  bg-[#9FB947] '>
                Save
              </Button>
            )}
          </div>
        </div>
      </form>
    </ThemeProvider>
  );
};

export default VendorAddPayment;
