import React from 'react';
import { useMemo } from 'react';
import {BsArrowLeft,BsArrowRight} from 'react-icons/bs'



// default component options
const defaultComponentOptions = {
  rowsPerPageText: 'Rows per page:',
  rangeSeparatorText: 'of',
  noRowsPerPage: false,
  selectAllRowsItem: false,
  selectAllRowsItemText: 'All',
};

// custom pagination component
export default function CustomPagination ({
    rowsPerPage,
    rowCount,
    currentPage,
    direction ,
    paginationRowsPerPageOptions = [10, 15, 20, 25, 30],
    paginationIconLastPage ,
    paginationIconFirstPage ,
    paginationIconNext ,
    paginationIconPrevious ,
    paginationComponentOptions ,
    onChangeRowsPerPage,
    onChangePage = null,
  }
  ){
    
    const handlePrevious = React.useCallback(() => currentPage >1 && onChangePage(currentPage - 1), [currentPage, onChangePage]);
    const handleNext = React.useCallback(() => currentPage < numPages && onChangePage(currentPage + 1), [currentPage, onChangePage]);
    const goToPage = (page) => onChangePage(page);
    // console.log(currentPage)
    const numPages = useMemo(()=>getNumberOfPages(rowCount, rowsPerPage), [rowCount, rowsPerPage]);
    const lastIndex = currentPage * rowsPerPage;
    const firstIndex = lastIndex - rowsPerPage + 1;
    const disabledLesser = currentPage === 1;
    const disabledGreater = currentPage === numPages;
    const options = { ...defaultComponentOptions, ...paginationComponentOptions };
    const range =
        currentPage === numPages
            ? `${firstIndex}-${rowCount} ${options.rangeSeparatorText} ${rowCount}`
            : `${firstIndex}-${lastIndex} ${options.rangeSeparatorText} ${rowCount}`;
    return (
      <div className="flex justify-between items-center gap-3 border px-2 py-1 rounded mt-6 bg-white" style={{width:'fit-content'}}> 
        <div className="border rounded-full p-2 flex justify-center items-center">
        <button id="pagination-previous-page"
                onClick={handlePrevious}
                type="button"
                aria-label="Previous Page">
                <BsArrowLeft className=' text-2xl ' />
        </button>
        </div>
        <div className="flex space-x-2">
          { currentPage >1 && <span className='px-3 py-1 text-lg cursor-pointer ' onClick={()=>goToPage(currentPage-1)}>{currentPage - 1 }</span>}
          <span className='bg-[#799a0a] text-white px-3 py-1 text-lg rounded' >{currentPage}</span>
          { currentPage <numPages && <span className="px-3 py-1 text-lg cursor-pointer " onClick={()=>goToPage(currentPage+1)}>{currentPage + 1} </span>}
        </div>
        <div className="border rounded-full p-2 flex justify-center items-center">
        <button id="pagination-next-page"
                onClick={handleNext}
                type="button"
                aria-label="Next Page">
                <BsArrowRight className=' text-2xl ' />
        </button>
        </div>
  
      </div>
    ) 
  }
  
export function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage);
}