import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  manage: {
    fromDate: "",
    toDate: "",
    month: "",
    year: "",
    type: "",
    shift: "date",
  },
  charityManage: {
    fromDate: "",
    toDate: "",
    month: "",
    year: "",
    shift: "date",
  },
  archiveManage: {
    fromDate: "",
    toDate: "",
    month: "",
    year: "",
    type: "",
    shift: "date",
  },
  assetsManage: {
    fromDate: "",
    toDate: "",
    status: "",
    equipmentType: "",
  },
  assetsLiabilitiesSearchKey: "",

  liabilityManage: {
    status: "",
    fromStartDate: "",
    toStartDate: "",
    fromEndDate: "",
    toEndDate: "",
    liabilityType: "",
  },
  manageVendor: {
    fromDate: "",
    toDate: "",
    vendorId: "",
  },
  dailySalesManage: {
    fromDate: "",
    toDate: "",
    milkSession: "",
  },
  dailySalesReports: {
    fromDate: "",
    toDate: "",
    vendorId: "",
  },

  bulkDailyMilkSales: {
    date: moment().format("YYYY-MM-DD"),
    fromDate: "",
    toDate: "",
    milkSession: "",
    shift: "date",
    vendorId: "",
  },

  archiveVendor: {
    fromDate: "",
    toDate: "",
    vendorId: "",
  },

  vendorPayments: {
    fromDate: "",
    toDate: "",
    vendorId: "",
  },
  specificVendorPayments: {
    fromDate: "",
    toDate: "",
  },
  milkPriceRevision: {
    fromDate: "",
    toDate: "",
  },
  vendorBills: {
    vendorId: "",
    fromDate: "",
    toDate: "",
  },
  vendorBillGeneration: {
    vendorId: "",
    fromDate: "",
    toDate: "",
  },

};

export const filterSlice = createSlice({
  name: "filterMange",
  initialState: initialState,
  reducers: {
    updateDocumentFilter(state, action) {
      const payload = action?.payload;

      let filterObj = {};
      if (Object.keys(payload).includes("shift")) {
        filterObj = {
          fromDate: "",
          toDate: "",
          month: "",
          year: "",
          type: "",
        };
      }
      state.manage = {
        ...state.manage,
        ...filterObj,

        ...action.payload,
      };
    },
    updateCharityFilter(state, action) {
      const payload = action?.payload;

      let filterObj = {};
      if (Object.keys(payload).includes("shift")) {
        filterObj = {
          fromDate: "",
          toDate: "",
          month: "",
          year: "",
        };
      }

      state.charityManage = {
        ...state.charityManage,
        ...filterObj,

        ...action.payload,
      };
    },
    updateArchiveManageFilter(state, action) {
      const payload = action?.payload;

      let filterObj = {};
      if (Object.keys(payload).includes("shift")) {
        filterObj = {
          fromDate: "",
          toDate: "",
          month: "",
          year: "",
          type: "",
        };
      }
      state.archiveManage = {
        ...state.archiveManage,
        ...filterObj,
        ...action.payload,
      };
    },
    updateAssetsManageFilter(state, action) {
      state.assetsManage = {
        ...state.assetsManage,
        ...action.payload,
      };
    },
    updateAssetsLiabilitiesSearchKey(state, action) {
      state.assetsLiabilitiesSearchKey = action?.payload;
    },
    updateLiabilityManageFilter(state, action) {
      state.liabilityManage = {
        ...state.liabilityManage,
        ...action.payload,
      };
    },
    updateManageVendorFilter(state, action) {
      state.manageVendor = {
        ...state.manageVendor,
        ...action.payload,
      };
    },
    updateMilkSalesManageFilter(state, action) {
      state.dailySalesManage = {
        ...state.dailySalesManage,
        ...action.payload,
      };
    },
    updateBulkDailyMilkSales(state, action) {
      state.bulkDailyMilkSales = {
        ...state.bulkDailyMilkSales,
        ...action.payload,
      };
    },
    updateVendorPayments(state, action) {
      state.vendorPayments = {
        ...state.vendorPayments,
        ...action.payload,
      };
    },
    updateSpecificVendorPayments(state, action) {
      state.specificVendorPayments = {
        ...state.specificVendorPayments,
        ...action.payload,
      };
    },

    updateMilkPriceRevisions(state, action) {
      state.milkPriceRevision = {
        ...state.milkPriceRevision,
        ...action.payload,
      };
    },
    updateArchiveVendorFilter(state, action) {
      state.archiveVendor = {
        ...state.archiveVendor,
        ...action.payload,
      };
    },
    updateVendorBillsFilter(state, action) {
      state.vendorBills = {
        ...state.vendorBills,
        ...action.payload,
      };
    },
    updateVendorBillGeneration(state, action) {
      state.vendorBillGeneration = {
        ...state.vendorBillGeneration,
        ...action.payload,
      };
    },
    updateDailySalesReports(state, action) {
      state.dailySalesReports = {
        ...state.dailySalesReports,
        ...action.payload,
      };
    },
 
  },
});
