import { Icon } from "@iconify/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { exportToCSV } from "../../helpers/ExcelSheetFunction";
import PdfDownload from "../../helpers/PdfDownload";
import {
  VendorBillGenerationFilter,
  VendorTotalBillsFilter,
} from "../../helpers/ReportFilteration";
import BillingReportsStepper from "./billingReports/BillingReportsStepper";

const VendorBillingReports = () => {
  const [searchKey, setSearchKey] = useState("");
  const [activePage, setActivePage] = useState("");
  const [downloadOpen, setDownloadOpen] = useState(false);

  const [filterOpen, setFilterOpen] = useState(false);

  const location = useLocation();
  const downloadData = useSelector((state) => state.data);

  console.log(downloadData, "download data");

  const outletProps = {
    searchKey: searchKey,
  };

  useEffect(() => {
    const pathname = location.pathname.split("/").reverse()[0];

    setActivePage(pathname);
    console.log(pathname);
  }, [location]);

  const header = [
    {
      title: "S.No",
      width: "5%",
    },
    {
      title: "Vendor Id",
      width: "7.916%",
    },
    {
      title: "Vendor Name",
      width: "7.916%",
    },
    {
      title: "Start Date",
      width: "7.916%",
    },
    {
      title: "End Date",
      width: "7.916%",
    },
    {
      title: "Milk Collection Days",
      width: "7.916%",
    },
    {
      title: "Net Payment",
      width: "7.916%",
    },
    {
      title: "Price Per Litre",
      width: "7.916%",
    },
    {
      title: "Sub-Total Milk Cost",
      width: "7.916%",
    },
    {
      title: "Total Milk Quantity",
      width: "7.916%",
    },
    {
      title: "Carried Forward Payment",
      width: "7.916%",
    },
    {
      title: "Total Paid Amount",
      width: "7.916%",
    },
    {
      title: "Status",
      width: "7.916%",
    },
  ];
  const keys = [
    "vendorId",
    "vendorName",
    "startDate",
    "endDate",
    "totalCollectionDays",
    "netPayment",
    "pricePerLitre",
    "subTotalMilkCost",
    "totalMilkQuantity",
    "carriedForwardPayment",
    "totalPaidAmount",
    "status",
  ];

  const handleExcelSheetDownload = () => {
    exportToCSV({
      fileName:
        activePage === "bills-regeneration"
          ? "Bill generation Reports"
          : "Total Bill Reports",
      downloadData: downloadData?.downloadData,
      keys: keys,
      header: header,
      totalDetailsRow: [
        "netPayment",
        "subTotalMilkCost",
        "totalMilkQuantity",
        "carriedForwardPayment",
        "totalPaidAmount",
      ],
    });
  };

  return (
    <div className='bg-white  w-full  my-[0.5rem] flex-1  flex flex-col gap-y-[2rem] rounded'>
      <div className='flex flex-col gap-y-[2rem] pt-4 pb-6 border-b  '>
        <div className='px-[0.5rem] xs:px-[1rem] md:px-[2rem] flex flex-row justify-between'>
          <h1 className='text-[1.2rem] text-[#1A3032] font-bold '>
            Vendor Billing Reports
          </h1>
          <div className=' flex flex-col gap-[2rem] max-md:hidden xl:hidden'>
            {activePage === "bills-regeneration" ? (
              <div className='relative flex justify-end self-end w-full'>
                <button
                  onClick={() => setFilterOpen(!filterOpen)}
                  style={{
                    background:
                      "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                  }}
                  className='text-white outline-none  rounded-md text-center  gap-[0.5rem] px-[2rem] py-[0.5rem] '>
                  Filter
                </button>

                {filterOpen && (
                  <div className='bg-white p-[1rem]  shadow-lg absolute top-[50px] right-[10%]  z-10 rounded-[0.5rem] w-auto'>
                    <VendorBillGenerationFilter />
                  </div>
                )}
              </div>
            ) : (
              <div className='relative flex justify-end w-full'>
                <button
                  onClick={() => setFilterOpen(!filterOpen)}
                  style={{
                    background:
                      "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                  }}
                  className='text-white outline-none  rounded-md text-center  gap-[0.5rem] px-[2rem] py-[0.5rem] '>
                  Filter
                </button>

                {filterOpen && (
                  <div className='bg-white p-[1rem]  shadow-lg absolute top-[50px] right-[10%]  z-10 rounded-[0.5rem] w-auto'>
                    <VendorTotalBillsFilter />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className='w-full flex flex-col gap-[1rem] md:gap-[2rem] md:flex-row md:items-center justify-between px-[0.5rem] xs:px-[1rem] md:px-[2rem] '>
          <div className='w-full flex  flex-col md:flex-row items-center gap-[1rem] md:gap-[2rem]'>
            <div
              className='w-full flex flex-row items-center text-[#555555] px-[1rem] py-[0.5rem] rounded-md gap-[0.5rem]'
              style={{
                background: "#FFFFFF",
                border: "0.1px solid #DFDFDF",
                boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
                borderRadius: "5.09937px",
              }}>
              <Icon icon='ri:search-line' className='text-[1.4rem]' />
              <input
                type='text'
                className='text-[#7C7C7C] placeholder-[#7C7C7C]  text-sm outline-none'
                placeholder='Search name'
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
            </div>
            <div className='relative w-full flex justify-end'>
              <button
                type='button'
                onClick={() => {
                  setDownloadOpen(!downloadOpen);
                }}
                style={{
                  background:
                    "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                }}
                className='rounded-md  text-white  flex flex-row gap-[0.5rem] px-[1rem] py-[0.5rem] cursor-pointer items-center'>
                <Icon
                  icon='material-symbols:download-rounded'
                  className='text-[1.5rem]'
                />
                <span>Download Report</span>
              </button>

              {downloadOpen && (
                <div className='bg-white p-[1rem]  shadow-lg absolute top-[50px] right-0 z-10 rounded-[0.5rem] w-auto flex flex-col gap-[1.5rem]'>
                  <PDFDownloadLink
                    fileName='billReports.pdf'
                    document={
                      <PdfDownload
                        headersArray={header}
                        keys={keys}
                        data={downloadData?.downloadData}
                        title='Bill Reports Data'
                        totalRow={{
                          index: 6,
                          data: downloadData?.totalStats,
                        }}
                      />
                    }>
                    <button className='text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]'>
                      {" "}
                      <Icon
                        icon='bxs:file-pdf'
                        className='text-[#BE0A23] w-[1.6rem] h-auto'
                      />
                      Download as PDF
                    </button>
                  </PDFDownloadLink>

                  <button
                    onClick={handleExcelSheetDownload}
                    className='text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]'>
                    <Icon
                      icon='file-icons:microsoft-excel'
                      className='text-[#29672E] w-[1.1rem] h-auto'
                    />
                    Download as Excel Sheet
                  </button>
                </div>
              )}
            </div>

            {/* <div className='w-full flex justify-end'>
              <Menu>
                <MenuHandler>
                  <button
                    style={{
                      background:
                        "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                    }}
                    className='rounded-md flex flex-row gap-[0.5rem] px-[1rem] py-[0.5rem] items-center text-white whitespace-nowrap'>
                    <Icon
                      icon='material-symbols:download-rounded'
                      className='text-[1.5rem]'
                    />
                    <span>Download Report</span>
                  </button>
                </MenuHandler>
                <MenuList>
                  <PDFDownloadLink
                    fileName='billReports.pdf'
                    document={
                      <PdfDownload
                        headersArray={header}
                        keys={keys}
                        data={downloadData?.downloadData}
                        title='Bill Reports Data'
                        totalRow={{
                          index: 6,
                          data: downloadData?.totalStats,
                        }}
                      />
                    }>
                    <MenuItem className='text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]'>
                      {" "}
                      <Icon
                        icon='bxs:file-pdf'
                        className='text-[#BE0A23] w-[1.6rem] h-auto'
                      />
                      Download as PDF
                    </MenuItem>
                  </PDFDownloadLink>

                  <hr className='my-[0.5rem]' />

                  <MenuItem
                    onClick={handleExcelSheetDownload}
                    className='text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]'>
                    <Icon
                      icon='file-icons:microsoft-excel'
                      className='text-[#29672E] w-[1.1rem] h-auto'
                    />
                    Download as Excel Sheet
                  </MenuItem>
                </MenuList>
              </Menu>
            </div> */}
          </div>
          <div className='max-md:w-full max-xs:text-[0.8rem] whitespace-nowrap max-md:justify-between max-md:flex md:hidden xl:flex flex-row gap-[1rem] md:gap-[2rem] '>
            <div>
              {activePage === "bills-regeneration" ? (
                <div className='relative flex justify-end w-full'>
                  <button
                    onClick={() => setFilterOpen(!filterOpen)}
                    style={{
                      background:
                        "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                    }}
                    className='text-white outline-none  rounded-md text-center  gap-[0.5rem] px-[2rem] py-[0.5rem] '>
                    Filter
                  </button>

                  {filterOpen && (
                    <div className='bg-white p-[1rem]  shadow-lg absolute top-[50px] right-[10%]  z-10 rounded-[0.5rem] w-auto'>
                      <VendorBillGenerationFilter />
                    </div>
                  )}
                </div>
              ) : (
                <div className='relative flex justify-end w-full'>
                  <button
                    onClick={() => setFilterOpen(!filterOpen)}
                    style={{
                      background:
                        "linear-gradient(90deg, #9FB947 -15.87%, #739500 100%)",
                    }}
                    className='text-white outline-none  rounded-md text-center  gap-[0.5rem] px-[2rem] py-[0.5rem] '>
                    Filter
                  </button>

                  {filterOpen && (
                    <div className='bg-white p-[1rem]  shadow-lg absolute top-[50px] right-[10%]  z-10 rounded-[0.5rem] w-auto'>
                      <VendorTotalBillsFilter />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <BillingReportsStepper />
      </div>
      <div className='w-full'>
        <div className='p-3 pt-0 animal-table w-full '>
          <div className='m-2 w-full '>
            <Outlet context={outletProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorBillingReports;
