let baseUrl;

const type = "PROD";

if (type === "PROD") {
  baseUrl = "https://api.lvreddydairyfarm.com/api/v1";
} else {
  baseUrl = "http://localhost:4000/api/v1";
  //baseUrl = "https://k3qw61dm-4000.inc1.devtunnels.ms/api/v1";
}

let userRole = JSON.parse(localStorage.getItem("user"));

//const userRole = user?.userRole;
const adminBaseUrl = `${baseUrl}/admin`;
const employeeBaseUrl = `${baseUrl}/employee`;
const subAdminBaseUrl = `${baseUrl}/subadmin`;

let activeBaseUrl;

if (userRole?.admin) {
  activeBaseUrl = adminBaseUrl;
} else if (userRole?.employee) {
  activeBaseUrl = employeeBaseUrl;
} else if (userRole?.subAdmin) {
  activeBaseUrl = subAdminBaseUrl;
}

export const animalRegisterUrl = `${activeBaseUrl}/animal/register-animal`;

console.log(activeBaseUrl, "active base url");

export const APIUrls = {
  getAllAnimal: `${activeBaseUrl}/getAllAnimal`,
  getTypeAnimal: `${activeBaseUrl}/getAnimal`,
  archiveAnimal: `${activeBaseUrl}/archive`,
  getCalfs: `${baseUrl}/calf/getCalf`,
  getSaled: `${baseUrl}/sale/getAllSaled`,
  getDeathHistory: `${baseUrl}/deathHistory/getDeathHistory`,
  getAnimalInfo: `${activeBaseUrl}/getAnimalInfo`,
  getAnimalBasics: `${activeBaseUrl}/fetch-animal-basics`,
  getCalfByAnimal: `${baseUrl}/calf/getCalf`,
  getGlobalSettings: `${baseUrl}/globalSetting/getSetting`,
  updateGlobalSettings: `${baseUrl}/globalSetting/update`,
  updateAnimalBasicInfo: `${activeBaseUrl}/updateAnimalInfo`,
  addCalf: `${baseUrl}/calf/createCalf`,
  updateCalf: `${baseUrl}/calf/updateCalf`,
  fetchCalfByCalfId: `${baseUrl}/calf/getCalfByCalfId`,
  getMilkRecord: `${baseUrl}/milkHistory/milkRecordByAnimal`,
  getMilkRecordByMilkRecordId: `${baseUrl}/milkHistory/getMilkRecordByMilkRecordId`,
  addMilkRecord: `${baseUrl}/milkHistory/createMilkRecord`,
  addMilkRecordByAnimaltag: `${baseUrl}/milkHistory/createMilkRecordByAnimalTag`,
  updateMilkRecord: `${baseUrl}/milkHistory/update`,
  deleteMilkRecord: `${baseUrl}/milkHistory/delete`,
  getLactations: `${baseUrl}/lactation/getLactations`,
  getLactationByAnimalId: `${baseUrl}/lactation/getLactationByAnimalId`,
  getLactationByLactationId: `${baseUrl}/lactation/getLactationByLactationId`,
  createLactation: `${baseUrl}/lactation/createLactation`,

  updateLactation: `${baseUrl}/lactation/update`,
  deleteLactation: `${baseUrl}/lactation/delete`,
  getInseminationByAnimal: `${baseUrl}/insemination/getInseminationByAnimal`,
  getInseminationByInseminationId: `${baseUrl}/insemination/getInseminationByInseminationId`,
  createInsemination: `${baseUrl}/insemination/createInsemination`,
  updateInsemination: `${baseUrl}/insemination/update`,
  deleteInsemination: `${baseUrl}/insemination/delete`,
  getPurchaseHistory: `${baseUrl}/purchase`,
  updatePurchaseHistory: `${baseUrl}/purchase/update`,
  getHealthHistoryByAnimal: `${baseUrl}/health/getHealthByAnimal`,
  getHealthHistoryByHealthId: `${baseUrl}/health/getHealthByHealthId`,

  createHealthHistory: `${baseUrl}/health/createHealth`,
  updateHealthHistory: `${baseUrl}/health/update`,
  deleteHealthHistory: `${baseUrl}/health/delete`,
  createSaleHistory: `${baseUrl}/sale/add`,
  createSaleWithAnimalCode: `${baseUrl}/sale/create-sale-with-animal-code`,
  updateSaleHistory: `${baseUrl}/sale/update`,
  getSaleHistoryByAnimalId: `${baseUrl}/sale/getSaledByAnimal`,
  getDeathHistoryByAnimalId: `${baseUrl}/deathHistory/getDeathByAnimal`,
  createDeathHistory: `${baseUrl}/deathHistory/create`,
  updateDeathHistory: `${baseUrl}/deathHistory/update`,
  getCalfBasicInfo: `${baseUrl}/calf/getBasicInfo`,
  updateCalfBasicInfo: `${baseUrl}/calf/updateBasicInfo`,
  getCalfPurchaseHistory: `${baseUrl}/calf/getPurchaseHistory`,
  updateCalfPurchaseHistory: `${baseUrl}/calf/updatePurchaseHistory`,
  getCalfHealthHistories: `${baseUrl}/calf/getHealthHistories`,
  getCalfHealthHistoryById: `${baseUrl}/calf/getHealthHistoryById`,
  deleteCalfHealthHistory: (healthId, calfId) =>
    `${baseUrl}/calf/deleteHealthHistory/${healthId}/${calfId}`,
  addCalfHealthHistory: `${baseUrl}/calf/addHealthHistory`,
  updateCalfHealthHistory: `${baseUrl}/calf/updateHealthHistory`,
  // deleteCalfHealthHistory: `${baseUrl}/calf/deleteHealthHistory`,
  getCalfSaleHistory: `${baseUrl}/calf/getSaleHistory`,
  addCalfSaleHistory: `${baseUrl}/calf/addSaleHistory`,
  updateCalfSaleHistory: `${baseUrl}/calf/updateSaleHistory`,
  getCalfDeathHistory: `${baseUrl}/calf/getDeathHistory`,
  addCalfDeathHistory: `${baseUrl}/calf/addDeathHistory`,
  updateCalfDeathHistory: `${baseUrl}/calf/updateDeathHistory`,
  animals: `${activeBaseUrl}/animals`,
  animalsByGender: `${activeBaseUrl}/animalsByGender`,
  calves: `${baseUrl}/calf/calves`,
  registerCalf: `${baseUrl}/calf/register`,
  saleHistories: `${baseUrl}/sale/saleHistories`,
  deathHistories: `${baseUrl}/deathHistory/deathHistories`,
  calvesByAnimalId: `${baseUrl}/calf/calvesByAnimalId`,
  healthHistoriesByAnimalId: `${baseUrl}/health/healthHistoriesByAnimalId`,
  inseminationsByAnimalId: `${baseUrl}/insemination/inseminationsByAnimalId`,
  lactationsByAnimalId: `${baseUrl}/lactation/lactationsByAnimalId`,
  milkRecordsByAnimalId: `${baseUrl}/milkHistory/milkRecordsByAnimalId`,
  healthHistoriesByCalfId: `${baseUrl}/health/healthHistoriesByCalfId`,
  getAnimalDetails: `${activeBaseUrl}/getAnimalDetails`,
  employeeRgistration: `${baseUrl}/employee/create`,
  fetchEmployees: `${baseUrl}/employee/employees`,
  archiveAnimal: `${activeBaseUrl}/animal/archive`,
  archiveCalf: `${baseUrl}/calf/archive`,
  archiveSale: `${baseUrl}/sale/archive`,
  archivedDeathRecord: `${baseUrl}/deathHistory/archive`,
  archiveMilkRecord: `${baseUrl}/milkHistory/archive`,
  archiveLactation: `${baseUrl}/lactation/archive`,
  archiveInsemination: `${baseUrl}/insemination/archive`,
  archiveHealthRecord: `${baseUrl}/health/archive`,
  addBreeds: `${baseUrl}/globalSetting/addbreeeds`,
  getBreedData: `${baseUrl}/globalSetting/getBreeds/data`,
  fetchSperms: `${activeBaseUrl}/sperms`,
  fetchActiveFemalesIds: `${activeBaseUrl}/activeFemalesIds`,
  getTotalAnimals: `${activeBaseUrl}/getTotalAnimals`,
  getTotalBufallos: `${activeBaseUrl}/getTotalBufallos`,
  getAnimalsInLactationAndDry: `${activeBaseUrl}/getAnimalsInLactationAndDry`,
  getPregnentAnimals: `${activeBaseUrl}/getPregnentAnimals`,
  getMilkReport: `${baseUrl}/milkHistory/getMilkRecords`,
  getTotalCalfs: `${baseUrl}/calf/tatalCalfs`,
  getLargestTagNumber: `${baseUrl}/admin/getLargestanimalTagNumber`,
  fetchEmployeementStatusHistory: (id, searchKey, page, download) =>
    `${baseUrl}/admin/animal/${id}/assignments-records?searchKey=${searchKey}&download=${download}`,
  fetchMilkProgress: (
    page,
    searchKey,
    download,
    fromDate,
    toDate,
    employeeId,
    fromMonth,
    toMonth,
    fromYear,
    toYear,
    type
  ) =>
    `${activeBaseUrl}/milk-progress?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}&employeeId=${employeeId}&fromMonth=${fromMonth}&toMonth=${toMonth}&fromYear=${fromYear}&toYear=${toYear}&type=${type}`,
};

export const authAPIUrls = {
  fetchUserRole: `${baseUrl}/auth/role`,
};
export const milkAPIUrls = {
  bulkMilksUpload: `${baseUrl}/milkHistory/bulk-upload`,
  milksByDate: `${baseUrl}/milkHistory/milks-by-date`,
};
export const employeeAPIUrls = {
  fetchFilteredEmployees: `${baseUrl}/employee/employees-filter`,
  getEmployeeBasicInfo: `${baseUrl}/employee/basic-info`,
  updateEmployeeBasicInfo: `${baseUrl}/employee/update-info`,
  fetchEmployeeEmpInfo: `${baseUrl}/employee/emp-info`,
  updateEmployeeEmpInfo: `${baseUrl}/employee/emp-info`,
  getEmployeeDocInfo: `${baseUrl}/employee/doc-info`,
  updateEmployeeDocInfo: `${baseUrl}/employee/doc-info`,
  getEmployeeBankInfo: `${activeBaseUrl}/employee/bank-info`,
  updateEmployeeBankInfo: `${activeBaseUrl}/employee/bank-info`,
  employeeBaseUrl: `${activeBaseUrl}/employee`,
  monthlyAttendances: `${baseUrl}/employee/attendance/monthly`,
  todayAttendance: `${baseUrl}/employee/attendance`,
  checkIn: `${baseUrl}/employee/checkIn`,
  checkOut: `${baseUrl}/employee/checkOut`,
  fetchAttendanceByDate: `${baseUrl}/employee/attendance`,
  updateAttendance: `${baseUrl}/employee/attendance/update`,
  updateEmployeeStatus: (id) => `${activeBaseUrl}/employee/${id}/status`,
  isEmployeeExist: `${baseUrl}/employee/exists`,
};
export const SaleAPIUrls = {
  fetchSaleableAnimalIds: `${baseUrl}/sale/saleable_animals`,
};
export const DeathAPIUrls = {
  addDeathRecordById: `${baseUrl}/deathHistory/addDeathRecordByAnimalCode`,
};

export const AdminAuthorURL = {
  attendance: {
    fetchEmployeeMonthlyAttendance: (id) =>
      `${activeBaseUrl}/attendance/employee/${id}/monthly`,
    updateAttendance: (id) =>
      `${activeBaseUrl}/attendance/employee/${id}/update`,
    fetchAttendanceByDate: (id) => `${activeBaseUrl}/attendance/employee/${id}`,
    fetchAttendanceLists: `${activeBaseUrl}/attendance/employees/datewise`,
    attendanceById: (id) => `${activeBaseUrl}/attendance/${id}`,
    createBulkAttendance: `${activeBaseUrl}/attendance/bulk`,
    fetchAttendancesByDate: (searchKey, date) =>
      `${activeBaseUrl}/attendance/date?searchKey=${searchKey}&date=${date}`,
  },
  employee: {
    fetchEmployeeIds: `${activeBaseUrl}/employee/ids`,
    fetchDownloadableEmployee: `${activeBaseUrl}/employee/downloadable`,
    regenerateSalary: (id) =>
      `${activeBaseUrl}/employee/${id}/salaries/regenerate`,
    regenerateSalaryRecords: (fromDate, toDate) =>
      `${activeBaseUrl}/employee/salaries/regenerate?fromDate=${fromDate}&toDate=${toDate}`,
    approveSalaryRecords: (fromDate, toDate) =>
      `${activeBaseUrl}/salaries/approve?fromDate=${fromDate}&toDate=${toDate}`,
    fetchSalaries: (fromDate, toDate, searchKey, status) =>
      `${activeBaseUrl}/salaries?fromDate=${fromDate}&toDate=${toDate}&searchKey=${searchKey}&status=${status}`,
  },
  calf: {
    fetchCalfNextTagNumber: (animalId) =>
      `${activeBaseUrl}/calf/next-tagnumber/${animalId}`,
  },
  payroll: {
    createAdvance: (id) => `${activeBaseUrl}/payroll/employee/${id}/advance`,
    createSalary: (id) => `${activeBaseUrl}/payroll/employee/${id}/salary`,
    fetchPayrolls: `${activeBaseUrl}/payroll/employee`,
    fetchDownPayrolls: `${activeBaseUrl}/payroll/employee/downloadable`,
    fetchPayrollById: (id) => `${activeBaseUrl}/payroll/${id}/employee`,
    updateAdvance: (id) => `${activeBaseUrl}/payroll/employee/advance/${id}`,
    updateSalary: (id) => `${activeBaseUrl}/payroll/employee/salary/${id}`,
    fetchPayrollByEmpId: (id) => `${activeBaseUrl}/payroll/employee/${id}`,
    createBulkAdvance: `${activeBaseUrl}/payroll/bulk`,
    fetchBulkAdvancesByDate: (searchKey, date) =>
      `${activeBaseUrl}/payroll/date?searchKey=${searchKey}&date=${date}`,
    fetchOverTimeAllowances: (page, searchKey, date, download) =>
      `${activeBaseUrl}?page=${page}&searchKey=${searchKey}&date=${date}&download=${download}`,
    uploadBulkOverTimeAllowanceUpload: `${activeBaseUrl}/overtime-allowances/bulk`,
    fetchOverTimeAllowanceByDate: (searchKey, date) =>
      `${activeBaseUrl}/overtime-allowances/date?searchKey=${searchKey}&date=${date}`,
    fetchSalaries: (fromDate, toDate, searchKey, status, page, download) =>
      `${activeBaseUrl}/salaries?fromDate=${fromDate}&toDate=${toDate}&searchKey=${searchKey}&status=${status}&page=${page}&download=${download}`,
    fetchAllOvertimeAllowances: (
      page,
      searchKey,
      date,
      download,
      fromDate,
      toDate,
      employeeId
    ) =>
      `${activeBaseUrl}/overtime-allowances?page=${page}&searchKey=${searchKey}&date=${date}&download=${download}&fromDate=${fromDate}&toDate=${toDate}&employeeId=${employeeId}`,
    fetchSalaryByEmployeeId: (id, fromDate, toDate, searchKey, page) =>
      `${activeBaseUrl}/employee/${id}/salaries?fromDate=${fromDate}&toDate=${toDate}&searchKey=${searchKey}&page=${page}`,
  },
  employeementStatusHistory: {
    createEmployeementStatus: (id) =>
      `${activeBaseUrl}/employee/${id}/employment-histories`,
    updateEmployeementStatus: (id) =>
      `${activeBaseUrl}/employment-histories/${id}`,
    fetchAllEmployeementStatusHistoriesById: (id, page, searchKey, download) =>
      `${activeBaseUrl}/employee/${id}/employment-histories?page=${page}&searchKey=${searchKey}&download=${download}`,
    fetchEmploymentStatusById: (id) =>
      `${activeBaseUrl}/employment-histories/${id}`,
    deleteEmploymentStatus: (id) =>
      `${activeBaseUrl}/employment-histories/${id}`,
    createSalaryHike: (id) => `${activeBaseUrl}/employee/${id}/salary-hikes`,
    fetchAllSalaryHikesByEmployeeId: (id, page, searchKey, download) =>
      `${activeBaseUrl}/employee/${id}/salary-hikes?page=${page}&searchKey=${searchKey}&download=${download}`,
    fetchSalaryById: (id) => `${activeBaseUrl}/salary-hikes/${id}`,
    updateSalaryById: (id) => `${activeBaseUrl}/salary-hikes/${id}`,
    deleteSalaryById: (id) => `${activeBaseUrl}/salary-hikes/${id}`,
  },

  archive: {
    fetchArchiveData: `${activeBaseUrl}/employee/archive`,
    archiveEmplyee: (id) => `${activeBaseUrl}/employee/${id}/archive`,
    restoreEmplyee: (id) => `${activeBaseUrl}/employee/${id}/restore`,
    deleteEmplyee: (id) => `${activeBaseUrl}/employee/${id}/delete`,
  },
  lactation: {
    fetchLactations: (
      searchKey,
      page,
      fromDateStart,
      toDateStart,
      fromDateEnd,
      toDateEnd,
      download,
      animalId,
      lactationNumber,
      employeeId
    ) =>
      `${activeBaseUrl}/lactations?searchKey=${searchKey}&page=${page}&fromDateStart=${fromDateStart}&toDateStart=${toDateStart}&fromDateEnd=${fromDateEnd}&toDateEnd=${toDateEnd}&download=${download}&animalId=${
        animalId || ""
      }&lactationNumber=${lactationNumber}&employeeId=${employeeId || ""}`,
    fetchNextLactationNumber: (animalId) =>
      `${activeBaseUrl}/lactation/next-lactation-number/${animalId}`,

    updateLactationEndDate: (id) => `${activeBaseUrl}/animal/${id}/lactation`,
  },
  insemination: {
    fetchInseminations: (
      searchKey,
      page,
      fromDate,
      toDate,
      month,
      year,
      status,
      download,
      animalId,
      employeeId,
      min,
      max
    ) =>
      `${activeBaseUrl}/inseminations?searchKey=${searchKey}&page=${page}&fromDate=${fromDate}&toDate=${toDate}&month=${month}&year=${year}&download=${download}&status=${status}&animalId=${
        animalId || ""
      }&employeeId=${employeeId || ""}&minDays=${min}&maxDays=${max}`,
  },
  purchase: {
    fetchPurchaseHistory: (
      searchKey,
      page,
      fromDate,
      toDate,
      month,
      year,
      type,
      gender,
      download
    ) =>
      `${activeBaseUrl}/purchase-records?searchKey=${searchKey}&page=${page}&fromDate=${fromDate}&toDate=${toDate}&month=${month}&year=${year}&download=${download}&type=${type}&gender=${gender}`,
  },
  health: {
    fetchHealthHistory: (
      searchKey,
      page,
      date,
      month,
      year,
      type,
      gender,
      diseaseType,
      download,
      animalId,
      employeeId
    ) =>
      `${activeBaseUrl}/health-records?searchKey=${searchKey}&page=${page}&date=${date}&month=${month}&year=${year}&download=${download}&type=${type}&gender=${gender}&diseaseType=${
        diseaseType || ""
      }&animalId=${animalId || ""}&employeeId=${employeeId || ""}`,
  },
  milk: {
    fetchMilkRecords: (
      searchKey,
      page,
      fromDate,
      toDate,
      month,
      year,
      download,
      animalId,
      lactationNumber,
      employeeId
    ) =>
      `${activeBaseUrl}/milk-records?searchKey=${searchKey}&page=${page}&fromDate=${fromDate}&toDate=${toDate}&month=${month}&year=${year}&download=${download}&animalId=${
        animalId || ""
      }&lactationNumber=${lactationNumber}&employeeId=${employeeId || ""}`,
  },
  vaccinations: {
    createVaccination: (id) => `${activeBaseUrl}/vaccination/create/${id}`,
    fetchVaccinationByAnimalId: (
      id,
      page,
      limit,
      searchKeyWord,
      toDate,
      fromDate,
      download
    ) =>
      `${activeBaseUrl}/vaccination/animal/${id}?page=${page}&limit=${limit}&searchKey=${
        searchKeyWord && searchKeyWord
      }&toDate=${toDate && toDate}&fromDate=${
        fromDate && fromDate
      }&download=${download}`,
    fetchVaccinationById: (id) => `${activeBaseUrl}/vaccination/${id}`,
    fetchAllVaccinations: (
      page,

      searchKey,
      download,
      fromDate,
      toDate
    ) =>
      `${activeBaseUrl}/vaccination/fetch?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}`,
    fetchDueVaccinations: ({
      page,
      limit,
      searchKeyWord,
      vaccineName,
      lastVaccinationDateFrom,
      lastVaccinationDateTo,
      nextVaccinationDateFrom,
      nextVaccinationDateTo,

      employeeId,
      animalId,
      gender,
      type,
      download,
    }) =>
      `${activeBaseUrl}/vaccination/due?page=${page}&limit=${limit}&searchKey=${
        searchKeyWord || ""
      }&lastVaccinationDateFrom=${
        lastVaccinationDateFrom || ""
      }&lastVaccinationDateTo=${
        lastVaccinationDateTo || ""
      }&nextVaccinationDateFrom=${
        nextVaccinationDateFrom || ""
      }&nextVaccinationDateTo=${nextVaccinationDateTo || ""}&gender=${
        gender || ""
      }&type=${type || ""}&vaccineName=${vaccineName || ""}&animalId=${
        animalId || ""
      }&download=${download}&employeeId=${employeeId || ""}`,
    updateVaccination: (id) => `${activeBaseUrl}/vaccination/${id}`,
    archiveVaccination: (id) => `${activeBaseUrl}/vaccination/${id}/archive`,
  },
  dewormings: {
    createDeworming: (id) => `${activeBaseUrl}/deworming/create/${id}`,
    fetchAllDewormings: (page, searchKey, download, fromDate, toDate) =>
      `${activeBaseUrl}/deworming/fetch?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}`,
    fetchDewormingByAnimalId: (
      id,
      page,
      limit,
      searchKeyWord,
      toDate,
      fromDate,
      download,
      employeeId
    ) =>
      `${activeBaseUrl}/deworming/animal/${id}?page=${page}&limit=${limit}&searchKey=${
        searchKeyWord && searchKeyWord
      }&toDate=${toDate && toDate}&fromDate=${
        fromDate && fromDate
      }&download=${download}&employeeId=${employeeId || ""}`,
    fetchDewormingByAnimalId: (
      id,
      page,
      limit,
      searchKeyWord,
      toDate,
      fromDate,
      download,
      employeeId
    ) =>
      `${activeBaseUrl}/deworming/animal/${id}?page=${page}&limit=${limit}&searchKey=${
        searchKeyWord && searchKeyWord
      }&toDate=${toDate && toDate}&fromDate=${
        fromDate && fromDate
      }&download=${download}&employeeId=${employeeId || ""}`,
    fetchDueDeworming: ({
      page,
      limit,
      searchKeyWord,
      dewormingName,
      lastDewormingDateFrom,
      lastDewormingDateTo,
      nextDewormingDateFrom,
      nextDewormingDateTo,

      employeeId,
      animalId,
      gender,
      type,
      download,
    }) =>
      `${activeBaseUrl}/deworming/due?page=${page}&limit=${limit}&searchKey=${
        searchKeyWord || ""
      }&lastDewormingDateFrom=${
        lastDewormingDateFrom || ""
      }&lastDewormingDateTo=${
        lastDewormingDateTo || ""
      }&nextDewormingDateFrom=${
        nextDewormingDateFrom || ""
      }&nextDewormingDateTo=${nextDewormingDateTo || ""}&gender=${
        gender || ""
      }&type=${type || ""}&dewormingName=${dewormingName || ""}&animalId=${
        animalId || ""
      }&download=${download}&employeeId=${employeeId || ""}`,
    fetchDewormingById: (id) => `${activeBaseUrl}/deworming/${id}`,
    updateDeworming: (id) => `${activeBaseUrl}/deworming/${id}`,
    archiveDeworming: (id) => `${activeBaseUrl}/deworming/${id}/archive`,
    fetchAllDewarminsList: `${activeBaseUrl}/deworming/fetch`,
  },
  reports: {
    fetchPregnancyList: (
      page,
      limit,
      searchKeyWord,
      fromDate,
      toDate,
      month,
      remainingDays,
      download,
      employeeId
    ) =>
      `${activeBaseUrl}/reports/pregnancy-list?page=${page}&limit=${limit}&searchKey=${
        searchKeyWord && searchKeyWord
      }&toDate=${toDate && toDate}&fromDate=${fromDate && fromDate}&month=${
        month && month
      }&remainingDays=${
        remainingDays && remainingDays
      }&download=${download}&employeeId=${employeeId || ""}`,
    fetchDryOffRecommendationList: (
      page,
      limit,
      searchKeyWord,
      fromDate,
      toDate,
      month,
      download,
      employeeId
    ) =>
      `${activeBaseUrl}/reports/recommended-dry-off?page=${page}&limit=${limit}&searchKey=${
        searchKeyWord && searchKeyWord
      }&toDate=${toDate && toDate}&fromDate=${fromDate && fromDate}&month=${
        month && month
      }&download=${download}&employeeId=${employeeId || ""}`,
    fetchAwaitingInsemination: (
      page,
      limit,
      searchKeyWord,
      download,
      employeeId
    ) =>
      `${activeBaseUrl}/reports/awaiting-inseminations?page=${page}&limit=${limit}&searchKey=${
        searchKeyWord && searchKeyWord
      }&download=${download}&employeeId=${employeeId || ""}`,
    fetchPregnancyCheckUpList: (
      page,
      limit,
      searchKeyWord,
      download,
      employeeId
    ) =>
      `${activeBaseUrl}/reports/pregnancy-check?page=${page}&limit=${limit}&searchKey=${
        searchKeyWord && searchKeyWord
      }&download=${download}&employeeId=${employeeId || ""}`,
  },
  animals: {
    fetchAnimalStatusCard: (id) => `${activeBaseUrl}/animal/${id}/status-cards`,
    fetchAnimalIds: (deceased, sold, gender, searchKey, calf) =>
      `${activeBaseUrl}/animal/animal-ids?deceased=${deceased || ""}&sold=${
        sold || ""
      }&gender=${gender || ""}&searchKey=${searchKey || ""}&calf=${calf || ""}`,
  },
  sale: {
    fetchSaleById: (id) => `${activeBaseUrl}/sale/${id}`,
  },
  death: {
    fetchDeathById: (id) => `${activeBaseUrl}/death/${id}`,
  },
  pregnancyCheckup: {
    addPregnancyCheckUp: (id) => `${activeBaseUrl}/pregnancy-check-up/${id}`,
    fetchPregnancyDetails: (id) =>
      `${activeBaseUrl}/insemination/${id}/pregnancy-check-up`,
    updatePregnancyCheckUp: (id) => `${activeBaseUrl}/pregnancy-check-up/${id}`,
    deletePregnancyCheckUp: (id) => `${activeBaseUrl}/pregnancy-check-up/${id}`,
  },

  diagnosticReport: {
    fetchAllDiagnosticReports: (
      searchKey,
      page,
      fromCollectionDate,
      toCollectionDate,
      toResultDate,
      fromResultDate,
      disease,
      status,

      download,
      employeeId
    ) =>
      `${activeBaseUrl}/diagnostic-report?page=${page}&searchKey=${searchKey}&download=${download}&fromCollectionDate=${fromCollectionDate}&toCollectionDate=${toCollectionDate}&fromResultDate=${fromResultDate}&toResultDate=${toResultDate}&disease=${disease}&status=${status}&employeeId=${
        employeeId || ""
      }`,
    createDiagnosticReport: (id) =>
      `${activeBaseUrl}/animal/${id}/diagnostic-report`,
    fetchDiagnosticReports: (
      id,
      page,
      limit,
      searchKey,
      fromDate,
      toDate,
      download
    ) =>
      `${activeBaseUrl}/animal/${id}/diagnostic-report?page=${page}&limit=${limit}&searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&download=${download}`,
    fetchDiagnosticReportById: (id) =>
      `${activeBaseUrl}/diagnostic-report/${id}`,
    updateDiagnosticReport: (id) => `${activeBaseUrl}/diagnostic-report/${id}`,
    deleteDiagnosticReport: (id) =>
      `${activeBaseUrl}/diagnostic-report/${id}/archive`,
  },
  todo: {
    createNewTodo: `${activeBaseUrl}/todo`,
    fetchAllTodos: (searchKey, page, fromDate, toDate, download) =>
      `${activeBaseUrl}/todo?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}`,
    fetchTodoById: (id) => `${activeBaseUrl}/todo/${id}`,
    deleteTodo: (id) => `${activeBaseUrl}/todo/${id}`,
    updateTodo: (id) => `${activeBaseUrl}/todo/${id}`,
  },

  subAdmin: {
    addNewSubAdmin: `${activeBaseUrl}/sub-admin`,
    fetchSubAdminList: (searchKey, download, page) =>
      `${activeBaseUrl}/sub-admin?searchKey=${searchKey}&download=${download}&page=${page}`,
    fetchSpecificSubAdmin: (id) => `${activeBaseUrl}/sub-admin/${id}`,
    updateSpecificSubAdmin: (id) => `${activeBaseUrl}/sub-admin/${id}`,
    deleteSpecificSubAdmin: (id) => `${activeBaseUrl}/sub-admin/${id}`,
    updatePassword: (id) => `${activeBaseUrl}/sub-admin/${id}/password`,
  },
  document: {
    createNewDocument: `${activeBaseUrl}/documents`,
    fetchDocuments: (
      page,
      searchKey,
      download,
      fromDate,
      toDate,
      month,
      year,
      type
    ) =>
      `${activeBaseUrl}/documents?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}&month=${month}&year=${year}&type=${type}`,
    fetchDocumentBYId: (id) => `${activeBaseUrl}/documents/${id}`,
    updateDocument: (id) => `${activeBaseUrl}/documents/${id}`,
    deleteDocumentById: (id) => `${activeBaseUrl}/documents/${id}/archive`,
  },
  archiveDocument: {
    fetchArchiveDocuments: (
      page,
      searchKey,
      download,
      fromDate,
      toDate,
      month,
      year,
      type
    ) =>
      `${activeBaseUrl}/documents/archives?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}&month=${month}&year=${year}&type=${type}`,
    restoreDocument: (id) => `${activeBaseUrl}/documents/${id}/restore`,
    deleteArchive: (id) => `${activeBaseUrl}/documents/${id}/delete`,
  },
  donations: {
    createNewDonation: `${activeBaseUrl}/donations`,
    fetchDonationById: (id) => `${activeBaseUrl}/donations/${id}`,
    updateDonationById: (id) => `${activeBaseUrl}/donations/${id}`,
    deleteDonation: (id) => `${activeBaseUrl}/donations/${id}/archive`,
    fetchDonations: (
      page,
      searchKey,
      download,
      fromDate,
      toDate,
      month,
      year
    ) =>
      `${activeBaseUrl}/donations?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}&month=${month}&year=${year}`,
  },
  assets: {
    createNewAsset: `${activeBaseUrl}/assets`,
    fetchAssets: (
      page,
      searchKey,
      download,
      status,
      fromDate,
      toDate,
      equipmentType
    ) =>
      `${activeBaseUrl}/assets?page=${page}&searchKey=${searchKey}&download=${download}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&equipmentType=${equipmentType}`,
    fetchSpecificAsset: (id) => `${activeBaseUrl}/assets/${id}`,
    updateSpecificAsset: (id) => `${activeBaseUrl}/assets/${id}`,
    deleteSpecificAsset: (id) => `${activeBaseUrl}/assets/${id}/delete`,
  },
  liability: {
    createNewLiability: `${activeBaseUrl}/liabilities`,
    fetchLiabilities: (
      page,
      searchKey,
      download,
      status,
      fromStartDate,
      toStartDate,
      fromEndDate,
      toEndDate,
      liabilityType
    ) =>
      `${activeBaseUrl}/liabilities?page=${page}&searchKey=${searchKey}&download=${download}&status=${status}&fromStartDate=${fromStartDate}&toStartDate=${toStartDate}&fromEndDate=${fromEndDate}&toEndDate=${toEndDate}&liabilityType=${liabilityType}`,
    fetchSpecificLiability: (id) => `${activeBaseUrl}/liabilities/${id}`,
    updateSpecificLiability: (id) => `${activeBaseUrl}/liabilities/${id}`,
    deleteSpecificLiability: (id) =>
      `${activeBaseUrl}/liabilities/${id}/delete`,
  },
  vendor: {
    createVendor: `${activeBaseUrl}/vendors`,
    fetchVendors: (page, searchKey, download, fromDate, toDate, vendorId) =>
      `${activeBaseUrl}/vendors?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}&vendorId=${vendorId}`,
    fetchVendorById: (id) => `${activeBaseUrl}/vendors/${id}`,
    archiveVendorById: (id) => `${activeBaseUrl}/vendor/${id}/archive`,

    updateVendorBasicInfo: (id) => `${activeBaseUrl}/vendors/${id}/basic-info`,
    updateDocuments: (id) => `${activeBaseUrl}/vendors/${id}/documents`,
    updateDepositInformation: (id) => `${activeBaseUrl}/vendors/${id}/deposits`,
    updateDailyMilkSale: (id) =>
      `${activeBaseUrl}/vendors/${id}/daily-milk-sale`,
    fetchDailySales: (
      id,
      page,
      searchKey,
      fromDate,
      toDate,
      download,
      milkSession
    ) =>
      `${activeBaseUrl}/vendors/${id}/daily-milk-sales?page=${page}&searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&download=${download}&milkSession=${milkSession}`,
    addDailySales: (id) => `${activeBaseUrl}/vendors/${id}/daily-milk-sale`,
    fetchDailySalesById: (saleId) =>
      `${activeBaseUrl}/daily-milk-sales/${saleId}`,
    updateSalesById: (saleId) => `${activeBaseUrl}/daily-milk-sales/${saleId}`,
    deleteMilkSaleById: (saleId) =>
      `${activeBaseUrl}/daily-milk-sales/${saleId}`,
    fetchMilkSalesByDate: (
      searchKey,
      date,
      fromDate,
      toDate,
      vendorId,
      milkSession
    ) =>
      `${activeBaseUrl}/daily-milk-sales/date-wise?searchKey=${searchKey}&date=${date}&fromDate=${fromDate}&toDate=${toDate}&vendorId=${vendorId}&milkSession=${milkSession}`,
    uploadBulkMilkSales: `${activeBaseUrl}/daily-milk-sales/bulk-upload`,
    fetchVendorIds: (searchKey) =>
      `${activeBaseUrl}/vendors/vendor-searchkey?searchKey=${searchKey}`,
    fetchPayments: (vendorId, fromDate, toDate, searchKey, page, download) =>
      `${activeBaseUrl}/vendors/payments?vendorId=${vendorId}&fromDate=${fromDate}&toDate=${toDate}&searchKey=${searchKey}&page=${page}&download=${download}`,
    fetchSpecificVendorPayments: (
      id,
      fromDate,
      toDate,
      searchKey,
      page,
      download
    ) =>
      `${activeBaseUrl}/vendors/${id}/payments?fromDate=${fromDate}&toDate=${toDate}&searchKey=${searchKey}&page=${page}&download=${download}`,
    addNewPayment: (id) => `${activeBaseUrl}/vendors/${id}/payments`,
    fetchPaymentById: (id) => `${activeBaseUrl}/vendors/payments/${id}`,
    updatePaymentById: (id) => `${activeBaseUrl}/vendors/payments/${id}`,
    deletePaymentById: (id) => `${activeBaseUrl}/vendors/payments/${id}`,
    regenerateVendorBillRecords: (
      fromMonth,
      toMonth,
      fromMonthShift,
      toMonthShift
    ) =>
      `${activeBaseUrl}/vendor/bills/regenerate?fromMonth=${fromMonth}&toMonth=${toMonth}&fromMonthShift=${fromMonthShift}&toMonthShift=${toMonthShift}`,
    approveRegenerateBillRecords: (fromMonth, toMonth) =>
      `${activeBaseUrl}/bills/approve?fromMonth=${fromMonth}&toMonth=${toMonth}`,
    fetchVendorMilkSalesReports: (
      page,
      searchKey,
      fromDate,
      toDate,
      download,
      vendorId
    ) =>
      `${activeBaseUrl}/vendors/daily-milk-sales?page=${page}&searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&download=${download}&vendorId=${vendorId}`,
    milkPriceChange: {
      createMilkPriceRevision: (id) =>
        `${activeBaseUrl}/vendor/${id}/milk-price-change`,
      fetchMilkRevisions: (id, page, searchKey, download, fromDate, toDate) =>
        `${activeBaseUrl}/vendor/${id}/milk-price-change?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}`,
      fetchMilkRevisionById: (id) => `${activeBaseUrl}/milk-price-change/${id}`,
      updateMilkRevisionById: (id) =>
        `${activeBaseUrl}/milk-price-change/${id}`,
      deleteMilkRevisionById: (id) =>
        `${activeBaseUrl}/milk-price-change/${id}`,
    },

    archiveVendor: {
      fetchArchiveData: (
        page,
        searchKey,
        fromDate,
        toDate,
        vendorId,
        download
      ) =>
        `${activeBaseUrl}/vendor/archive?page=${page}&searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&vendorId=${vendorId}&download=${download}`,
      restoreVendor: (id) => `${activeBaseUrl}/vendor/${id}/restore`,
      deleteVendor: (id) => `${activeBaseUrl}/vendor/${id}/delete`,
    },

    fetchBills: (
      vendorId,
      fromDate,
      toDate,
      searchKey,
      status,
      page,
      download
    ) =>
      `${activeBaseUrl}/bills?vendorId=${vendorId || ""}&fromDate=${
        fromDate || ""
      }&toDate=${toDate || ""}&searchKey=${searchKey || ""}&status=${
        status || ""
      }&page=${page}&download=${download}`,
  },
  fooderManagement: {
    greenFooder: {
      addNewLeaseLand: `${activeBaseUrl}/land-lease/`,
      updateLeaseLand: (id) => `${activeBaseUrl}/land-lease/${id}`,
      fetchLeaseLandList: (
        page,
        searchKey,
        download,
        fromDateStart,
        toDateStart,
        fromDateEnd,
        toDateEnd,
        leaseId
      ) =>
        `${activeBaseUrl}/land-lease/?page=${page}&searchKey=${searchKey}&fromStartDate=${fromDateStart}&toStartDate=${toDateStart}&fromEndDate=${fromDateEnd}&toEndDate=${toDateEnd}&leaseId=${leaseId}&download=${download}`,
      fetchLeaseLandById: (id) => `${activeBaseUrl}/land-lease/${id}`,
      updateLeaseLandBasicInformation: (id) =>
        `${activeBaseUrl}/land-lease/${id}/basic-info`,
      updateLeaseLandFarmerInformation: (id) =>
        `${activeBaseUrl}/land-lease/${id}/farmer-info`,
      addCropInformation: (id) => `${activeBaseUrl}/land/${id}/crops`,
      updateSpecificCrop: (cropId) => `${activeBaseUrl}/crops/${cropId}`,
      fetchAllCrops: (
        page,
        searchKey,
        download,
        fromCropStartDate,
        toCropStartDate,
        fromCropEndDate,
        toCropEndDate,
        landId
      ) => `
      ${activeBaseUrl}/crops?page=${page}&searchKey=${searchKey}&download=${download}&fromCultStartDate=${fromCropStartDate}&toCultStartDate=${toCropStartDate}&fromCultEndDate=${fromCropEndDate}&toCultEndDate=${toCropEndDate}&landId=${landId}`,
      fetchCropDetailsByLandId: (
        id,
        page,
        searchKey,
        download,
        fromStartDate,
        fromEndDate,
        toStartDate,
        toEndDate
      ) =>
        `${activeBaseUrl}/land/${id}/crops?page=${page}&searchKey=${searchKey}&fromCultStartDate=${fromStartDate}&toCultStartDate=${toStartDate}&fromCultEndDate=${fromEndDate}&toCultEndDate=${toEndDate}`,
      fetchCropDetailsByCropId: (cropId) => `${activeBaseUrl}/crops/${cropId}`,
      updateCropDetailsByCropId: (cropId) => `${activeBaseUrl}/crops/${cropId}`,
      deleteCropById: (id) => `${activeBaseUrl}/crops/${id}`,
      addYieldInformation: (id) => `${activeBaseUrl}/crop/${id}/yields`,
      updateYieldInformation: (yieldId) => `${activeBaseUrl}/yields/${yieldId}`,
      deleteYieldInformationByYieldId: (yieldId) =>
        `${activeBaseUrl}/yields/${yieldId}`,
      fetchAllYieldsList: (
        page,
        searchKey,
        download,
        fromYieldDate,
        toYieldDate,
        cropId,
        landId
      ) =>
        `${activeBaseUrl}/yields?page=${page}&searchKey=${searchKey}&download=${download}&fromYieldDate=${fromYieldDate}&toYieldDate=${toYieldDate}&cropId=${cropId}&landId=${landId}`,
      fetchYieldsByLandId: (
        landId,
        page,
        searchKey,
        download,
        fromYieldDate,
        toYieldDate,
        cropId
      ) =>
        `${activeBaseUrl}/lands/${landId}/yields?page=${page}&searchKey=${searchKey}&download=${download}&fromYieldDate=${fromYieldDate}&toYieldDate=${toYieldDate}&cropId=${cropId}`,
      fetchYieldDetailsByYieldId: (yieldId) =>
        `${activeBaseUrl}/yields/${yieldId}`,
      deleteYieldByYieldId: (yieldId) => `${activeBaseUrl}/yields/${yieldId}`,
      fetchCropIds: (searchKey, landId) =>
        `${activeBaseUrl}/crop/ids?searchKey=${searchKey}&landId=${landId}`,
      fetchLandIds: (id, searchKey) => `${activeBaseUrl}/land-lease/${id}`,
      addNewExpense: (landId) => `${activeBaseUrl}/crop/${landId}/expenses`,
      updateExpense: (expenseId) => `${activeBaseUrl}/expenses/${expenseId}`,
      fetchAllExpensesList: (
        page,
        searchKey,
        download,
        fromExpenseDate,
        toExpenseDate,
        cropId,
        landId
      ) =>
        `${activeBaseUrl}/expenses?page=${page}&searchKey=${searchKey}&download=${download}&fromExpenseDate=${fromExpenseDate}&toExpenseDate=${toExpenseDate}&cropId=${cropId}&landId=${landId}`,
      fetchExpensesByLandId: (
        landId,
        page,
        searchKey,
        download,
        fromExpenseDate,
        toExpenseDate,
        cropId
      ) =>
        `${activeBaseUrl}/lands/${landId}/expenses?page=${page}&searchKey=${searchKey}&download=${download}&fromExpenseDate=${fromExpenseDate}&toExpenseDate=${toExpenseDate}&cropId=${cropId}`,
      fetchExpenseDetailsByExpenseId: (expenseId) =>
        `${activeBaseUrl}/expenses/${expenseId}`,
      deleteExpenseByExpenseId: (expenseId) =>
        `${activeBaseUrl}/expenses/${expenseId}`,
      addPurchaseInformation: `${activeBaseUrl}/green-fooder/purchases`,
      fetchPurchaseList: (page, searchKey, download, fromDate, toDate) =>
        `${activeBaseUrl}/green-fooder/purchases?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}`,
      fetchPurchaseDetailsByPurchaseId: (purchaseId) =>
        `${activeBaseUrl}/green-fooder/purchases/${purchaseId}`,
      updatePurchaseInformation: (purchaseId) =>
        `${activeBaseUrl}/green-fooder/purchases/${purchaseId}`,
      deletePurchaseByPurchaseId: (purchaseId) =>
        `${activeBaseUrl}/green-fooder/purchases/${purchaseId}`,

      addNewSaleInformation: (landId) =>
        `${activeBaseUrl}/land/${landId}/sales`,
      updateSpecificSaleInformation: (saleId) =>
        `${activeBaseUrl}/land/sales/${saleId}`,
      fetchAllSalesList: (page, searchKey, download, fromDate, toDate) =>
        `${activeBaseUrl}/land/sales?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}`,
      fetchSaleDetailsBySaleId: (saleId) =>
        `${activeBaseUrl}/land/sales/${saleId}`,
      fetchSalesBySpecificLandId: (
        landId,
        page,
        searchKey,
        download,
        fromDate,
        toDate
      ) =>
        `${activeBaseUrl}/lands/${landId}/sales?page=${page}&searchKey=${searchKey}&download=${download}&fromDate=${fromDate}&toDate=${toDate}`,
      deleteSaleBySaleId: (saleId) => `${activeBaseUrl}/land/sales/${saleId}`,
    },
  },
};

export const EmployeeAuthorURL = {
  milk: {
    fetchMilksByDate: (searchKey, date) =>
      `${employeeBaseUrl}/milks?searchKey=${
        searchKey && searchKey
      }&date=${date}}`,
    bulkMilksUpload: `${employeeBaseUrl}/milk/bulk-upload`,
  },
  animals: {
    fetchMilkingAnimalIds: (searchKey) =>
      `${employeeBaseUrl}/animals/milking?searchKey=${searchKey && searchKey}`,
  },
};
