export const theme = {
  select: {
    defaultProps: {
      variant: "outlined",
      color: "green",
      size: "md",
      label: "",
      error: false,
      success: false,
      arrow: undefined,
      value: undefined,
      onChange: undefined,
      selected: undefined,
      offset: 5,
      dismiss: {},
      animate: {
        unmount: {},
        mount: {},
      },
      autoHeight: false,
      lockScroll: false,
      labelProps: {},
      menuProps: {},
      className: "",
      disabled: false,
      containerProps: undefined,
    },
    valid: {
      variants: ["standard", "outlined", "static"],
      sizes: ["md", "lg"],
      colors: ["lime", "light-green", "green"],
    },
    styles: {
      base: {
        container: {
          position: "relative",
          width: "w-full",
          minWidth: "min-w-[200px]",
        },
        select: {
          peer: "peer",
          width: "w-full",
          height: "h-full",

          color: "text-blue-gray-700",
          fontFamily: "font-sans",
          fontWeight: "font-normal",
          textAlign: "text-left",
          outline: "outline outline-0 focus:outline-0",
          disabled: "disabled:bg-white disabled:border-0",
          transition: "transition-all",
        },
        arrow: {
          initial: {
            display: "grid",
            placeItems: "place-items-center",
            position: "absolute",
            top: "top-2/4",
            right: "right-2",
            pt: "pt-px",
            width: "w-5",
            height: "h-5",
            color: "text-blue-gray-400",
            transform: "rotate-0 -translate-y-2/4",
            transition: "transition-all",
          },
          active: {
            transform: "rotate-180",
            mt: "mt-px",
          },
        },
        label: {
          display: "flex",
          width: "w-full",
          height: "h-full",
          userSelect: "select-none",
          pointerEvents: "pointer-events-none",
          position: "absolute",
          left: "left-0",
          fontWeight: "font-normal",
          transition: "transition-all",
          color: "#7B7B7B",
        },
        menu: {
          width: "w-full",
          maxHeight: "max-h-[15rem]",
          bg: "bg-white",
          p: "p-3",
          border: "border border-blue-gray-50",
          borderRadius: "rounded-md",
          boxShadow: "shadow-lg shadow-blue-gray-500/10",
          fontFamily: "Manrope",
          fontSize: "text-[12px]",
          fontWeight: "font-[600]",
          color: "text-[#7B7B7B]",
          overflow: "overflow-auto",
          outline: "focus:outline-none",
        },
        option: {
          initial: {
            pt: "pt-[9px]",
            pb: "pb-2",
            px: "px-3",
            borderRadius: "rounded-md",
            lightHeight: "leading-tight",
            cursor: "cursor-pointer",
            userSelect: "select-none",
            background:
              "hover:bg-gradient-to-r hover:from-[#9FB947] hover:to-[#739500] focus:bg-[#739500]",
            opacity: "hover:bg-opacity-80 focus:bg-opacity-80",
            color: "hover:text-[#fff] focus:text-[#fff]",
            outline: "outline outline-0",
            transition: "transition-all",
          },
          active: {
            bg: "bg-gradient-to-r from-[#9FB947] to-[#739500] bg-opacity-80",
            color: "text-[#fff]",
          },
          disabled: {
            opacity: "opacity-50",
            cursor: "cursor-not-allowed",
            userSelect: "select-none",
            pointerEvents: "pointer-events-none",
          },
        },
      },
      variants: {
        outlined: {
          base: {
            select: {},
            label: {
              position: "-top-1.5",
              before: {
                content: "before:content[' ']",
                display: "before:block",
                boxSizing: "before:box-border",
                width: "before:w-2.5",
                height: "before:h-1.5",
                mt: "before:mt-[6.5px]",
                mr: "before:mr-1",
                borderRadius: "before:rounded-tl-md",
                pointerEvents: "before:pointer-events-none",
                transition: "before:transition-all",
                disabled: "peer-disabled:before:border-transparent",
              },
              after: {
                content: "after:content[' ']",
                display: "after:block",
                flexGrow: "after:flex-grow",
                boxSizing: "after:box-border",
                width: "after:w-2.5",
                height: "after:h-1.5",
                mt: "after:mt-[6.5px]",
                ml: "after:ml-1",
                borderRadius: "after:rounded-tr-md",
                pointerEvents: "after:pointer-events-none",
                transition: "after:transition-all",
                disabled: "peer-disabled:after:border-transparent",
              },
            },
          },
          sizes: {
            md: {
              container: {
                height: "h-[2.7rem]",
                backgroundColor: "bg-[#fff]",
              },
              select: {
                fontSize: "text-sm",
                px: "px-3",
                py: "py-2.5",
                borderRadius: "rounded-[7px]",
              },
              label: {
                initial: {},

                states: {
                  close: {
                    lineHeight: "leading-[3.9]",
                  },
                  open: {
                    lineHeight: "leading-tight",
                  },
                  withValue: {
                    lineHeight: "leading-tight",
                  },
                },
              },
            },
          },
          colors: {
            select: {},
          },
        },
      },
    },
  },
};
