import { Option, Select } from "@material-tailwind/react";
import "flatpickr/dist/themes/material_green.css";
import moment from "moment";
import { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SelectT from "react-select";
import AsyncSelect from "react-select/async";
import { APIUrls, AdminAuthorURL } from "../../baseUrl/BaseUrl";
import { useAuth } from "../../stores/AuthContext";
import {
  employeeActions,
  filterManageActions,
  filtrationManageActions,
  leaseLandRegistrationActions,
} from "../../stores/IndexRedux";

export const OverAllAnimalFilter = ({ type }) => {
  const {
    fromDate,
    toDate,
    lactationNumber,
    breed,
    employeeId,
    inseminationMinDays,
    inseminationMaxDays,
    lactationMinDays,
    lactationMaxDays,
  } = useSelector((state) => state.filtrationManage.overAllAnimal);
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [breeds, setBreeds] = useState([]);

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  useEffect(() => {
    fetchEmployeeIds("");
    GetBreedanimal();
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  const GetBreedanimal = async () => {
    try {
      const breeds = await fetch(APIUrls.getBreedData);
      const breedJson = await breeds.json();
      if (breeds.ok) {
        setBreeds(breedJson?.response?.breedArray);
      } else {
        setBreeds([]);
      }
      console.log(breedJson);
    } catch (error) {
      setBreeds([]);
    }
  };
  return (
    <div className='flex flex-row flex-wrap justify-between gap-4 '>
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select From Date'
        options={{ ...dateOptions, maxDate: toDate }}
        value={fromDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateOverAllAnimal({
              fromDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select To Date'
        options={{ ...dateOptions, minDate: fromDate }}
        value={toDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateOverAllAnimal({
              toDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />
      {type !== "bull" ? (
        <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
          <Select
            label='Lactation Number'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
            value={lactationNumber}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateOverAllAnimal({
                  lactationNumber: e,
                })
              );
            }}>
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
            <Option value={3}>3</Option>
            <Option value={4}>4</Option>
            <Option value={5}>5</Option>
            <Option value={6}>6</Option>
            <Option value={7}>7</Option>
          </Select>
        </div>
      ) : null}
      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        {breeds.length > 0 ? (
          <div>
            <Select
              label='Select Breed'
              className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateOverAllAnimal({ breed: e })
                );
              }}
              value={breed}>
              {breeds.map((breed, index) => (
                <Option key={index} value={breed}>
                  {breed}
                </Option>
              ))}
            </Select>
          </div>
        ) : (
          <Select
            label='Select Breed'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '>
            <Option>No options</Option>
          </Select>
        )}
      </div>

      <AsyncSelect
        styles={{
          control: (baseStyles, state) => {
            return {
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            };
          },
        }}
        theme={(theme) => {
          return {
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary25: "#e5e7eb",
              primary50: "#e5e7eb",
              primary: "#9FB947",
            },
          };
        }}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateOverAllAnimal({
              employeeId: e,
            })
          );
        }}
        placeholder={"Select Employee"}
        className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
        isClearable
        value={employeeId ? employeeId : null}
        defaultOptions={employees}
        loadOptions={loadOptions}
      />

      {type !== "bull" && (
        <>
          <div className='flex flex-row  items-center  gap-x-[4rem]'>
            <div className='flex flex-col gap-[0.5rem]'>
              <label className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
                Insemination Min Days
              </label>
              <input
                type='number'
                placeholder='Min days'
                value={inseminationMinDays}
                onChange={(e) => {
                  dispatch(
                    filtrationManageActions.updateOverAllAnimal({
                      inseminationMinDays: parseInt(e.target.value),
                    })
                  );
                }}
                className='w-full xs:w-[200px]   outline-none border border-solid border-[#AFBACA] p-[0.5rem] rounded-[0.5rem] cursor-pointer'
              />
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
                {" "}
                Insemination Max Days
              </label>
              <input
                type='number'
                placeholder='Max days'
                value={inseminationMaxDays}
                onChange={(e) => {
                  dispatch(
                    filtrationManageActions.updateOverAllAnimal({
                      inseminationMaxDays: parseInt(e.target.value),
                    })
                  );
                }}
                className='w-full xs:w-[200px] outline-none border border-solid border-[#AFBACA] p-[0.5rem] rounded-[0.5rem] cursor-pointer'
              />
            </div>
          </div>
          <div className='flex flex-row  items-center  gap-x-[4rem]'>
            <div className='flex flex-col gap-[0.5rem]'>
              <label className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
                Lactation Min Days
              </label>
              <input
                type='number'
                placeholder='Min days'
                value={lactationMinDays}
                onChange={(e) => {
                  dispatch(
                    filtrationManageActions.updateOverAllAnimal({
                      lactationMinDays: parseInt(e.target.value),
                    })
                  );
                }}
                className='w-full xs:w-[200px]   outline-none border border-solid border-[#AFBACA] p-[0.5rem] rounded-[0.5rem] cursor-pointer'
              />
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
                {" "}
                Lactation Max Days
              </label>
              <input
                type='number'
                placeholder='Max days'
                value={lactationMaxDays}
                onChange={(e) => {
                  dispatch(
                    filtrationManageActions.updateOverAllAnimal({
                      lactationMaxDays: parseInt(e.target.value),
                    })
                  );
                }}
                className='w-full xs:w-[200px] outline-none border border-solid border-[#AFBACA] p-[0.5rem] rounded-[0.5rem] cursor-pointer'
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export const CalfFilter = () => {
  const { fromDate, toDate, gender, age } = useSelector(
    (state) => state.filtrationManage.calf
  );
  const dispatch = useDispatch();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  return (
    <div className='flex flex-row flex-wrap justify-between gap-4 '>
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select From Date'
        options={{ ...dateOptions, maxDate: toDate }}
        value={fromDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateCalf({
              fromDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select To Date'
        options={{ ...dateOptions, minDate: fromDate }}
        value={toDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateCalf({
              toDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />

      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <Select
          label='Select age'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={age}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateCalf({
                age: e,
              })
            );
          }}>
          <Option value={1}>1</Option>
          <Option value={2}>2</Option>
          <Option value={3}>3</Option>
          <Option value={4}>4</Option>
          <Option value={5}>5</Option>
          <Option value={6}>6</Option>
          <Option value={7}>7</Option>
        </Select>
      </div>
      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <Select
          label='Select gender'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={gender}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateCalf({
                gender: e,
              })
            );
          }}>
          <Option value={"Male"}>Male</Option>
          <Option value={"Female"}>Female</Option>
        </Select>
      </div>
    </div>
  );
};
export const SaleFilter = () => {
  const { date, month, year, shift, type, gender } = useSelector(
    (state) => state.filtrationManage.sale
  );
  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  return (
    <div className='flex flex-row flex-wrap justify-between gap-4 '>
      <div className='flex flex-col  justify-between gap-4 max-xs:w-full'>
        <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row'>
          <button
            onClick={() => {
              dispatch(filtrationManageActions.updateSale({ shift: "date" }));
            }}
            className={`${
              shift === "date" && "bg-[#9FB947] text-[white]"
            } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
            Date
          </button>
          <button
            onClick={() => {
              dispatch(filtrationManageActions.updateSale({ shift: "month" }));
            }}
            className={`${
              shift === "month" && "bg-[#9FB947] text-[white]"
            } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
            Month
          </button>
          <button
            onClick={() => {
              dispatch(filtrationManageActions.updateSale({ shift: "year" }));
            }}
            className={`${
              shift === "year" && "bg-[#9FB947] text-[white]"
            } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
            Year
          </button>
        </div>

        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={dateOptions[shift].label}
          options={dateOptions[shift].dateFormat}
          value={
            shift === "date"
              ? date
              : shift === "month"
              ? month
              : shift === "year"
              ? year
              : ""
          }
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateSale({
                [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
              })
            );
          }}
        />
      </div>
      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <Select
          label='Select gender'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={gender}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateSale({
                gender: e,
              })
            );
          }}>
          <Option value={"Male"}>Male</Option>
          <Option value={"Female"}>Female</Option>
        </Select>
      </div>
      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <Select
          label='Select type'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={type}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateSale({
                type: e,
              })
            );
          }}>
          <Option value={"Animal"}>Animal</Option>
          <Option value={"Calf"}>Calf</Option>
        </Select>
      </div>
    </div>
  );
};
export const DeathFilter = () => {
  const { date, month, year, shift, type, gender } = useSelector(
    (state) => state.filtrationManage.death
  );
  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  return (
    <div className='flex flex-row flex-wrap justify-between gap-4 '>
      <div className='flex flex-col  justify-between gap-4 max-xs:w-full'>
        <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row'>
          <button
            onClick={() => {
              dispatch(filtrationManageActions.updateDeath({ shift: "date" }));
            }}
            className={`${
              shift === "date" && "bg-[#9FB947] text-[white]"
            } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
            Date
          </button>
          <button
            onClick={() => {
              dispatch(filtrationManageActions.updateDeath({ shift: "month" }));
            }}
            className={`${
              shift === "month" && "bg-[#9FB947] text-[white]"
            } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
            Month
          </button>
          <button
            onClick={() => {
              dispatch(filtrationManageActions.updateDeath({ shift: "year" }));
            }}
            className={`${
              shift === "year" && "bg-[#9FB947] text-[white]"
            } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
            Year
          </button>
        </div>

        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={dateOptions[shift].label}
          options={dateOptions[shift].dateFormat}
          value={
            shift === "date"
              ? date
              : shift === "month"
              ? month
              : shift === "year"
              ? year
              : ""
          }
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateDeath({
                [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
              })
            );
          }}
        />
      </div>
      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <Select
          label='Select gender'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={gender}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateDeath({
                gender: e,
              })
            );
          }}>
          <Option value={"Male"}>Male</Option>
          <Option value={"Female"}>Female</Option>
        </Select>
      </div>
      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <Select
          label='Select type'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={type}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateDeath({
                type: e,
              })
            );
          }}>
          <Option value={"Animal"}>Animal</Option>
          <Option value={"Calf"}>Calf</Option>
        </Select>
      </div>
    </div>
  );
};

export const InseminationFilter = () => {
  const {
    fromDate,
    toDate,
    month,
    year,
    shift,
    status,
    animalId,
    employeeId,
    min,
    max,
  } = useSelector((state) => state.filtrationManage.insemination);
  const dispatch = useDispatch();
  const [animals, setAnimals] = useState();
  const [employees, setEmployees] = useState();

  const dateOptions = {
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  useEffect(() => {
    fetchMilkableAnimalIds("");
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchMilkableAnimalIds(inputValue);
    return data;
  };

  const { getAccessToken } = useAuth();
  /**---------------------fetching saleable animals----------------------------- */
  const fetchMilkableAnimalIds = async (animalTagNumber) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        AdminAuthorURL.animals.fetchAnimalIds(
          "UNDEASED",
          "UNSOLD",
          "Female",
          animalTagNumber
        ),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (responseJson.statusCode === 200 && responseJson.response) {
        const result = responseJson.response;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.animalTagNumber,
          value: item.animalTagNumber,
        }));
        setAnimals(newResult);
        return newResult;
      } else {
        setAnimals([]);
        return [];
      }
    } catch (error) {
      setAnimals([]);
    }
  };
  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  // useEffect(() => {
  //   if (min !== "" && max !== "") {
  //     if (min > max) {
  //       alert("Min value should be less than or equal to max value.");
  //     } else if (max < min) {
  //       alert("Max value should be greater than or equal to min value.");
  //     }
  //   }
  // }, [min, max]);

  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row w-full xs:max-w-[200px]'>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updateInsemination({ shift: "range" })
            );
          }}
          className={`${
            shift === "range" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updateInsemination({ shift: "month" })
            );
          }}
          className={`${
            shift === "month" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updateInsemination({ shift: "year" })
            );
          }}
          className={`${
            shift === "year" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button>
      </div>
      <div className='flex flex-row flex-wrap justify-between gap-y-[1rem] gap-x-[4rem] '>
        {shift === "range" ? (
          <>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select From Date'
              options={{ ...dateOptions, maxDate: toDate }}
              value={fromDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateInsemination({
                    fromDate: moment(e[0]).format("YYYY-MM-DD"),
                    toDate,
                  })
                );
              }}
            />
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Date'
              options={{ ...dateOptions, minDate: fromDate }}
              value={toDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateInsemination({
                    toDate: moment(e[0]).format("YYYY-MM-DD"),
                    fromDate,
                  })
                );
              }}
            />
          </>
        ) : shift === "month" || shift === "year" ? (
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={dateOptions[shift].label}
            options={dateOptions[shift].dateFormat}
            value={shift === "month" ? month : shift === "year" ? year : ""}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateInsemination({
                  [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
                })
              );
            }}
          />
        ) : null}
        <div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                  cursor: "pointer",
                };
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#e5e7eb",
                  primary50: "#e5e7eb",
                  primary: "#9FB947",
                },
              };
            }}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateInsemination({
                  employeeId: e,
                })
              );
            }}
            placeholder={"Select Employee"}
            className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={employeeId ? employeeId : null}
            defaultOptions={employees}
            loadOptions={employeeLoadOptions}
          />
        </div>
        <div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                  cursor: "pointer",
                };
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#e5e7eb",
                  primary50: "#e5e7eb",
                  primary: "#9FB947",
                },
              };
            }}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateInsemination({
                  animalId: e,
                })
              );
            }}
            placeholder={"Select Animal"}
            className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={animalId ? animalId : null}
            defaultOptions={animals}
            loadOptions={loadOptions}
          />
        </div>

        <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
          <Select
            label='Select status'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
            value={status}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateInsemination({
                  status: e,
                })
              );
            }}>
            <Option value={"Successful"}>Successful</Option>
            <Option value={"Unsuccessful"}>Unsuccessful</Option>
            <Option value={"Continues"}>Continues</Option>
            <Option value={"Abortion"}>Abortion</Option>
            <Option value={"Uncertain"}>Uncertain</Option>
          </Select>
        </div>
      </div>
      <div className='flex flex-row  items-center  gap-x-[4rem]'>
        <div className='flex flex-col gap-[0.5rem]'>
          <label className='text-[0.9rem] text-[#3D4A5C] font-[500]'>Min</label>
          <input
            type='number'
            placeholder='Min days'
            value={min}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateInsemination({
                  min: parseInt(e.target.value),
                })
              );
            }}
            className='w-full xs:w-[200px]   outline-none border border-solid border-[#AFBACA] p-[0.5rem] rounded-[0.5rem] cursor-pointer'
          />
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label className='text-[0.9rem] text-[#3D4A5C] font-[500]'>Max</label>
          <input
            type='number'
            placeholder='Max days'
            value={max}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateInsemination({
                  max: parseInt(e.target.value),
                })
              );
            }}
            className='w-full xs:w-[200px] outline-none border border-solid border-[#AFBACA] p-[0.5rem] rounded-[0.5rem] cursor-pointer'
          />
        </div>
      </div>
    </div>
  );
};

export const LactationFilter = () => {
  const {
    fromDateStart,
    toDateStart,
    fromDateEnd,
    toDateEnd,
    animalId,
    employeeId,
    lactationNumber,
  } = useSelector((state) => state.filtrationManage.lactation);
  const dispatch = useDispatch();
  const [animals, setAnimals] = useState();
  const [employees, setEmployees] = useState();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  useEffect(() => {
    fetchMilkableAnimalIds("");
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchMilkableAnimalIds(inputValue);
    return data;
  };

  const { getAccessToken } = useAuth();
  /**---------------------fetching saleable animals----------------------------- */
  const fetchMilkableAnimalIds = async (animalTagNumber) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        AdminAuthorURL.animals.fetchAnimalIds(
          "UNDEASED",
          "UNSOLD",
          "Female",
          animalTagNumber
        ),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (responseJson.statusCode === 200 && responseJson.response) {
        const result = responseJson.response;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.animalTagNumber,
          value: item.animalTagNumber,
        }));
        setAnimals(newResult);
        return newResult;
      } else {
        setAnimals([]);
        return [];
      }
    } catch (error) {
      setAnimals([]);
    }
  };

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };
  return (
    <div className='flex flex-col  gap-2 '>
      <div className='flex flex-col xs:flex-row justify-between gap-2'>
        <div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                  cursor: "pointer",
                };
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#e5e7eb",
                  primary50: "#e5e7eb",
                  primary: "#9FB947",
                },
              };
            }}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateLactation({
                  animalId: e,
                })
              );
            }}
            placeholder={"Select Animal"}
            className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={animalId ? animalId : null}
            defaultOptions={animals}
            loadOptions={loadOptions}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
          <Select
            label='Select Lact. No.'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
            value={lactationNumber}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateLactation({
                  lactationNumber: e,
                })
              );
            }}>
            <Option value={"First"}>first</Option>
            <Option value={"Second"}>Second</Option>
            <Option value={"Third"}>Third</Option>
            <Option value={"Fourth"}>Fourth</Option>
            <Option value={"Fifth"}>Fifth</Option>
            <Option value={"Sixth"}>Sixth</Option>
            <Option value={"Seventh"}>Seventh</Option>
          </Select>
        </div>
      </div>
      <div className='flex flex-col xs:flex-row justify-between gap-2'>
        <div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                  cursor: "pointer",
                };
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#e5e7eb",
                  primary50: "#e5e7eb",
                  primary: "#9FB947",
                },
              };
            }}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateLactation({
                  employeeId: e,
                })
              );
            }}
            placeholder={"Select Employee"}
            className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={employeeId ? employeeId : null}
            defaultOptions={employees}
            loadOptions={employeeLoadOptions}
          />
        </div>
      </div>
      <div className='flex flex-col gap-[0.5rem] w-full'>
        <label
          htmlFor='calfTagNumber'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Lactation Start
        </label>
        <div className='flex flex-col xs:flex-row justify-between w-full gap-[1rem]'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toDateStart }}
            value={fromDateStart}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateLactation({
                  fromDateStart: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromDateStart }}
            value={toDateStart}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateLactation({
                  toDateStart: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
        </div>
      </div>
      <div className='flex flex-col gap-[0.5rem] w-full'>
        <label
          htmlFor='calfTagNumber'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Lactation End
        </label>
        <div className='flex flex-col xs:flex-row justify-between w-full gap-[1rem]'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toDateEnd }}
            value={fromDateEnd}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateLactation({
                  fromDateEnd: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromDateEnd }}
            value={toDateEnd}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateLactation({
                  toDateEnd: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const PurchaseFilter = () => {
  const { fromDate, toDate, month, year, shift, type, gender } = useSelector(
    (state) => state.filtrationManage.purchase
  );
  const dispatch = useDispatch();

  const dateOptions = {
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row w-full xs:max-w-[200px]'>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updatePurchase({ shift: "range" })
            );
          }}
          className={`${
            shift === "range" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updatePurchase({ shift: "month" })
            );
          }}
          className={`${
            shift === "month" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        <button
          onClick={() => {
            dispatch(filtrationManageActions.updatePurchase({ shift: "year" }));
          }}
          className={`${
            shift === "year" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button>
      </div>
      <div className='flex flex-row flex-wrap justify-between gap-y-[1rem] gap-x-[4rem] '>
        {shift === "range" ? (
          <>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select From Date'
              options={{ ...dateOptions, maxDate: toDate }}
              value={fromDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updatePurchase({
                    fromDate: moment(e[0]).format("YYYY-MM-DD"),
                    toDate,
                  })
                );
              }}
            />
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Date'
              options={{ ...dateOptions, minDate: fromDate }}
              value={toDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updatePurchase({
                    toDate: moment(e[0]).format("YYYY-MM-DD"),
                    fromDate,
                  })
                );
              }}
            />
          </>
        ) : shift === "month" || shift === "year" ? (
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={dateOptions[shift].label}
            options={dateOptions[shift].dateFormat}
            value={shift === "month" ? month : shift === "year" ? year : ""}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updatePurchase({
                  [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
                })
              );
            }}
          />
        ) : null}

        <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
          <Select
            label='Select gender'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
            value={gender}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updatePurchase({
                  gender: e,
                })
              );
            }}>
            <Option value={"Male"}>Male</Option>
            <Option value={"Female"}>Female</Option>
          </Select>
        </div>
        <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
          <Select
            label='Select type'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
            value={type}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updatePurchase({
                  type: e,
                })
              );
            }}>
            <Option value={"Animal"}>Animal</Option>
            <Option value={"Calf"}>Calf</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};
export const MilkFilter = () => {
  const {
    fromDate,
    toDate,
    month,
    year,
    shift,
    animalId,
    lactationNumber,
    employeeId,
  } = useSelector((state) => state.filtrationManage.milkRecord);
  const dispatch = useDispatch();
  const [animals, setAnimals] = useState();

  const [employees, setEmployees] = useState();

  const dateOptions = {
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  useEffect(() => {
    fetchMilkableAnimalIds("");
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchMilkableAnimalIds(inputValue);
    return data;
  };

  const { getAccessToken } = useAuth();
  /**---------------------fetching saleable animals----------------------------- */
  const fetchMilkableAnimalIds = async (animalTagNumber) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        AdminAuthorURL.animals.fetchAnimalIds(
          "UNDEASED",
          "UNSOLD",
          "Female",
          animalTagNumber
        ),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (responseJson.statusCode === 200 && responseJson.response) {
        const result = responseJson.response;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.animalTagNumber,
          value: item.animalTagNumber,
        }));
        setAnimals(newResult);
        return newResult;
      } else {
        setAnimals([]);
        return [];
      }
    } catch (error) {
      setAnimals([]);
    }
  };

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row w-full xs:max-w-[200px]'>
        <button
          onClick={() => {
            dispatch(filtrationManageActions.updateMilk({ shift: "range" }));
          }}
          className={`${
            shift === "range" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
        <button
          onClick={() => {
            dispatch(filtrationManageActions.updateMilk({ shift: "month" }));
          }}
          className={`${
            shift === "month" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        <button
          onClick={() => {
            dispatch(filtrationManageActions.updateMilk({ shift: "year" }));
          }}
          className={`${
            shift === "year" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button>
      </div>
      <div className='flex flex-row flex-wrap justify-between gap-y-[1rem] gap-x-[4rem] '>
        {shift === "range" ? (
          <>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select From Date'
              options={{ ...dateOptions, maxDate: toDate }}
              value={fromDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateMilk({
                    fromDate: moment(e[0]).format("YYYY-MM-DD"),
                    toDate,
                  })
                );
              }}
            />
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Date'
              options={{ ...dateOptions, minDate: fromDate }}
              value={toDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateMilk({
                    toDate: moment(e[0]).format("YYYY-MM-DD"),
                    fromDate,
                  })
                );
              }}
            />
          </>
        ) : shift === "month" || shift === "year" ? (
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={dateOptions[shift].label}
            options={dateOptions[shift].dateFormat}
            value={shift === "month" ? month : shift === "year" ? year : ""}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateMilk({
                  [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
                })
              );
            }}
          />
        ) : null}

        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateMilk({
                animalId: e,
              })
            );
          }}
          placeholder={"Select Animal"}
          className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={animalId ? animalId : null}
          defaultOptions={animals}
          loadOptions={loadOptions}
        />
        <div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                  cursor: "pointer",
                };
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#e5e7eb",
                  primary50: "#e5e7eb",
                  primary: "#9FB947",
                },
              };
            }}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateMilk({
                  employeeId: e,
                })
              );
            }}
            placeholder={"Select Employee"}
            className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={employeeId ? employeeId : null}
            defaultOptions={employees}
            loadOptions={employeeLoadOptions}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
          <Select
            label='Select type'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
            value={lactationNumber}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateMilk({
                  lactationNumber: e,
                })
              );
            }}>
            <Option value={"First"}>first</Option>
            <Option value={"Second"}>Second</Option>
            <Option value={"Third"}>Third</Option>
            <Option value={"Fourth"}>Fourth</Option>
            <Option value={"Fifth"}>Fifth</Option>
            <Option value={"Sixth"}>Sixth</Option>
            <Option value={"Seventh"}>Seventh</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export const HealthFilter = () => {
  const {
    date,
    month,
    year,
    shift,
    type,
    gender,
    diseaseType,
    animalId,
    employeeId,
  } = useSelector((state) => state.filtrationManage.health);
  const dispatch = useDispatch();
  const [diseases, setDiseases] = useState();
  const [animals, setAnimals] = useState();
  const [employees, setEmployees] = useState();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  /**-------------------------------------------------Fetching global data--------------------------------------------------- */
  const GetBreedanimal = async () => {
    try {
      const breeds = await fetch(APIUrls.getBreedData);
      const breedJson = await breeds.json();
      console.log(breedJson);
      if (breeds.ok) {
        const result = breedJson.response.diseases;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item,
          value: item,
        }));
        console.log(newResult);
        setDiseases(newResult);
      } else {
        setDiseases([]);
      }
    } catch (error) {
      setDiseases([]);
    }
  };

  useEffect(() => {
    GetBreedanimal();
    fetchMilkableAnimalIds("");
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchMilkableAnimalIds(inputValue);
    return data;
  };

  const { getAccessToken } = useAuth();
  /**---------------------fetching saleable animals----------------------------- */
  const fetchMilkableAnimalIds = async (animalTagNumber) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        AdminAuthorURL.animals.fetchAnimalIds(
          "UNDEASED",
          "UNSOLD",
          "",
          animalTagNumber,
          "INCLUDE"
        ),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (responseJson.statusCode === 200 && responseJson.response) {
        const result = responseJson.response;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.animalTagNumber,
          value: item.animalTagNumber,
        }));
        setAnimals(newResult);
        return newResult;
      } else {
        setAnimals([]);
        return [];
      }
    } catch (error) {
      setAnimals([]);
    }
  };
  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-row flex-wrap justify-between gap-4 '>
      <div className='flex flex-col  justify-between gap-4 max-xs:w-full'>
        <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row'>
          <button
            onClick={() => {
              dispatch(filtrationManageActions.updateHealth({ shift: "date" }));
            }}
            className={`${
              shift === "date" ? "bg-[#9FB947] text-[white]" : ""
            } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
            Date
          </button>
          <button
            onClick={() => {
              dispatch(
                filtrationManageActions.updateHealth({ shift: "month" })
              );
            }}
            className={`${
              shift === "month" ? "bg-[#9FB947] text-[white]" : ""
            } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
            Month
          </button>
          <button
            onClick={() => {
              dispatch(filtrationManageActions.updateHealth({ shift: "year" }));
            }}
            className={`${
              shift === "year" ? "bg-[#9FB947] text-[white]" : ""
            } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
            Year
          </button>
        </div>

        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={dateOptions[shift].label}
          options={dateOptions[shift].dateFormat}
          value={
            shift === "date"
              ? date
              : shift === "month"
              ? month
              : shift === "year"
              ? year
              : ""
          }
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateHealth({
                [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
              })
            );
          }}
        />
      </div>

      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <Select
          label='Select gender'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={gender}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateHealth({
                gender: e,
              })
            );
          }}>
          <Option value={"Male"}>Male</Option>
          <Option value={"Female"}>Female</Option>
        </Select>
      </div>

      <AsyncSelect
        styles={{
          control: (baseStyles, state) => {
            return {
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            };
          },
        }}
        theme={(theme) => {
          return {
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary25: "#e5e7eb",
              primary50: "#e5e7eb",
              primary: "#9FB947",
            },
          };
        }}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateHealth({
              animalId: e,
            })
          );
        }}
        placeholder={"Select Animal"}
        className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
        isClearable
        value={animalId ? animalId : null}
        defaultOptions={animals}
        loadOptions={loadOptions}
      />
      <AsyncSelect
        styles={{
          control: (baseStyles, state) => {
            return {
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            };
          },
        }}
        theme={(theme) => {
          return {
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary25: "#e5e7eb",
              primary50: "#e5e7eb",
              primary: "#9FB947",
            },
          };
        }}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateHealth({
              employeeId: e,
            })
          );
        }}
        placeholder={"Select Employee"}
        className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
        isClearable
        value={employeeId ? employeeId : null}
        defaultOptions={employees}
        loadOptions={employeeLoadOptions}
      />
      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <Select
          label='Select type'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={type}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateHealth({
                type: e,
              })
            );
          }}>
          <Option value={"Animal"}>Animal</Option>
          <Option value={"Calf"}>Calf</Option>
        </Select>
      </div>
      <SelectT
        className='w-full xs:w-[200px] cursor-pointer'
        classNamePrefix='select Disease'
        defaultValue={diseaseType}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateHealth({
              diseaseType: e,
            })
          );
        }}
        isLoading={false}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        name='color'
        options={diseases}
        placeholder={"Select Disease"}
        styles={{
          control: (baseStyles, state) => {
            return {
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            };
          },
        }}
        theme={(theme) => {
          return {
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary25: "#e5e7eb",
              primary50: "#e5e7eb",
              primary: "#9FB947",
            },
          };
        }}
      />
    </div>
  );
};

export const PregnancyListFilter = ({ flatpickrRef }) => {
  const { fromDate, toDate, month, year, shift, status, employeeId } =
    useSelector((state) => state.filtrationManage.pregnancy);
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };
  useEffect(() => {
    fetchEmployeeIds("");
  }, []);
  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row w-full xs:max-w-[200px]'>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updatePregnancy({ shift: "range" })
            );
          }}
          className={`${
            shift === "range" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updatePregnancy({ shift: "month" })
            );
          }}
          className={`${
            shift === "month" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        {/* <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updatePregnancy({ shift: "year" })
            );
          }}
          className={`${
            shift === "year" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button> */}
      </div>
      <div className='flex flex-row flex-wrap justify-between gap-y-[1rem] gap-x-[4rem] '>
        {shift === "range" ? (
          <>
            <div>
              <Flatpickr
                ref={flatpickrRef}
                type='date'
                className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
                placeholder='Select From Date'
                options={{ ...dateOptions.date.dateFormat, maxDate: toDate }}
                value={fromDate}
                onChange={(e) => {
                  dispatch(
                    filtrationManageActions.updatePregnancy({
                      fromDate: moment(e[0]).format("YYYY-MM-DD"),
                      toDate,
                    })
                  );
                }}
              />
            </div>
            <div>
              <Flatpickr
                ref={flatpickrRef}
                type='date'
                className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
                placeholder='Select To Date'
                options={{ ...dateOptions.date.dateFormat, minDate: fromDate }}
                value={toDate}
                onChange={(e) => {
                  dispatch(
                    filtrationManageActions.updatePregnancy({
                      toDate: moment(e[0]).format("YYYY-MM-DD"),
                      fromDate,
                    })
                  );
                }}
              />
            </div>
          </>
        ) : shift === "month" || shift === "year" ? (
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={dateOptions[shift].label}
            options={dateOptions[shift].dateFormat}
            value={shift === "month" ? month : shift === "year" ? year : ""}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updatePregnancy({
                  [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
                })
              );
            }}
          />
        ) : null}
      </div>

      <div>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updatePregnancy({
                employeeId: e,
              })
            );
          }}
          placeholder={"Select Employee"}
          className='w-full xs:w-[200px]  focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={employeeId ? employeeId : null}
          defaultOptions={employees}
          loadOptions={employeeLoadOptions}
        />
      </div>
    </div>
  );
};

export const CalvingListFilter = () => {
  const { fromDate, toDate, month, year, shift, employeeId } = useSelector(
    (state) => state.filtrationManage.calving
  );
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);
  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();

      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row w-full xs:max-w-[200px]'>
        <button
          onClick={() => {
            dispatch(filtrationManageActions.updateCalving({ shift: "range" }));
          }}
          className={`${
            shift === "range" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
        <button
          onClick={() => {
            dispatch(filtrationManageActions.updateCalving({ shift: "month" }));
          }}
          className={`${
            shift === "month" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        {/* <button
          onClick={() => {
            dispatch(filtrationManageActions.updateCalving({ shift: "year" }));
          }}
          className={`${
            shift === "year" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button> */}
      </div>
      <div className='flex flex-row flex-wrap justify-between gap-y-[1rem] gap-x-[4rem] '>
        {shift === "range" ? (
          <>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select From Date'
              options={{ ...dateOptions.date.dateFormat, maxDate: toDate }}
              value={fromDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateCalving({
                    fromDate: moment(e[0]).format("YYYY-MM-DD"),
                    toDate,
                  })
                );
              }}
            />
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Date'
              options={{ ...dateOptions.date.dateFormat, minDate: fromDate }}
              value={toDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateCalving({
                    toDate: moment(e[0]).format("YYYY-MM-DD"),
                    fromDate,
                  })
                );
              }}
            />
          </>
        ) : shift === "month" || shift === "year" ? (
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={dateOptions[shift].label}
            options={dateOptions[shift].dateFormat}
            value={shift === "month" ? month : shift === "year" ? year : ""}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateCalving({
                  [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
                })
              );
            }}
          />
        ) : null}
      </div>
      <div>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateCalving({
                employeeId: e,
              })
            );
          }}
          placeholder={"Select Employee"}
          className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={employeeId ? employeeId : null}
          defaultOptions={employees}
          loadOptions={employeeLoadOptions}
        />
      </div>
    </div>
  );
};

export const DryOffFilter = () => {
  const { fromDate, toDate, month, year, shift, employeeId } = useSelector(
    (state) => state.filtrationManage.dryOff
  );
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);
  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();

      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  const regenerateSalaryByMonth = () => {};

  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row w-full xs:max-w-[200px]'>
        <button
          onClick={() => {
            dispatch(filtrationManageActions.updateDryOff({ shift: "range" }));
          }}
          className={`${
            shift === "range" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
        <button
          onClick={() => {
            dispatch(filtrationManageActions.updateDryOff({ shift: "month" }));
          }}
          className={`${
            shift === "month" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        {/* <button
          onClick={() => {
            dispatch(filtrationManageActions.updateDryOff({ shift: "year" }));
          }}
          className={`${
            shift === "year" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button> */}
      </div>
      <div className='flex flex-row flex-wrap justify-between gap-y-[1rem] gap-x-[4rem] '>
        {shift === "range" ? (
          <>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select From Date'
              options={{ ...dateOptions.date.dateFormat, maxDate: toDate }}
              value={fromDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateDryOff({
                    fromDate: moment(e[0]).format("YYYY-MM-DD"),
                    toDate,
                  })
                );
              }}
            />
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Date'
              options={{ ...dateOptions.date.dateFormat, minDate: fromDate }}
              value={toDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateDryOff({
                    toDate: moment(e[0]).format("YYYY-MM-DD"),
                    fromDate,
                  })
                );
              }}
            />
          </>
        ) : shift === "month" || shift === "year" ? (
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={dateOptions[shift].label}
            options={dateOptions[shift].dateFormat}
            value={shift === "month" ? month : shift === "year" ? year : ""}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDryOff({
                  [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
                })
              );
            }}
          />
        ) : null}
      </div>
      <div>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateDryOff({
                employeeId: e,
              })
            );
          }}
          placeholder={"Select Employee"}
          className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={employeeId ? employeeId : null}
          defaultOptions={employees}
          loadOptions={employeeLoadOptions}
        />
      </div>
    </div>
  );
};
export const AwaitingInseminationFilter = () => {
  const { fromDate, toDate, month, year, shift, employeeId } = useSelector(
    (state) => state.filtrationManage.awaitingInsemination
  );
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);
  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();

      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row w-full xs:max-w-[200px]'>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updateAwaitingInsemination({
                shift: "range",
              })
            );
          }}
          className={`${
            shift === "range" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updateAwaitingInsemination({
                shift: "month",
              })
            );
          }}
          className={`${
            shift === "month" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        {/* <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updateAwaitingInsemination({
                shift: "year",
              })
            );
          }}
          className={`${
            shift === "year" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button> */}
      </div>
      <div className='flex flex-row flex-wrap justify-between gap-y-[1rem] gap-x-[4rem] '>
        {shift === "range" ? (
          <>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select From Date'
              options={{ ...dateOptions.date.dateFormat, maxDate: toDate }}
              value={fromDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateAwaitingInsemination({
                    fromDate: moment(e[0]).format("YYYY-MM-DD"),
                    toDate,
                  })
                );
              }}
            />
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Date'
              options={{ ...dateOptions.date.dateFormat, minDate: fromDate }}
              value={toDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updateAwaitingInsemination({
                    toDate: moment(e[0]).format("YYYY-MM-DD"),
                    fromDate,
                  })
                );
              }}
            />
          </>
        ) : shift === "month" || shift === "year" ? (
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={dateOptions[shift].label}
            options={dateOptions[shift].dateFormat}
            value={shift === "month" ? month : shift === "year" ? year : ""}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateAwaitingInsemination({
                  [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
                })
              );
            }}
          />
        ) : null}
      </div>
      <div>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateAwaitingInsemination({
                employeeId: e,
              })
            );
          }}
          placeholder={"Select Employee"}
          className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={employeeId ? employeeId : null}
          defaultOptions={employees}
          loadOptions={employeeLoadOptions}
        />
      </div>
    </div>
  );
};
export const PregnancyCheckupFilter = () => {
  const { fromDate, toDate, month, year, shift, employeeId } = useSelector(
    (state) => state.filtrationManage.pregnancyCheckup
  );
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);
  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();

      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row w-full xs:max-w-[200px]'>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updatePregnancyCheckup({ shift: "range" })
            );
          }}
          className={`${
            shift === "range" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
        <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updatePregnancyCheckup({ shift: "month" })
            );
          }}
          className={`${
            shift === "month" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        {/* <button
          onClick={() => {
            dispatch(
              filtrationManageActions.updatePregnancyCheckup({ shift: "year" })
            );
          }}
          className={`${
            shift === "year" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button> */}
      </div>
      <div className='flex flex-row flex-wrap justify-between gap-y-[1rem] gap-x-[4rem] '>
        {shift === "range" ? (
          <>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select From Date'
              options={{ ...dateOptions.date.dateFormat, maxDate: toDate }}
              value={fromDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updatePregnancyCheckup({
                    fromDate: moment(e[0]).format("YYYY-MM-DD"),
                    toDate,
                  })
                );
              }}
            />
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Date'
              options={{ ...dateOptions.date.dateFormat, minDate: fromDate }}
              value={toDate}
              onChange={(e) => {
                dispatch(
                  filtrationManageActions.updatePregnancyCheckup({
                    toDate: moment(e[0]).format("YYYY-MM-DD"),
                    fromDate,
                  })
                );
              }}
            />
          </>
        ) : shift === "month" || shift === "year" ? (
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={dateOptions[shift].label}
            options={dateOptions[shift].dateFormat}
            value={shift === "month" ? month : shift === "year" ? year : ""}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updatePregnancyCheckup({
                  [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
                })
              );
            }}
          />
        ) : null}
      </div>
      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updatePregnancyCheckup({
                employeeId: e,
              })
            );
          }}
          placeholder={"Select Employee"}
          className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={employeeId ? employeeId : null}
          defaultOptions={employees}
          loadOptions={employeeLoadOptions}
        />
      </div>
    </div>
  );
};

export const DiagnosisFilter = () => {
  const {
    fromCollectionDate,
    toCollectionDate,
    fromResultDate,
    toResultDate,
    status,
    disease,
    searchKey,
    employeeId,
  } = useSelector((state) => state.filtrationManage.diagnosisRecord);
  const dispatch = useDispatch();
  console.log(disease);

  const [employees, setEmployees] = useState();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();

      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;

        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-row flex-wrap justify-between gap-4 '>
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select From Collection Date'
        options={{ ...dateOptions, maxDate: toCollectionDate }}
        value={fromCollectionDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateDiagnosisReport({
              fromCollectionDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select To Collection Date'
        options={{ ...dateOptions, minDate: fromCollectionDate }}
        value={toCollectionDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateDiagnosisReport({
              toCollectionDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />

      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select From Result Date'
        options={{ ...dateOptions, maxDate: toResultDate }}
        value={fromResultDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateDiagnosisReport({
              fromResultDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select To Result Date'
        options={{ ...dateOptions, minDate: fromResultDate }}
        value={toResultDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateDiagnosisReport({
              toResultDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />

      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateDiagnosisReport({
                employeeId: e,
              })
            );
          }}
          placeholder={"Select Employee"}
          className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={employeeId ? employeeId : null}
          defaultOptions={employees}
          loadOptions={employeeLoadOptions}
        />
      </div>
      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <Select
          label='Select Status'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateDiagnosisReport({ status: e })
            );
          }}>
          <Option value={"Positive"}>Positive</Option>
          <Option value={"Negative"}>Negative</Option>
        </Select>
      </div>
      <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
        <Select
          label='Select Disease'
          value={disease}
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateDiagnosisReport({ disease: e })
            );
          }}>
          <Option value={"Brucellosis"}>Brucellosis</Option>
        </Select>
      </div>
    </div>
  );
};

export const DewormingFilter = ({ type }) => {
  const { fromDate, toDate } = useSelector(
    (state) => state.filtrationManage.deworming
  );
  const dispatch = useDispatch();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  return (
    <div className='flex flex-row flex-wrap justify-between gap-4 '>
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select From Date'
        options={{ ...dateOptions, maxDate: toDate }}
        value={fromDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateDeworming({
              fromDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select To Date'
        options={{ ...dateOptions, minDate: fromDate }}
        value={toDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateDeworming({
              toDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />
    </div>
  );
};
export const DueDewormingFilter = () => {
  const {
    vaccineName,
    lastDewormingDateFrom,
    lastDewormingDateTo,
    nextDewormingDateFrom,
    nextDewormingDateTo,
    employeeId,
    animalId,
    gender,
    type,
  } = useSelector((state) => state.filtrationManage.dueDeworming);
  const dispatch = useDispatch();
  const [animals, setAnimals] = useState();
  const [employees, setEmployees] = useState();
  const dewormings = [
    { label: "TRIOVAC", value: "TRIOVAC" },
    { label: "ZYCLOZ", value: "ZYCLOZ" },
  ];

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  useEffect(() => {
    fetchMilkableAnimalIds("");
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchMilkableAnimalIds(inputValue);
    return data;
  };

  const { getAccessToken } = useAuth();
  /**---------------------fetching saleable animals----------------------------- */
  const fetchMilkableAnimalIds = async (animalTagNumber) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        AdminAuthorURL.animals.fetchAnimalIds(
          "UNDEASED",
          "UNSOLD",
          "",
          animalTagNumber,
          "INCLUDE"
        ),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (responseJson.statusCode === 200 && responseJson.response) {
        const result = responseJson.response;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.animalTagNumber,
          value: item.animalTagNumber,
        }));
        setAnimals(newResult);
        return newResult;
      } else {
        setAnimals([]);
        return [];
      }
    } catch (error) {
      setAnimals([]);
    }
  };

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };
  return (
    <div className='flex flex-col  gap-2 '>
      <div className='flex flex-col xs:flex-row justify-between gap-2'>
        <div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                  cursor: "pointer",
                };
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#e5e7eb",
                  primary50: "#e5e7eb",
                  primary: "#9FB947",
                },
              };
            }}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueDeworming({
                  animalId: e,
                })
              );
            }}
            placeholder={"Select Animal"}
            className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={animalId ? animalId : null}
            defaultOptions={animals}
            loadOptions={loadOptions}
          />
        </div>
        <SelectT
          className='w-full xs:w-[200px] cursor-pointer'
          classNamePrefix='select Disease'
          defaultValue={vaccineName}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateDueDeworming({
                vaccineName: e,
              })
            );
          }}
          isLoading={false}
          isClearable={true}
          isRtl={false}
          isSearchable={true}
          name='color'
          options={dewormings}
          placeholder={"Select Deworming"}
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
        />
      </div>
      <div className='flex flex-col xs:flex-row justify-between gap-2'>
        <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
          <Select
            label='Select gender'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
            value={gender}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueDeworming({
                  gender: e,
                })
              );
            }}>
            <Option value={"Male"}>Male</Option>
            <Option value={"Female"}>Female</Option>
          </Select>
        </div>
        <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
          <Select
            label='Select type'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
            value={type}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueDeworming({
                  type: e,
                })
              );
            }}>
            <Option value={"Animal"}>Animal</Option>
            <Option value={"Calf"}>Calf</Option>
          </Select>
        </div>
      </div>

      <div className='flex flex-col xs:flex-row justify-between gap-2'>
        <div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                  cursor: "pointer",
                };
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#e5e7eb",
                  primary50: "#e5e7eb",
                  primary: "#9FB947",
                },
              };
            }}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueDeworming({
                  employeeId: e,
                })
              );
            }}
            placeholder={"Select Employee"}
            className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={employeeId ? employeeId : null}
            defaultOptions={employees}
            loadOptions={employeeLoadOptions}
          />
        </div>
      </div>
      <div className='flex flex-col gap-[0.5rem] w-full'>
        <label
          htmlFor='calfTagNumber'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Last Deworming
        </label>
        <div className='flex flex-col xs:flex-row justify-between w-full gap-[1rem]'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: lastDewormingDateTo }}
            value={lastDewormingDateFrom}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueDeworming({
                  lastDewormingDateFrom: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: lastDewormingDateFrom }}
            value={lastDewormingDateTo}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueDeworming({
                  lastDewormingDateTo: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
        </div>
      </div>
      <div className='flex flex-col gap-[0.5rem] w-full'>
        <label
          htmlFor='calfTagNumber'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Next Deworming
        </label>
        <div className='flex flex-col xs:flex-row justify-between w-full gap-[1rem]'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: nextDewormingDateTo }}
            value={nextDewormingDateFrom}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueDeworming({
                  nextDewormingDateFrom: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: nextDewormingDateFrom }}
            value={nextDewormingDateTo}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueDeworming({
                  nextDewormingDateTo: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const VaccinationFilter = ({ type }) => {
  const { fromDate, toDate } = useSelector(
    (state) => state.filtrationManage.vaccination
  );
  const dispatch = useDispatch();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  return (
    <div className='flex flex-row flex-wrap justify-between gap-4 '>
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select From Date'
        options={{ ...dateOptions, maxDate: toDate }}
        value={fromDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateVaccination({
              fromDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />
      <Flatpickr
        type='date'
        className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
        placeholder='Select To Date'
        options={{ ...dateOptions, minDate: fromDate }}
        value={toDate}
        onChange={(e) => {
          dispatch(
            filtrationManageActions.updateVaccination({
              toDate: moment(e[0]).format("YYYY-MM-DD"),
            })
          );
        }}
      />
    </div>
  );
};

export const DueVaccinationFilter = () => {
  const {
    vaccineName,
    lastVaccinationDateFrom,
    lastVaccinationDateTo,
    nextVaccinationDateFrom,
    nextVaccinationDateTo,
    employeeId,
    animalId,
    gender,
    type,
  } = useSelector((state) => state.filtrationManage.dueVaccination);
  const dispatch = useDispatch();
  const [animals, setAnimals] = useState();
  const [employees, setEmployees] = useState();
  const dewormings = [
    { label: "TRIOVAC", value: "TRIOVAC" },
    { label: "ZYCLOZ", value: "ZYCLOZ" },
  ];

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  useEffect(() => {
    fetchMilkableAnimalIds("");
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchMilkableAnimalIds(inputValue);
    return data;
  };

  const { getAccessToken } = useAuth();
  /**---------------------fetching saleable animals----------------------------- */
  const fetchMilkableAnimalIds = async (animalTagNumber) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        AdminAuthorURL.animals.fetchAnimalIds(
          "UNDEASED",
          "UNSOLD",
          "",
          animalTagNumber,
          "INCLUDE"
        ),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (responseJson.statusCode === 200 && responseJson.response) {
        const result = responseJson.response;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.animalTagNumber,
          value: item.animalTagNumber,
        }));
        setAnimals(newResult);
        return newResult;
      } else {
        setAnimals([]);
        return [];
      }
    } catch (error) {
      setAnimals([]);
    }
  };

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };
  return (
    <div className='flex flex-col  gap-2 '>
      <div className='flex flex-col xs:flex-row justify-between gap-2'>
        <div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                  cursor: "pointer",
                };
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#e5e7eb",
                  primary50: "#e5e7eb",
                  primary: "#9FB947",
                },
              };
            }}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueVaccination({
                  animalId: e,
                })
              );
            }}
            placeholder={"Select Animal"}
            className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={animalId ? animalId : null}
            defaultOptions={animals}
            loadOptions={loadOptions}
          />
        </div>
        <SelectT
          className='w-full xs:w-[200px] cursor-pointer'
          classNamePrefix='select Disease'
          defaultValue={vaccineName}
          onChange={(e) => {
            dispatch(
              filtrationManageActions.updateDueVaccination({
                vaccineName: e,
              })
            );
          }}
          isLoading={false}
          isClearable={true}
          isRtl={false}
          isSearchable={true}
          name='color'
          options={dewormings}
          placeholder={"Select Vaccination"}
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
        />
      </div>
      <div className='flex flex-col xs:flex-row justify-between gap-2'>
        <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
          <Select
            label='Select gender'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
            value={gender}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueVaccination({
                  gender: e,
                })
              );
            }}>
            <Option value={"Male"}>Male</Option>
            <Option value={"Female"}>Female</Option>
          </Select>
        </div>
        <div className='flex flex-col gap-[0.5rem] max-xs:w-full'>
          <Select
            label='Select type'
            className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
            value={type}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueVaccination({
                  type: e,
                })
              );
            }}>
            <Option value={"Animal"}>Animal</Option>
            <Option value={"Calf"}>Calf</Option>
          </Select>
        </div>
      </div>

      <div className='flex flex-col xs:flex-row justify-between gap-2'>
        <div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                  cursor: "pointer",
                };
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#e5e7eb",
                  primary50: "#e5e7eb",
                  primary: "#9FB947",
                },
              };
            }}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueVaccination({
                  employeeId: e,
                })
              );
            }}
            placeholder={"Select Employee"}
            className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={employeeId ? employeeId : null}
            defaultOptions={employees}
            loadOptions={employeeLoadOptions}
          />
        </div>
      </div>
      <div className='flex flex-col gap-[0.5rem] w-full'>
        <label
          htmlFor='calfTagNumber'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Last Vaccination
        </label>
        <div className='flex flex-col xs:flex-row justify-between w-full gap-[1rem]'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: lastVaccinationDateTo }}
            value={lastVaccinationDateFrom}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueVaccination({
                  lastVaccinationDateFrom: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: lastVaccinationDateFrom }}
            value={lastVaccinationDateTo}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueVaccination({
                  lastVaccinationDateTo: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
        </div>
      </div>
      <div className='flex flex-col gap-[0.5rem] w-full'>
        <label
          htmlFor='calfTagNumber'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Next Vaccination
        </label>
        <div className='flex flex-col xs:flex-row justify-between w-full gap-[1rem]'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: nextVaccinationDateTo }}
            value={nextVaccinationDateFrom}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueVaccination({
                  nextVaccinationDateFrom: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: nextVaccinationDateFrom }}
            value={nextVaccinationDateTo}
            onChange={(e) => {
              dispatch(
                filtrationManageActions.updateDueVaccination({
                  nextVaccinationDateTo: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const MilkMeterFilter = () => {
  const {
    fromDate,
    toDate,
    fromMonth,
    toMonth,
    fromYear,
    toYear,

    employeeId,
    shift,
  } = useSelector((state) => state.filtrationManage.milkMeter);
  const dispatch = useDispatch();

  console.log(employeeId, "employee id");

  const [employees, setEmployees] = useState();

  const dateOptions = {
    daily: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    monthly: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    yearly: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);

  const { getAccessToken } = useAuth();

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  const employeeLoadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col gap-2 w-full z-[999]'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row w-full xs:max-w-[200px]'>
        <button
          // onClick={() => {
          //   dispatch(
          //     filtrationManageActions.updateMilkMeter({ shift: "daily" })
          //   );
          // }}
          className={`${
            shift === "daily" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Daily
        </button>
        <button
          // onClick={() => {
          //   dispatch(
          //     filtrationManageActions.updateMilkMeter({ shift: "monthly" })
          //   );
          // }}
          className={`${
            shift === "monthly" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Monthly
        </button>
        <button
          // onClick={() => {
          //   dispatch(
          //     filtrationManageActions.updateMilkMeter({ shift: "yearly" })
          //   );
          // }}
          className={`${
            shift === "yearly" ? "bg-[#9FB947] text-[white]" : ""
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Yearly
        </button>
      </div>
      <div className='flex flex-row flex-wrap justify-between gap-y-[1rem] gap-x-[4rem] '>
        {shift === "daily" ? (
          <>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder={"Select From Date"}
              options={{ ...dateOptions.daily.dateFormat, maxDate: toDate }}
              value={fromDate}
              onChange={(e) => {
                if (e) {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      fromDate: moment(e[0]).format("YYYY-MM-DD"),
                    })
                  );
                } else {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      fromDate: "",
                    })
                  );
                }
              }}
            />
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Date'
              options={{ ...dateOptions.daily.dateFormat, minDate: fromDate }}
              value={toDate}
              onChange={(e) => {
                if (e) {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      toDate: moment(e[0]).format("YYYY-MM-DD"),
                    })
                  );
                } else {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      toDate: "",
                    })
                  );
                }
              }}
            />
          </>
        ) : shift === "monthly" ? (
          <>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder={"Select From Month"}
              options={{ ...dateOptions.monthly.dateFormat, maxDate: toMonth }}
              value={fromMonth}
              onChange={(e) => {
                if (e) {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      fromMonth: moment(e[0]).format("YYYY-MM"),
                    })
                  );
                } else {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      fromMonth: "",
                    })
                  );
                }
              }}
            />
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Month'
              options={{
                ...dateOptions.monthly.dateFormat,
                minDate: fromMonth,
              }}
              value={toMonth}
              onChange={(e) => {
                if (e) {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      toMonth: moment(e[0]).format("YYYY-MM"),
                    })
                  );
                } else {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      toMonth: "",
                    })
                  );
                }
              }}
            />
          </>
        ) : (
          <>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder={"Select From Year"}
              options={{ ...dateOptions.yearly.dateFormat, maxDate: toYear }}
              value={fromYear}
              onChange={(e) => {
                if (e) {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      fromYear: moment(e[0]).format("YYYY"),
                    })
                  );
                } else {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      fromYear: "",
                    })
                  );
                }
              }}
            />
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Year'
              options={{ ...dateOptions.yearly.dateFormat, minDate: fromYear }}
              value={toYear}
              onChange={(e) => {
                if (e) {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      toYear: moment(e[0]).format("YYYY"),
                    })
                  );
                } else {
                  dispatch(
                    filtrationManageActions.updateMilkMeter({
                      toYear: "",
                    })
                  );
                }
              }}
            />
          </>
        )}

        <div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                  cursor: "pointer",
                };
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#e5e7eb",
                  primary50: "#e5e7eb",
                  primary: "#9FB947",
                },
              };
            }}
            onChange={(e) => {
              if (e) {
                dispatch(
                  filtrationManageActions.updateMilkMeter({
                    employeeId: e,
                  })
                );
              } else {
                dispatch(
                  filtrationManageActions.updateMilkMeter({
                    employeeId: "",
                  })
                );
              }
            }}
            placeholder={"Select Employee"}
            className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
            isClearable
            value={employeeId ? employeeId : null}
            defaultOptions={employees}
            loadOptions={employeeLoadOptions}
          />
        </div>
      </div>
    </div>
  );
};

// export const DailyMilkMeter = ({ type }) => {
//   const { fromDate, toDate, employeeId } = useSelector(
//     (state) => state.filtrationManage.dailyMilkMeter
//   );
//   const dispatch = useDispatch();
//   const [employees, setEmployees] = useState([]);

//   const dateOptions = {
//     dateFormat: "Y-m-d",
//   };

//   const fetchEmployeeIds = async (searchKey) => {
//     try {
//       const response = await fetch(
//         `${
//           AdminAuthorURL.employee.fetchEmployeeIds
//         }?searchKey=${searchKey}&normal=${true}`
//       );
//       const responseJson = await response.json();
//       console.log(responseJson);
//       if (
//         responseJson.statusCode === 200 &&
//         responseJson.response &&
//         responseJson.response.employees
//       ) {
//         const result = responseJson.response.employees;
//         console.log(result);
//         const newResult = result.map((item) => ({
//           label: item.employeeName,
//           value: item._id,
//         }));
//         setEmployees(newResult);
//         return newResult;
//       } else {
//         setEmployees([]);
//         return [];
//       }
//     } catch (error) {
//       console.log(error);
//       setEmployees([]);
//     }
//   };

//   const loadOptions = async (inputValue) => {
//     const data = await fetchEmployeeIds(inputValue);
//     return data;
//   };

//   return (
//     <div className='flex flex-row flex-wrap justify-between gap-4 '>
//       <Flatpickr
//         type='date'
//         className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
//         placeholder='Select From Date'
//         options={{ ...dateOptions, maxDate: toDate }}
//         value={fromDate}
//         onChange={(e) => {
//           dispatch(
//             filtrationManageActions.updateDailyMilkMeter({
//               fromDate: moment(e[0]).format("YYYY-MM-DD"),
//             })
//           );
//         }}
//       />
//       <Flatpickr
//         type='date'
//         className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
//         placeholder='Select To Date'
//         options={{ ...dateOptions, minDate: fromDate }}
//         value={toDate}
//         onChange={(e) => {
//           dispatch(
//             filtrationManageActions.updateDailyMilkMeter({
//               toDate: moment(e[0]).format("YYYY-MM-DD"),
//             })
//           );
//         }}
//       />

//       <AsyncSelect
//         menuPortalTarget={document.body}
//         menuPlacement='auto'
//         styles={{
//           menuPortal: (base) => ({
//             ...base,
//             zIndex: 9999,
//           }),
//           control: (baseStyles, state) => {
//             return {
//               ...baseStyles,
//               borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
//               cursor: "pointer",
//             };
//           },
//         }}
//         theme={(theme) => {
//           return {
//             ...theme,
//             borderRadius: 6,
//             colors: {
//               ...theme.colors,
//               primary25: "#e5e7eb",
//               primary50: "#e5e7eb",
//               primary: "#9FB947",
//             },
//           };
//         }}
//         onChange={(e) => {
//           dispatch(
//             filtrationManageActions.updateDailyMilkMeter({
//               employeeId: e,
//             })
//           );
//         }}
//         placeholder={"Select Employee"}
//         className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
//         isClearable
//         value={employeeId ? employeeId : null}
//         defaultOptions={employees}
//         loadOptions={loadOptions}
//       />
//     </div>
//   );
// };

export const EmployeeAttendanceFilter = ({ type }) => {
  const { fromDate, toDate, status, employeeName, date, shift } = useSelector(
    (state) => state.employee.attendanceFilter
  );

  const [employees, setEmployees] = useState([]);

  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row'>
        <button
          onClick={() => {
            dispatch(
              employeeActions.updateDaily({
                shift: "date",
              })
            );
          }}
          className={`${
            shift === "date" && "bg-[#9FB947] text-[white]"
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Date
        </button>
        <button
          onClick={() => {
            dispatch(
              employeeActions.updateAttendanceFilter({
                shift: "range",
              })
            );
          }}
          className={`${
            shift === "range" && "bg-[#9FB947] text-[white]"
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
      </div>
      {shift === "range" && (
        <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={"Select from date"}
            options={dateOptions["date"].dateFormat}
            value={fromDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  employeeActions.updateAttendanceFilter({
                    fromDate: moment(e[0]).format(
                      dateOptions["date"].momentFormat
                    ),
                  })
                );
              } else {
                dispatch(
                  employeeActions.updateAttendanceFilter({
                    fromDate: "",
                  })
                );
              }
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={"Select end date"}
            options={dateOptions["date"].dateFormat}
            value={toDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  employeeActions.updateAttendanceFilter({
                    toDate: moment(e[0]).format(
                      dateOptions["date"].momentFormat
                    ),
                  })
                );
              } else {
                dispatch(
                  employeeActions.updateAttendanceFilter({
                    toDate: "",
                  })
                );
              }
            }}
          />
        </div>
      )}

      {shift === "date" && (
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={dateOptions["date"].dateFormat}
          value={date}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                employeeActions.updateAttendanceFilter({
                  date: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                employeeActions.updateAttendanceFilter({
                  date: "",
                })
              );
            }
          }}
        />
      )}
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          placeholder={"Select Employee"}
          className='w-full xs:w-[200px] shrink-0 focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={employeeName}
          defaultOptions={employees}
          loadOptions={loadOptions}
          onChange={(value) => {
            dispatch(
              employeeActions.updateAttendanceFilter({
                employeeName: value,
              })
            );
          }}
        />
        <Select
          label='Select Status'
          onChange={(value) => {
            if (value) {
              dispatch(
                employeeActions.updateAttendanceFilter({
                  status: value,
                })
              );
            } else {
              dispatch(
                employeeActions.updateAttendanceFilter({
                  status: "",
                })
              );
            }
          }}>
          <Option value={"On Vacation"}>On Vacation</Option>
          <Option value={"Absent"}>Absent</Option>
          <Option value={"Present"}>Present</Option>
          
        </Select>
      </div>
    </div>
  );
};

export const EmployeeAdvanceFilter = ({ type }) => {
  const { fromDate, toDate, employeeName, date, shift } = useSelector(
    (state) => state.employee.advanceFilter
  );

  console.log(shift, "advance shift");

  const [employees, setEmployees] = useState([]);

  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row'>
        <button
          onClick={() => {
            dispatch(
              employeeActions.updateAdvanceFilter({
                shift: "date",
              })
            );
          }}
          className={`${
            shift === "date" && "bg-[#9FB947] text-[white]"
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Date
        </button>
        <button
          onClick={() => {
            dispatch(
              employeeActions.updateAdvanceFilter({
                shift: "range",
              })
            );
          }}
          className={`${
            shift === "range" && "bg-[#9FB947] text-[white]"
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
      </div>
      {shift === "range" && (
        <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={"Select from date"}
            options={dateOptions["date"].dateFormat}
            value={fromDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  employeeActions.updateAdvanceFilter({
                    fromDate: moment(e[0]).format(
                      dateOptions["date"].momentFormat
                    ),
                  })
                );
              } else {
                dispatch(
                  employeeActions.updateAdvanceFilter({
                    fromDate: "",
                  })
                );
              }
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={"Select end date"}
            options={dateOptions["date"].dateFormat}
            value={toDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  employeeActions.updateAdvanceFilter({
                    toDate: moment(e[0]).format(
                      dateOptions["date"].momentFormat
                    ),
                  })
                );
              } else {
                dispatch(
                  employeeActions.updateAdvanceFilter({
                    toDate: "",
                  })
                );
              }
            }}
          />
        </div>
      )}

      {shift === "date" && (
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={dateOptions["date"].dateFormat}
          value={date}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                employeeActions.updateAdvanceFilter({
                  date: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                employeeActions.updateAdvanceFilter({
                  date: "",
                })
              );
            }
          }}
        />
      )}
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          placeholder={"Select Employee"}
          className='w-full xs:w-[200px] shrink-0 focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={employeeName}
          defaultOptions={employees}
          loadOptions={loadOptions}
          onChange={(value) => {
            dispatch(
              employeeActions.updateAdvanceFilter({
                employeeName: value,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export const EmployeeSalaryFilter = ({ type }) => {
  const { startMonth, endMonth, employeeName } = useSelector(
    (state) => state.employee.salaryFilters
  );

  console.log(employeeName, "emppppppppppppppppppppppp");
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [breeds, setBreeds] = useState([]);

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item.employeeName,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  console.log(startMonth, endMonth, "oaia");

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select Start Month"}
          options={dateOptions["month"].dateFormat}
          value={startMonth}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                employeeActions.updateSalaryFilter({
                  startMonth: moment(e[0]).format(
                    dateOptions["month"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                employeeActions.updateSalaryFilter({
                  startMonth: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select end month"}
          options={dateOptions["month"].dateFormat}
          value={endMonth}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                employeeActions.updateSalaryFilter({
                  endMonth: moment(e[0]).format(
                    dateOptions["month"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                employeeActions.updateSalaryFilter({
                  endMonth: "",
                })
              );
            }
          }}
        />
      </div>

      <AsyncSelect
        styles={{
          control: (baseStyles, state) => {
            return {
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            };
          },
        }}
        theme={(theme) => {
          return {
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary25: "#e5e7eb",
              primary50: "#e5e7eb",
              primary: "#9FB947",
            },
          };
        }}
        placeholder={"Select Employee"}
        className='w-full xs:w-[200px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
        isClearable
        value={employeeName}
        defaultOptions={employees}
        loadOptions={loadOptions}
        onChange={(value) => {
          if (value) {
            dispatch(
              employeeActions.updateSalaryFilter({
                employeeName: value,
              })
            );
          } else {
            dispatch(
              employeeActions.updateSalaryFilter({
                employeeName: "",
              })
            );
          }
        }}
      />
    </div>
  );
};

export const EmployeeSalaryGenerationFilter = ({ type }) => {
  const { fromDate, toDate, employeeName, status } = useSelector(
    (state) => state.employee.salaryGenerationFilters
  );

  console.log(employeeName, "emppppppppppppppppppppppp");
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item.employeeName,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select Start Month"}
          options={dateOptions["month"].dateFormat}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                employeeActions.updateSalaryGenerationFilter({
                  fromDate: moment(e[0]).format(
                    dateOptions["month"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                employeeActions.updateSalaryGenerationFilter({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select end month"}
          options={dateOptions["month"].dateFormat}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                employeeActions.updateSalaryGenerationFilter({
                  toDate: moment(e[0]).format(
                    dateOptions["month"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                employeeActions.updateSalaryGenerationFilter({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          placeholder={"Select Employee"}
          className='w-full xs:w-[200px] shrink-0 focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={employeeName}
          defaultOptions={employees}
          loadOptions={loadOptions}
          onChange={(value) => {
            dispatch(
              employeeActions.updateSalaryGenerationFilter({
                employeeName: value,
              })
            );
          }}
        />

        {/* <Select
          label='Select Status'
          onChange={(value) => {
            dispatch(
              employeeActions.updateSalaryGenerationFilter({
                status: value,
              })
            );
          }}>
          <Option value={"PREPARED"}>PREPARED</Option>
          <Option value={"APPROVED"}>APPROVED</Option>
        </Select> */}
      </div>
    </div>
  );
};

export const EmployeeOvertimeAllowanceFilter = ({ type }) => {
  const { fromDate, toDate, employeeName, date, shift } = useSelector(
    (state) => state.employee.overtimeAllowanceFilter
  );

  const [employees, setEmployees] = useState([]);

  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchEmployeeIds = async (searchKey) => {
    try {
      const response = await fetch(
        `${
          AdminAuthorURL.employee.fetchEmployeeIds
        }?searchKey=${searchKey}&normal=${true}`
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.employees
      ) {
        const result = responseJson.response.employees;
        console.log(result);
        const newResult = result.map((item) => ({
          label: item.employeeName,
          value: item._id,
        }));
        setEmployees(newResult);
        return newResult;
      } else {
        setEmployees([]);
        return [];
      }
    } catch (error) {
      console.log(error);
      setEmployees([]);
    }
  };

  useEffect(() => {
    fetchEmployeeIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchEmployeeIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row'>
        <button
          onClick={() => {
            dispatch(
              employeeActions.updateOvertimeAllowanceFilter({
                shift: "date",
              })
            );
          }}
          className={`${
            shift === "date" && "bg-[#9FB947] text-[white]"
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Date
        </button>
        <button
          onClick={() => {
            dispatch(
              employeeActions.updateOvertimeAllowanceFilter({
                shift: "range",
              })
            );
          }}
          className={`${
            shift === "range" && "bg-[#9FB947] text-[white]"
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Range
        </button>
      </div>
      {shift === "range" && (
        <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={"Select from date"}
            options={dateOptions["date"].dateFormat}
            value={fromDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  employeeActions.updateOvertimeAllowanceFilter({
                    fromDate: moment(e[0]).format(
                      dateOptions["date"].momentFormat
                    ),
                  })
                );
              } else {
                dispatch(
                  employeeActions.updateOvertimeAllowanceFilter({
                    fromDate: "",
                  })
                );
              }
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder={"Select end date"}
            options={dateOptions["date"].dateFormat}
            value={toDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  employeeActions.updateOvertimeAllowanceFilter({
                    toDate: moment(e[0]).format(
                      dateOptions["date"].momentFormat
                    ),
                  })
                );
              } else {
                dispatch(
                  employeeActions.updateOvertimeAllowanceFilter({
                    toDate: "",
                  })
                );
              }
            }}
          />
        </div>
      )}

      {shift === "date" && (
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={dateOptions["date"].dateFormat}
          value={date}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                employeeActions.updateOvertimeAllowanceFilter({
                  date: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                employeeActions.updateOvertimeAllowanceFilter({
                  date: "",
                })
              );
            }
          }}
        />
      )}
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          placeholder={"Select Employee"}
          className='w-full xs:w-[200px] shrink-0 focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={employeeName}
          defaultOptions={employees}
          loadOptions={loadOptions}
          onChange={(value) => {
            dispatch(
              employeeActions.updateOvertimeAllowanceFilter({
                employeeName: value,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export const DocumentFilter = () => {
  const { fromDate, toDate, month, year, type, shift } = useSelector(
    (state) => state.filterManage.manage
  );
  const state = useSelector((state) => state?.filterManage);
  console.log("state charity");

  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  return (
    <div className='flex flex-col  justify-between gap-4'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row'>
        <button
          onClick={() => {
            dispatch(
              filterManageActions?.updateDocumentFilter({
                shift: "date",
              })
            );
          }}
          className={`${
            shift === "date" && "bg-[#9FB947] text-[white]"
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Date
        </button>
        <button
          onClick={() => {
            dispatch(
              filterManageActions?.updateDocumentFilter({
                shift: "month",
              })
            );
          }}
          className={`${
            shift === "month" && "bg-[#9FB947] text-[white]"
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        <button
          onClick={() => {
            dispatch(
              filterManageActions?.updateDocumentFilter({
                shift: "year",
              })
            );
          }}
          className={`${
            shift === "year" && "bg-[#9FB947] text-[white]"
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button>
      </div>

      <div
        className={`flex flex-col gap-[1rem] lg:flex-row lg:items-center ${
          shift !== "date" && "hidden"
        } `}>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder='Select From Date'
          options={{ ...dateOptions, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions?.updateDocumentFilter({
                  fromDate: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            } else {
              dispatch(
                filterManageActions?.updateDocumentFilter({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder='Select To Date'
          options={{ ...dateOptions, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions?.updateDocumentFilter({
                  toDate: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            } else {
              dispatch(
                filterManageActions?.updateDocumentFilter({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>

      {shift !== "date" && (
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full  cursor-pointer'
          placeholder={dateOptions[shift].label}
          options={dateOptions[shift].dateFormat}
          value={shift === "month" ? month : shift === "year" ? year : ""}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions?.updateDocumentFilter({
                  [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
                })
              );
            } else {
              dispatch(
                filterManageActions?.updateDocumentFilter({
                  [shift]: "",
                })
              );
            }
          }}
        />
      )}

      <div className='flex flex-col gap-[0.5rem]'>
        <Select
          label='Select type'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={type}
          onChange={(value) => {
            dispatch(filterManageActions.updateDocumentFilter({ type: value }));
          }}>
          <Option value='licences'>Licences</Option>
          <Option value='purchaseReceipt'>Purchase receipt</Option>
          <Option value='salesReceipt'>Sales receipt</Option>
          <Option value='taxDocuments'>Tax documents</Option>
          <Option value='others'>Others</Option>
        </Select>
      </div>
    </div>
  );
};

export const CharityFilter = () => {
  const { fromDate, toDate, month, year, shift } = useSelector(
    (state) => state.filterManage.charityManage
  );

  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  return (
    <div className='flex flex-col  justify-between gap-4'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row'>
        <button
          onClick={() => {
            dispatch(
              filterManageActions?.updateCharityFilter({
                shift: "date",
              })
            );
          }}
          className={`${
            shift === "date" && "bg-[#9FB947] text-[white]"
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Date
        </button>
        <button
          onClick={() => {
            dispatch(
              filterManageActions?.updateCharityFilter({
                shift: "month",
              })
            );
          }}
          className={`${
            shift === "month" && "bg-[#9FB947] text-[white]"
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        <button
          onClick={() => {
            dispatch(
              filterManageActions?.updateCharityFilter({
                shift: "year",
              })
            );
          }}
          className={`${
            shift === "year" && "bg-[#9FB947] text-[white]"
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button>
      </div>

      <div
        className={`flex flex-col gap-[1rem] lg:flex-row lg:items-center ${
          shift !== "date" && "hidden"
        } `}>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder='Select From Date'
          options={{ ...dateOptions, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            dispatch(
              filterManageActions?.updateCharityFilter({
                fromDate: moment(e[0]).format("YYYY-MM-DD"),
              })
            );
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder='Select To Date'
          options={{ ...dateOptions, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            dispatch(
              filterManageActions?.updateCharityFilter({
                toDate: moment(e[0]).format("YYYY-MM-DD"),
              })
            );
          }}
        />
      </div>

      {shift !== "date" && (
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full  cursor-pointer'
          placeholder={dateOptions[shift].label}
          options={dateOptions[shift].dateFormat}
          value={shift === "month" ? month : shift === "year" ? year : ""}
          onChange={(e) => {
            dispatch(
              filterManageActions?.updateCharityFilter({
                [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
              })
            );
          }}
        />
      )}
    </div>
  );
};

export const ArchiveDocumentFilter = () => {
  const { fromDate, toDate, month, year, type, shift } = useSelector(
    (state) => state.filterManage.archiveManage
  );

  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  return (
    <div className='flex flex-col  justify-between gap-4'>
      <div className=' bg-[#F5F4F6]  rounded-md justify-between flex flex-row'>
        <button
          onClick={() => {
            dispatch(
              filterManageActions?.updateArchiveManageFilter({
                shift: "date",
              })
            );
          }}
          className={`${
            shift === "date" && "bg-[#9FB947] text-[white]"
          } text-[#545454]  rounded-md py-1 px-4 font-amulya_medium`}>
          Date
        </button>
        <button
          onClick={() => {
            dispatch(
              filterManageActions?.updateArchiveManageFilter({
                shift: "month",
              })
            );
          }}
          className={`${
            shift === "month" && "bg-[#9FB947] text-[white]"
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Month
        </button>
        <button
          onClick={() => {
            dispatch(
              filterManageActions?.updateArchiveManageFilter({
                shift: "year",
              })
            );
          }}
          className={`${
            shift === "year" && "bg-[#9FB947] text-[white]"
          } text-[#545454] focus:text-[white] rounded-md py-1 px-4 font-amulya_medium`}>
          Year
        </button>
      </div>

      <div
        className={`flex flex-col gap-[1rem] lg:flex-row lg:items-center ${
          shift !== "date" && "hidden"
        } `}>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder='Select From Date'
          options={{ ...dateOptions, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions?.updateArchiveManageFilter({
                  fromDate: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            } else {
              dispatch(
                filterManageActions?.updateArchiveManageFilter({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder='Select To Date'
          options={{ ...dateOptions, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions?.updateArchiveManageFilter({
                  toDate: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            } else {
              dispatch(
                filterManageActions?.updateArchiveManageFilter({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>

      {shift !== "date" && (
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full  cursor-pointer'
          placeholder={dateOptions[shift]?.label}
          options={dateOptions[shift].dateFormat}
          value={shift === "month" ? month : shift === "year" ? year : ""}
          onChange={(e) => {
            dispatch(
              filterManageActions?.updateArchiveManageFilter({
                [shift]: moment(e[0]).format(dateOptions[shift].momentFormat),
              })
            );
          }}
        />
      )}

      <div className='flex flex-col gap-[0.5rem]'>
        <Select
          label='Select type'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={type}
          onChange={(value) => {
            dispatch(
              filterManageActions.updateArchiveManageFilter({ type: value })
            );
          }}>
          <Option value='licences'>Licences</Option>
          <Option value='purchaseReceipt'>Purchase receipt</Option>
          <Option value='salesReceipt'>Sales receipt</Option>
          <Option value='taxDocuments'>Tax documents</Option>
          <Option value='others'>Others</Option>
        </Select>
      </div>
    </div>
  );
};

export const AssetManagementFilter = () => {
  const { fromDate, toDate, status, equipmentType } = useSelector(
    (state) => state.filterManage.archiveManage
  );

  const [shift, setShift] = useState("date");
  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const equipmentTypes = [
    "Tractor",
    "Harvester",
    "Milking Machine",
    "Feed Mixer",
    "Manure Spreader",
    "Irrigation System",
    "ATV/UTV",
    "Truck",
    "Trailer",
    "Other",
  ];

  return (
    <div className='flex flex-col  justify-between gap-4'>
      <div className={`flex flex-col gap-[1rem] lg:flex-row lg:items-center  `}>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder='Select From Date'
          options={{ ...dateOptions, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions?.updateAssetsManageFilter({
                  fromDate: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            } else {
              dispatch(
                filterManageActions?.updateAssetsManageFilter({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder='Select To Date'
          options={{ ...dateOptions, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions?.updateAssetsManageFilter({
                  toDate: moment(e[0]).format("YYYY-MM-DD"),
                })
              );
            } else {
              dispatch(
                filterManageActions?.updateAssetsManageFilter({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>

      <div className='flex flex-col gap-[0.5rem]  lg:flex-row lg:items-center'>
        <Select
          label='Select equipment type'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={equipmentType}
          onChange={(value) => {
            dispatch(
              filterManageActions?.updateAssetsManageFilter({
                equipmentType: value,
              })
            );
          }}>
          {equipmentTypes?.map((item) => (
            <Option value={item}>{item}</Option>
          ))}
        </Select>
        <Select
          label='Select status'
          className='text-[0.9rem] text-[#3D4A5C] font-[500] w-full '
          value={status}
          onChange={(value) => {
            dispatch(
              filterManageActions.updateAssetsManageFilter({
                status: value,
              })
            );
          }}>
          <Option value={"operational"}>Operational</Option>
          <Option value={"underMaintenance"}>Under Maintenance</Option>
          <Option value={"retired"}>Retired</Option>
        </Select>
      </div>
    </div>
  );
};

export const LiabilityManagementFilter = () => {
  const {
    fromStartDate,
    toStartDate,
    fromEndDate,
    toEndDate,
    status,
    liabilityType,
  } = useSelector((state) => state?.filterManage?.liabilityManage);
  const dispatch = useDispatch();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  return (
    <div className='flex flex-col  gap-2 h-fit overflow-y-auto'>
      <div className='flex flex-col gap-[0.5rem]  sm:flex-row lg:items-center'>
        <div className='flex flex-col gap-[1rem] col-span-2 md:col-span-1'>
          <label
            htmlFor='status'
            className='text-[0.65rem] sm:text-[0.75rem] lg:text-[0.875rem]'>
            Status
          </label>
          <div className='flex flex-col gap-[0.5rem]'>
            <Select
              onChange={(value) => {
                dispatch(
                  filterManageActions.updateLiabilityManageFilter({
                    status: value,
                  })
                );
              }}
              id='status'
              label='Select status'
              value={status}>
              <Option value={"Active"}>Active</Option>
              <Option value={"Closed"}>Closed</Option>
            </Select>
          </div>
        </div>
        <div className='flex flex-col gap-[1rem] col-span-2 md:col-span-1'>
          <label
            htmlFor='status'
            cla
            ssName='text-[0.65rem] sm:text-[0.75rem] lg:text-[0.875rem]'>
            Liability Type
          </label>
          <div className='flex flex-col gap-[0.5rem]'>
            <Select
              onChange={(value) => {
                dispatch(
                  filterManageActions.updateLiabilityManageFilter({
                    liabilityType: value,
                  })
                );
              }}
              label='Select Liability Type'
              value={liabilityType}>
              <Option value={"Secured"}>Secured</Option>
              <Option value={"Unsecured"}>Unsecured</Option>
            </Select>
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-[0.5rem] w-full'>
        <label
          htmlFor='calfTagNumber'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Liability Start
        </label>
        <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toStartDate }}
            value={fromStartDate}
            onChange={(e) => {
              console.log(e[0], "from date from start");

              if (e[0]) {
                dispatch(
                  filterManageActions.updateLiabilityManageFilter({
                    fromStartDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  filterManageActions.updateLiabilityManageFilter({
                    fromStartDate: "",
                  })
                );
              }
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromStartDate }}
            value={toStartDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  filterManageActions.updateLiabilityManageFilter({
                    toStartDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  filterManageActions.updateLiabilityManageFilter({
                    toStartDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>
      <div className='flex flex-col gap-[0.5rem] w-full'>
        <label htmlFor='' className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Liability End
        </label>
        <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toEndDate }}
            value={fromEndDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  filterManageActions.updateLiabilityManageFilter({
                    fromEndDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  filterManageActions.updateLiabilityManageFilter({
                    fromEndDate: "",
                  })
                );
              }
            }}
          />
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromEndDate }}
            value={toEndDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  filterManageActions.updateLiabilityManageFilter({
                    toEndDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  filterManageActions.updateLiabilityManageFilter({
                    toEndDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const DailyMilkSalesManagementFilter = () => {
  const { fromDate, toDate, milkSession } = useSelector(
    (state) => state?.filterManage?.dailySalesManage
  );
  const dispatch = useDispatch();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  return (
    <div className='flex flex-col  gap-2  '>
      <div className='flex flex-col gap-[0.5rem] w-full'>
        <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
          <div className='flex flex-col gap-[0.5rem] w-full'>
            <label
              htmlFor='fromDate'
              className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
              From Date
            </label>

            <Flatpickr
              type='date'
              id='toDate'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select From Date'
              options={{ ...dateOptions, maxDate: toDate }}
              value={fromDate}
              onChange={(e) => {
                console.log(e[0], "from date from start");

                if (e[0]) {
                  dispatch(
                    filterManageActions.updateMilkSalesManageFilter({
                      fromDate: moment(e[0]).format("YYYY-MM-DD"),
                    })
                  );
                } else {
                  dispatch(
                    filterManageActions.updateMilkSalesManageFilter({
                      fromDate: "",
                    })
                  );
                }
              }}
            />
          </div>
          <div className='flex flex-col gap-[0.5rem] w-full'>
            <label
              htmlFor='toDate'
              className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
              To Date
            </label>

            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Date'
              options={{ ...dateOptions, minDate: fromDate }}
              value={toDate}
              onChange={(e) => {
                if (e[0]) {
                  dispatch(
                    filterManageActions.updateMilkSalesManageFilter({
                      toDate: moment(e[0]).format("YYYY-MM-DD"),
                    })
                  );
                } else {
                  dispatch(
                    filterManageActions.updateMilkSalesManageFilter({
                      toDate: "",
                    })
                  );
                }
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <Select
          onChange={(value) => {
            dispatch(
              filterManageActions.updateMilkSalesManageFilter({
                milkSession: value,
              })
            );
          }}
          label='Session'
          className=''>
          <Option value='Morning'>Morning</Option>
          <Option value='Evening'>Evening</Option>
        </Select>
      </div>
    </div>
  );
};

export const DailyMilkBulkUploadSalesFilter = () => {
  const { fromDate, toDate, milkSession, vendorId } = useSelector(
    (state) => state?.filterManage?.bulkDailyMilkSales
  );
  const dispatch = useDispatch();
  const [vendors, setVendors] = useState([]);

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  const { getAccessToken } = useAuth();

  const fetchVendorIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchVendorIds(searchKey);
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const responseJson = await response.json();
      console.log(responseJson, "vendor ids");
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.vendors
      ) {
        const result = responseJson.response.vendors;
        console.log(result, "vendors details");
        const newResult = result.map((item) => ({
          label: item.name,
          value: item._id,
        }));

        setVendors(newResult);

        return newResult;
      } else {
        setVendors([]);

        return [];
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    fetchVendorIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchVendorIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col  gap-2'>
      <div className='flex flex-col gap-[0.5rem] w-full'>
        <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
          <div className='flex flex-col gap-[0.5rem] w-full'>
            <label
              htmlFor='fromDate'
              className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
              From Date
            </label>
            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select From Date'
              options={{ ...dateOptions, maxDate: toDate }}
              value={fromDate}
              onChange={(e) => {
                console.log(e[0], "from date from start");

                if (e[0]) {
                  dispatch(
                    filterManageActions.updateBulkDailyMilkSales({
                      fromDate: moment(e[0]).format("YYYY-MM-DD"),
                    })
                  );
                  dispatch(
                    filterManageActions.updateBulkDailyMilkSales({
                      date: "",
                    })
                  );
                } else {
                  dispatch(
                    filterManageActions.updateBulkDailyMilkSales({
                      fromDate: "",
                    })
                  );
                }
              }}
            />
          </div>

          <div className='flex flex-col gap-[0.5rem] w-full'>
            <label
              htmlFor='toDate'
              className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
              To Date
            </label>

            <Flatpickr
              type='date'
              className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
              placeholder='Select To Date'
              options={{ ...dateOptions, minDate: fromDate }}
              value={toDate}
              onChange={(e) => {
                if (e[0]) {
                  dispatch(
                    filterManageActions.updateBulkDailyMilkSales({
                      toDate: moment(e[0]).format("YYYY-MM-DD"),
                    })
                  );
                  dispatch(
                    filterManageActions.updateBulkDailyMilkSales({
                      date: "",
                    })
                  );
                } else {
                  dispatch(
                    filterManageActions.updateBulkDailyMilkSales({
                      toDate: "",
                    })
                  );
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Select
          onChange={(value) => {
            if (value) {
              dispatch(
                filterManageActions.updateBulkDailyMilkSales({
                  milkSession: value,
                })
              );
            } else {
              dispatch(
                filterManageActions.updateBulkDailyMilkSales({
                  milkSession: "",
                })
              );
            }
          }}
          label='Session'
          className=''>
          <Option value='Morning'>Morning</Option>
          <Option value='Evening'>Evening</Option>
        </Select>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          placeholder={"Select Vendor"}
          className='w-full xs:w-[200px] shrink-0 focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={vendorId}
          defaultOptions={vendors}
          loadOptions={loadOptions}
          onChange={(value) => {
            if (value) {
              dispatch(
                filterManageActions.updateBulkDailyMilkSales({
                  vendorId: value,
                })
              );
            } else {
              dispatch(
                filterManageActions.updateBulkDailyMilkSales({
                  vendorId: "",
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export const VendorPaymentsFilter = () => {
  const { fromDate, toDate, vendorId } = useSelector(
    (state) => state.filterManage?.vendorPayments
  );

  const [vendors, setVendors] = useState([]);

  const dispatch = useDispatch();

  const { getAccessToken } = useAuth();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchVendorIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchVendorIds(searchKey);
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const responseJson = await response.json();
      console.log(responseJson, "vendor ids");
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.vendors
      ) {
        const result = responseJson.response.vendors;
        console.log(result, "vendors details");
        const newResult = result.map((item) => ({
          label: item.name,
          value: item._id,
        }));

        setVendors(newResult);

        return newResult;
      } else {
        setVendors([]);

        return [];
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    fetchVendorIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchVendorIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={{ ...dateOptions["date"].dateFormat, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateVendorPayments({
                  fromDate: moment(e[0]).format(
                    dateOptions["date"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateVendorPayments({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select end date"}
          options={{ ...dateOptions["date"].dateFormat, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateVendorPayments({
                  toDate: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateVendorPayments({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>

      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          placeholder={"Select Vendor"}
          className='w-full xs:w-[200px] shrink-0 focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={vendorId}
          defaultOptions={vendors}
          loadOptions={loadOptions}
          onChange={(value) => {
            if (value) {
              dispatch(
                filterManageActions.updateVendorPayments({
                  vendorId: value,
                })
              );
            } else {
              dispatch(
                filterManageActions.updateVendorPayments({
                  vendorId: "",
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export const SpecificVendorPaymentFilter = () => {
  const { fromDate, toDate } = useSelector(
    (state) => state.filterManage?.specificVendorPayments
  );

  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={{ ...dateOptions["date"].dateFormat, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateSpecificVendorPayments({
                  fromDate: moment(e[0]).format(
                    dateOptions["date"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateSpecificVendorPayments({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select end date"}
          options={{ ...dateOptions["date"].dateFormat, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateSpecificVendorPayments({
                  toDate: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateSpecificVendorPayments({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export const VendorArchiveFilter = () => {
  const { fromDate, toDate, vendorId } = useSelector(
    (state) => state.filterManage?.archiveVendor
  );

  const [vendors, setVendors] = useState([]);

  const dispatch = useDispatch();

  const { getAccessToken } = useAuth();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchVendorIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchVendorIds(searchKey);
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const responseJson = await response.json();
      console.log(responseJson, "vendor ids");
      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.vendors
      ) {
        const result = responseJson.response.vendors;
        console.log(result, "vendors details");
        const newResult = result.map((item) => ({
          label: item.name,
          value: item._id,
        }));

        setVendors(newResult);

        return newResult;
      } else {
        setVendors([]);

        return [];
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    fetchVendorIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchVendorIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={{ ...dateOptions["date"].dateFormat, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateArchiveVendorFilter({
                  fromDate: moment(e[0]).format(
                    dateOptions["date"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateArchiveVendorFilter({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select end date"}
          options={{ ...dateOptions["date"].dateFormat, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateArchiveVendorFilter({
                  toDate: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateArchiveVendorFilter({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export const VendorTotalBillsFilter = () => {
  const { fromDate, toDate, vendorId } = useSelector(
    (state) => state.filterManage?.vendorBills
  );

  const [vendors, setVendors] = useState([]);

  const dispatch = useDispatch();

  const { getAccessToken } = useAuth();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchVendorIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchVendorIds(searchKey);
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.vendors
      ) {
        const result = responseJson.response.vendors;

        const newResult = result.map((item) => ({
          label: item.name,
          value: item._id,
        }));

        setVendors(newResult);

        return newResult;
      } else {
        setVendors([]);

        return [];
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    fetchVendorIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchVendorIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={{ ...dateOptions["date"].dateFormat, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateVendorBillsFilter({
                  fromDate: moment(e[0]).format(
                    dateOptions["date"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateVendorBillsFilter({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select end date"}
          options={{ ...dateOptions["date"].dateFormat, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateVendorBillsFilter({
                  toDate: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateVendorBillsFilter({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>

      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          placeholder={"Select Vendor"}
          className='w-full xs:w-[200px] shrink-0 focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={vendorId}
          defaultOptions={vendors}
          loadOptions={loadOptions}
          onChange={(value) => {
            if (value) {
              dispatch(
                filterManageActions.updateVendorBillsFilter({
                  vendorId: value,
                })
              );
            } else {
              dispatch(
                filterManageActions.updateVendorBillsFilter({
                  vendorId: "",
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export const VendorManageFilter = () => {
  const { fromDate, toDate, vendorId } = useSelector(
    (state) => state.filterManage?.manageVendor
  );

  const [vendors, setVendors] = useState([]);

  const dispatch = useDispatch();

  const { getAccessToken } = useAuth();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchVendorIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchVendorIds(searchKey);
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.vendors
      ) {
        const result = responseJson.response.vendors;

        const newResult = result.map((item) => ({
          label: item.name,
          value: item._id,
        }));

        setVendors(newResult);

        return newResult;
      } else {
        setVendors([]);

        return [];
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    fetchVendorIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchVendorIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={{ ...dateOptions["date"].dateFormat, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateManageVendorFilter({
                  fromDate: moment(e[0]).format(
                    dateOptions["date"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateManageVendorFilter({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select end date"}
          options={{ ...dateOptions["date"].dateFormat, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateManageVendorFilter({
                  toDate: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateManageVendorFilter({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>

      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
                color: "#000",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          placeholder={"Select Vendor"}
          className='w-full xs:w-[200px] shrink-0 focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={vendorId}
          defaultOptions={vendors}
          loadOptions={loadOptions}
          onChange={(value) => {
            if (value) {
              dispatch(
                filterManageActions.updateManageVendorFilter({
                  vendorId: value,
                })
              );
            } else {
              dispatch(
                filterManageActions.updateManageVendorFilter({
                  vendorId: "",
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export const VendorBillGenerationFilter = () => {
  const { fromDate, toDate, vendorId } = useSelector(
    (state) => state.filterManage?.vendorBillGeneration
  );

  const [vendors, setVendors] = useState([]);

  const dispatch = useDispatch();

  const { getAccessToken } = useAuth();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchVendorIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchVendorIds(searchKey);
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.vendors
      ) {
        const result = responseJson.response.vendors;

        const newResult = result.map((item) => ({
          label: item.name,
          value: item._id,
        }));

        setVendors(newResult);

        return newResult;
      } else {
        setVendors([]);

        return [];
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    fetchVendorIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchVendorIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={{ ...dateOptions["date"].dateFormat, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateVendorBillGeneration({
                  fromDate: moment(e[0]).format(
                    dateOptions["date"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateVendorBillGeneration({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select end date"}
          options={{ ...dateOptions["date"].dateFormat, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateVendorBillGeneration({
                  toDate: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateVendorBillGeneration({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>

      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
                color: "#000",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          placeholder={"Select Vendor"}
          className='w-full xs:w-[200px] shrink-0 focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={vendorId}
          defaultOptions={vendors}
          loadOptions={loadOptions}
          onChange={(value) => {
            if (value) {
              dispatch(
                filterManageActions.updateVendorBillGeneration({
                  vendorId: value,
                })
              );
            } else {
              dispatch(
                filterManageActions.updateVendorBillGeneration({
                  vendorId: "",
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export const MilkPriceRevisionFilter = () => {
  const { fromDate, toDate } = useSelector(
    (state) => state.filterManage?.milkPriceRevision
  );

  const dispatch = useDispatch();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={{ ...dateOptions["date"].dateFormat, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateMilkPriceRevisions({
                  fromDate: moment(e[0]).format(
                    dateOptions["date"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateMilkPriceRevisions({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select end date"}
          options={{ ...dateOptions["date"].dateFormat, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateMilkPriceRevisions({
                  toDate: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateMilkPriceRevisions({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export const VendorDailySalesReports = () => {
  const { fromDate, toDate, vendorId } = useSelector(
    (state) => state.filterManage?.dailySalesReports
  );

  const [vendors, setVendors] = useState([]);

  const dispatch = useDispatch();

  const { getAccessToken } = useAuth();

  const dateOptions = {
    date: {
      label: "Select Date",
      momentFormat: "YYYY-MM-DD",
      dateFormat: {
        dateFormat: "Y-m-d",
      },
    },
    month: {
      label: "Select Month",
      momentFormat: "YYYY-MM",

      dateFormat: {
        dateFormat: "Y-m",
      },
    },
    year: {
      label: "Select Year",
      momentFormat: "YYYY",

      dateFormat: {
        dateFormat: "Y",
      },
    },
  };

  const fetchVendorIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.vendor.fetchVendorIds(searchKey);
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (
        responseJson.statusCode === 200 &&
        responseJson.response &&
        responseJson.response.vendors
      ) {
        const result = responseJson.response.vendors;

        const newResult = result.map((item) => ({
          label: item.name,
          value: item._id,
        }));

        setVendors(newResult);

        return newResult;
      } else {
        setVendors([]);

        return [];
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    fetchVendorIds("");
  }, []);

  const loadOptions = async (inputValue) => {
    const data = await fetchVendorIds(inputValue);
    return data;
  };

  return (
    <div className='flex flex-col   gap-[1rem] '>
      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select from date"}
          options={{ ...dateOptions["date"].dateFormat, maxDate: toDate }}
          value={fromDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateDailySalesReports({
                  fromDate: moment(e[0]).format(
                    dateOptions["date"].momentFormat
                  ),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateDailySalesReports({
                  fromDate: "",
                })
              );
            }
          }}
        />
        <Flatpickr
          type='date'
          className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
          placeholder={"Select end date"}
          options={{ ...dateOptions["date"].dateFormat, minDate: fromDate }}
          value={toDate}
          onChange={(e) => {
            if (e[0]) {
              dispatch(
                filterManageActions.updateDailySalesReports({
                  toDate: moment(e[0]).format(dateOptions["date"].momentFormat),
                })
              );
            } else {
              dispatch(
                filterManageActions.updateDailySalesReports({
                  toDate: "",
                })
              );
            }
          }}
        />
      </div>

      <div className='flex flex-col gap-[1rem] sm:flex-row sm:items-center'>
        <AsyncSelect
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
                cursor: "pointer",
                color: "#000",
              };
            },
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          placeholder={"Select Vendor"}
          className='w-full xs:w-[200px] shrink-0 focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          value={vendorId}
          defaultOptions={vendors}
          loadOptions={loadOptions}
          onChange={(value) => {
            if (value) {
              dispatch(
                filterManageActions.updateDailySalesReports({
                  vendorId: value,
                })
              );
            } else {
              dispatch(
                filterManageActions.updateDailySalesReports({
                  vendorId: "",
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export const LeaseLandListFilter = () => {
  const { fromDateStart, toDateStart, fromDateEnd, toDateEnd, leaseId } =
    useSelector((state) => state?.leaseLand?.leaseLandFilters);
  const dispatch = useDispatch();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  return (
    <div className='flex flex-col  gap-2 h-fit overflow-y-auto'>
      <div className='flex flex-col gap-[0.5rem]'>
        <label
          htmlFor='leaseId'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Lease ID
        </label>
        <Select
          onChange={(value) => {
            dispatch(
              leaseLandRegistrationActions.updateCropsList({
                leaseId: value,
              })
            );
          }}
          label='Select Lease Id'
          value={leaseId}>
          <Option value={"Secured"}>Secured</Option>
          <Option value={"Unsecured"}>Unsecured</Option>
        </Select>
      </div>

      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From Start Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toDateStart }}
            value={fromDateStart}
            onChange={(e) => {
              console.log(e[0], "from date from start");

              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateLeaseLandFilter({
                    fromDateStart: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateLeaseLandFilter({
                    fromDateStart: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From End Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromDateStart }}
            value={toDateStart}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateLeaseLandFilter({
                    toDateStart: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateLeaseLandFilter({
                    toStartDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>

      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label htmlFor='' className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            End From Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toDateEnd }}
            value={fromDateEnd}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateLeaseLandFilter({
                    fromDateEnd: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateLeaseLandFilter({
                    fromDateEnd: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label htmlFor='' className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            End To Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromDateEnd }}
            value={toDateEnd}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateLeaseLandFilter({
                    toDateEnd: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateLeaseLandFilter({
                    toDateEnd: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const SpecificLandCropsListFilter = () => {
  const { fromStartDate, fromEndDate, toStartDate, toEndDate } = useSelector(
    (state) => state?.leaseLand?.specificLandCropsList
  );
  const dispatch = useDispatch();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  return (
    <div className='flex flex-col  gap-2 h-fit overflow-y-auto'>
      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From Start Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toStartDate }}
            value={fromStartDate}
            onChange={(e) => {
              console.log(e[0], "from date from start");

              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandCropList({
                    fromStartDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandCropList({
                    fromStartDate: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From To Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromStartDate }}
            value={toStartDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandCropList({
                    toStartDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandCropList({
                    toStartDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>

      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label htmlFor='' className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            End From Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: fromEndDate }}
            value={fromEndDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandCropList({
                    fromEndDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandCropList({
                    fromEndDate: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label htmlFor='' className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            End To Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromEndDate }}
            value={toEndDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandCropList({
                    toEndDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateLeaseLandFilter({
                    toEndDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const TotalCropsListFilter = () => {
  const { fromDateStart, toDateStart, fromDateEnd, toDateEnd, leaseId } =
    useSelector((state) => state?.leaseLand?.cropsListFilter);
  const dispatch = useDispatch();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  return (
    <div className='flex flex-col  gap-2 h-fit overflow-y-auto'>
      <div className='flex flex-col gap-[0.5rem]'>
        <label
          htmlFor='leaseId'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Lease ID
        </label>
        <Select
          onChange={(value) => {
            dispatch(
              leaseLandRegistrationActions.updateCropsList({
                leaseId: value,
              })
            );
          }}
          label='Select Lease Id'
          value={leaseId}>
          <Option value={"Secured"}>Secured</Option>
          <Option value={"Unsecured"}>Unsecured</Option>
        </Select>
      </div>

      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From Start Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toDateStart }}
            value={fromDateStart}
            onChange={(e) => {
              console.log(e[0], "from date from start");

              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateCropsList({
                    fromDateStart: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateCropsList({
                    fromDateStart: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From End Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromDateStart }}
            value={toDateStart}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateCropsList({
                    toDateStart: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateCropsList({
                    toStartDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>

      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label htmlFor='' className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            End From Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toDateEnd }}
            value={fromDateEnd}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateCropsList({
                    fromDateEnd: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateCropsList({
                    fromDateEnd: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label htmlFor='' className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            End To Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromDateEnd }}
            value={toDateEnd}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateCropsList({
                    toDateEnd: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateCropsList({
                    toDateEnd: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const TotalYieldListFilter = () => {
  const { fromYieldDate, toYieldDate, cropId, landId } = useSelector(
    (state) => state?.leaseLand?.yieldListFilter
  );
  const dispatch = useDispatch();

  const [cropIds, setCropIds] = useState([]);
  const [landIds, setLandIds] = useState([]);

  const { getAccessToken } = useAuth();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  const fetchCropsIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchCropIds(
        searchKey || "",
        ""
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.ok) {
        const updatedData = responseData?.response?.crops?.map((item) => ({
          label: `${item?.id}-${item?.cropName}`,
          value: item?._id,
        }));

        console.log(updatedData, "updated data");

        setCropIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  const fetchLandIds = async (searchKey) => {
    try {
      const token = await getAccessToken();

      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchLandIds(
        "",
        searchKey
      );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(responseData, "land ids");
      console.log(response, "land ids response");

      if (response.ok) {
        const updatedData = responseData?.response?.limitedData?.map(
          (item) => ({
            label: `${item?.id}`,
            value: item?._id,
          })
        );

        setLandIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  useEffect(() => {
    fetchCropsIds("");

    fetchLandIds("");
  }, []);

  const loadCropDetails = async (key) => {
    const options = await fetchCropsIds(key);

    return options;
  };

  const loadLandDetails = async (key) => {
    const options = await fetchLandIds(key);

    return options;
  };

  return (
    <div className='flex flex-col  gap-2 h-fit overflow-y-auto'>
      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From Yield Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toYieldDate }}
            value={fromYieldDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateYieldListFilter({
                    fromYieldDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateYieldListFilter({
                    fromYieldDate: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            To Yield Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromYieldDate }}
            value={toYieldDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateYieldListFilter({
                    toYieldDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateYieldListFilter({
                    toYieldDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>

      <div className='flex flex-col gap-[0.5rem]'>
        <label
          htmlFor='landId'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Land ID
        </label>
        <AsyncSelect
          menuPortalTarget={document.body}
          menuPlacement='auto'
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            menu: (provided, state) => ({
              ...provided,
              width: "auto",
              minWidth: "100%",
            }),
            menuList: (provided, state) => ({
              ...provided,
              maxHeight: "200px",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            }),
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          value={landId}
          onChange={(value) => {
            if (value) {
              dispatch(
                leaseLandRegistrationActions.updateYieldListFilter({
                  landId: value,
                })
              );
            } else {
              dispatch(
                leaseLandRegistrationActions.updateYieldListFilter({
                  landId: "",
                })
              );
            }
          }}
          className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          defaultOptions={landIds}
          loadOptions={loadLandDetails}
        />
      </div>
      <div className='flex flex-col gap-[0.5rem]'>
        <label
          htmlFor='cropId'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Crop ID
        </label>
        <AsyncSelect
          menuPortalTarget={document.body}
          menuPlacement='auto'
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            menu: (provided, state) => ({
              ...provided,
              width: "auto",
              minWidth: "100%",
            }),
            menuList: (provided, state) => ({
              ...provided,
              maxHeight: "200px",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            }),
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          value={cropId}
          onChange={(value) => {
            if (value) {
              dispatch(
                leaseLandRegistrationActions.updateYieldListFilter({
                  cropId: value,
                })
              );
            } else {
              dispatch(
                leaseLandRegistrationActions.updateYieldListFilter({
                  cropId: "",
                })
              );
            }
          }}
          className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          defaultOptions={cropIds}
          loadOptions={loadCropDetails}
        />
      </div>
    </div>
  );
};

export const TotalExpensesListFilter = () => {
  const { fromExpenseDate, toExpenseDate, landId, cropId } = useSelector(
    (state) => state?.leaseLand?.expenseListFilter
  );
  const dispatch = useDispatch();

  const [cropIds, setCropIds] = useState([]);
  const [landIds, setLandIds] = useState([]);

  const { getAccessToken } = useAuth();

  const { id } = useParams();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  const fetchCropsIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchCropIds(
        searchKey || "",
        ""
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.ok) {
        const updatedData = responseData?.response?.crops?.map((item) => ({
          label: `${item?.id}-${item?.cropName}`,
          value: item?._id,
        }));

        console.log(updatedData, "updated data");

        setCropIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  const fetchLandIds = async (searchKey) => {
    try {
      const token = await getAccessToken();

      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchLandIds(
        "",
        searchKey
      );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(responseData, "land ids");
      console.log(response, "land ids response");

      if (response.ok) {
        const updatedData = responseData?.response?.limitedData?.map(
          (item) => ({
            label: `${item?.id}`,
            value: item?._id,
          })
        );

        setLandIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  useEffect(() => {
    fetchCropsIds("");

    fetchLandIds("");
  }, []);

  const loadCropDetails = async (key) => {
    const options = await fetchCropsIds(key);

    return options;
  };

  const loadLandDetails = async (key) => {
    const options = await fetchLandIds(key);

    return options;
  };

  return (
    <div className='flex flex-col  gap-2 h-fit overflow-y-auto'>
      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From Expense Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toExpenseDate }}
            value={fromExpenseDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateExpenseListFilter({
                    fromExpenseDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateExpenseListFilter({
                    fromExpenseDate: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            To Expense Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromExpenseDate }}
            value={toExpenseDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateExpenseListFilter({
                    toExpenseDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateExpenseListFilter({
                    toExpenseDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>
      <div className='flex flex-col gap-[0.5rem]'>
        <label
          htmlFor='landId'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Land ID
        </label>
        <AsyncSelect
          menuPortalTarget={document.body}
          menuPlacement='auto'
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            menu: (provided, state) => ({
              ...provided,
              width: "auto",
              minWidth: "100%",
            }),
            menuList: (provided, state) => ({
              ...provided,
              maxHeight: "200px",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            }),
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          value={landId}
          onChange={(value) => {
            if (value) {
              dispatch(
                leaseLandRegistrationActions.updateExpenseListFilter({
                  landId: value,
                })
              );
            } else {
              dispatch(
                leaseLandRegistrationActions.updateExpenseListFilter({
                  landId: "",
                })
              );
            }
          }}
          className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          defaultOptions={landIds}
          loadOptions={loadLandDetails}
        />
      </div>

      <div className='flex flex-col gap-[0.5rem]'>
        <label
          htmlFor='cropId'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Crop ID
        </label>
        <AsyncSelect
          menuPortalTarget={document.body}
          menuPlacement='auto'
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            menu: (provided, state) => ({
              ...provided,
              width: "auto",
              minWidth: "100%",
            }),
            menuList: (provided, state) => ({
              ...provided,
              maxHeight: "200px",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            }),
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          value={cropId}
          onChange={(value) => {
            if (value) {
              dispatch(
                leaseLandRegistrationActions.updateExpenseListFilter({
                  cropId: value,
                })
              );
            } else {
              dispatch(
                leaseLandRegistrationActions.updateExpenseListFilter({
                  cropId: "",
                })
              );
            }
          }}
          className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          defaultOptions={cropIds}
          loadOptions={loadCropDetails}
        />
      </div>
    </div>
  );
};

export const SpecificLandYieldFilter = () => {
  const { fromYieldDate, toYieldDate, cropId } = useSelector(
    (state) => state?.leaseLand?.specificLandYieldsList
  );
  const [cropIds, setCropIds] = useState([]);
  const { getAccessToken } = useAuth();
  const dispatch = useDispatch();

  const { id } = useParams();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  const fetchCropsIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchCropIds(
        searchKey || "",
        id
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.ok) {
        const updatedData = responseData?.response?.crops?.map((item) => ({
          label: `${item?.id}-${item?.cropName}`,
          value: item?._id,
        }));

        console.log(updatedData, "updated data");

        setCropIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  useEffect(() => {
    fetchCropsIds("");
  }, []);

  const loadCropDetails = async (key) => {
    const options = await fetchCropsIds(key);

    return options;
  };

  console.log(cropIds, "crops ids");

  return (
    <div className='flex flex-col  gap-2 h-fit overflow-y-auto'>
      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From Yield Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toYieldDate }}
            value={fromYieldDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateYieldListFilter({
                    fromYieldDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandYieldsList({
                    fromYieldDate: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            To Yield Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromYieldDate }}
            value={toYieldDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandYieldsList({
                    toYieldDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandYieldsList({
                    toYieldDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>

      <div className='flex flex-col gap-[0.5rem]'>
        <label
          htmlFor='cropId'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Crop ID
        </label>
        <AsyncSelect
          menuPortalTarget={document.body}
          menuPlacement='auto'
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            menu: (provided, state) => ({
              ...provided,
              width: "auto",
              minWidth: "100%",
            }),
            menuList: (provided, state) => ({
              ...provided,
              maxHeight: "200px",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            }),
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          onChange={(e) => {
            if (e) {
              dispatch(
                leaseLandRegistrationActions.updateSpecificLandYieldsList({
                  cropId: e?.value,
                })
              );
            } else {
              dispatch(
                leaseLandRegistrationActions.updateSpecificLandYieldsList({
                  cropId: e?.value,
                })
              );
            }
          }}
          className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          defaultOptions={cropIds}
          loadOptions={loadCropDetails}
        />
      </div>
    </div>
  );
};

export const SpecificLandExpensesListFilter = () => {
  const { fromExpenseDate, toExpenseDate, cropId } = useSelector(
    (state) => state?.leaseLand?.specificLandExpensesList
  );
  const dispatch = useDispatch();

  const [cropIds, setCropIds] = useState([]);
  const [landIds, setLandIds] = useState([]);

  const { getAccessToken } = useAuth();

  const { id } = useParams();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  const fetchCropsIds = async (searchKey) => {
    try {
      const token = await getAccessToken();
      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchCropIds(
        searchKey || "",
        ""
      );
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.ok) {
        const updatedData = responseData?.response?.crops?.map((item) => ({
          label: `${item?.id}-${item?.cropName}`,
          value: item?._id,
        }));

        console.log(updatedData, "updated data");

        setCropIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  const fetchLandIds = async (searchKey) => {
    try {
      const token = await getAccessToken();

      const url = AdminAuthorURL.fooderManagement.greenFooder.fetchLandIds(
        id,
        searchKey
      );

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const responseData = await response.json();

      console.log(responseData, "land ids");
      console.log(response, "land ids response");

      if (response.ok) {
        const updatedData = responseData?.response?.limitedData?.map(
          (item) => ({
            label: `${item?.id}`,
            value: item?._id,
          })
        );

        setLandIds(updatedData);

        return updatedData;
      } else {
        return [];
      }
    } catch (e) {
      // handle error
    }
  };

  useEffect(() => {
    fetchCropsIds("");

    fetchLandIds("");
  }, []);

  const loadCropDetails = async (key) => {
    const options = await fetchCropsIds(key);

    return options;
  };

  const loadLandDetails = async (key) => {
    const options = await fetchLandIds(key);

    return options;
  };

  return (
    <div className='flex flex-col  gap-2 h-fit overflow-y-auto'>
      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From Expense Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toExpenseDate }}
            value={fromExpenseDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandExpensesList({
                    fromExpenseDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandExpensesList({
                    fromExpenseDate: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            To Expense Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromExpenseDate }}
            value={toExpenseDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandExpensesList({
                    toExpenseDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateSpecificLandExpensesList({
                    toExpenseDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>

      <div className='flex flex-col gap-[0.5rem]'>
        <label
          htmlFor='cropId'
          className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
          Crop ID
        </label>
        <AsyncSelect
          menuPortalTarget={document.body}
          menuPlacement='auto'
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            menu: (provided, state) => ({
              ...provided,
              width: "auto",
              minWidth: "100%",
            }),
            menuList: (provided, state) => ({
              ...provided,
              maxHeight: "200px",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#9FB947" : "#AFBACA",
              cursor: "pointer",
            }),
          }}
          theme={(theme) => {
            return {
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#e5e7eb",
                primary50: "#e5e7eb",
                primary: "#9FB947",
              },
            };
          }}
          value={cropId}
          onChange={(value) => {
            if (value) {
              dispatch(
                leaseLandRegistrationActions.updateSpecificLandExpensesList({
                  cropId: value,
                })
              );
            } else {
              dispatch(
                leaseLandRegistrationActions.updateSpecificLandExpensesList({
                  cropId: "",
                })
              );
            }
          }}
          className='min-w-[220px] focus:border-[#AFBACA] outline-none active:border-[#AFBACA] cursor-pointer'
          isClearable
          defaultOptions={cropIds}
          loadOptions={loadCropDetails}
        />
      </div>
    </div>
  );
};

export const TotalPurchasesListFilter = () => {
  const { fromDate, toDate } = useSelector(
    (state) => state?.leaseLand?.purchasesList
  );
  const dispatch = useDispatch();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  return (
    <div className='flex flex-col  gap-2 h-fit overflow-y-auto'>
      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toDate }}
            value={fromDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updatePurchasesListFilter({
                    fromDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updatePurchasesListFilter({
                    fromDate: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            To Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromDate }}
            value={toDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updatePurchasesListFilter({
                    toDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updatePurchasesListFilter({
                    toDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const TotalSalesListFilter = () => {
  const { fromDate, toDate } = useSelector(
    (state) => state?.leaseLand?.saleInformationList
  );
  const dispatch = useDispatch();

  const dateOptions = {
    dateFormat: "Y-m-d",
  };

  return (
    <div className='flex flex-col  gap-2 h-fit overflow-y-auto'>
      <div className='flex flex-col sm:flex-row justify-between w-full gap-[1rem]'>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            From Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select From Date'
            options={{ ...dateOptions, maxDate: toDate }}
            value={fromDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateSaleInformationListFilter({
                    fromDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateSaleInformationListFilter({
                    fromDate: "",
                  })
                );
              }
            }}
          />
        </div>
        <div className='flex flex-col gap-[0.5rem] w-full'>
          <label
            htmlFor='calfTagNumber'
            className='text-[0.9rem] text-[#3D4A5C] font-[500]'>
            To Date
          </label>
          <Flatpickr
            type='date'
            className='border  border-[#AFBACA] rounded-md px-[1rem] py-[0.5rem] text-[#3D4A5C] text-[0.9rem] placeholder-[#8897AE] w-full xs:w-[200px] cursor-pointer'
            placeholder='Select To Date'
            options={{ ...dateOptions, minDate: fromDate }}
            value={toDate}
            onChange={(e) => {
              if (e[0]) {
                dispatch(
                  leaseLandRegistrationActions.updateSaleInformationListFilter({
                    toDate: moment(e[0]).format("YYYY-MM-DD"),
                  })
                );
              } else {
                dispatch(
                  leaseLandRegistrationActions.updateSaleInformationListFilter({
                    toDate: "",
                  })
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};




