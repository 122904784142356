import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  basicInformation: {
    animalTagNumber: null,
    animalBreed: null,
    animalDateOfBirth: null,
    animalGender: "Male",
    animalImageFileName: null,
    assignedEmployee: null,
    motherTagNumber: null,
    fatherTagNumber: null,
    fatherBreed: null,
    currentMilkStatus: null,
    milkQuantityInMorning: null,
    milkQuantityInEvening: null,
  },
  calfHistory: {
    calfTagNumber: null,
    calfDateOfBirth: null,
    calfGender: null,
    calfWeight: null,
    calfLife: "Alive",
    calfImageFileName: null,
  },
  lactationHistory: {
    lactationNumber: null,
    lactationStart: null,
    lactationEnd: null,
    dryPeriod: null,
    lactationRemarks: null,
  },
  inseminationHistory: {
    inseminationDate: null,
    bullBreed: null,
    inseminationMethod: null,
    detectionMethod: null,
    treatmentTime: null,
    InseminationRemarks: null,
    inseminationStatus: null,
    spermaNo: null,
    _AISId: null,
    embryoId: null,
    maternalId: null,
    paternalId: null,
  },
  purchaseHistory: {
    purchaseDate: null,
    purchaseAmount: null,
    purchaseOwnerName: null,
    purchaseOwnerContactNumber: null,
    purchasePlace: null,
    purchaseCity: null,
    purchaseState: null,
  },
  healthHistory: {
    diseaseObservedDate: null,
    diseaseType: null,
    medicinesUsed: [],
    curedDate: null,
    healthRemarks: null,
  },
  formSaveLoaderBtn: false,
  animalBreeds: [],
  sperms: [],
  diseases: [],
  medicines: [],
  spermError: null,
};

const calfInitialState = {
  basicInformation: {
    calfTagNumber: null,
    calfBreed: null,
    calfDateOfBirth: null,
    calfGender: "Male",
    calfImageFileName: [],
    assignedEmployee: null,
    motherTagNumber: null,
    fatherTagNumber: null,
    calfWeight: null,
    calfLife: "Alive",
  },
  purchaseHistory: {
    purchaseDate: null,
    purchaseAmount: null,
    purchaseOwnerName: null,
    purchaseOwnerContactNumber: null,
    purchasePlace: null,
    purchaseCity: null,
    purchaseState: null,
  },
  healthHistory: {
    diseaseObservedDate: null,
    diseaseType: null,
    medicinesUsed: [],
    curedDate: null,
    healthRemarks: null,
    calfFirstDewormingDate: null,
    calfFirstDewormingMedicinesUsed: [],
    calfSecondDewormingDate: null,
    calfSecondDewormingMedicinesUsed: [],
  },
  formSaveLoaderBtn: false,
};

export const animalRegistrationSlice = createSlice({
  name: "animalRegistration",
  initialState,
  reducers: {
    updateBasicInformation(state, action) {
      state.basicInformation = { ...state.basicInformation, ...action.payload };
    },
    updateCalfHistory(state, action) {
      state.calfHistory = {
        ...state.calfHistory,
        ...action.payload,
      };
    },
    updateLactationHistory(state, action) {
      state.lactationHistory = {
        ...state.lactationHistory,
        ...action.payload,
      };
    },
    updateInseminationHistory(state, action) {
      state.inseminationHistory = {
        ...state.inseminationHistory,
        ...action.payload,
      };
    },
    updatePurchaseHistory(state, action) {
      state.purchaseHistory = {
        ...state.purchaseHistory,
        ...action.payload,
      };
    },
    updateHealthHistory(state, action) {
      state.healthHistory = {
        ...state.healthHistory,
        ...action.payload,
      };
    },
    updateAnimalBreeds(state, action) {
      state.animalBreeds = action.payload.animalBreeds;
    },
    updateSperms(state, action) {
      state.sperms = action.payload.sperms;
      state.spermError = action.payload.spermError;
    },
    updateDisease(state, action) {
      state.diseases = action.payload.diseases;
    },
    updateMedicine(state, action) {
      state.medicines = action.payload.medicines;
    },
    deleteAnimalImage(state, action) {
      state.basicInformation = {
        ...state.basicInformation,
        animalImageFileName: null,
      };
    },
    deleteCalfImage(state, action) {
      state.calfHistory = {
        ...state.calfHistory,
        calfImageFileName: null,
      };
    },
    formSaveLoaderBtnUpdate(state, action) {
      state.formSaveLoaderBtn = action.payload.buttonState;
    },
    resetForm(state, action) {
      state.basicInformation = {
        ...state.basicInformation,
        animalTagNumber: null,
        animalBreed: null,
        animalDateOfBirth: null,
        animalGender: "Male",
        animalImageFileName: null,
        assignedEmployee: null,
        motherTagNumber: null,
        fatherTagNumber: null,
        fatherBreed: null,
        currentMilkStatus: null,
        milkQuantityInMorning: null,
        milkQuantityInEvening: null,
      };
      state.calfHistory = {
        ...state.calfHistory,
        calfTagNumber: null,
        calfDateOfBirth: null,
        calfGender: null,
        calfWeight: null,
        calfImageFileName: null,
        calfLife: "Alive",
      };
      state.lactationHistory = {
        ...state.lactationHistory,
        lactationNumber: null,
        lactationStart: null,
        lactationEnd: null,
        dryPeriod: null,
        lactationRemarks: null,
      };
      state.inseminationHistory = {
        ...state.inseminationHistory,
        inseminationDate: null,
        bullBreed: null,
        inseminationMethod: null,
        detectionMethod: null,
        treatmentTime: null,
        InseminationRemarks: null,
        inseminationStatus: null,
      };
      state.purchaseHistory = {
        ...state.purchaseHistory,
        purchaseDate: null,
        purchaseAmount: null,
        purchaseOwnerName: null,
        purchaseOwnerContactNumber: null,
        purchasePlace: null,
        purchaseCity: null,
        purchaseState: null,
      };
      state.healthHistory = {
        ...state.healthHistory,
        diseaseObservedDate: null,
        diseaseType: null,
        medicinesUsed: [],
        curedDate: null,
        healthRemarks: null,
      };
    },
  },
});

export const calfRegistrationSlice = createSlice({
  name: "calfRegistration",
  initialState: calfInitialState,
  reducers: {
    updateBasicInformation(state, action) {
      state.basicInformation = { ...state.basicInformation, ...action.payload };
    },
    updatePurchaseHistory(state, action) {
      state.purchaseHistory = {
        ...state.purchaseHistory,
        ...action.payload,
      };
    },
    updateHealthHistory(state, action) {
      state.healthHistory = {
        ...state.healthHistory,
        ...action.payload,
      };
    },
    deleteCalfImage(state, action) {
      state.basicInformation = {
        ...state.basicInformation,
        calfImageFileName: action.payload,
      };
    },

    formSaveLoaderBtnUpdate(state, action) {
      state.formSaveLoaderBtn = action.payload.buttonState;
    },
    resetForm(state, action) {
     return calfInitialState
    },
  },
});
